// @flow

import { fromJS } from 'immutable';

import { crudResourceActionTypes } from './actions';

import type { ReduxActionType } from 'types';

/*
 *
 * CRUDResource reducer
 *
 * crudServiceReducer: Switch statement to set state based on current action type
 *
 */

const crudServiceReducer = (resourceName: string) => (
    state: Object = fromJS({
        list: {},
        errors: {},
    }),
    action: ReduxActionType
) => {
    switch (action.type) {
        case crudResourceActionTypes((resourceName: string)).FETCH_LIST_SUCCESS: {
            const list = action.payload.data;
            return state.set('list', fromJS(list)).set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string)).FETCH_LIST_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        default:
            return state;
    }
};

export default crudServiceReducer;
