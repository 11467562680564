// @flow

import React from 'react';
import { injectIntl } from 'react-intl';
import Select from 'react-select';
import { List } from 'immutable';

// Styles
import 'react-select/dist/react-select.css';

// Types
import type { ImmutableList, ImmutableMap, IntlType } from 'types';

type OptionType = {
    label: string,
    value: string,
    disabled?: boolean,
};

type DefaultProps = {
    values?: ImmutableList<ImmutableMap<string, string>>,
};

type Props = DefaultProps & {
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    name: string,
    options: Array<OptionType>,
    onChange: Function,
    defaultOption?: string,
};

type State = {
    values: Array<OptionType>,
};

/**
 * Shared wrapper component of react-select
 */

class MultiSelectField extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const values =
            props.values && List.isList(props.values) ? props.values.toJS() : props.values;
        this.state = {
            values: values || [],
        };
    }

    handleSelectChange = (values: Array<OptionType>) => {
        this.setState(
            {
                values,
            },
            () => this.props.onChange(this.props.name, this.state.values)
        );
    };

    render() {
        const { defaultOption, intl, name, options } = this.props;
        const { values } = this.state;

        return (
            <Select
                closeOnSelect={false}
                name={name}
                multi
                onChange={this.handleSelectChange}
                options={options}
                placeholder={intl.formatMessage({
                    id: defaultOption,
                })}
                value={values}
            />
        );
    }
}

export default injectIntl(MultiSelectField);
