// @flow

import React from 'react';
import { appLocales } from 'i18n';

// Styles
import { LangContainer, LangInner, List, Item } from './styles';

type Props = {
    /** Current locale */
    locale: string,
    onToggleLocale: () => void,
    /** Alters markup based on location */
    footer?: boolean,
};

/**
 * Locale toggle with configuration
 */

function LocaleToggle(props: Props) {
    const options = appLocales.map((value: string, index: number) => (
        <Item key={value}>
            {index > 0 ? <span>&#47;</span> : null}
            <button
                key={value}
                type="button"
                value={value}
                onClick={props.onToggleLocale}
                disabled={props.locale === value}
            >
                {value}
            </button>
        </Item>
    ));

    let langComponent;
    if (props.footer) {
        langComponent = (
            <LangInner>
                <List>{options}</List>
            </LangInner>
        );
    } else {
        langComponent = <List>{options}</List>;
    }

    return <LangContainer>{langComponent}</LangContainer>;
}

LocaleToggle.defaultProps = {
    footer: false,
};

export default LocaleToggle;
