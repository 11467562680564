// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Assets
import caret from 'assets/icon-caret-white.svg';

// Styles
import {
    NavItemContainer,
    NavItemWithActionContainer,
    LinkGroupContainer,
    GroupName,
} from './styles';

// Types
import type { UserType } from 'types';

// Utils
import { isAdminOrAbove, isSuperAdmin } from 'utils/authentication';

type Props = {
    handleIsItemSelected: Function,
    path: string,
    user?: UserType,
};

type State = {
    itemsVisible: boolean,
};

/**
 * SideNav link group for navigation, influences by user's role
 */

class LinkGroup extends React.Component<Props, State> {
    state = {
        itemsVisible: true,
    };

    handleToggleItemsVisibility() {
        return () => this.setState({ itemsVisible: !this.state.itemsVisible });
    }

    render() {
        const { path, user } = this.props;
        const role = user && user.get('role');

        return (
            <LinkGroupContainer>
                <NavItemContainer onClick={this.handleToggleItemsVisibility()}>
                    <GroupName itemsVisible={this.state.itemsVisible}>
                        <FormattedMessage id="components.SideNavLayout.LinkGroupNames.Management" />
                        <img src={caret} alt="caret" />
                    </GroupName>
                </NavItemContainer>
                {role &&
                    isAdminOrAbove(role) && (
                        <NavItemWithActionContainer
                            hidden={!this.state.itemsVisible}
                            selected={this.props.handleIsItemSelected(path, 'users')}
                        >
                            <Link to="/users">
                                <FormattedMessage id="containers.UsersPage.NavLinkText" />
                            </Link>
                        </NavItemWithActionContainer>
                    )}
                {role &&
                    isSuperAdmin(role) && (
                        <NavItemWithActionContainer
                            hidden={!this.state.itemsVisible}
                            selected={this.props.handleIsItemSelected(path, 'applications')}
                        >
                            <Link to="/applications">
                                <FormattedMessage id="containers.ApplicationsPage.NavLinkText" />
                            </Link>
                        </NavItemWithActionContainer>
                    )}
                <NavItemWithActionContainer
                    hidden={!this.state.itemsVisible}
                    selected={this.props.handleIsItemSelected(path, 'content')}
                >
                    <Link to="/content">
                        <FormattedMessage id="containers.ContentPage.NavLinkText" />
                    </Link>
                </NavItemWithActionContainer>
                {role &&
                    isAdminOrAbove(role) && (
                        <NavItemWithActionContainer
                            hidden={!this.state.itemsVisible}
                            selected={this.props.handleIsItemSelected(path, 'statistics')}
                        >
                            <Link to="/statistics">
                                <FormattedMessage id="containers.StatisticsPage.NavLinkText" />
                            </Link>
                        </NavItemWithActionContainer>
                    )}
            </LinkGroupContainer>
        );
    }
}

export default LinkGroup;
