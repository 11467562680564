// @flow

import React from 'react';

// Styles
import { Title, TitleContainer } from './styles';
import { FlexContainer, FlexItem } from 'styles/common';

// Types
import type { ReactNode } from 'types';

type Props = {
    text: string,
    children?: ReactNode,
};

/**
 * Shared subsection title with possible right side content
 */

const SubSectionTitle = (props: Props) => (
    <TitleContainer>
        <FlexItem justifyContent="flex-end">
            <Title>{props.text}</Title>
        </FlexItem>
        <FlexItem justifyContent="flex-end" maxWidth="120px">
            {props.children ? (
                <FlexContainer justifyContent="flex-end">{props.children}</FlexContainer>
            ) : null}
        </FlexItem>
    </TitleContainer>
);

SubSectionTitle.defaultProps = {
    children: null,
};

export default SubSectionTitle;
