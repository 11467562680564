// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

export const TitleContainer = styled.div`
    border-bottom: 1px solid ${colors.grayE1};
    display: flex;
    &:not(:first-child) {
        margin-top: 20px;
    }
    margin-bottom: 10px;
    height: 40px;
`;

export const Title = styled.span`
    border-bottom: 1px solid ${(props) => (props.main ? colors.tealDark : colors.gray64)};
    color: ${colors.gray33};
    font-size: 16px;
    line-height: 40px;
    padding-bottom: 10px;
`;
