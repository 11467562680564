// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

// Components
import Logout from 'components/Logout';
import Search from 'components/Form/Search';

// Services
import { selectUser } from 'services/Authentication/selectors';

// Styles
import { Divider, Line, FlexRowContainer } from 'styles/common';
import { Actions, ControlPanelContainer, SingleTab, Tabs, Toolbar } from './styles';

// Types
import type { ImmutableList, ImmutableMap, ReactNode, UserType } from 'types';

// Utils
import { isSuperAdmin } from 'utils/authentication';

type DefaultProps = {
    advancedSearch?: boolean,
    onLogout?: Function,
    searchResultCount?: number,
    shouldDisplayLogout?: boolean,
    slim?: boolean,
    paddingTop?: string,
    unpaddedTabs?: boolean,
    resourceTitleId?: string,
    resourceType?: string,
    toolBar?: ReactNode,
};

type Props = DefaultProps & {
    controls: ReactNode,
    tabItemsList: ?ImmutableList<ImmutableMap<string, string>>,
    navigationList: ?ImmutableList<ImmutableMap<string, string>>,
    selectedTabItemId: number,
    isClickable: boolean,
    onItemClick: ?Function,
    onNavClick: ?Function,
    onSearch: ?Function,
    user: ?ImmutableMap<string, UserType>,
};

/**
 * Shared Control Panel component with toolbar
 */

class ControlPanel extends React.PureComponent<Props> {
    static defaultProps = {
        selectedTabItemId: 0,
        isClickable: false,
        advancedSearch: false,
    };

    render() {
        const {
            advancedSearch,
            controls,
            isClickable,
            navigationList,
            onItemClick,
            onLogout,
            onNavClick,
            onSearch,
            paddingTop,
            resourceTitleId,
            resourceType,
            searchResultCount,
            selectedTabItemId,
            shouldDisplayLogout,
            slim,
            tabItemsList,
            toolBar,
            unpaddedTabs,
            user,
        } = this.props;
        const role = user && user.get('role');

        let leftToolbar;
        if (toolBar) {
            leftToolbar = toolBar;
        } else if (onSearch) {
            leftToolbar = (
                <Search
                    advancedSearch={advancedSearch}
                    clearAdvancedSearch
                    onSearch={onSearch}
                    resourceType={resourceType}
                    resultCount={searchResultCount}
                />
            );
        }
        const rightToolbar = controls && <Actions>{controls}</Actions>;

        const toolbar = (
            <Toolbar>
                {leftToolbar}
                {rightToolbar}
            </Toolbar>
        );

        let header;
        let list;

        // UserPage: Build out list of Tab items via tabItemsList
        if (tabItemsList && !tabItemsList.isEmpty()) {
            list = tabItemsList.map((singleItem: ImmutableMap<string, string>) => {
                // (If no role or if role & isSuperAdmin)
                // And if singleItem.slug is 'super_admin'
                if (
                    (!role || (role && !isSuperAdmin(role))) &&
                    singleItem.get('slug') === 'super_admin'
                ) {
                    return null;
                }
                return (
                    <SingleTab
                        key={singleItem.get('id')}
                        selected={singleItem.get('id') === selectedTabItemId}
                        onClick={isClickable ? onItemClick(singleItem.get('id')) : null}
                    >
                        {singleItem.get('label')}
                    </SingleTab>
                );
            });
            header = <Tabs unpaddedTabs={unpaddedTabs}>{list}</Tabs>;
        }

        // ContentPage: Build out list of Navigation items via navigationList
        if (navigationList && !navigationList.isEmpty()) {
            list = navigationList
                .filter(
                    (item) =>
                        (item.get('adminOnly') &&
                            this.props.user &&
                            (this.props.user.get('role') === 'admin' ||
                                this.props.user.get('role') === 'super_admin')) ||
                        !item.get('adminOnly')
                )
                .map((singleItem: ImmutableMap<string, string>) => (
                    <React.Fragment key={singleItem.get('id')}>
                        {singleItem.get('divided') && <Divider height="18px" margin="11px 10px" />}
                        <SingleTab
                            selected={singleItem.get('id') === selectedTabItemId}
                            onClick={onNavClick && onNavClick(singleItem)}
                        >
                            <FormattedMessage id={singleItem.get('string')} />
                        </SingleTab>
                    </React.Fragment>
                ));
            header = <Tabs unpaddedTabs={unpaddedTabs}>{list}</Tabs>;
        }

        // Set single title header for pages without navigation/list
        if (resourceTitleId) {
            header = (
                <Tabs unpaddedTabs={unpaddedTabs}>
                    <SingleTab selected>
                        <FormattedMessage id={resourceTitleId} />
                    </SingleTab>
                </Tabs>
            );
        }

        return (
            <ControlPanelContainer slim={slim} paddingTop={paddingTop}>
                <FlexRowContainer justifyContent="space-between">
                    {header}
                    {shouldDisplayLogout && <Logout onLogout={onLogout} />}
                </FlexRowContainer>
                <Line />
                {toolbar}
            </ControlPanelContainer>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    user: selectUser(),
});

export default connect(mapStateToProps)(ControlPanel);
