// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Select, DefaultOption } from 'styles/form';

// Types
import type { IntlType } from 'types';

type OptionType = {
    intlId?: string,
    key: string | number,
    value?: string | number,
    disabled?: boolean,
};

type DefaultProps = {
    disabled?: boolean,
    defaultOption?: string,
    grouped?: boolan,
    textField?: ?string,
    value?: string | number | null,
};

type Props = DefaultProps & {
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    options: Array<OptionType>,
};

/**
 * Shared select input
 */

class SelectField extends React.PureComponent<Props> {
    static defaultProps: DefaultProps = {
        disabled: false,
        grouped: false,
        textField: '',
        value: '',
    };

    render() {
        const { value, grouped, options, defaultOption, textField, intl, ...rest } = this.props;
        return (
            <Select empty={!value} value={value ? value.toString() : ''} {...rest}>
                {defaultOption && (
                    <DefaultOption value={''}>
                        {intl.formatMessage({ id: defaultOption })}
                    </DefaultOption>
                )}
                {options.map((option: OptionType) => {
                    let optionInner;
                    if (option.intlId) {
                        optionInner = intl.formatMessage({ id: option.intlId });
                    } else if (textField) {
                        optionInner = option[textField];
                    } else {
                        optionInner = option.value;
                    }

                    return (
                        <option
                            key={option.key}
                            disabled={option.disabled || option.group}
                            value={option.key}
                        >
                            {grouped && !option.group && '\u00A0'}
                            {optionInner}
                        </option>
                    );
                })}
            </Select>
        );
    }
}

export default injectIntl(SelectField);
