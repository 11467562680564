// @flow

import React from 'react';
import { Redirect } from 'react-router-dom';

/**
 * Home Page.
 *
 * The home page should redirect authenticated users to the content page
 * (non-authenticatd users will have been redirected straight to login by the private routing)
 *
 */

const HomePage = () => <Redirect to="/content" />;

export default HomePage;
