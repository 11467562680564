// @flow

import styled from 'styled-components';

export const AudioContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Audio = styled.audio`
    margin: 20px;
`;
