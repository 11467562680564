// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { fromJS } from 'immutable';

// Components
import Collection from 'components/Collection';
import FileInput from 'components/Form/FileInput';
import Modal from 'components/Modal';
import Media from 'components/Media';
import Select from 'components/Form/Select';

// Services
import collectionResource from 'services/Collection';
import mediaResource from 'services/Media';
import ResourceManagement from 'components/ResourceManagement';

// Styles
import { ActionsContainer, FlexContainer, FlexItemContainer } from 'styles/common';
import { PrimaryButton, ReverseButton, SecondaryButton } from 'styles/buttons';
import { LabelSmall } from 'styles/form';

// Types
import type {
    CollectionType,
    ImmutableList,
    ImmutableMap,
    InputEvent,
    IntlType,
    MediaType,
} from 'types';

// Utils
import {
    createFormData,
    formatDate,
    getItemFromLocalStorage,
    getRouteFromLocation,
    sanitizeString,
    saveItemToLocalStorage,
} from 'utils';

import { contentPageNavList, RESOURCE_IDS } from 'utils/constants';

type DefaultProps = {
    /** from withRouter: Allows us to check for route params.model */
    computedMatch?: Object,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    /** from withRouter: current route's location */
    location?: Object,
    /** list of imports from backend (Mass Import) */
    listImports?: ImmutableList<ImmutableMap<string, string>>,
};

type Props = DefaultProps & {
    /** fetches list of imports from backend (Mass Import) */
    getImports: Function,
    /** from withRouter: Allows us redirect */
    history: Object,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
};

type State = {
    entityType: string,
    importing: boolean,
    isImport: boolean,
    listImports?: ImmutableList<ImmutableMap<string, string>>,
    selectedCollection: ?CollectionType,
    selectedImportLog?: Object,
    selectedMedia: ?MediaType,
    selectedResource: Object,
    selectedResourceId: string | number,
    showCollectionModal: boolean,
    showDeleteModal: boolean,
    showMediaModal: boolean,
    selectedTabItemId: ?number,
    selectedType: ?string,
};

/**
 * Content Page.
 *
 * Index view to create & modify media & collections.
 * Handles mass import view as well
 *
 */

class ContentPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const route = props && props.location && getRouteFromLocation(props.location);
        // Get current tab that is saved in LocalStorage
        let currentTab = route && getItemFromLocalStorage(`currentTab:${route}`);
        const importTab = contentPageNavList.find(
            (singleItem: ImmutableMap<string, string>) => singleItem.get('route') === '/import'
        );
        if (props.location.pathname.indexOf('/import') >= 0) {
            currentTab = importTab.get('id');
        }
        // Get currently selected NavigationList item, based on currentTab equaling item's id
        const currentTabsNavigationItem =
            currentTab &&
            contentPageNavList.find(
                (singleItem: ImmutableMap<string, string>) =>
                    singleItem.get('id') === parseInt(currentTab)
            );
        this.state = {
            entityType: currentTabsNavigationItem && currentTabsNavigationItem.get('resource'),
            importing: false,
            isImport: Number(currentTab) === importTab.get('id'),
            selectedCollection: null,
            selectedMedia: null,
            // Get resource from currently selected NagivationList item, or default to media
            selectedResource:
                currentTabsNavigationItem &&
                currentTabsNavigationItem.get('resource') === RESOURCE_IDS.COLLECTION
                    ? collectionResource()
                    : mediaResource(),
            selectedResourceId:
                (currentTabsNavigationItem && currentTabsNavigationItem.get('resource')) ||
                RESOURCE_IDS.MEDIA,
            showCollectionModal: false,
            showDeleteModal: false,
            showMediaModal: false,
            // Get currentTab, or default to 1
            selectedTabItemId: parseInt(currentTab) || 1,
            selectedType: getItemFromLocalStorage('selectedType') || '',
        };
        // If we are on the /content page but current tab is import,
        // Set the correct location
        if (
            this.state.isImport &&
            props.location.pathname.indexOf('/import') < 0 &&
            !props.selectedTabItemId
        ) {
            props.history.push('/import');
        }
    }

    /**
     * If modelType is defined via computedMatch.params.model, display media or collection model. Used to initial model on load
     * Check for list imports, if not fetch from backend via getImports() (Mass Import)
     */
    componentDidMount() {
        const { computedMatch } = this.props;
        const modalType = computedMatch && computedMatch.params.modal;
        if (modalType === RESOURCE_IDS.MEDIA) {
            this.handleOnShowMediaModal();
        } else if (modalType === RESOURCE_IDS.COLLECTION) {
            this.handleOnShowCollectionModal();
        }

        if (
            !this.props.listImports ||
            (this.props.listImports && this.props.listImports.isEmpty())
        ) {
            this.props.getImports();
        }
    }

    /**
     * If list of Imports has changed between current and nextProps, replace local state with list from backend
     *
     * @param {Props} nextProps
     */
    componentWillReceiveProps(nextProps: Props) {
        const { lastImport, listImports } = nextProps;
        if (listImports && listImports !== this.props.listImports) {
            const selectedImportLog = this.state.importing && lastImport ? lastImport.toJS() : null;
            this.setState({
                importing: false,
                listImports,
                selectedImportLog,
            });
        }
    }

    handleDeleteSelectedContent = (event) => {
        this.content.handleDelete(event);
    };

    /**
     * Saving provided keyValue to localStorge with keyName: currentTab:[route]
     *
     * @param {string | number} keyValue
     */
    handleSaveTabToLocalStorage = (keyValue: string | number) => {
        const { location } = this.props;
        let route = location && getRouteFromLocation(location);
        if (route === 'import') {
            route = 'content';
        }
        saveItemToLocalStorage(`currentTab:${route}`, keyValue);
    };

    /**
     * Hide media & collection model and show delete confirmation modal
     */
    handleShowDeleteModal = () => {
        this.setState({
            showDeleteModal: true,
            showMediaModal: false,
            showCollectionModal: false,
        });
    };

    /**
     * Save resource configuration to local state to display proper resource & modal (Media)
     */
    handleOnShowMediaModal = () => {
        this.setState({
            selectedResource: mediaResource(),
            selectedResourceId: RESOURCE_IDS.MEDIA,
            selectedTabItemId: 1,
            showDeleteModal: false,
            showMediaModal: true,
        });
    };

    /**
     * Save resource configuration to local state to display proper resource & modal (Collection)
     */
    handleOnShowCollectionModal = () => {
        this.setState({
            selectedResource: collectionResource(),
            selectedResourceId: RESOURCE_IDS.COLLECTION,
            selectedTabItemId: 2,
            showCollectionModal: true,
            showDeleteModal: false,
        });
    };

    /**
     * Trigger parent's submit handler, @param closeModal as false to trigger a refresh within resource's thunk
     *
     * @param {boolean} closeModal
     */
    handleOnSubmitContentModal = (closeModal: boolean = false) => (event: Event) => {
        this.content.handleOnSubmitForm(closeModal)(event);
    };

    /**
     * Heset local state & close modal
     */
    handleOnCancelContentModal = (event: Event) => {
        // Reset content's local state
        this.content.handleOnCancel(event);
        // Reset selected Media/Collection, then close modal
        this.setState(
            {
                selectedMedia: null,
                selectedCollection: null,
            },
            () => {
                this.handleCloseContentModal();
                this.handleRefreshContentPage();
            }
        );
    };

    handleRefreshContentPage = () => {
        const { computedMatch } = this.props;
        const modalType = computedMatch && computedMatch.params.modal;

        if (typeof modalType !== 'undefined') {
            this.props.history.push('/content');
        }
    };

    /**
     * Sets up ref between parent and child component
     */
    handleOnRef = () => (ref) => {
        this.content = ref;
    };

    handleCloseContentModal = () => {
        this.setState({
            showDeleteModal: false,
            showMediaModal: false,
            showCollectionModal: false,
        });
    };

    /**
     * Triggers importsCSV() to send CSV to backend to be processed
     */
    handleMassImport = (event: InputEvent) => {
        if (event && event.target.files) {
            this.setState({
                importing: true,
            });
            const file = event.target.files[0];
            const formData = createFormData(fromJS({ file }));
            this.props.importCSV(formData);
        }
    };

    handleMassImportLogChange = ({ target: { value } }: InputEvent) => {
        const selectedImportLog = this.props.listImports.find(
            (i: number) => i.get('id') === Number(value)
        );
        this.setState({
            selectedImportLog: selectedImportLog ? selectedImportLog.toJS() : null,
        });
    };

    /**
     * Handles click action of resource managements navigatiom,
     * Save resource configuration to local state to display proper resource
     *
     * @param {ImmutableMap} tab
     */
    handleNavigationItemClick = (tab: ImmutableMap<string, mixed>) => {
        const resource = tab && tab.get('resource');
        const type = tab && tab.get('type');

        // Routing
        const route = tab && tab.get('route');
        const currentRoute = this.props.history.location.pathname;
        const defaultRoute = '/content/';

        let activeRoute;
        if (route) {
            activeRoute = route;
        } else if (currentRoute !== defaultRoute) {
            activeRoute = defaultRoute;
        }

        let selectedResource;
        let selectedResourceId;
        switch (resource) {
            case RESOURCE_IDS.COLLECTION:
                selectedResource = collectionResource();
                selectedResourceId = RESOURCE_IDS.COLLECTION;
                break;
            case RESOURCE_IDS.MEDIA:
            default:
                selectedResource = mediaResource();
                selectedResourceId = RESOURCE_IDS.MEDIA;
        }

        const importTab = contentPageNavList.find(
            (singleItem: ImmutableMap<string, string>) => singleItem.get('route') === '/import'
        );

        this.setState(
            {
                entityType: resource,
                isImport: Number(tab.get('id')) === importTab.get('id'),
                selectedResource,
                selectedResourceId,
                selectedTabItemId: tab.get('id'),
                selectedType: type || '',
            },
            () => {
                this.handleSaveTabToLocalStorage(tab.get('id'));
                if (activeRoute) {
                    this.props.history.push(activeRoute);
                }
            }
        );
    };

    /**
     * Set selected collection in local state & show collection modal
     */
    handleOnCollectionModifyAction = (collection: CollectionType) => {
        this.setState({
            selectedCollection: collection,
            showCollectionModal: true,
        });
    };

    /**
     * Set selected media in local state & show media modal
     */
    handleOnMediaModifyAction = (media: MediaType) => {
        this.setState({
            selectedMedia: media,
            showMediaModal: true,
        });
    };

    /**
     * Render's modal's controls for interaction with resource/content
     * @param type disables save action if no @param content is present
     * @param content also provides timestamp for "updated at" label
     *
     * @param {string} type
     * @param {MediaType / CollectionType} content
     */
    renderContentModalControls = (type: string, content: ?MediaType | ?CollectionType) => {
        const updatedAt = content && content.updatedAt;
        const timestamp = updatedAt && formatDate(updatedAt);
        return (
            <FlexContainer justifyContent="space-between" gutter="30">
                {timestamp ? (
                    <FlexItemContainer direction="column" textAlign="left">
                        <LabelSmall marginless>
                            <FormattedMessage id="global.lastUpdate" values={{ time: timestamp }} />
                        </LabelSmall>
                    </FlexItemContainer>
                ) : null}
                <ActionsContainer flexGrown="1.5">
                    {content &&
                        content.has('id') && (
                            <PrimaryButton
                                disabled={type === RESOURCE_IDS.MEDIA && !this.state.selectedMedia}
                                onClick={this.handleOnSubmitContentModal(false)}
                            >
                                {this.props.intl.formatMessage({
                                    id: 'global.saveContinue',
                                })}
                            </PrimaryButton>
                        )}
                    <SecondaryButton
                        disabled={type === RESOURCE_IDS.MEDIA && !this.state.selectedMedia}
                        onClick={this.handleOnSubmitContentModal(true)}
                    >
                        {this.props.intl.formatMessage({
                            id: 'global.saveClose',
                        })}
                    </SecondaryButton>
                    <ReverseButton onClick={this.handleOnCancelContentModal}>
                        {this.props.intl.formatMessage({
                            id: 'global.cancel',
                        })}
                    </ReverseButton>
                </ActionsContainer>
            </FlexContainer>
        );
    };

    /**
     * Render delete confirmation modal
     * Looks at selectedCollection & selectedMedia to determine proper strings to display
     * If neither are found, returns null
     */
    renderDeleteContentModal = () => {
        const selectedCollection = this.state.selectedCollection;
        const selectedMedia = this.state.selectedMedia;

        if (selectedCollection || selectedMedia) {
            let actionId;
            let bodyId = '';
            let title;
            let headerTitleId;
            let cancelDeleteAction = null;

            if (selectedMedia) {
                actionId = 'components.Media.delete.action';
                bodyId = 'components.Media.delete.message';
                title = selectedMedia && selectedMedia.get('titleFr');
                headerTitleId = 'components.Media.delete';
                cancelDeleteAction = this.handleOnShowMediaModal;
            } else if (selectedCollection) {
                actionId = 'components.Collection.delete.action';
                bodyId = 'components.Collection.delete.message';
                title = selectedCollection && selectedCollection.get('titleFr');
                headerTitleId = 'components.Collection.delete';
                cancelDeleteAction = this.handleOnShowCollectionModal;
            }

            title = (title && sanitizeString(title)) || title;

            return (
                <Modal
                    controls={
                        <ActionsContainer flexGrown="1.5">
                            <PrimaryButton onClick={this.handleDeleteSelectedContent}>
                                {this.props.intl.formatMessage({
                                    id: actionId,
                                })}
                            </PrimaryButton>
                            <SecondaryButton onClick={cancelDeleteAction}>
                                {this.props.intl.formatMessage({
                                    id: 'global.cancel',
                                })}
                            </SecondaryButton>
                        </ActionsContainer>
                    }
                    headerTitle={
                        headerTitleId && <FormattedMessage id={headerTitleId} values={{ title }} />
                    }
                    onModalClose={this.handleOnCancelContentModal}
                    show={this.state.showDeleteModal}
                >
                    <FormattedMessage
                        id={bodyId}
                        values={{
                            title: <strong>{`"${title}"`}</strong>,
                        }}
                    />
                </Modal>
            );
        } else {
            return null;
        }
    };

    /**
     * Renders list of actions for control panel
     * If selectedTabItemId equals importTab's id, then display file input for CSV upload
     * Else display create media/collection actions
     */
    renderListActions = () => {
        const importTab = contentPageNavList.find(
            (singleItem: ImmutableMap<string, string>) => singleItem.get('route') === '/import'
        );
        return this.state.selectedTabItemId === importTab.get('id') ? (
            <FileInput
                accept=".csv"
                buttonId="containers.ContentPage.importCSV"
                disabled={this.state.importing}
                inputless
                name="file"
                onChange={this.handleMassImport}
                primary
                value={null}
            />
        ) : (
            <ActionsContainer flexGrown="1.5">
                <PrimaryButton onClick={this.handleOnShowMediaModal}>
                    {this.props.intl.formatMessage({
                        id: 'containers.ContentPage.createMedia',
                    })}
                </PrimaryButton>
                <SecondaryButton onClick={this.handleOnShowCollectionModal}>
                    {this.props.intl.formatMessage({
                        id: 'containers.ContentPage.createCollection',
                    })}
                </SecondaryButton>
            </ActionsContainer>
        );
    };

    renderMediaModal = () => {
        let title =
            (this.state.selectedMedia &&
                (this.state.selectedMedia.get('titleFr') ||
                    this.state.selectedMedia.get('titleEn'))) ||
            null;
        title = (title && sanitizeString(title)) || title;

        return (
            <Modal
                controls={this.renderContentModalControls(
                    RESOURCE_IDS.MEDIA,
                    this.state.selectedMedia
                )}
                headerTitle={
                    <FormattedMessage
                        id={
                            this.state.selectedMedia && title
                                ? 'components.Media.edit'
                                : 'components.Media.create'
                        }
                        values={{ title }}
                    />
                }
                flush
                onModalClose={this.handleOnCancelContentModal}
                show={this.state.showMediaModal}
                large
            >
                <Media
                    mediaId={this.state.selectedMedia && this.state.selectedMedia.get('id')}
                    onDelete={this.handleShowDeleteModal}
                    onMediaCreation={this.handleOnMediaModifyAction}
                    onRef={this.handleOnRef()}
                />
            </Modal>
        );
    };

    renderCollectionModal = () => {
        let title =
            (this.state.selectedCollection &&
                (this.state.selectedCollection.get('titleFr') ||
                    this.state.selectedCollection.get('titleEn'))) ||
            null;
        title = (title && sanitizeString(title)) || title;

        return (
            <Modal
                controls={this.renderContentModalControls(
                    RESOURCE_IDS.COLLECTION,
                    this.state.selectedCollection
                )}
                headerTitle={
                    <FormattedMessage
                        id={
                            this.state.selectedCollection && title
                                ? 'components.Collection.edit'
                                : 'components.Collection.create'
                        }
                        values={{ title }}
                    />
                }
                flush
                onModalClose={this.handleOnCancelContentModal}
                show={this.state.showCollectionModal}
                large
            >
                <Collection
                    collectionId={
                        this.state.selectedCollection && this.state.selectedCollection.get('id')
                    }
                    onDelete={this.handleShowDeleteModal}
                    onCollectionCreation={this.handleOnCollectionModifyAction}
                    onRef={this.handleOnRef()}
                />
            </Modal>
        );
    };

    renderMassImportToolBar = () => {
        const { listImports } = this.props;
        const { selectedImportLog } = this.state;

        return (
            <Select
                name="importLog"
                disabled={false}
                onChange={this.handleMassImportLogChange}
                value={selectedImportLog && selectedImportLog.id}
                options={
                    listImports &&
                    listImports.toJS().map((entry: Object) => ({
                        key: entry.id,
                        value: `${entry.status ? '✔' : '✖'} ${this.props.intl.formatMessage({
                            id: 'containers.ContentPage.massImportLog.prefix',
                        })} ${entry.createdAt}`,
                    }))
                }
                defaultOption="containers.ContentPage.massImportLog.placeholder"
            />
        );
    };

    render() {
        const { selectedResource } = this.state;
        const importTab = contentPageNavList.find(
            (singleItem: ImmutableMap<string, string>) => singleItem.get('route') === '/import'
        );

        return (
            <div>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'containers.ContentPage.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'containers.ContentPage.pageTitle',
                        })}
                    />
                </Helmet>
                {/* Index of current resource */}
                <ResourceManagement
                    onItemModifyAction={
                        this.state.selectedResourceId === RESOURCE_IDS.COLLECTION
                            ? this.handleOnCollectionModifyAction
                            : this.handleOnMediaModifyAction
                    }
                    controls={this.renderListActions()}
                    importing={this.state.importing}
                    isImport={this.state.isImport}
                    onNavigationItemClick={this.handleNavigationItemClick}
                    resourceNavigation={contentPageNavList}
                    resourceType={this.state.selectedResourceId}
                    resourceListFetchAction={
                        selectedResource.thunks().fetchPaginatedBySearchCriteria
                    }
                    resourceListSelector={selectedResource
                        .selectors()
                        .selectPaginatedBySearchCriteria()}
                    resourceListIsFetchingSelector={selectedResource
                        .selectors()
                        .selectPaginatedIsFetching()}
                    selectedImportLog={this.state.selectedImportLog}
                    selectedTabId={this.state.selectedTabItemId}
                    selectedType={this.state.selectedType}
                    tabItemsListFetchAction={null}
                    tabItemsListSelector={null}
                    toolBar={
                        this.state.selectedTabItemId === importTab.get('id') &&
                        this.renderMassImportToolBar()
                    }
                />
                {/* Modal of current resource */}
                {this.renderDeleteContentModal()}
                {this.renderCollectionModal()}
                {this.renderMediaModal()}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) =>
    createStructuredSelector({
        lastImport: mediaResource()
            .selectors()
            .selectLastImport(),
        listImports: mediaResource()
            .selectors()
            .selectAllImports(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            getImports: mediaResource().thunks().fetchImports,
            importCSV: mediaResource().thunks().importCSV,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(injectIntl(ContentPage)));
