// @flow

import React from 'react';
import { injectIntl } from 'react-intl';
import { Editor } from '@tinymce/tinymce-react';

import { TINYMCE_API_KEY } from 'env';

// Styles
import './styles.css';

// Types
import type { IntlType } from 'types';

type Props = {
    disabled: boolean,
    onChange: () => void,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    placeholder?: string,
    value?: number | string,
};

/**
 * Shared input component
 */

class TinyMCEInput extends React.PureComponent<Props> {
    static defaultProps = {
        placeholder: '',
        value: '',
    };

    handleOnChange = (event: Event) => {
        const value = event.target.getContent();
        event.target.value = value;
        this.props.onChange(event);
    };

    render() {
        const { value, placeholder, intl, disabled, ...rest } = this.props;
        return (
            <Editor
                key={rest.key || rest.name || rest.id}
                apiKey={TINYMCE_API_KEY}
                disabled={disabled}
                init={{
                    menubar: false,
                    plugins: 'charmap code image link media paste preview searchreplace',
                    statusbar: false,
                    toolbar:
                        'undo redo | bold italic underline strikethrough | cut copy paste pastetext pasteword searchreplace | image link unlink | subscript superscript charmap | preview code',
                }}
                cloudChannel={'5'}
                onChange={this.handleOnChange}
                initialValue={value || (placeholder && intl.formatMessage({ id: placeholder }))}
            />
        );
    }
}

export default injectIntl(TinyMCEInput);
