// @flow

import styled from 'styled-components';
import { FlexContainer } from 'styles/common';

export const UserContainer = FlexContainer.extend`
    flex-direction: column;
    flex-wrap: nowrap;
`;

export const UserFormContainer = styled.form`
    display: flex;
    flex-direction: column;
`;
