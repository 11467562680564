// @flow

import { crudResourceActionTypes, crudResourceActions } from 'services/Category/actions';
import crudServiceReducer from 'services/Category/reducer';
import crudResources from 'services/Category/resources';
import crudSelectors from 'services/Category/selectors';
import crudThunks from 'services/Category/thunks';

/*
 *
 * Category Resource (REDUX)
 *
 * Includes:
 *  - actions & action types
 *  - reducers - Connects response from API to store
 *  - resources - API calls
 *  - selectors - Fetches data from store
 *  - thunks - Performs actions and deals with outcome of resources
 *
 * Usage instructions:
 *  - Resource Object name: Update to reflect the resource in question
 *  - resourceName attribute: Update to reflect the resource name
 */

const categoryResource = () => {
    const resourceName = 'Category';

    return {
        actionTypes: () => crudResourceActionTypes((resourceName: string)),
        actions: () => crudResourceActions((resourceName: string)),
        serviceReducer: () => crudServiceReducer((resourceName: string)),
        resources: () => crudResources((resourceName: string)),
        selectors: () => crudSelectors((resourceName: string)),
        thunks: () => crudThunks((resourceName: string)),
    };
};

export default categoryResource;
