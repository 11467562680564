// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Components
import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import Input from 'components/Form/Input';

// Services
import { selectPasswordResetMessage, selectErrors } from 'services/Authentication/selectors';
import { userPasswordResetRequest } from 'services/Authentication/thunks';

// Styles
import { Container, Content } from './styles';
import { PrimaryButton } from 'styles/buttons';
import { Line } from 'styles/common';
import {
    FormGroup,
    FormTitleGroup,
    FormTitle,
    FormNotice,
    SubmitContainer,
    Label,
    ErrorsContainer,
} from 'styles/form';

// Types
import type { ImmutableMap, ReduxDispatch, InputEvent, IntlType } from 'types';
import type { UserPasswordResetRequestType } from 'services/Authentication/thunks';

type Props = {
    /** sends password reset request backend */
    userPasswordResetRequest: UserPasswordResetRequestType,
    /** ImmutableMap of errors from backend upon request */
    errors: ?ImmutableMap<string, Object>,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    /** NOT FULLY IMPLEMENTED: Current locale for bilingual emails */
    locale: string,
};

type State = {
    email: string,
    loading: boolean,
    formMessageId: ?string,
};

/**
 * Forgot Your Password Page.
 *
 * Forgot your password view which allows users to initiate the reset password process
 *
 */

export class ForgotPasswordPage extends React.Component<Props, State> {
    state = {
        email: '',
        loading: false,
        formMessageId: null,
    };

    /**
     * Define formMessageId based on backend errors if present
     */
    componentWillReceiveProps(nextProps: Props) {
        let formMessageId = null;

        if (nextProps.errors && nextProps.errors.get('reset')) {
            formMessageId = 'containers.ForgetPasswordPage.invalidUser';
        } else if (nextProps.passwordResetMessage === 'password_reset_email_sent') {
            formMessageId = 'containers.ForgetPasswordPage.passwordResetEmailSent';
        }

        this.setState({
            loading: false,
            formMessageId,
        });
    }

    handleChangeField = (name: string) => ({ target: { value } }: InputEvent) => {
        this.setState({
            [name]: value,
        });
    };

    /**
     * Submit form to send request to backend
     */
    handleSubmitForm = (evt: InputEvent) => {
        evt.preventDefault();

        this.setState({
            loading: true,
        });

        this.props.userPasswordResetRequest(this.state.email, this.props.locale);
    };

    getValidationState = (error: ?string) => (error ? 'error' : null);

    render() {
        return (
            <Container>
                <Content>
                    <Helmet>
                        <title>
                            {this.props.intl.formatMessage({
                                id: 'containers.ForgetPasswordPage.helmetTitle',
                            })}
                        </title>
                        <meta
                            name="description"
                            content={this.props.intl.formatMessage({
                                id: 'containers.ForgetPasswordPage.helmetDesc',
                            })}
                        />
                    </Helmet>
                    {this.state.loading ? (
                        <Loading loading />
                    ) : (
                        <div>
                            <FormTitleGroup>
                                <FormTitle data-test-id="headerText">
                                    <FormattedMessage id="containers.ForgetPasswordPage.pageTitle" />
                                </FormTitle>
                            </FormTitleGroup>

                            <form onSubmit={this.handleSubmitForm}>
                                <FormGroup
                                    validationState={this.getValidationState(
                                        this.props.errors && this.props.errors.get('email')
                                    )}
                                >
                                    <Label htmlFor="email">
                                        <FormattedMessage id="containers.ForgetPasswordPage.emailLabel" />
                                    </Label>

                                    <Input
                                        disabled={this.state.loading}
                                        name="email"
                                        onChange={this.handleChangeField('email')}
                                        placeholder={
                                            'containers.ForgetPasswordPage.emailPlaceholder'
                                        }
                                        type="email"
                                        value={this.state.email}
                                    />
                                </FormGroup>

                                <FormNotice>
                                    <FormattedMessage
                                        id={
                                            this.state.formMessageId
                                                ? this.state.formMessageId
                                                : `containers.ForgetPasswordPage.notice`
                                        }
                                    />
                                </FormNotice>

                                <ErrorsContainer
                                    hidden={!this.props.errors || !this.props.errors.size}
                                >
                                    <ErrorMessage
                                        error={this.props.errors && this.props.errors.get('email')}
                                        values={{ attribute: 'email' }}
                                    />
                                    <p>
                                        <FormattedMessage id="components.ErrorMessage.tryAgain" />
                                    </p>
                                </ErrorsContainer>

                                <Line />

                                <SubmitContainer>
                                    <PrimaryButton
                                        disabled={this.state.loading || !this.state.email}
                                    >
                                        <FormattedMessage id="containers.ForgetPasswordPage.submit" />
                                    </PrimaryButton>
                                </SubmitContainer>
                            </form>
                        </div>
                    )}
                </Content>
            </Container>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    passwordResetMessage: selectPasswordResetMessage(),
    errors: selectErrors(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators({ userPasswordResetRequest }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ForgotPasswordPage));
