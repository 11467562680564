// @flow

import React from 'react';
import { fromJS } from 'immutable';
import { DropTarget as dropTarget } from 'react-dnd';

// Assets
import caret from 'assets/icon-caret-black.svg';

// Components
import Loading from 'components/Loading';
import Thumbnail from 'components/Image/Thumbnail';
import SquareImage from 'components/Image/SquareImage';

// Styles
import { CarouselContainer, NavigationButton, NavigationContainer } from './styles';

type State = {
    currentIndex: number,
    loading: boolean,
    draggingOver: boolean,
};

type Props = {
    defaultIndex?: number | null,
    images?: Object,
    square?: boolean,
    width?: string,
    onChange: Function,
    connectDropTarget?: Function,
    isOver?: boolean,
    canDrop?: boolean,
    accepts?: Array<string>,
    onDrop?: Function,
};

const boxTarget = {
    drop(props, monitor) {
        if (props.onDrop) {
            props.onDrop(props, monitor)(new Event('thumbnailDropped'));
        }
    },
};

/**
 * Shared carousel image component for video thumbnails
 */

class Carousel extends React.Component<Props, State> {
    static defaultProps = {
        defaultIndex: 0,
        images: fromJS([]),
        square: false,
        width: '',
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            draggingOver: false,
            currentIndex: props.defaultIndex || 0,
        };
        // if (props.images && props.images.size > 0 && !this.props.defaultIndex) {
        if (props.images && props.images.size > 0) {
            this.state = {
                ...this.state,
                loading: false,
            };
            props.onChange(props.images.getIn([this.state.currentIndex, 'id']));
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        const draggingOver = nextProps.canDrop && nextProps.isOver;
        this.setState({
            draggingOver,
        });

        if (
            nextProps.images !== this.props.images ||
            nextProps.defaultIndex !== this.props.defaultIndex
        ) {
            this.setup(nextProps);
        }
    }

    setup(props: Props) {
        const { currentIndex } = this.state;
        this.setState(
            {
                loading: false,
                currentIndex: props.defaultIndex || currentIndex,
            },
            () => {
                props.onChange(props.images.getIn([this.state.currentIndex, 'id']));
            }
        );
    }

    handleOnPrevious = (event: Event) => {
        event.stopPropagation();
        this.setState(
            {
                currentIndex:
                    this.state.currentIndex === 0
                        ? this.props.images.size - 1
                        : this.state.currentIndex - 1,
            },
            () => {
                this.props.onChange(this.props.images.getIn([this.state.currentIndex, 'id']));
            }
        );
    };

    handleOnNext = (event: Event) => {
        event.stopPropagation();
        this.setState(
            {
                currentIndex:
                    this.state.currentIndex === this.props.images.size - 1
                        ? 0
                        : this.state.currentIndex + 1,
            },
            () => {
                this.props.onChange(this.props.images.getIn([this.state.currentIndex, 'id']));
            }
        );
    };

    render() {
        const { canDrop, connectDropTarget, isOver } = this.props;
        const { draggingOver, loading } = this.state;
        const image = this.props.images &&
            this.props.images.get(this.state.currentIndex) && {
                src: this.props.images.getIn([this.state.currentIndex, 'url']),
            };

        return (
            connectDropTarget &&
            connectDropTarget(
                <div>
                    <CarouselContainer dragging={draggingOver}>
                        {!this.state.loading && (
                            <NavigationContainer>
                                <NavigationButton
                                    navigationSense="left"
                                    onClick={this.handleOnPrevious}
                                >
                                    <img src={caret} alt="caret" />
                                </NavigationButton>
                                <NavigationButton
                                    navigationSense="right"
                                    onClick={this.handleOnNext}
                                >
                                    <img src={caret} alt="caret" />
                                </NavigationButton>
                            </NavigationContainer>
                        )}
                        <Thumbnail square width="160px">
                            {loading ? (
                                <Loading inline loading small />
                            ) : (
                                <SquareImage cover={false} width="160px" {...image} />
                            )}
                        </Thumbnail>
                    </CarouselContainer>
                </div>
            )
        );
    }
}

export default dropTarget((props) => props.accepts, boxTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
}))(Carousel);
