// @flow

import styled from 'styled-components';

import colors from 'styles/colors';

const StyledButton = styled.button`
    background-color: ${colors.grayF2};
    height: 30px;
    width: 102px;
    color: ${colors.tealLight};
    font-weight: 500;
    border: none;
    cursor: pointer;

    ${(props: ?Object) => {
        let styles = '';

        if (props) {
            for (const key of Object.keys(props)) {
                styles += `${key}: ${props[key]};`;
            }
        }

        return styles;
    }};
`;

export { StyledButton };
