// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Input from 'components/Form/Input';

// Styles
import { Hidden } from './styles';
import { FlexContainer, FlexItemContainer } from 'styles/common';
import { PrimarySpan, ReverseSpan } from 'styles/buttons';
import { Label } from 'styles/form';

type DefaultProps = {
    accept?: string,
    buttonId?: string,
    disabled?: boolean,
    filename?: string,
    gutter?: number,
    inputless?: boolean,
    primary?: boolean,
};

type Props = DefaultProps & {
    name: string,
    onChange: Function,
};

type State = {
    filename?: string,
};

/**
 * Shared file input component with configuration
 */

class FileInput extends React.Component<Props, State> {
    static defaultProps: DefaultProps = {
        accept: '',
        buttonId: '',
        disabled: false,
        gutter: 20,
        placeholder: '',
        inputless: false,
        primary: false,
    };

    handleClick = () => {
        // TODO needs to be refactored
        const selector = document.getElementById(this.props.name);
        if (selector) {
            selector.click();
        }
    };

    handleChange = (event: Event) => {
        const filename = event.target.files && event.target.files[0].name;
        event.persist();
        this.setState({ filename }, () => {
            this.props.onChange(event);
            event.target.value = null;
        });
    };

    render() {
        const {
            accept,
            buttonId,
            disabled,
            filename,
            gutter,
            inputless,
            name,
            primary,
        } = this.props;
        let value = '';
        if (filename) {
            value = filename;
        } else if (this.state && this.state.filename) {
            value = this.state && this.state.filename;
        }

        let button;
        if (primary && buttonId) {
            button = (
                <PrimarySpan>
                    <FormattedMessage id={buttonId} />
                </PrimarySpan>
            );
        } else if (buttonId) {
            button = (
                <ReverseSpan>
                    <FormattedMessage id={buttonId} />
                </ReverseSpan>
            );
        }

        return (
            <FlexContainer justifyContent="space-between" gutter={gutter} width="auto">
                {buttonId && (
                    <FlexItemContainer direction="column" justifyContent="flex-start" collapsed>
                        <Label htmlFor={name} marginless>
                            <ReverseSpan>
                                <FormattedMessage id={buttonId} />
                            </ReverseSpan>
                        </Label>
                    </FlexItemContainer>
                )}
                <FlexItemContainer direction="column" justifyContent="flex-start">
                    <Hidden id={name} name={name} type="file" onChange={this.handleChange} />
                    {!inputless && (
                        <Input
                            type="text"
                            placeholder="global.noFile"
                            value={value}
                            onClick={this.handleClick}
                            disabled={disabled}
                            accept={accept}
                            name={name}
                            readOnly
                        />
                    )}
                </FlexItemContainer>
            </FlexContainer>
        );
    }
}

export default FileInput;
