// @flow

// Authentication related utility functions

export const ROLE_SLUGS = {
    CONTRIBUTOR: 'contributeur',
    TRANSLATOR: 'traducteur',
    REVIEWER: 'reviseur',
    ADMIN: 'admin',
    SUPER: 'super_admin',
};

/**
 * Returns true if the user is logged in (jwt token present) and false otherwise
 */
export const isAuthenticated = () =>
    localStorage.getItem('api_token') && localStorage.getItem('api_token') !== 'undefined';

export const deAuthenticate = () => localStorage.removeItem('api_token');

/**
 * isAdminOrAbove: Returns true if the user's is 'super_admin' or 'admin'
 */
export const isAdminOrAbove = (role: string) => [ROLE_SLUGS.SUPER, ROLE_SLUGS.ADMIN].includes(role);

/**
 * isSuperAdmin: Returns true if the user's is 'super_admin'
 */
export const isSuperAdmin = (role: string) => role === ROLE_SLUGS.SUPER;

/**
 * isContributorOrAbove: Returns true if the user's is 'super_admin', 'admin' or 'contributeur'
 */
export const isContributorOrAbove = (role: string) =>
    [ROLE_SLUGS.SUPER, ROLE_SLUGS.ADMIN, ROLE_SLUGS.CONTRIBUTOR].includes(role);

/**
 * isReviewer: Returns true if the user's is 'reviseur'
 */
export const isReviewer = (role: string) => role === ROLE_SLUGS.REVIEWER;

/**
 * isTranslator: Returns true if the user's is 'traducteur'
 */
export const isTranslator = (role: string) => role === ROLE_SLUGS.TRANSLATOR;
