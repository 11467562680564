// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

// Components
import Loading from 'components/Loading';
import ControlPanel from 'components/ControlPanel';
import ResourceIndex from './ResourceIndex';
import PaginationBar from './PaginationBar';

// Styles
import {
    ImportLogErrorContainer,
    ImportLogErrorContainerKey,
    ImportLogErrorContainerValue,
    ResourceIndexContainer,
    ResourceIndexMessage,
} from './styles';

// Types
import type { ImmutableList, ImmutableMap, ReactNode, ReduxDispatch } from 'types';

// Utils
import { getItemFromLocalStorage, getRouteFromLocation, saveItemToLocalStorage } from 'utils';
import { RESOURCE_IDS } from 'utils/constants';

type DefaultProps = {
    controls?: ReactNode,
    fetchTabItemsList?: Function,
    importing: boolean,
    isImport: boolean,
    location?: Object,
    onChangeResourceListType?: Function,
    onItemModifyAction?: Function,
    onNavigationItemClick?: Function,
    selectedImportLog?: Object,
    selectedTabId?: number,
    selectedType?: ?string,
    tabItemsListFetchAction?: Function,
    tabItemsListSelector?: Function,
    toolBar?: ReactNode,
};

type Props = DefaultProps & {
    fetchResourceList: Function,
    resourceNavigation?: ?ImmutableList<ImmutableMap<string, string>>,
    resourceListFetchAction: Function,
    resourceListSelector: Function,
    resourceListIsFetchingSelector: Function,
    resourceList: ?Object,
    resourceListIsFetching: ?boolean,
    resourceType: ?string,
    tabItemsList: ?ImmutableList<ImmutableMap<string, string>>,
};

type State = {
    customColumns?: Object,
    resourceListCurrentPage: number,
    showModifyResourceModal: boolean,
    selectedTabItemId: number,
    searchCriteria: {
        selectedTabItemId: number,
        selectedType?: string,
        search?: string,
        sortBy: string,
        sortOrder: string,
    },
};

/**
 * Shared resource management component for backend data fetching
 */

class ResourceManagement extends React.Component<Props, State> {
    static defaultProps: DefaultProps = {
        selectedTabId: 2,
    };

    constructor(props: Props) {
        super(props);
        const route = props && props.location && getRouteFromLocation(props.location);
        const currentTab = route && getItemFromLocalStorage(`currentTab:${route}`);
        this.state = {
            customColumns: {
                typeDates: 1,
                languages: 1,
            },
            resourceListCurrentPage: 1,
            searchCriteria: {
                entityType: props.resourceType,
                ids:
                    (props.selectedImportLog &&
                        props.selectedImportLog.output &&
                        Object.values(props.selectedImportLog.output.data)) ||
                    null,
                selectedTabItemId: parseInt(currentTab) || props.selectedTabId || 2,
                selectedType: getItemFromLocalStorage('selectedType') || props.selectedType || '',
                search: '',
                sortBy: '',
                sortOrder: 'inactive',
            },
            selectedTabItemId: parseInt(currentTab) || props.selectedTabId || 2,
            showModifyResourceModal: false,
        };
    }

    componentDidMount() {
        // Retrieve resources for display
        if (this.props.fetchTabItemsList) {
            this.props.fetchTabItemsList();
        }
        if (
            (!this.props.isImport || (this.props.isImport && this.props.selectedImportLog)) &&
            !this.isLoading()
        ) {
            this.props.fetchResourceList(
                this.state.searchCriteria,
                this.state.resourceListCurrentPage
            );
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        const { selectedImportLog, selectedTabId, selectedType } = nextProps;
        // If selectedTabId has changed, reset pagination
        const resetPagination = nextProps.selectedTabId !== this.props.selectedTabId;
        // If selectedTabId or selectedTab is present, update searchCriteria
        if (selectedTabId || selectedType) {
            this.setState((prevState: Object) => ({
                resourceListCurrentPage: resetPagination ? 1 : prevState.resourceListCurrentPage,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    selectedType,
                },
            }));
        }
        if (selectedImportLog && selectedImportLog !== this.props.selectedImportLog) {
            // If selected import was a success: retrieve the created media
            if (selectedImportLog.status === true) {
                const ids = Object.values(selectedImportLog.output.data);
                const searchCriteria = {
                    ...this.state.searchCriteria,
                    ids,
                };
                this.setState({ searchCriteria }, () => {
                    this.props.fetchResourceList(
                        this.state.searchCriteria,
                        this.state.resourceListCurrentPage
                    );
                });
            }
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (
            this.state.resourceListCurrentPage !== prevState.resourceListCurrentPage ||
            this.state.selectedTabItemId !== prevState.selectedTabItemId
        ) {
            this.props.fetchResourceList(
                {
                    ...this.state.searchCriteria,
                    entityType: this.props.resourceType,
                },
                this.state.resourceListCurrentPage
            );
        }
    }

    // Check if component is loading (to decide if spinner should be shown for the entire page)
    isLoading = () => this.props.resourceListIsFetching;

    // Toggle the modify modal visibility to show it
    // handleListItemModifyAction = () => this.setState({ showModifyResourceModal: true });
    handleListItemModifyAction = (item) => {
        this.props.onItemModifyAction(item);
    };

    // Close the modify modal
    handleModalClose = () => this.setState({ showModifyResourceModal: false });

    handleNavItemClick = (tab: ImmutableMap<string, mixed>) => () => {
        this.props.onNavigationItemClick(tab);
        const tabId = tab && parseInt(tab.get('id'));
        const tabType = tab && tab.get('type');
        this.setState(
            {
                selectedTabItemId: tabId,
            },
            () => {
                this.handleSaveTabToLocalStorage(tabId);
                this.handleSaveTypeToLocalStorage(tabType);
            }
        );
    };

    // Change between tab items (on top of the resource list), also update the search criteria for data fetching
    handleControlPanelItemClick = (selectedTabItemId: number) => () => {
        this.setState({ selectedTabItemId }, () =>
            this.handleSaveTabToLocalStorage(selectedTabItemId)
        );

        const newSearchCriteria = { ...this.state.searchCriteria };
        newSearchCriteria.selectedTabItemId = selectedTabItemId;
        this.setState({ searchCriteria: newSearchCriteria });
    };

    // Saves customColumn selected options in state to be past to children
    // (for persistence when SortableTh is also involved)
    // If shouldFetch is true, reset sortBy/sortOrder, on callback: handleFetchResourceList()
    handleCustomColumnChange = (
        name: number | string,
        value: number,
        shouldFetch: boolean = false
    ) => {
        if (shouldFetch) {
            this.setState(
                (prevState: State) => ({
                    customColumns: {
                        ...prevState.customColumns,
                        [name]: value,
                    },
                    searchCriteria: {
                        ...prevState.searchCriteria,
                        sortBy: '',
                        sortOrder: 'inactive',
                    },
                }),
                () => shouldFetch && this.handleFetchResourceList()
            );
        } else {
            this.setState((prevState: State) => ({
                customColumns: {
                    ...prevState.customColumns,
                    [name]: value,
                },
            }));
        }
    };

    handleFetchResourceList = () =>
        this.props.fetchResourceList(this.state.searchCriteria, this.state.resourceListCurrentPage);

    // Saving provided keyValue to localStorge with keyName: currentTab:[route]
    handleSaveTabToLocalStorage = (keyValue: string | number) => {
        const { location } = this.props;
        let route = location && getRouteFromLocation(location);
        if (route === 'import') {
            route = 'content';
        }
        saveItemToLocalStorage(`currentTab:${route}`, keyValue);
    };

    handleSaveTypeToLocalStorage = (keyValue: string) => {
        saveItemToLocalStorage('selectedType', keyValue);
    };

    handleSearch = (search: string, advancedSearch?: Object) => {
        const { resourceType } = this.props;
        this.setState(
            (prevState: Object) => ({
                searchCriteria: {
                    ...prevState.searchCriteria,
                    advancedSearch: advancedSearch && advancedSearch.toJS(),
                    search,
                    entityType: resourceType,
                },
            }),
            () => this.handleFetchResourceList()
        );
    };

    // Saves sortBy/sortOrder to state, if sortOrder is 'inactive', null values
    // On callback handleFetchResourceList()
    handleSortBy = (sortBy: string, sortOrder: string) => {
        this.setState(
            (prevState: Object) => ({
                searchCriteria: {
                    ...prevState.searchCriteria,
                    sortBy: sortOrder === 'inactive' ? null : sortBy,
                    sortOrder: sortOrder === 'inactive' ? null : sortOrder,
                },
            }),
            () => this.handleFetchResourceList()
        );
    };

    // Change between pages of results for the resource index/list
    handlePaginationBarPageSelection = (
        resourceListCurrentPage: number,
        resourceListPagesTotal: number
    ) => () =>
        resourceListCurrentPage > 0 && resourceListCurrentPage <= resourceListPagesTotal
            ? this.setState({ resourceListCurrentPage })
            : null;

    render() {
        const {
            controls,
            resourceList,
            resourceListIsFetching,
            resourceNavigation,
            resourceType,
            tabItemsList,
        } = this.props;
        const resourceListData = resourceList && resourceList.get('data');

        let content = null;

        if (this.isLoading()) {
            content = <Loading loading />;
        } else if (this.props.importing) {
            content = (
                <ResourceIndexContainer alignItems="center">
                    <ResourceIndexMessage>
                        <FormattedMessage id="components.ResourceManagement.import.running" />
                    </ResourceIndexMessage>
                </ResourceIndexContainer>
            );
        } else if (this.props.isImport && !this.props.selectedImportLog) {
            // If we are on import tab but no log selected: display a corresponding message
            content = (
                <ResourceIndexContainer alignItems="center">
                    <ResourceIndexMessage>
                        <FormattedMessage id="components.ResourceManagement.import.noLogSelected" />
                    </ResourceIndexMessage>
                </ResourceIndexContainer>
            );
        } else if (
            this.props.isImport &&
            this.props.selectedImportLog &&
            !this.props.selectedImportLog.status
        ) {
            // If we are on import tab, import log selected but import failed: display errors
            content = (
                <div>
                    <ResourceIndexContainer alignItems="center" direction="column">
                        <ResourceIndexMessage error>
                            <FormattedMessage id="components.ResourceManagement.import.failed" />
                        </ResourceIndexMessage>
                        <div>
                            {this.props.selectedImportLog.output &&
                                Object.entries(this.props.selectedImportLog.output.errors).map(
                                    ([key, ers]) => (
                                        <ImportLogErrorContainer key={key}>
                                            <ImportLogErrorContainerKey>
                                                <span>{key}:&nbsp;</span>
                                            </ImportLogErrorContainerKey>
                                            <ImportLogErrorContainerValue>
                                                {ers.map((er, index) => (
                                                    // eslint-disable-next-line react/no-array-index-key
                                                    <div key={index}>
                                                        <span>{er}</span>
                                                    </div>
                                                ))}
                                            </ImportLogErrorContainerValue>
                                        </ImportLogErrorContainer>
                                    )
                                )}
                        </div>
                    </ResourceIndexContainer>
                </div>
            );
        } else {
            // Other cases: display fetched media
            content = (
                <div>
                    <ResourceIndex
                        currentSorting={{
                            sortBy: this.state.searchCriteria.sortBy,
                            sortOrder: this.state.searchCriteria.sortOrder,
                        }}
                        customColumns={this.state.customColumns}
                        onCustomColumnChange={this.handleCustomColumnChange}
                        onListItemModifyAction={this.handleListItemModifyAction}
                        onSortBy={this.handleSortBy}
                        resourceType={resourceType}
                        resourceList={resourceList && resourceListData}
                        resourceListIsFetching={resourceListIsFetching}
                    />
                    <PaginationBar
                        currentPage={this.state.resourceListCurrentPage}
                        pagesTotal={resourceList && resourceList.get('lastPage')}
                        onPageSelection={this.handlePaginationBarPageSelection}
                    />
                </div>
            );
        }

        return (
            <div>
                <ControlPanel
                    advancedSearch={
                        resourceType === RESOURCE_IDS.MEDIA ||
                        resourceType === RESOURCE_IDS.COLLECTION
                    }
                    controls={controls}
                    isClickable
                    navigationList={resourceNavigation}
                    onNavClick={this.handleNavItemClick}
                    onItemClick={this.handleControlPanelItemClick}
                    onSearch={this.handleSearch}
                    resourceType={resourceType}
                    searchResultCount={resourceList && resourceList.get('total')}
                    selectedTabItemId={this.state.selectedTabItemId}
                    tabItemsList={tabItemsList}
                    toolBar={this.props.toolBar}
                    {...this.props}
                />
                {content}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    tabItemsList: ownProps.tabItemsListSelector && ownProps.tabItemsListSelector(state),
    resourceList: ownProps.resourceListSelector(state),
    resourceListIsFetching:
        ownProps.resourceListIsFetchingSelector && ownProps.resourceListIsFetchingSelector(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch, ownProps: Props) =>
    bindActionCreators(
        {
            fetchTabItemsList: ownProps.tabItemsListFetchAction,
            fetchResourceList: ownProps.resourceListFetchAction,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ResourceManagement)
);
