// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';

// Types
import type { IntlType } from 'types';

type Props = {
    /** injectIntl for formatMessage strings */
    intl: IntlType,
};

/**
 * Not Found (404) Page.
 *
 * Simple 404 view with link to principle route (/)
 *
 */

export class NotFoundPage extends React.PureComponent<Props> {
    render() {
        return (
            <div>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'containers.NotFoundPage.helmetTitle',
                        })}
                    </title>
                </Helmet>
                <h4>
                    {this.props.intl.formatMessage({
                        id: 'containers.NotFoundPage.pageTitle',
                    })}
                </h4>

                <Link to="/">
                    {`${this.props.intl.formatMessage({
                        id: 'containers.NotFoundPage.goBack',
                    })} ${this.props.intl.formatMessage({ id: 'containers.HomePage.pageTitle' })}`}
                </Link>
            </div>
        );
    }
}

export default injectIntl(NotFoundPage);
