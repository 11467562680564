// @flow

import React from 'react';

// Styles
import { StyledCollectionThumbnail, CollectionCount } from './styles';

type Props = {
    /** image alt tag */
    alt?: string,
    /** displayed count. max of 999 */
    count?: number,
    src?: string,
};

/**
 * Shared collection thumbnail (with child count)
 */

const CollectionThumbnail = ({ alt, count, src, ...rest }: Props) => (
    <StyledCollectionThumbnail empty={!src} {...rest}>
        <CollectionCount>{Number(count) > 999 ? `+999` : count}</CollectionCount>
        {src && <img src={src} alt={alt} />}
    </StyledCollectionThumbnail>
);

CollectionThumbnail.defaultProps = {
    alt: '',
    count: 0,
    src: '',
};

export default CollectionThumbnail;
