// @flow

import React from 'react';

import { StyledButton } from './styles';

import type { ReactNode } from 'types';

type Props = {
    onClick: Function,
    children: ReactNode,
};

/**
 * Button: Shared button component
 */

const Button = ({ onClick, children, ...props }: Props) => (
    <StyledButton onClick={onClick} {...props}>
        {children}
    </StyledButton>
);

export default Button;
