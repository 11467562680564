// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

export const TitleContainer = styled.div`
    display: flex;
    margin: 30px 0 20px;
`;

export const Title = styled.span`
    color: ${colors.tealDark};
    font-size: 16px;
    line-height: 18px;
`;
