// @flow

import styled from 'styled-components';

import colors from 'styles/colors';

export const Ul = styled.ul`
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
`;

export const Li = styled.li`
    padding: 5px 0;
    line-height: 18px;
    font-size: 14px;
    flex: 1;
`;
