// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Flex, Box } from 'grid-styled';

// Components
import Select from 'components/Form/Select';

// Styles
import { FormGroup, Label, LabelSubtle } from 'styles/form';
import { FlexContainer, FlexItemContainer } from 'styles/common';

// Types
import type { ImmutableMap } from 'types';

// Utils
import { arrayOfDaysFromMonth, arrayOfMonthNumbers, arrayOfYears } from 'utils';

type Props = {
    data?: ImmutableMap<string, mixed>,
    handleErrors: Function,
    handleValidationState: Function,
    isLoading: boolean,
    onChangeField: Function,
};

export default class DateInformationBlock extends React.PureComponent<Props> {
    render() {
        return (
            <React.Fragment>
                <FormGroup
                    validationState={this.props.handleValidationState(
                        this.props.handleErrors('exifCreationDateYear')
                    )}
                >
                    <Label htmlFor="exifCreationDateDay">
                        <FormattedMessage id="components.Media.inputLabelValueCreationDate" /> *
                        <LabelSubtle>
                            {' '}
                            <FormattedMessage id="components.Media.inputLabelValueEXIF" />
                        </LabelSubtle>
                    </Label>
                    <Flex>
                        <Box width={1 / 3}>
                            <FlexContainer justifyContent="space-between" gutter="10">
                                <FlexItemContainer direction="column" justifyContent="flex-start">
                                    <Select
                                        defaultOption={
                                            'components.Media.inputLabelValueCreationDateDay.placeholder'
                                        }
                                        disabled={this.props.isLoading}
                                        name="exifCreationDateDay"
                                        onChange={this.props.onChangeField('exifCreationDateDay')}
                                        options={arrayOfDaysFromMonth(
                                            parseInt(
                                                this.props.data &&
                                                    this.props.data.get('exifCreationDateMonth')
                                            )
                                        ).map((day: { key: number, value: number }) => ({
                                            key: day.key,
                                            value: day.value,
                                        }))}
                                        value={
                                            this.props.data &&
                                            this.props.data.get('exifCreationDateDay')
                                        }
                                    />
                                </FlexItemContainer>
                                <FlexItemContainer direction="column" justifyContent="center">
                                    <Select
                                        defaultOption={
                                            'components.Media.inputLabelValueCreationDateMonth.placeholder'
                                        }
                                        disabled={this.props.isLoading}
                                        full
                                        name="exifCreationDateMonth"
                                        onChange={this.props.onChangeField('exifCreationDateMonth')}
                                        options={arrayOfMonthNumbers}
                                        value={
                                            this.props.data &&
                                            this.props.data.get('exifCreationDateMonth') &&
                                            this.props.data.get('exifCreationDateMonth').toString()
                                        }
                                    />
                                </FlexItemContainer>
                                <FlexItemContainer direction="column" justifyContent="center">
                                    <Select
                                        defaultOption={
                                            'components.Media.inputLabelValueCreationDateYear.placeholder'
                                        }
                                        disabled={this.props.isLoading}
                                        name="exifCreationDateYear"
                                        onChange={this.props.onChangeField('exifCreationDateYear')}
                                        options={arrayOfYears(1900)
                                            .map((year: number) => ({
                                                key: year,
                                                value: year,
                                            }))
                                            .reverse()}
                                        value={
                                            this.props.data &&
                                            this.props.data.get('exifCreationDateYear')
                                        }
                                    />
                                </FlexItemContainer>
                            </FlexContainer>
                        </Box>
                    </Flex>
                </FormGroup>
            </React.Fragment>
        );
    }
}
