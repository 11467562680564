// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Container, Radiobutton, Hidden, Text } from './styles';

// Types
import type { IntlType } from 'types';

type Props = {
    checked: boolean,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    label: string,
    onChange: Function,
    value: string | number,
    name: string,
};

/**
 * Shared radiobutton component
 */

const RadioButton = ({ checked, intl, label, name, onChange, value }: Props) => (
    <Container>
        <Radiobutton checked={checked} />
        <Text checked={checked}>{intl.formatMessage({ id: label })}</Text>
        <Hidden checked={checked} name={name} onChange={onChange} type="radio" value={value} />
    </Container>
);

export default injectIntl(RadioButton);
