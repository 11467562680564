// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Textarea } from 'styles/form';

// Types
import type { IntlType } from 'types';

type Props = {
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    placeholder?: string,
    value?: ?string,
};

/**
 * Shared textarea
 */

class TextareaField extends React.PureComponent<Props> {
    static defaultProps = {
        placeholder: '',
        value: '',
    };

    render() {
        const { intl, placeholder, value, ...rest } = this.props;
        return (
            <Textarea
                value={value || ''}
                placeholder={placeholder && intl.formatMessage({ id: placeholder })}
                {...rest}
            />
        );
    }
}

export default injectIntl(TextareaField);
