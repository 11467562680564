// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import ErrorMessage from 'components/ErrorMessage';
import Select from 'components/Form/Select';

// Styles
import { FlexContainer, FlexItemContainer } from 'styles/common';
import { FormGroup, Label } from 'styles/form';

// Types
import type { ImmutableMap } from 'types';

// Utils
import { arrayOfDaysFromMonth, arrayOfMonthNumbers, arrayOfYears } from 'utils';
import { entityLanguages, cardLanguages } from 'utils/constants';

type Props = {
    data?: ImmutableMap<string, mixed>,
    errors: ImmutableMap<string, any>,
    handleErrors: Function,
    handleValidationState: Function,
    isLoading: boolean,
    onChangeField: Function,
};

class CollectionLanguageBlock extends React.PureComponent<Props> {
    render() {
        return (
            <React.Fragment>
                <FlexContainer justifyContent="space-between" gutter="30">
                    <FlexItemContainer
                        direction="column"
                        justifyContent="flex-start"
                        minWidth="36%"
                    >
                        <FormGroup
                            validationState={this.props.handleValidationState(
                                this.props.handleErrors('creationDateYear')
                            )}
                        >
                            <Label>
                                <FormattedMessage id="components.CollectionLanguageBlock.inputLabelValueDate" />{' '}
                                *
                            </Label>
                            <FlexContainer justifyContent="space-between" gutter="10">
                                <FlexItemContainer direction="column" justifyContent="flex-start">
                                    <Select
                                        defaultOption={
                                            'components.Media.inputLabelValueCreationDateDay.placeholder'
                                        }
                                        disabled={this.props.isLoading}
                                        name="creationDateDay"
                                        onChange={this.props.onChangeField('creationDateDay')}
                                        options={arrayOfDaysFromMonth(
                                            parseInt(
                                                this.props.data &&
                                                    this.props.data.get('creationDateMonth')
                                            )
                                        ).map((day: { key: number, value: number }) => ({
                                            key: day.key,
                                            value: day.value,
                                        }))}
                                        value={
                                            this.props.data &&
                                            this.props.data.get('creationDateDay')
                                        }
                                    />
                                </FlexItemContainer>
                                <FlexItemContainer direction="column" justifyContent="center">
                                    <Select
                                        defaultOption="components.Media.inputLabelValueCreationDateMonth.placeholder"
                                        disabled={this.props.isLoading}
                                        full
                                        name="creationDateMonth"
                                        onChange={this.props.onChangeField('creationDateMonth')}
                                        options={arrayOfMonthNumbers}
                                        value={
                                            this.props.data &&
                                            this.props.data.get('creationDateMonth') &&
                                            this.props.data.get('creationDateMonth').toString()
                                        }
                                    />
                                </FlexItemContainer>
                                <FlexItemContainer direction="column" justifyContent="center">
                                    <Select
                                        defaultOption={
                                            'components.Media.inputLabelValueCreationDateYear.placeholder'
                                        }
                                        disabled={this.props.isLoading}
                                        name="creationDateYear"
                                        onChange={this.props.onChangeField('creationDateYear')}
                                        options={arrayOfYears(1900).map((year: number) => ({
                                            key: year,
                                            value: year,
                                        }))}
                                        value={
                                            this.props.data &&
                                            this.props.data.get('creationDateYear')
                                        }
                                    />
                                </FlexItemContainer>
                            </FlexContainer>
                            <ErrorMessage
                                error={
                                    this.props.handleErrors('creationDateYear')
                                        ? this.props.errors.getIn([
                                              'data',
                                              'errors',
                                              'creationDateYear',
                                          ])
                                        : null
                                }
                                values={{
                                    attribute: 'components.Media.inputLabelValueCreationDate',
                                }}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup
                            validationState={this.props.handleValidationState(
                                this.props.handleErrors('collectionLanguage')
                            )}
                        >
                            <Label htmlFor="collectionLanguage">
                                <FormattedMessage id="components.CollectionLanguageBlock.inputLabelValueCollectionLanguage" />{' '}
                                *
                            </Label>
                            <Select
                                name="collectionLanguage"
                                onChange={this.props.onChangeField('collectionLanguage')}
                                disabled={this.props.isLoading}
                                value={this.props.data && this.props.data.get('collectionLanguage')}
                                options={entityLanguages}
                                defaultOption="components.CollectionLanguageBlock.inputLabelValueCollectionLanguage.placeholder"
                                full
                            />
                            <ErrorMessage
                                error={
                                    this.props.handleErrors('collectionLanguage')
                                        ? this.props.errors.getIn([
                                              'data',
                                              'errors',
                                              'collectionLanguage',
                                          ])
                                        : null
                                }
                                values={{
                                    attribute:
                                        'components.CollectionLanguageBlock.inputLabelValueCollectionLanguage',
                                }}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup
                            validationState={this.props.handleValidationState(
                                this.props.handleErrors('cardLanguage')
                            )}
                        >
                            <Label htmlFor="cardLanguage">
                                <FormattedMessage id="components.CollectionLanguageBlock.inputLabelValueFormLanguage" />{' '}
                                *
                            </Label>
                            <Select
                                name="cardLanguage"
                                onChange={this.props.onChangeField('cardLanguage')}
                                disabled={this.props.isLoading}
                                value={this.props.data && this.props.data.get('cardLanguage')}
                                options={cardLanguages}
                                defaultOption="components.CollectionLanguageBlock.inputLabelValueFormLanguage.placeholder"
                                full
                            />
                            <ErrorMessage
                                error={
                                    this.props.handleErrors('cardLanguage')
                                        ? this.props.errors.getIn([
                                              'data',
                                              'errors',
                                              'cardLanguage',
                                          ])
                                        : null
                                }
                                values={{
                                    attribute:
                                        'components.CollectionLanguageBlock.inputLabelValueFormLanguage',
                                }}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
            </React.Fragment>
        );
    }
}

export default CollectionLanguageBlock;
