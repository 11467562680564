// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { fromJS } from 'immutable';

// Services
import { selectUser } from 'services/Authentication/selectors';
import categoryResource from 'services/Category';
import collectionResource from 'services/Collection';
import mediaResource from 'services/Media';

// Components
import Collection from 'components/Collection';
import CollectionManagement from 'components/ResourceManagement/CollectionManagement';
import Image from 'components/Image';
import ControlPanelBreadCrumb from 'components/ControlPanel/breadCrumb';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import ResourceSelector from 'components/ResourceSelector';
import SectionTabs from 'components/SectionTabs';
import Thumbnail from 'components/Image/Thumbnail';
import ToggleSwitchBoolean from 'components/Form/ToggleSwitchBoolean';

// Styles
import {
    Divider,
    FlexContainer,
    FlexItemContainer,
    Indented,
    LineBreak,
    ActionsContainer,
    Table,
    Td,
} from 'styles/common';
import { PrimaryButton, ReverseButton, SecondaryButton } from 'styles/buttons';
import { Label, LabelSmall } from 'styles/form';
import { Container, ContentContainer, InformationContainer } from './styles';

// Types
import type {
    CategoryType,
    CollectionType,
    ImmutableList,
    ImmutableMap,
    IntlType,
    ReduxDispatch,
    UserType,
} from 'types';

// Utils
import {
    createFormData,
    formatDate,
    getItemFromLocalStorage,
    getRouteFromLocation,
    limitString,
    sanitizeString,
    saveItemToLocalStorage,
} from 'utils';
import { isAdminOrAbove } from 'utils/authentication';
import { RESOURCE_IDS } from 'utils/constants';

type DefaultProps = {
    /** triggers batch attach (collections) request to backend */
    batchAttachChildCollections: Function,
    /** triggers batch attach (media) request to backend */
    batchAttachMedia: Function,
    /** triggers batch detach (collections) request to backend */
    batchDetachChildCollections: Function,
    /** triggers batch detach (media) request to backend */
    batchDetachMedia: Function,
    /** provides access to collection/media categories */
    categories: ImmutableMap<string, CategoryType>,
    /** provides access to current collection */
    collection: ?CollectionType,
    /** from withRouter: Allows us to check for route params.id */
    computedMatch: Object,
    /** ImmutableMap of errors from backend upon request */
    errors: ?ImmutableMap<string, Object>,
    /** triggers fetch request to backend */
    fetchCategories: Function,
    /** triggers fetch request to backend */
    fetchCollection: Function,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    /** triggers update request to backend */
    updateCollection: Function,
    /** provides access to current user */
    user?: UserType,
};

type Props = DefaultProps & {
    /** from withRouter: current route's location */
    location?: Object,
};

type State = {
    collection: ?CollectionType,
    loading: boolean,
    resourceListCurrentPage: number,
    search?: string,
    selectedCollections: ImmutableList<string>,
    selectedMedia: ImmutableList<string>,
    selectedTabItemId?: string | number,
    showAddCollectionsModal: boolean,
    showAddMediaModal: boolean,
    selectedCollectionsToDelete: ImmutableList<string>,
    selectedMediaToDelete: ImmutableList<string>,
    showCollectionModal: boolean,
    showDeleteModal: boolean,
    showModifyResourceModal: boolean,
};

/**
 * Single Collection Page.
 *
 * Single collection view to view & edit collection
 *
 */

export class SingleCollectionPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const route = props && props.location && getRouteFromLocation(props.location);
        const currentTab = route && getItemFromLocalStorage(`currentTab:${route}`);
        this.state = {
            collection: props.collection,
            loading: false,
            resourceListCurrentPage: 1,
            selectedCollections: null,
            selectedMedia: null,
            selectedTabItemId: currentTab || 'media',
            showAddCollectionsModal: false,
            showAddMediaModal: false,
            selectedCollectionsToDelete: null,
            selectedMediaToDelete: null,
            showCollectionModal: false,
            showDeleteModal: false,
            showModifyResourceModal: false,
        };
    }

    /**
     * Check for collection/media categories, if empty trigger fetch request
     * Fetch collection based on params.id (Requested collection id)
     */
    componentDidMount() {
        // If the ID is passed in the query string / present
        if (
            this.props.computedMatch &&
            this.props.computedMatch.params &&
            this.props.computedMatch.params.id
        ) {
            // Retrieve media categories
            if (this.props.categories.isEmpty()) {
                this.props.fetchCategories();
            }

            // Retrieve media for display
            this.props.fetchCollection(this.props.computedMatch.params.id);
        }
    }

    /**
     * If errors were raised for the collection re-fetch to assure the display of the correct information
     *
     * @param {Props} nextProps
     */
    componentWillReceiveProps(nextProps: Props) {
        const { errors } = nextProps;

        if (errors && !errors.isEmpty()) {
            this.props.fetchCollection(this.props.computedMatch.params.id);
        }

        this.setState({
            loading: false,
        });
    }

    handleChangeField = (name: string, isCheckbox: boolean = false) => ({
        target: { value, checked },
    }: InputEvent) => {
        const { collection } = this.props;
        if (collection) {
            const newCollection = collection.setIn([name], isCheckbox ? checked : value);
            // Show spinner to avoid mutiple overlapping changes
            this.setState(
                {
                    loading: true,
                    collection: newCollection,
                },
                () => this.handleUpdateCollection()
            );
        }
    };

    /**
     * Submit collection update request after sending collection from local state through createFormData()
     */
    handleUpdateCollection = () => {
        const id =
            this.props.computedMatch &&
            this.props.computedMatch.params &&
            this.props.computedMatch.params.id;
        if (id && this.state.collection) {
            const formData = createFormData(this.state.collection);
            this.props.updateCollection(id, formData, `/collections/${id}`);
        }
    };

    handleCloseContentModal = () => {
        this.setState({
            showAddCollectionsModal: false,
            showAddMediaModal: false,
            showDeleteModal: false,
            showCollectionModal: false,
        });
    };

    /**
     * Change between tab items (on bottom section selection)
     *
     * @param {string} selectedTabItemId
     */
    handleControlPanelItemClick = (selectedTabItemId: string) => () => {
        this.setState({ selectedTabItemId });
    };

    handleDeleteSelectedContent = () => {
        this.content.handleDelete();
    };

    handleDetachCollectionToCollection = () => {
        const { collection } = this.props;
        const { selectedCollectionsToDelete } = this.state;
        if (selectedCollectionsToDelete) {
            this.props.batchDetachChildCollections(collection && collection.get('id'), {
                childCollectionIds: selectedCollectionsToDelete.toJS(),
            });
        }
    };

    handleDetachMediaToCollection = () => {
        const { collection } = this.props;
        const { selectedMediaToDelete } = this.state;
        if (selectedMediaToDelete) {
            this.props.batchDetachMedia(collection && collection.get('id'), {
                mediaIds: selectedMediaToDelete.toJS(),
            });
        }
    };

    handleOnCollectionModifyAction = () => {
        this.setState({
            showCollectionModal: true,
        });
    };

    /**
     * Sets up ref between parent and child component
     */
    handleOnRef = () => (ref) => {
        this.content = ref;
    };

    handleOnShowCollectionModal = () => {
        this.setState({
            showAddMediaModal: false,
            showDeleteModal: false,
            showCollectionModal: true,
        });
    };

    handleShowAddCollectionsModal = (show: boolean = true) => () => {
        this.setState({
            showDeleteModal: false,
            showCollectionModal: false,
            showAddCollectionsModal: show,
            showAddMediaModal: false,
        });
    };

    handleShowAddMediaModal = (show: boolean = true) => () => {
        this.setState({
            showDeleteModal: false,
            showCollectionModal: false,
            showAddCollectionsModal: false,
            showAddMediaModal: show,
        });
    };

    handleOnSubmitContentModal = (event: Event) => {
        this.content.handleOnSubmitForm(true)(event);
    };

    handleAttachCollectionToCollection = () => {
        const { collection } = this.props;
        const { selectedCollections } = this.state;
        if (selectedCollections) {
            this.props.batchAttachChildCollections(collection && collection.get('id'), {
                childCollectionIds: selectedCollections.toJS(),
            });
        }
    };

    handleAttachMediaToCollection = () => {
        const { collection } = this.props;
        const { selectedMedia } = this.state;
        if (selectedMedia) {
            this.props.batchAttachMedia(collection && collection.get('id'), {
                mediaIds: selectedMedia.toJS(),
            });
        }
    };

    /**
     * Saving provided keyValue to localStorge with keyName: currentTab:[route]
     *
     * @param {string | number} keyValue
     */
    handleSaveTabToLocalStorage = (keyValue: string | number) => {
        const { location } = this.props;
        const route = location && getRouteFromLocation(location);
        saveItemToLocalStorage(`currentTab:${route}`, keyValue);
    };

    handleSelectedCollections = (list: ImmutableList<string>) => {
        this.setState({
            selectedCollections: list,
        });
    };

    handleSelectedMedia = (list: ImmutableList<string>) => {
        this.setState({
            selectedMedia: list,
        });
    };

    handleShowDeleteModal = () => {
        this.setState({
            showDeleteModal: true,
            showCollectionModal: false,
        });
    };

    handleSelectedCollectionsToBeDeleted = (list: ImmutableList<string>) => {
        this.setState({
            selectedCollectionsToDelete: list,
        });
    };

    handleSelectedMediaToBeDeleted = (list: ImmutableList<string>) => {
        this.setState({
            selectedMediaToDelete: list,
        });
    };

    handleTabItemClick = (key: string) => () => {
        this.setState(
            {
                selectedTabItemId: key,
            },
            this.handleSaveTabToLocalStorage(key)
        );
    };

    /**
     * Renders action items for media/collection "add to collection" modal
     * @param media determines displayed strings & events for media vs collections
     *
     * @param {boolean} media
     */
    renderAddItemsActions = (media: boolean = true) => {
        const { selectedCollections, selectedMedia } = this.state;

        const selectedCount = media
            ? selectedMedia && selectedMedia.size.toString()
            : selectedCollections && selectedCollections.size.toString();

        return (
            <FlexContainer justifyContent="space-between" gutter="30">
                <FlexItemContainer direction="column" textAlign="left">
                    <LabelSmall marginless>
                        <FormattedMessage
                            id={
                                media
                                    ? 'views.SingleCollectionPage.selectedMediaCount'
                                    : 'views.SingleCollectionPage.selectedCollectionsCount'
                            }
                            values={{ count: selectedCount || '0' }}
                        />
                    </LabelSmall>
                </FlexItemContainer>
                <ActionsContainer flexGrown="1.5">
                    <PrimaryButton
                        disabled={!this.props.collection}
                        onClick={
                            media
                                ? this.handleAttachMediaToCollection
                                : this.handleAttachCollectionToCollection
                        }
                        textOnly
                    >
                        {this.props.intl.formatMessage({
                            id: 'views.SingleCollectionPage.addToCollection',
                        })}
                    </PrimaryButton>
                    <SecondaryButton onClick={this.handleShowAddMediaModal(false)}>
                        {this.props.intl.formatMessage({
                            id: 'global.cancel',
                        })}
                    </SecondaryButton>
                </ActionsContainer>
            </FlexContainer>
        );
    };

    /**
     * Renders "add to collection" modal for collections (children)
     * <Modal /> wrapped <ResourceSelector />
     */
    renderAddCollectionModal = () => {
        const { collection } = this.props;
        if (collection) {
            let title =
                (collection && (collection.get('titleFr') || collection.get('titleEn'))) || null;
            title = (title && sanitizeString(title)) || title;
            return (
                <Modal
                    controls={this.renderAddItemsActions(false)}
                    headerTitle={title}
                    onModalClose={this.handleCloseContentModal}
                    show={this.state.showAddCollectionsModal}
                    large
                >
                    <ResourceSelector
                        collection={collection}
                        onChange={this.handleSelectedCollections}
                        resource={'collections'}
                        resourceListFetchAction={
                            collectionResource().thunks().fetchSecondaryPaginatedBySearchCriteria
                        }
                        resourceListSelector={collectionResource()
                            .selectors()
                            .selectSecondaryPaginatedBySearchCriteria()}
                        resourceListIsFetchingSelector={collectionResource()
                            .selectors()
                            .selectSecondaryPaginatedIsFetching()}
                    />
                </Modal>
            );
        } else {
            return null;
        }
    };

    /**
     * Renders "add to collection" modal for media (children)
     * <Modal /> wrapped <ResourceSelector />
     */
    renderAddMediaModal = () => {
        const { collection } = this.props;
        if (collection) {
            let title =
                (collection && (collection.get('titleFr') || collection.get('titleEn'))) || null;
            title = (title && sanitizeString(title)) || title;
            return (
                <Modal
                    controls={this.renderAddItemsActions(true)}
                    headerTitle={title}
                    onModalClose={this.handleCloseContentModal}
                    show={this.state.showAddMediaModal}
                    large
                >
                    <ResourceSelector
                        collection={collection}
                        onChange={this.handleSelectedMedia}
                        resource={'media'}
                        resourceListFetchAction={
                            mediaResource().thunks().fetchSecondaryPaginatedBySearchCriteria
                        }
                        resourceListSelector={mediaResource()
                            .selectors()
                            .selectSecondaryPaginatedBySearchCriteria()}
                        resourceListIsFetchingSelector={mediaResource()
                            .selectors()
                            .selectSecondaryPaginatedIsFetching()}
                        selectReadableMimeTypes={mediaResource()
                            .selectors()
                            .selectReadableMimeTypes()}
                    />
                </Modal>
            );
        } else {
            return null;
        }
    };

    renderCollectionThumbnail = (image) => <Thumbnail width="100px" height="57px" {...image} />;

    /**
     * Render's modal's controls for interaction with resource/content
     */
    renderContentModalActions = () => {
        const updatedAt = (this.props.collection && this.props.collection.updatedAt) || null;
        const timestamp = updatedAt && formatDate(updatedAt);
        return (
            <FlexContainer justifyContent="space-between" gutter="30">
                {timestamp ? (
                    <FlexItemContainer direction="column" textAlign="left">
                        <LabelSmall marginless>
                            <FormattedMessage id="global.lastUpdate" values={{ time: timestamp }} />
                        </LabelSmall>
                    </FlexItemContainer>
                ) : null}
                <ActionsContainer flexGrown="1.5">
                    <PrimaryButton
                        disabled={!this.props.collection}
                        onClick={this.handleOnSubmitContentModal}
                    >
                        {this.props.intl.formatMessage({
                            id: 'global.saveChanges',
                        })}
                    </PrimaryButton>
                    <SecondaryButton onClick={this.handleCloseContentModal}>
                        {this.props.intl.formatMessage({
                            id: 'global.cancel',
                        })}
                    </SecondaryButton>
                </ActionsContainer>
            </FlexContainer>
        );
    };

    /**
     * Render delete confirmation modal
     * Looks at collection from store, returns null if not found
     */
    renderDeleteContentModal = () => {
        const { collection } = this.props;
        if (collection) {
            let title =
                (collection && (collection.get('titleFr') || collection.get('titleEn'))) || null;
            title = (title && sanitizeString(title)) || title;
            return (
                <Modal
                    controls={
                        <ActionsContainer flexGrown="1.5">
                            <PrimaryButton onClick={this.handleDeleteSelectedContent}>
                                {this.props.intl.formatMessage({
                                    id: 'components.Collection.delete.action',
                                })}
                            </PrimaryButton>
                            <SecondaryButton onClick={this.handleOnShowCollectionModal}>
                                {this.props.intl.formatMessage({
                                    id: 'global.cancel',
                                })}
                            </SecondaryButton>
                        </ActionsContainer>
                    }
                    headerTitle={
                        <FormattedMessage id="components.Collection.delete" values={{ title }} />
                    }
                    onModalClose={this.handleCloseContentModal}
                    show={this.state.showDeleteModal}
                >
                    <FormattedMessage
                        id="components.Collection.delete.message"
                        values={{
                            title: <strong>{`"${title}"`}</strong>,
                        }}
                    />
                </Modal>
            );
        } else {
            return null;
        }
    };

    renderListActions = () => (
        <ReverseButton onClick={this.handleOnShowCollectionModal}>
            {this.props.intl.formatMessage({
                id: 'views.SingleCollectionPage.editCollection',
            })}
        </ReverseButton>
    );

    renderCollectionModal = () => {
        const { collection } = this.props;
        if (collection) {
            const id = collection && collection.get('id');
            let title =
                (collection && (collection.get('titleFr') || collection.get('titleEn'))) || null;
            title = (title && sanitizeString(title)) || title;

            return (
                <Modal
                    controls={this.renderContentModalActions()}
                    headerTitle={
                        <FormattedMessage id="components.Collection.edit" values={{ title }} />
                    }
                    flush
                    onModalClose={this.handleCloseContentModal}
                    show={this.state.showCollectionModal}
                    large
                >
                    <Collection
                        collectionId={id}
                        onDelete={this.handleShowDeleteModal}
                        onRef={this.handleOnRef()}
                        onUpdateRouting={`/collections/${id}`}
                    />
                </Modal>
            );
        } else {
            return null;
        }
    };

    renderCollectionManagementActions = (media: boolean = true) => {
        const addActionId = media
            ? 'views.SingleCollectionPage.Media.add'
            : 'views.SingleCollectionPage.Collection.add';

        return (
            <React.Fragment>
                <ReverseButton
                    onClick={
                        media
                            ? this.handleDetachMediaToCollection
                            : this.handleDetachCollectionToCollection
                    }
                    textOnly
                >
                    {this.props.intl.formatMessage({
                        id: 'views.SingleCollectionPage.delete.action',
                    })}
                </ReverseButton>
                <Divider height="30px" margin="0 20px" />
                <ReverseButton
                    onClick={
                        media
                            ? this.handleShowAddMediaModal()
                            : this.handleShowAddCollectionsModal()
                    }
                >
                    {this.props.intl.formatMessage({
                        id: addActionId,
                    })}
                </ReverseButton>
            </React.Fragment>
        );
    };

    renderImage = (image) => <Thumbnail {...image} />;

    /**
     * Renders main tab content based on selectedTabItemId from locale state
     * Displays <CollectionManagement /> with neccessary props
     */
    renderTabContent = () => {
        const { collection } = this.props;

        let content;
        switch (this.state.selectedTabItemId) {
            default:
            case RESOURCE_IDS.MEDIA:
                content = (
                    <CollectionManagement
                        collectionId={collection && collection.get('id')}
                        controls={this.renderCollectionManagementActions(true)}
                        empty={collection && collection.get('childContentCount') === 0}
                        onSelectChildrenToBeDeleted={this.handleSelectedMediaToBeDeleted}
                        order={collection && collection.get('mediaOrdering')}
                        resourceType={RESOURCE_IDS.MEDIA}
                        resourceListFetchAction={
                            mediaResource().thunks().fetchPaginatedBySearchCriteria
                        }
                        resourceListSelector={mediaResource()
                            .selectors()
                            .selectPaginatedBySearchCriteria()}
                        resourceListIsFetchingSelector={mediaResource()
                            .selectors()
                            .selectPaginatedIsFetching()}
                    />
                );
                break;
            case RESOURCE_IDS.COLLECTION:
                content = (
                    <CollectionManagement
                        collectionId={collection && collection.get('id')}
                        controls={this.renderCollectionManagementActions(false)}
                        empty={collection && collection.get('childContentCount') === 0}
                        order={collection && collection.get('collectionOrdering')}
                        onSelectChildrenToBeDeleted={this.handleSelectedCollectionsToBeDeleted}
                        resourceType={RESOURCE_IDS.COLLECTION}
                        resourceListFetchAction={
                            collectionResource().thunks().fetchPaginatedBySearchCriteria
                        }
                        resourceListSelector={collectionResource()
                            .selectors()
                            .selectPaginatedBySearchCriteria()}
                        resourceListIsFetchingSelector={collectionResource()
                            .selectors()
                            .selectPaginatedIsFetching()}
                    />
                );
                break;
        }
        return content;
    };

    render() {
        const { collection, computedMatch, errors, intl, user } = this.props;
        const role = user && user.get('role');

        /**
         * If still mounting display spinner
         */
        if (
            (this.state.loading && !errors.size) ||
            !collection ||
            (collection && collection.get('id') !== parseInt(computedMatch.params.id))
        ) {
            return <Loading />;
        }

        const id = collection && collection.get('id');
        let title = collection && (collection.get('titleFr') || collection.get('titleEn'));
        title = (title && sanitizeString(title)) || null;

        let desc =
            collection && (collection.get('descriptionFr') || collection.get('descriptionEn'));
        desc = (desc && sanitizeString(desc)) || null;

        const thumbnail = collection && collection.get('thumbnail');
        const thumbnailFile = thumbnail && thumbnail.get('file');

        return (
            <div>
                <Helmet>
                    <title>
                        {intl.formatMessage({
                            id: 'views.SingleCollectionPage.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={intl.formatMessage({
                            id: 'views.SingleCollectionPage.pageTitle',
                        })}
                    />
                </Helmet>
                <ControlPanelBreadCrumb
                    controls={this.renderListActions()}
                    title={title}
                    backTitleId="views.SingleCollectionPage.allCollections"
                    backTitleRoute="/content"
                    unpaddedTabs
                />
                <Container>
                    <InformationContainer>
                        <Table padding="16px 20px" valign="top">
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="views.SingleCollectionPage.tableHeaderFieldThumbnail" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="views.SingleCollectionPage.tableHeaderFieldTitle" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="views.SingleCollectionPage.tableHeaderFieldDescription" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="views.SingleCollectionPage.tableHeaderFieldAccessLevel" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <Td width="145px" height="93px">
                                        {thumbnailFile && (
                                            <Image
                                                alt={title}
                                                id={id}
                                                format="cropped"
                                                height="60px"
                                                render={this.renderImage}
                                                resource="collections"
                                                type="image"
                                                width="105px"
                                            />
                                        )}
                                    </Td>
                                    <Td minWidth="280px">
                                        <Label>{title}</Label>
                                        <LabelSmall marginless>
                                            <FormattedMessage
                                                id="views.SingleCollectionPage.tableBodyLabelId"
                                                values={{ id }}
                                            />
                                        </LabelSmall>
                                    </Td>
                                    <td>{limitString(desc)}</td>
                                    <Td minWidth="220px">
                                        <ToggleSwitchBoolean
                                            disabled={!role || (role && !isAdminOrAbove(role))}
                                            labelOn="views.SingleCollectionPage.isVisible.active"
                                            labelOff="views.SingleCollectionPage.isVisible.inactive"
                                            name="isVisible"
                                            onChange={this.handleChangeField('isVisible', true)}
                                            right
                                            value={collection && collection.get('isVisible')}
                                        />
                                        {/* <LineBreak height="20px" />
                                        <ToggleSwitchBoolean
                                            disabled={!role || (role && !isAdminOrAbove(role))}
                                            labelOn="views.SingleCollectionPage.isPrivate.active"
                                            labelOff="views.SingleCollectionPage.isPrivate.inactive"
                                            name="isPrivate"
                                            onChange={this.handleChangeField('isPrivate', true)}
                                            right
                                            value={collection && collection.get('isPrivate')}
                                        /> */}
                                    </Td>
                                </tr>
                            </tbody>
                        </Table>
                    </InformationContainer>
                    <LineBreak height="20px" />
                    <ContentContainer>
                        <Indented margin="0 20px">
                            <SectionTabs
                                onItemClick={this.handleTabItemClick}
                                selectedTabItemId={this.state.selectedTabItemId}
                                tabs={fromJS([
                                    {
                                        id: 'media',
                                        string: 'views.SingleCollectionPage.tabMedia',
                                    },
                                    {
                                        id: 'collection',
                                        string: 'views.SingleCollectionPage.tabCollections',
                                    },
                                ])}
                                unpaddedTabs
                            />
                        </Indented>
                        {this.renderTabContent()}
                    </ContentContainer>
                </Container>
                {this.renderDeleteContentModal()}
                {this.renderCollectionModal()}
                {this.renderAddCollectionModal()}
                {this.renderAddMediaModal()}
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    collection: collectionResource()
        .selectors()
        .selectSingle(),
    categories: categoryResource()
        .selectors()
        .selectAll(),
    errors: collectionResource()
        .selectors()
        .selectErrors(),
    user: selectUser(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            batchAttachChildCollections: collectionResource().thunks().batchAttachChildCollections,
            batchAttachMedia: collectionResource().thunks().batchAttachMedia,
            batchDetachChildCollections: collectionResource().thunks().batchDetachChildCollections,
            batchDetachMedia: collectionResource().thunks().batchDetachMedia,
            fetchCollection: collectionResource().thunks().fetch,
            fetchCategories: categoryResource().thunks().fetchAll,
            updateCollection: collectionResource().thunks().update,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(SingleCollectionPage));
