// @flow

import { createSelector } from 'reselect';

/*
 *
 * CRUDResource selectors
 *
 * crudSelectors: Select subset of resource's state
 *
 */

const crudSelectors = (resourceName: string) => {
    // Direct selector to the resource
    const selectResourceState = () => (state) => state.get(`${resourceName.toLowerCase()}`);

    return {
        select: (id: number) =>
            createSelector(
                selectResourceState(),
                (substate) => (id ? substate.getIn(['list', id]) : null)
            ),
        selectDeleted: () =>
            createSelector(selectResourceState(), (substate) => substate.get('deleted')),
        selectErrors: () =>
            createSelector(selectResourceState(), (substate) => substate.get('errors')),
        selectNew: () => createSelector(selectResourceState(), (substate) => substate.get('new')),
        selectPaginatedBySearchCriteria: () =>
            createSelector(selectResourceState(), (substate) =>
                substate.get('paginatedBySearchCriteriaList')
            ),
        selectPaginatedIsFetching: () =>
            createSelector(selectResourceState(), (substate) =>
                substate.get('paginatedListIsFetching')
            ),
        selectSecondaryPaginatedBySearchCriteria: () =>
            createSelector(selectResourceState(), (substate) =>
                substate.get('secondaryPaginatedBySearchCriteriaList')
            ),
        selectSecondaryPaginatedIsFetching: () =>
            createSelector(selectResourceState(), (substate) =>
                substate.get('secondaryPaginatedListIsFetching')
            ),
        selectSingle: () =>
            createSelector(selectResourceState(), (substate) => substate.get('single')),
    };
};

export default crudSelectors;
