// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import ResourceManagement from 'components/ResourceManagement';
import Modal from 'components/Modal';
import ApplicationForm from 'components/ApplicationForm';

// Services
import applicationResource from 'services/Application';

// Styles
import { PrimaryButton } from 'styles/buttons';

// Types
import type { ApplicationType, IntlType } from 'types';

// Utils
import { RESOURCE_IDS } from 'utils/constants';

type Props = {
    /** injectIntl for formatMessage strings */
    intl: IntlType,
};

type State = {
    showApplicationModal: boolean,
    selectedApplication: ?ApplicationType,
};

/**
 * Site Application Page.
 *
 * Index view to create & modify site applications.
 *
 */

export class ApplicationsPage extends React.Component<Props, State> {
    state = {
        showApplicationModal: false,
        selectedApplication: null,
    };

    /**
     * Toggles the visibility of the application modal
     * @param nullifyApplication as true to remove application's object from local state
     */
    handleShowModal = (showApplicationModal: boolean, nullifyApplication?: boolean) => () => {
        this.setState({
            showApplicationModal,
        });

        if (nullifyApplication) {
            this.setState({
                selectedApplication: null,
            });
        }
    };

    /**
     * Set selected application in local state & show application modal
     */
    handleOnItemModifyAction = (application: ApplicationType) => {
        this.setState({
            selectedApplication: application,
            showApplicationModal: true,
        });
    };

    /**
     * Renders list of actions for control panel
     */
    renderListActions = () => (
        <PrimaryButton onClick={this.handleShowModal(true)}>
            {this.props.intl.formatMessage({
                id: 'containers.ApplicationsPage.newAction',
            })}
        </PrimaryButton>
    );

    render() {
        return (
            <div>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'containers.ApplicationsPage.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'containers.ApplicationsPage.pageTitle',
                        })}
                    />
                </Helmet>
                {/* Index of current resource */}
                <ResourceManagement
                    onItemModifyAction={this.handleOnItemModifyAction}
                    controls={this.renderListActions()}
                    resourceType={RESOURCE_IDS.APPLICATION}
                    resourceListFetchAction={
                        applicationResource().thunks().fetchPaginatedBySearchCriteria
                    }
                    resourceListSelector={applicationResource()
                        .selectors()
                        .selectPaginatedBySearchCriteria()}
                    resourceListIsFetchingSelector={applicationResource()
                        .selectors()
                        .selectPaginatedIsFetching()}
                    tabItemsListFetchAction={null}
                    tabItemsListSelector={null}
                    resourceTitleId="components.ResourceIndex.Applications.title"
                />
                {/* Modal of current resource */}
                <Modal
                    headerTitle={
                        <FormattedMessage
                            id={
                                this.state.selectedApplication
                                    ? 'components.Application.Edit'
                                    : 'components.Application.Create'
                            }
                            values={{
                                name: this.state.selectedApplication
                                    ? this.state.selectedApplication.name
                                    : null,
                            }}
                        />
                    }
                    onModalClose={this.handleShowModal(false, true)}
                    show={this.state.showApplicationModal}
                    flush
                >
                    <ApplicationForm
                        applicationId={
                            this.state.selectedApplication && this.state.selectedApplication.id
                        }
                        onModalShow={this.handleShowModal}
                    />
                </Modal>
            </div>
        );
    }
}

export default injectIntl(ApplicationsPage);
