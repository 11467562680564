// @flow

import { createSelector } from 'reselect';

/*
 *
 * CRUDResource selectors
 *
 * crudSelectors: Select subset of resource's state
 *
 */

const crudSelectors = (resourceName: string) => {
    // Direct selector to the resource
    const selectResourceState = () => (state) => state.get(`${resourceName.toLowerCase()}`);

    return {
        selectAll: () =>
            createSelector(selectResourceState(), (substate) => substate.get('list').toList()),
    };
};

export default crudSelectors;
