// @flow

import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import Input from 'components/Form/Input';
import Loading from 'components/Loading';
import ErrorMessage from 'components/ErrorMessage';
import RFFErrorMessage from 'components/RFFErrorMessage';
import Select from 'components/Form/Select';

import establishmentResource from 'services/Establishment';
import userResource from 'services/User';

import { FlexItemContainer, FlexRowContainer, Line } from 'styles/common';
import { PrimaryButton } from 'styles/buttons';
import {
    FormContainer,
    FormGroup,
    Label,
    SubmitActionContainer,
    ModifyActionContainer,
    ResetActionContainer,
    ChangesAppliedMessageContainer,
} from 'styles/form';

import { titles } from 'utils/constants';
import { required, passwordConfirmationMatch } from 'utils/form';

import type { EstablishmentType, ImmutableMap, IntlType, UserType } from 'types';

import { Container } from './styles';

type Props = {
    errors: ?ImmutableMap<string, Object>,
    establishments: ImmutableMap<string, EstablishmentType>,
    getEstablishments: Function,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    updateUser: Function,
    user: ?ImmutableMap<string, UserType>,
};

type State = {
    modifying: ?boolean,
    changesApplied: ?boolean,
};

class SettingsForm extends Component<Props, State> {
    state = {
        modifying: false,
        changesApplied: false,
    };

    componentDidMount() {
        if (!this.props.establishments.size) {
            this.props.getEstablishments();
        }
    }

    componentWillReceiveProps(newProps: Props) {
        if (this.props.user && !this.props.user.equals(newProps.user)) {
            this.setState({
                modifying: false,
                changesApplied: true,
            });
        }
    }

    // Check if component is loading (to decide if spinner should be shown for the entire page)
    isLoading = () => !this.props.user;

    getValidationState = (error: ?boolean) => (error ? 'error' : null);

    getProcessedErrorResponseForField = (fieldName: string) =>
        this.props.errors &&
        this.props.errors.size &&
        this.props.errors.getIn(['data', 'errors', fieldName, '0', 'code']);

    handleModify = ({ target: { value } }: InputEvent) => {
        this.setState({
            modifying: true,
            changesApplied: false,
        });
    };

    handleSubmitForm = (values) => {
        this.props.updateUser(values.id, {
            title: values.title,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            password: values.newPassword,
            passwordConfirmation: values.newPasswordConfirmation,
            establishmentId: values.establishmentId,
        });
    };

    renderForm = ({ handleSubmit, reset, submitting, pristine, values }) => (
        <form onSubmit={handleSubmit}>
            <FlexRowContainer justifyContent="flex-start">
                <FlexItemContainer direction="column" maxWidth="75px" justifyContent="flex-start">
                    <FormGroup
                        validationState={this.getValidationState(
                            this.getProcessedErrorResponseForField('firstName')
                        )}
                    >
                        <Field name="title">
                            {({ input, meta }) => (
                                <div>
                                    <Label htmlFor="title">
                                        <FormattedMessage id="components.User.title" />
                                    </Label>
                                    <Select
                                        {...input}
                                        disabled={!this.state.modifying}
                                        options={titles.map((value: string) => ({
                                            key: value,
                                            value,
                                        }))}
                                    />
                                </div>
                            )}
                        </Field>
                    </FormGroup>
                </FlexItemContainer>
            </FlexRowContainer>

            <FlexRowContainer justifyContent="space-between">
                <FlexItemContainer maxWidth={'calc(50% - 5px)'} direction={'column'}>
                    <FormGroup
                        validationState={this.getValidationState(
                            this.getProcessedErrorResponseForField('firstName')
                        )}
                    >
                        <Field name="firstName" validate={required}>
                            {({ input, meta }) => (
                                <div>
                                    <Label>
                                        {this.props.intl.formatMessage({
                                            id:
                                                'components.Forms.SettingsForm.inputLabelValueFirstName',
                                        })}
                                    </Label>
                                    <Input
                                        {...input}
                                        type="text"
                                        placeholder={
                                            'components.Forms.SettingsForm.inputPlaceholderFirstName'
                                        }
                                        disabled={!this.state.modifying}
                                    />
                                </div>
                            )}
                        </Field>
                    </FormGroup>
                </FlexItemContainer>
                <FlexItemContainer maxWidth={'calc(50% - 5px)'} direction={'column'}>
                    <FormGroup
                        validationState={this.getValidationState(
                            this.getProcessedErrorResponseForField('lastName')
                        )}
                    >
                        <Field name="lastName" validate={required}>
                            {({ input, meta }) => (
                                <div>
                                    <Label>
                                        {this.props.intl.formatMessage({
                                            id:
                                                'components.Forms.SettingsForm.inputLabelValueLastName',
                                        })}
                                    </Label>
                                    <Input
                                        {...input}
                                        type="text"
                                        placeholder={
                                            'components.Forms.SettingsForm.inputPlaceholderLastName'
                                        }
                                        disabled={!this.state.modifying}
                                    />
                                </div>
                            )}
                        </Field>
                    </FormGroup>
                </FlexItemContainer>
            </FlexRowContainer>

            <FlexRowContainer>
                <FlexItemContainer direction="column" justifyContent="flex-start">
                    <FormGroup
                        validationState={this.getValidationState(
                            this.getProcessedErrorResponseForField('establishment')
                        )}
                    >
                        <Field name="establishmentId">
                            {({ input, meta }) => (
                                <div>
                                    <Label htmlFor="establishmentId">
                                        <FormattedMessage id="components.User.location" />
                                    </Label>
                                    <Select
                                        {...input}
                                        disabled={!this.state.modifying}
                                        options={this.props.establishments.toJS().map((est) => ({
                                            key: est.id,
                                            value: est.name,
                                        }))}
                                    />
                                </div>
                            )}
                        </Field>
                    </FormGroup>
                </FlexItemContainer>
            </FlexRowContainer>

            <FormGroup
                validationState={this.getValidationState(
                    this.getProcessedErrorResponseForField('email')
                )}
            >
                <Field name="email" validate={required}>
                    {({ input, meta }) => (
                        <div>
                            <Label>
                                {this.props.intl.formatMessage({
                                    id: 'components.Forms.SettingsForm.inputLabelValueEmail',
                                })}
                            </Label>
                            <Input
                                {...input}
                                type="email"
                                placeholder={'components.Forms.SettingsForm.inputPlaceholderEmail'}
                                disabled
                            />
                        </div>
                    )}
                </Field>
            </FormGroup>
            <FlexRowContainer justifyContent="space-between">
                <FlexItemContainer maxWidth={'calc(50% - 5px)'} direction={'column'}>
                    <FormGroup
                        validationState={this.getValidationState(
                            this.getProcessedErrorResponseForField('password')
                        )}
                    >
                        <Field name="newPassword" validate={passwordConfirmationMatch}>
                            {({ input, meta }) => (
                                <div>
                                    <Label>
                                        {this.props.intl.formatMessage({
                                            id:
                                                'components.Forms.SettingsForm.inputLabelValuePassword',
                                        })}
                                    </Label>
                                    <Input
                                        {...input}
                                        type="password"
                                        placeholder={'global.password'}
                                        disabled={!this.state.modifying}
                                    />
                                </div>
                            )}
                        </Field>
                    </FormGroup>
                </FlexItemContainer>
                {this.state.modifying && (
                    <FlexItemContainer maxWidth={'calc(50% - 5px)'} direction={'column'}>
                        <FormGroup
                            validationState={this.getValidationState(
                                this.getProcessedErrorResponseForField('password')
                            )}
                        >
                            <Field
                                name="newPasswordConfirmation"
                                validate={passwordConfirmationMatch}
                            >
                                {({ input, meta }) => (
                                    <div>
                                        <Label>
                                            {this.props.intl.formatMessage({
                                                id:
                                                    'components.Forms.SettingsForm.inputLabelValuePasswordConfirmation',
                                            })}
                                        </Label>
                                        <Input
                                            {...input}
                                            type="password"
                                            placeholder={'global.password'}
                                            disabled={!this.state.modifying}
                                        />
                                    </div>
                                )}
                            </Field>
                        </FormGroup>
                    </FlexItemContainer>
                )}
            </FlexRowContainer>
            {/* Front end errors */}
            <RFFErrorMessage
                name="firstName"
                fieldIdText="components.Forms.SettingsForm.inputLabelValueFirstName"
            />
            <RFFErrorMessage
                name="lastName"
                fieldIdText="components.Forms.SettingsForm.inputLabelValueLastName"
            />
            <RFFErrorMessage
                name="email"
                fieldIdText="components.Forms.SettingsForm.inputLabelValueEmail"
            />
            <RFFErrorMessage
                name="newPassword"
                fieldIdText="components.Forms.SettingsForm.inputLabelValuePassword"
            />
            {/* Back end errors */}
            <ErrorMessage
                basicErrorStyles
                error={
                    this.getProcessedErrorResponseForField('firstName')
                        ? this.props.errors.getIn(['data', 'errors', 'firstName'])
                        : null
                }
                values={{ attribute: 'components.Forms.SettingsForm.inputLabelValueFirstName' }}
            />
            <ErrorMessage
                basicErrorStyles
                error={
                    this.getProcessedErrorResponseForField('lastName')
                        ? this.props.errors.getIn(['data', 'errors', 'lastName'])
                        : null
                }
                values={{ attribute: 'components.Forms.SettingsForm.inputLabelValueLastName' }}
            />
            <ErrorMessage
                basicErrorStyles
                error={
                    this.getProcessedErrorResponseForField('email')
                        ? this.props.errors.getIn(['data', 'errors', 'email'])
                        : null
                }
                values={{ attribute: 'components.Forms.SettingsForm.inputLabelValueEmail' }}
            />
            <ErrorMessage
                basicErrorStyles
                error={
                    this.getProcessedErrorResponseForField('password')
                        ? this.props.errors.getIn(['data', 'errors', 'password'])
                        : null
                }
                values={{ attribute: 'components.Forms.SettingsForm.inputLabelValuePassword' }}
            />
            <ErrorMessage
                basicErrorStyles
                error={
                    this.getProcessedErrorResponseForField('internalServerError')
                        ? this.props.errors.getIn(['data', 'errors', 'internalServerError'])
                        : null
                }
            />

            <Line margin="10px 0 0 0" />

            <FlexRowContainer justifyContent="flex-start" className="buttons" alignItems="center">
                {!this.state.modifying && (
                    <ModifyActionContainer>
                        <PrimaryButton grayBackground onClick={this.handleModify}>
                            {this.props.intl.formatMessage({
                                id: 'components.Forms.SettingsForm.buttonLabelModify',
                            })}
                        </PrimaryButton>
                    </ModifyActionContainer>
                )}
                {this.state.changesApplied && (
                    <ChangesAppliedMessageContainer>
                        <FormattedMessage id="components.Forms.SettingsForm.updateSuccessMessage" />
                    </ChangesAppliedMessageContainer>
                )}
                {this.state.modifying && (
                    <SubmitActionContainer>
                        <PrimaryButton disabled={submitting || pristine}>
                            {this.props.intl.formatMessage({
                                id: 'components.Forms.SettingsForm.buttonLabelSubmit',
                            })}
                        </PrimaryButton>
                    </SubmitActionContainer>
                )}
                {this.state.modifying && (
                    <ResetActionContainer>
                        <PrimaryButton
                            grayBackground
                            onClick={reset}
                            disabled={submitting || pristine}
                        >
                            {this.props.intl.formatMessage({
                                id: 'components.Forms.SettingsForm.buttonLabelReset',
                            })}
                        </PrimaryButton>
                    </ResetActionContainer>
                )}
            </FlexRowContainer>
        </form>
    );

    render() {
        if (this.isLoading()) {
            return <Loading loading />;
        }

        return (
            <Container>
                <FormContainer>
                    <Form
                        onSubmit={this.handleSubmitForm}
                        initialValues={this.props.user && this.props.user.toJS()}
                        render={this.renderForm}
                    />
                </FormContainer>
            </Container>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    errors: userResource()
        .selectors()
        .selectErrors(),
    establishments: establishmentResource()
        .selectors()
        .selectAll(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            getEstablishments: establishmentResource().thunks().fetchAll,
            updateUser: userResource().thunks().update,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(SettingsForm));
