// @flow

import styled from 'styled-components';

import colors from 'styles/colors';

const Dropdown = styled.select`
    background-color: white;
`;

const Option = styled.option``;

const Item = styled.div`
    display: inline-block;
`;

const LangContainer = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 20px;
`;

const LangInner = styled.div`
    width: 100%;
    padding-top: 30px;
    padding-bottom: 10px;

    border-top: 1px solid ${colors.grayE1};
    text-align: center;

    div button {
        color: ${colors.gray64};

        &:disabled {
            color: ${colors.black};
        }
    }
`;

const List = styled.div`
    margin: 0 auto;

    button {
        border: none;
        background: none;
        line-height: 18px;
        font-size: 14px;
        color: ${colors.grayB3};
        text-transform: capitalize;

        cursor: pointer;

        &:disabled {
            color: white;
            font-weight: 600;

            cursor: default;
        }
    }
`;

export { Dropdown, LangContainer, LangInner, List, Item, Option };
