// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { List } from 'immutable';

// Components
import AdvancedSearchForm from 'components/Form/AdvancedSearchForm';
import Modal from 'components/Modal';

// Styles
import { Divider } from 'styles/common';

// Styles
import {
    AdvancedSearchButton,
    AdvancedSearchContainer,
    AdvancedSearchCountLabel,
    SearchButton,
    SearchCancel,
    SearchContainer,
    SearchForm,
    SearchInner,
    SearchInput,
} from 'styles/form';

// Types
import type { ImmutableList, InputEvent, IntlType } from 'types';

type DefaultProps = {
    advancedSearch?: boolean,
    clearAdvancedSearch?: boolean,
    full?: boolean,
    onSearch?: (string) => void,
    onSearchChange?: (string) => void,
    placeholder?: string,
    resourceType?: string,
    resultCount?: number,
    searchValue?: string,
};

type Props = DefaultProps & {
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    onClearAdvancedSearch?: () => void,
};

type State = {
    advancedSearch: Object | null,
    advancedSearchModal: boolean,
    search: string,
};

/**
 * Shared search component with optional advancedSearch integration
 */

class Search extends React.Component<Props, State> {
    static defaultProps: DefaultProps = {
        advancedSearch: false,
        clearAdvancedSearch: false,
        instantSearch: false,
        full: false,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            advancedSearch: null,
            advancedSearchModal: false,
            search: props.searchValue || '',
        };
    }

    handleAdvancedSearchModal = () => {
        this.setState((prevState: State) => ({
            ...prevState,
            advancedSearchModal: !prevState.advancedSearchModal,
        }));
    };

    handleAdvancedSearchSubmitForm = (search: string, advancedSearch: Object) => (
        event: InputEvent
    ) => {
        this.setState(
            {
                advancedSearch,
                search,
            },
            () => {
                this.handleAdvancedSearchModal();
                this.handleSubmitForm(event);
            }
        );
    };

    handleCancelSearch = (clearAdvancedSearch?: boolean = false) => (event: InputEvent) => {
        event.persist();
        this.setState(
            (prevState: State) => ({
                advancedSearch: clearAdvancedSearch ? null : prevState.advancedSearch,
                search: '',
            }),
            () => {
                if (clearAdvancedSearch && this.props.onClearAdvancedSearch) {
                    this.props.onClearAdvancedSearch();
                }
                return (
                    this.props.onSearch &&
                    this.props.onSearch(this.state.search, this.state.advancedSearch)
                );
            }
        );
    };

    handleChangeField = (event: InputEvent) => {
        this.setState(
            {
                search: event.target.value,
            },
            () => this.props.onSearchChange && this.props.onSearchChange(this.state.search)
        );
        // debounce(500, this.props.onSearch(this.state.search));
    };

    handleKeyPress = (event: InputEvent) => {
        if (event.key === 'Enter') {
            this.handleSubmitForm(event);
        }
    };

    handleSubmitForm = (event: InputEvent) => {
        event.preventDefault();
        event.persist();
        if (this.props.onSearch) {
            this.props.onSearch(this.state.search, this.state.advancedSearch);
        }
    };

    render() {
        const { advancedSearch } = this.state;

        // If advancedSearch is present in state
        // Iterate over content & build out criteriaCount of selected/set criteria
        // Get size if list, else count as 1 for string
        let criteriaCount = 0;
        if (advancedSearch) {
            advancedSearch.forEach((criteria: string | ImmutableList<string>, key: string) => {
                if (criteria && List.isList(criteria)) {
                    criteriaCount += +Number(criteria.size);
                } else if (key === 'limitSearch') {
                    // Don't count "all" as it's the default option
                    if (criteria !== 'all') {
                        criteriaCount += 1;
                    }
                } else if (criteria && !['userId', 'role'].includes(key)) {
                    criteriaCount += 1;
                }
            });
        }

        return (
            <React.Fragment>
                <SearchContainer>
                    <SearchInner justifyContent="flex-start">
                        <SearchForm full={this.props.full} data-test-id="searchForm">
                            <SearchInput
                                name="search"
                                type="text"
                                placeholder={this.props.intl.formatMessage({
                                    id:
                                        this.props.placeholder ||
                                        'components.Search.searchPlaceholder',
                                })}
                                value={this.state.search}
                                onChange={this.handleChangeField}
                                data-test-id="searchInput"
                                onKeyDown={this.handleKeyPress}
                            />
                            <SearchButton title="Search" onClick={this.handleSubmitForm} />
                            <SearchCancel
                                onClick={this.handleCancelSearch(this.props.clearAdvancedSearch)}
                            />
                        </SearchForm>
                        {this.props.advancedSearch && (
                            <AdvancedSearchContainer>
                                <AdvancedSearchButton
                                    active={this.state.advancedSearch}
                                    onClick={this.handleAdvancedSearchModal}
                                >
                                    {this.props.intl.formatMessage({
                                        id: 'components.Search.advancedSearch',
                                    })}
                                </AdvancedSearchButton>
                                {advancedSearch && criteriaCount ? (
                                    <AdvancedSearchCountLabel>
                                        <FormattedMessage
                                            id="components.Search.selectedCriteriaCount"
                                            values={{ count: criteriaCount.toString() }}
                                        />
                                    </AdvancedSearchCountLabel>
                                ) : null}
                                {advancedSearch && criteriaCount && this.props.resultCount ? (
                                    <Divider height="20px" margin="0" />
                                ) : null}
                                {this.props.resultCount ? (
                                    <AdvancedSearchCountLabel>
                                        <FormattedMessage
                                            id="components.Search.resultCount"
                                            values={{ count: this.props.resultCount.toString() }}
                                        />
                                    </AdvancedSearchCountLabel>
                                ) : null}
                            </AdvancedSearchContainer>
                        )}
                    </SearchInner>
                </SearchContainer>
                {this.props.advancedSearch && (
                    <Modal
                        flush
                        headerTitle={<FormattedMessage id="components.Search.advancedSearch" />}
                        onModalClose={this.handleAdvancedSearchModal}
                        show={this.state.advancedSearchModal}
                    >
                        <AdvancedSearchForm
                            advancedSearchValues={this.state.advancedSearch}
                            onAdvancedSearchFormSubmit={this.handleAdvancedSearchSubmitForm}
                            onAdvancedSearchModal={this.handleAdvancedSearchModal}
                            searchValue={this.state.search}
                            resourceType={this.props.resourceType}
                        />
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}

export default injectIntl(Search);
