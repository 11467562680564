// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Styles
import { TabsContainer, SingleTab, Tabs } from './styles';
import { FlexContainer, FlexItem } from 'styles/common';

// Types
import type { ImmutableList, ImmutableMap, ReactNode } from 'types';

type DefaultProps = {
    children?: ReactNode,
    small?: boolean,
    unpaddedTabs?: boolean,
    white?: boolean,
};

type Props = DefaultProps & {
    /** ImmutableList of possible tabs */
    tabs: ?ImmutableList<ImmutableMap<string, string>>,
    /** Currently selected tab id */
    selectedTabItemId: string,
    onItemClick: ?Function,
};

/**
 * Shared section tabs
 */

class SectionTabs extends React.PureComponent<Props> {
    static defaultProps: DefaultProps = {
        children: null,
        small: false,
        unpaddedTabs: false,
        white: false,
    };

    render() {
        const {
            children,
            onItemClick,
            small,
            selectedTabItemId,
            tabs,
            unpaddedTabs,
            white,
        } = this.props;
        const list =
            tabs &&
            tabs.map((singleItem: ?ImmutableMap<string, string>) => (
                <SingleTab
                    key={singleItem && singleItem.get('id')}
                    selected={singleItem && singleItem.get('id') === selectedTabItemId}
                    onClick={onItemClick && onItemClick(singleItem.get('id'))}
                    isClickable
                >
                    <FormattedMessage
                        id={(singleItem && singleItem.get('string')) || ''}
                        values={
                            (singleItem &&
                                singleItem.get('values') &&
                                singleItem.get('values').toJS()) ||
                            {}
                        }
                    />
                </SingleTab>
            ));
        return (
            <TabsContainer small={small} unpaddedTabs={unpaddedTabs} white={white}>
                <FlexItem justifyContent="flex-end">
                    <Tabs small={small}>{list}</Tabs>
                </FlexItem>
                <FlexItem justifyContent="flex-end" maxWidth="120px">
                    {children ? (
                        <FlexContainer justifyContent="flex-end">{children}</FlexContainer>
                    ) : null}
                </FlexItem>
            </TabsContainer>
        );
    }
}

export default SectionTabs;
