// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Input, Label, Span, Text } from './ToggleSwitch/styles';

// Types
import type { InputEvent, IntlType } from 'types';

type DefaultProps = {
    disabled?: boolean,
    name?: string,
    onChange?: Function,
    onOrOff?: boolean,
    right?: boolean,
    title?: string,
    value?: boolean,
};

type Props = DefaultProps & {
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    /** intl id for "on" label */
    labelOn: string,
    /** intl id for "off" label */
    labelOff: string,
    changeToggleAfterHandlerExecution?: boolean,
};

type State = {
    value: boolean,
};

/**
 * Shared toggle switch for booleans with configuration
 */

class ToggleSwitchBoolean extends React.Component<Props, State> {
    static defaultProps: DefaultProps = {
        disabled: false,
        onOrOff: false,
        right: false,
        value: false,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            value: props.value || false,
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        if (
            nextProps.changeToggleAfterHandlerExecution ||
            (!this.props.onChange && nextProps.value !== this.props.value)
        ) {
            this.setState({
                value: nextProps.value,
            });
        }
    }

    handleChange = (event: InputEvent) => {
        event.persist();
        if (this.props.onChange) {
            if (!this.props.changeToggleAfterHandlerExecution) {
                this.setState(
                    {
                        value: event.target.checked,
                    },
                    () => {
                        this.props.onChange(event);
                    }
                );
            } else {
                this.props.onChange();
            }
        }
    };

    render() {
        const { disabled, onOrOff, name, intl, right, title } = this.props;
        let { labelOn, labelOff } = this.props;

        labelOn = intl.formatMessage({ id: labelOn });
        labelOff = intl.formatMessage({ id: labelOff });

        const TextToggle = (
            <Text disabled={!this.state.value || disabled} right={right}>
                {this.state.value ? labelOn : labelOff}
            </Text>
        );

        const TextOn = (
            <Text disabled={!this.state.value || disabled} right>
                {labelOn}
            </Text>
        );

        const TextOff = <Text disabled={this.state.value || disabled}>{labelOff}</Text>;

        const labelPart = (
            <Label title={title}>
                <Input
                    checked={this.state.value}
                    disabled={disabled}
                    name={name}
                    onChange={disabled ? null : this.handleChange}
                    type="checkbox"
                    value={!this.state.value}
                />
                <Span onOrOff={onOrOff} />
            </Label>
        );

        let comp;
        if (onOrOff) {
            comp = (
                <div>
                    {TextOff} {labelPart} {TextOn}
                </div>
            );
        } else if (right) {
            comp = (
                <div>
                    {labelPart} {TextToggle}
                </div>
            );
        } else {
            comp = (
                <div>
                    {TextToggle} {labelPart}
                </div>
            );
        }

        return comp;
    }
}

export default injectIntl(ToggleSwitchBoolean);
