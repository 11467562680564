// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const Label = styled.label`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border-radius: 2px;
    width: 30px;
    height: 16px;
`;

const Span = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${(props) => (props.full ? colors.teal : colors.grayE1)};
    -webkit-transition: 0.2s;
    transition: 0.2s;

    &:before {
        background-color: white;
        border-radius: 1px;
        bottom: 1px;
        content: '';
        height: 14px;
        left: 1px;
        position: absolute;
        transition: 0.2s;
        -webkit-transition: 0.2s;
        width: 14px;
    }
`;

const Input = styled.input`
    &:checked + ${Span} {
        background-color: ${colors.teal};
    }

    &:focus + ${Span} {
        box-shadow: 0 0 1px ${colors.teal};
    }

    &:checked + ${Span}:before {
        -webkit-transform: translateX(14px);
        -ms-transform: translateX(14px);
        transform: translateX(14px);
    }

    &:disabled + ${Span} {
        background-color: ${colors.grayE1};
        box-shadow: 0 0 1px ${colors.grayE1};
        cursor: default;
    }
`;

const Text = styled.span`
    display: inline-block;
    vertical-align: middle;
    color: ${(props) => (props.disabled ? colors.grayB3 : colors.gray15)};
    ${(props) => (props.right ? `margin-left: 10px;` : ` margin-right: 10px;`)};
`;

export { Input, Label, Span, Text };
