// @flow
/* eslint react/no-unused-prop-types: 0 */

import React from 'react';

// Assets
import audioPlaceholder from 'assets/image-placeholders/Full/audio-type.svg';
import audioPlaceholderCropped from 'assets/image-placeholders/Cropped/audio-type.svg';
import documentPlaceholder from 'assets/image-placeholders/Full/document-type.svg';
import documentPlaceholderCropped from 'assets/image-placeholders/Cropped/document-type.svg';
import illustrationPlaceholder from 'assets/image-placeholders/Full/illustration-type.svg';
import illustrationPlaceholderCropped from 'assets/image-placeholders/Cropped/illustration-type.svg';
import imagePlaceholder from 'assets/image-placeholders/Full/image-type.svg';
import imagePlaceholderCropped from 'assets/image-placeholders/Cropped/image-type.svg';
import linkPlaceholder from 'assets/image-placeholders/Full/link-type.svg';
import linkPlaceholderCropped from 'assets/image-placeholders/Cropped/link-type.svg';
import videoPlaceholder from 'assets/image-placeholders/Full/video-type.svg';
import videoPlaceholderCropped from 'assets/image-placeholders/Cropped/video-type.svg';

// ENV vars
import { API_URL, API_VER } from 'env';

// Utils
import { queryBuilder } from 'utils';

type DefaultProps = {
    format?: string,
    type?: string,
    changeOfResourceIdInProcess?: boolean,
};

type Props = DefaultProps & {
    id?: number | string,
    itunes: boolean,
    render: Function,
    resource: string,
    showPlaceholder?: boolean,
};

type State = {
    loading: boolean,
    src: ?string,
    timestamp: number,
};

/**
 * Shared image component with placeholder (type dependent) fallback
 */

class Image extends React.Component<Props, State> {
    static defaultProps: DefaultProps = {
        itunes: false,
        resource: 'media',
        showPlaceholder: false,
        type: '',
    };

    state = {
        loading: true,
        src: null,
        timestamp: Date.now(),
    };

    componentDidMount() {
        if (!this.props.changeOfResourceIdInProcess) {
            this.displayImageOrPlaceholder(this.props);
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.id) {
            this.displayImageOrPlaceholder(nextProps);
        }
    }

    displayImageOrPlaceholder = (props: Props) => {
        if (props.showPlaceholder) {
            this.setState({
                loading: false,
                src: this.getPlaceholderSrc(props.type, props.format === 'cropped'),
            });
        } else {
            const params = {
                format: this.props.format || null,
                itunes: this.props.itunes || null,
                // timestamp: this.state.timestamp || null,
            };

            const query = `?${queryBuilder(params)}`;
            const endpoint = props.format ? 'thumbnail' : 'view';

            const url = `${API_URL}${API_VER}${props.resource}/${endpoint}/${props.id}${query}`;

            this.setState({
                loading: false,
                src: url,
            });
        }
    };

    getPlaceholderSrc = (type: string, cropped: boolean = false) => {
        let filePlaceholder;
        switch (type) {
            case 'audio':
                filePlaceholder = cropped ? audioPlaceholderCropped : audioPlaceholder;
                break;
            case 'video':
                filePlaceholder = cropped ? videoPlaceholderCropped : videoPlaceholder;
                break;
            case 'illustration':
                filePlaceholder = cropped
                    ? illustrationPlaceholderCropped
                    : illustrationPlaceholder;
                break;
            case 'image':
                filePlaceholder = cropped ? imagePlaceholderCropped : imagePlaceholder;
                break;
            case 'link':
                filePlaceholder = cropped ? linkPlaceholderCropped : linkPlaceholder;
                break;
            case 'document':
            default:
                filePlaceholder = cropped ? documentPlaceholderCropped : documentPlaceholder;
        }
        return filePlaceholder;
    };

    render() {
        const { loading, src } = this.state;

        if (loading) {
            return null;
        }

        return this.props.render({ src, ...this.props });
    }
}

export default Image;
