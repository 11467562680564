// @flow

import pluralize from 'pluralize';
import { replace } from 'react-router-redux';

import type { ReduxDispatch, ResponseErrorType } from 'types';
import crudResources from './resources';
import { crudResourceActions } from './actions';

/*
 *
 * CRUDResource thunks
 *
 * Basic function: Check response and dispatch action to save data/error to redux store
 *
 */

const crudThunks = (resourceName: string) => ({
    /*
    * Create: Create a single entry of the resource
    */
    create: (resource: Object) => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .create(resource)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedCreateFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data.data || response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedCreateSuccess(
                            response.data.data || response.data
                        )
                    );
                    dispatch(replace(`/${pluralize(resourceName.toLowerCase())}`));
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(crudResourceActions((resourceName: string)).receivedCreateFailure(error));
            }),

    /*
    * fetch: Fetch a single entry of the resource by id
    */
    fetch: (id: string) => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .fetch(id)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data.data || response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchSuccess(
                            response.data.data || response.data
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(crudResourceActions((resourceName: string)).receivedFetchFailure(error));
            }),

    /*
    * fetchPaginatedBySearchCriteria: Fetch paginated entries of the resource with search criteria for filtering
    *
    * For the paginated API we keep the entire response.data so we keep the pagination response info,
    *           such as the total of pages for the fetch, not just the resource data list)
    */
    fetchPaginatedBySearchCriteria: (searchCriteria: Object, page: number, perPage: number) => (
        dispatch: ReduxDispatch
    ) => {
        // Set isFetching to true for loading animation
        dispatch(
            crudResourceActions((resourceName: string)).receivedFetchPaginatedIsFetching(true)
        );
        crudResources((resourceName: string))
            .fetchPaginatedBySearchCriteria(searchCriteria, page, perPage)
            .then((response) => {
                // Set isFetching to false to remove loading animation
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchPaginatedIsFetching(
                        false
                    )
                );
                if (response.data.error) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedFetchPaginatedBySearchCriteriaFailure(response.data.errorMessage)
                    );
                } else if (response.data.data) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedFetchPaginatedBySearchCriteriaSuccess(response.data)
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                // Set isFetching to false to remove loading animation
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchPaginatedIsFetching(
                        false
                    )
                );
                dispatch(
                    crudResourceActions(
                        (resourceName: string)
                    ).receivedFetchPaginatedBySearchCriteriaFailure(error)
                );
            });
    },

    /*
    * Generate: Generate a key/name of the resource by name
    */
    generate: (name: string) => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .generate(name)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedGenerateFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedGenerateSuccess(
                            response.data
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedGenerateFailure(error)
                );
            }),

    /*
    * resetErrors: Reset errors
    */
    resetErrors: () => (dispatch: ReduxDispatch) =>
        dispatch(crudResourceActions((resourceName: string)).resetErrors()),

    /*
    * Update: Update a single entry of the resource
    */
    update: (id: string, resource: Object) => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .update(id, resource)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedUpdateFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data.data || response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedUpdateSuccess(
                            response.data.data || response.data
                        )
                    );
                    dispatch(replace(`/${pluralize(resourceName.toLowerCase())}`));
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(crudResourceActions((resourceName: string)).receivedUpdateFailure(error));
            }),
});

export default crudThunks;
