// @flow

import React from 'react';

type Props = {
    url: string,
    kind: 'subtitles' | 'chapters',
    language?: string,
};

/**
 * Video track component used by VideoPlayer
 */

const VideoTrack = ({ url, kind, language, ...rest }: Props) => (
    <track
        src={url}
        kind={kind}
        srcLang={language}
        label={language && language.toUpperCase()}
        {...rest}
    />
);

VideoTrack.defaultProps = {
    language: '',
};

export default VideoTrack;
