// @flow

import { decamelize } from 'humps';
import moment from 'moment';
import { List } from 'immutable';
import sanitizeHtml from 'sanitize-html';

// Constants
import { dateFormat, characterLimit } from 'utils/constants';
import { DEFAULT_LOCALE } from 'services/Language/actions';

// Types
import type { ImmutableMap } from 'types';

export const twoDigitInt = (int: number) => int && (int < 10 ? '0' : '') + int;

export const arrayOfDaysFromMonth = (monthByNumber: number = 6) => {
    const month = monthByNumber - 1; // Months are 0 based; http://momentjs.com/docs/#/get-set/month/
    const days = moment()
        .month(month)
        .daysInMonth();
    return Array.from({ length: days }, (v: number, k: number) => k + 1).map((day: number) => ({
        key: twoDigitInt(day),
        value: twoDigitInt(day),
    }));
};

export const arrayOfMonthNumbers = Array.from({ length: 12 }, (v: number, k: number) => k + 1).map(
    (month: number) => ({
        key: twoDigitInt(month),
        value: twoDigitInt(month),
    })
);

export const arrayOfMonths = Array.from({ length: 12 }, (v: number, k: number) => k + 1).map(
    (month: number = 0) => ({
        key: twoDigitInt(month),
        intlId: `global.month.${twoDigitInt(month).toString()}`,
    })
);

export const arrayOfYears = (start: number = 1992) => {
    const array = [];
    const current = new Date().getFullYear();
    let st = start;
    while (st <= current) {
        array.push(st++);
    }
    return array;
};

// Converts bytes to friendly format
export const formatBytes = (a, b) => {
    if (a === 0) {
        return '0 Bytes';
    }
    const c = 1024;
    const d = b || 2;
    const e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const f = Math.floor(Math.log(a) / Math.log(c));
    return `${parseFloat((a / Math.pow(c, f)).toFixed(d))} ${e[f]}`;
};

// Validates that the input string is a valid date formatted as "mm/dd/yyyy"
export const isValidDate = (
    dateString: string,
    dateStringFormat: string = 'mm/dd/yyyy',
    strict: boolean = false
) => moment(dateString, dateStringFormat, strict).isValid();

// Convert mysql datetime to frontend date format
export const formatDate = (dateString: string, lang: string = DEFAULT_LOCALE) =>
    moment(dateString, 'YYYY-MM-DD HH:mm:ss')
        .locale(lang)
        .format(dateFormat);

export const createFormData = (data: Object) => {
    if (data) {
        // Remove empty fields. This matches what RequestHelper was doing on the backend.
        const newData = data
            .filter(
                (value) =>
                    (value && ((List.isList(value) && value.size !== 0) || !List.isList(value))) ||
                    value === 0 ||
                    value === false ||
                    value === ''
            )
            .toJS();

        // Create FormData
        const formData = new FormData();
        for (const topKey in newData) {
            if (newData.hasOwnProperty(topKey)) {
                const value = newData[topKey];
                if (Array.isArray(value) && value.length > 0) {
                    // Array of objects
                    value.forEach((val: Object, i: number) => {
                        // Append string/number
                        if (typeof val === 'string' || typeof val === 'number') {
                            formData.append(decamelize(`${topKey}[${i}]`), val);
                        }
                        // Loop over object content
                        if (typeof val === 'object') {
                            Object.keys(val).forEach((key: string) => {
                                if (val[key]) {
                                    formData.append(
                                        decamelize(`${topKey}[${i}][${key}]`),
                                        val[key]
                                    );
                                }
                            });
                        }
                    });
                } else {
                    formData.append(decamelize(topKey), value);
                }
            }
        }
        // Inspect formData
        // for (const pair of formData.entries()) {
        //     console.log('createFormData', pair);
        // }
        return formData;
    }
};

export const limitString = (string: string, length: number = characterLimit) => {
    if (!string) {
        return '';
    }

    const append = string.length > length ? ' ...' : '';
    return `${string.substring(0, length)}${append}`;
};

export const queryBuilder = (obj: Object) => {
    const esc = encodeURIComponent;
    // Build query
    const query = Object.keys(obj)
        .filter((k) => obj[k] !== null)
        .map((k) => `${esc(k)}=${esc(obj[k])}`)
        .join('&');
    return query;
};

export const getRouteFromLocation = (location: Object) => {
    // Pass location object from props
    const pathname = location && location.pathname;
    // Grab pathname from location, remove leading '/' via .replace()
    // Split string via '/'
    const parts = pathname && pathname.replace(/^\/+/g, '').split('/');
    // Return index 0 of parts, else null
    return (parts && parts[0]) || null;
};

export const getItemFromLocalStorage = (keyName: string) => localStorage.getItem(keyName) || null;

export const saveItemToLocalStorage = (keyName: string, keyValue: string | number = '') => {
    if (keyName) {
        localStorage.setItem(keyName, keyValue.toString());
    }
};

export const clearItemFromLocalStorage = (keyName) => {
    // Set array to hold keyNames
    const array = [];
    // Iterate over localStorage and insert the keyNames that meet the condition into array
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage && localStorage.key(i);
        if (key && key.indexOf(keyName) !== -1) {
            array.push(localStorage.key(i));
        }
    }
    // Iterate over array and remove the items by keyName
    for (let i = 0; i < array.length; i++) {
        const key = array[i];
        if (key) {
            localStorage.removeItem(key);
        }
    }
};

export const sentenceCase = (string: string) =>
    string && string.charAt(0).toUpperCase() + string.slice(1);

export const getReviewStatus = (singleItem: ImmutableMap<string, string>) => {
    // Base review status is no
    let reviewStatus = 'global.no';
    if (singleItem.get('reviewedStatus') === 1) {
        reviewStatus = 'global.yes';
    }

    return reviewStatus;
};

export const getTranslationStatus = (singleItem: ImmutableMap<string, string>) => {
    // Base translation status is no
    let translationStatus = 'global.no';
    if (singleItem.get('translatedStatus') === 1) {
        translationStatus = 'global.yes';
    }

    return translationStatus;
};

export const sanitizeString = (text: string) =>
    sanitizeHtml(text, {
        allowedTags: [],
    });
