// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import Modal from 'components/Modal';

// Styles
import { PrimaryButton, SecondaryButton } from 'styles/buttons';
import LogoutContainer from './styles';

// Types
import type { IntlType } from 'types';

type Props = {
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    onLogout: () => void,
};

type State = {
    isOpen: boolean,
};

/**
 * Shared logout component with confirmation model
 */

class Logout extends React.Component<Props, State> {
    state = {
        isOpen: false,
    };

    handleToggleModal = () => {
        this.setState((prevState, props) => ({ isOpen: !prevState.isOpen }));
    };

    renderButtons() {
        return (
            <div>
                <PrimaryButton
                    inline
                    onClick={this.props.onLogout}
                    data-test-id="logoutConfirmButton"
                >
                    <FormattedMessage id="components.Header.logout" />
                </PrimaryButton>
                <SecondaryButton
                    inline
                    onClick={this.handleToggleModal}
                    data-test-id="logoutCancelButton"
                >
                    <FormattedMessage id="components.ErrorMessage.cancel" />
                </SecondaryButton>
            </div>
        );
    }

    render() {
        return (
            <LogoutContainer>
                <PrimaryButton
                    grayBackground
                    onClick={this.handleToggleModal}
                    data-test-id="logoutModalButton"
                >
                    {this.props.intl.formatMessage({
                        id: 'components.Header.logout',
                    })}
                </PrimaryButton>
                <Modal
                    show={this.state.isOpen}
                    onModalClose={this.handleToggleModal}
                    headerTitle={<FormattedMessage id="components.Header.logout" />}
                    controls={this.renderButtons()}
                    small
                >
                    <p>
                        <FormattedMessage id="components.Header.logout.confirm" />
                    </p>
                </Modal>
            </LogoutContainer>
        );
    }
}

export default injectIntl(Logout);
