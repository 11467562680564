// @flow

import React from 'react';

// Assets
import Spinner from 'assets/loading.svg';

// Styles
import { Container, Content, LoadingImg } from './styles';

type Props = {
    inline?: boolean,
    loading?: boolean,
    height?: string,
    small?: boolean,
};

/**
 * Loading indicator with configuration
 */

function Loading(props: Props) {
    if (!props.loading) {
        return null;
    }

    return (
        <Container inline={props.inline} height={props.height}>
            <Content>
                <LoadingImg src={Spinner} alt="Loading" small={props.small} />
            </Content>
        </Container>
    );
}

Loading.defaultProps = {
    inline: false,
    loading: true,
    height: '',
    small: false,
};

export default Loading;
