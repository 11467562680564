// @flow

import React from 'react';
import { Switch } from 'react-router-dom';

import { Container } from 'styles/common';

import ApplicationsPage from 'views/ApplicationsPage';
import BasicLayout from 'components/Layouts/BasicLayout';
import ContentPage from 'views/ContentPage';
import ForgotPasswordPage from 'views/ForgotPasswordPage';
import HomePage from 'views/HomePage';
import LoginPage from 'views/LoginPage';
import NotFound from 'views/NotFound';
import PasswordCreateResetPage from 'views/PasswordCreateResetPage';
import RouteWithLayout from 'components/RouteWithLayout';
import SettingsPage from 'views/SettingsPage';
import SideNavLayout from 'components/Layouts/SideNavLayout';
import SingleCollectionPage from 'views/SingleCollectionPage';
import SingleMediaPage from 'views/SingleMediaPage';
import StatisticsPage from 'views/StatisticsPage';
import UsersPage from 'views/UsersPage';

import type { LogoutUserType } from 'services/Authentication/thunks';
import type { ImmutableMap, UserType } from 'types';

type Props = {
    user: ?ImmutableMap<string, UserType>,
    locale: string,
    handleToggleLocale: () => void,
    onLogout: LogoutUserType,
};

function Routes(props: Props) {
    return (
        <Container>
            <Switch>
                <RouteWithLayout
                    path="/"
                    component={HomePage}
                    layout={BasicLayout}
                    exact
                    isPrivateRoute
                    {...props}
                />
                <RouteWithLayout
                    path="/login"
                    component={LoginPage}
                    layout={BasicLayout}
                    exact
                    {...props}
                />
                <RouteWithLayout
                    path="/forgot-password"
                    component={ForgotPasswordPage}
                    layout={BasicLayout}
                    {...props}
                />
                <RouteWithLayout
                    path="/create-password/:token"
                    component={PasswordCreateResetPage}
                    layout={BasicLayout}
                    {...props}
                />
                <RouteWithLayout
                    path="/password-reset/:token"
                    component={PasswordCreateResetPage}
                    layout={BasicLayout}
                    {...props}
                />
                <RouteWithLayout
                    path="/password-reset/*"
                    component={ForgotPasswordPage}
                    layout={BasicLayout}
                    {...props}
                />
                <RouteWithLayout
                    path="/users"
                    component={UsersPage}
                    layout={SideNavLayout}
                    pageTitleTranslationKey="containers.UsersPage.pageTitle"
                    exact
                    isPrivateRoute
                    excludeRoles={['contributeur', 'reviseur', 'traducteur']}
                    {...props}
                />
                <RouteWithLayout
                    path="/applications"
                    component={ApplicationsPage}
                    layout={SideNavLayout}
                    pageTitleTranslationKey="containers.ApplicationsPage.pageTitle"
                    exact
                    isPrivateRoute
                    excludeRoles={['admin', 'contributeur', 'reviseur', 'traducteur']}
                    {...props}
                />
                <RouteWithLayout
                    path="/import"
                    component={ContentPage}
                    layout={SideNavLayout}
                    pageTitleTranslationKey="containers.MassImportPage.pageTitle"
                    exact
                    isPrivateRoute
                    excludeRoles={['contributeur', 'reviseur', 'traducteur']}
                    {...props}
                />
                <RouteWithLayout
                    path="/content/:modal?"
                    component={ContentPage}
                    layout={SideNavLayout}
                    pageTitleTranslationKey="containers.ContentPage.pageTitle"
                    exact
                    isPrivateRoute
                    {...props}
                />
                <RouteWithLayout
                    path="/media/:id"
                    component={SingleMediaPage}
                    layout={SideNavLayout}
                    pageTitleTranslationKey="containers.ContentPage.pageTitle"
                    exact
                    isPrivateRoute
                    {...props}
                />
                <RouteWithLayout
                    path="/collections/:id"
                    component={SingleCollectionPage}
                    layout={SideNavLayout}
                    pageTitleTranslationKey="containers.ContentPage.pageTitle"
                    exact
                    isPrivateRoute
                    {...props}
                />
                <RouteWithLayout
                    path="/statistics"
                    component={StatisticsPage}
                    layout={SideNavLayout}
                    pageTitleTranslationKey="containers.StatisticPage.pageTitle"
                    exact
                    isPrivateRoute
                    excludeRoles={['contributeur', 'reviseur', 'traducteur']}
                    {...props}
                />
                <RouteWithLayout
                    path="/profile"
                    component={SettingsPage}
                    layout={SideNavLayout}
                    pageTitleTranslationKey="containers.SettingsPage.pageTitle"
                    exact
                    isPrivateRoute
                    shouldDisplayLogout
                    userId={props.user ? props.user.get('id') : null}
                    {...props}
                />
                <RouteWithLayout component={NotFound} layout={BasicLayout} {...props} />
            </Switch>
        </Container>
    );
}

export default Routes;
