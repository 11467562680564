// @flow
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

// Utils
import { isAuthenticated } from 'utils/authentication';

/**
 * Component to be used for making routes private (can only be accessed by Authenticated users, otherwise sent to splash page for login)
 */

const PrivateRoute = ({ component: Component, ...props }: Object) => {
    if (isAuthenticated()) {
        /**
         * Check if user is one of excludedRoles, if so redirect to content page
         */
        const role = props.user && props.user.get('role');
        if (props.excludeRoles && props.excludeRoles.includes(role)) {
            return <Redirect to="/content" />;
        }
        /**
         * User is Authenticated
         */
        return <Route render={() => <Component {...props} />} />;
    }

    /**
     * User not authenticated, redirect to the login page
     */
    return <Redirect to="/login" />;
};

export default PrivateRoute;
