// @flow

import React, { type Node } from 'react';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';

// Components
import FileInput from 'components/Form/FileInput';
import Thumbnail from 'components/Image/Thumbnail';
import Image from 'components/Image';
import SquareImage from 'components/Image/SquareImage';

// Styles
import { FlexContainer, FlexItemContainer, LineBreak } from 'styles/common';
import { FormGroup, InputNotice } from 'styles/form';

// Types
import type { ImmutableMap } from 'types';

type Props = {
    onChangeField: Function,
    isLoading: boolean,
    data?: ImmutableMap<string, mixed>,
};

type State = {
    preview?: string,
    thumbnail: ?Node,
};

class CollectionThumbnailBlock extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const thumbnail = props.data && props.data.get('thumbnail');
        this.state = {
            preview: '',
            thumbnail,
        };
    }

    componentDidMount() {
        const { thumbnail } = this.state;
        const { data } = this.props;

        // If thumbnail is local, and not saved in DB set preview to base64 of image
        if (thumbnail && !(data && data.getIn(['thumbnail', 'id']))) {
            const reader = new FileReader();
            reader.onload = (e: Event) => {
                const preview = e.target.result;
                this.setState(
                    {
                        preview,
                    },
                    () => this.props.onChangeField('thumbnail', thumbnail)
                );
            };
            reader.readAsDataURL(thumbnail);
        }
    }

    handleImagePreview = (name: string) => (event: Event) => {
        const image = event && event.target.files[0];
        if (image) {
            const reader = new FileReader();
            reader.onload = (e: Event) => {
                const preview = e.target.result;
                if (preview.includes('image')) {
                    this.setState(
                        {
                            preview,
                            [name]: image,
                        },
                        () => this.props.onChangeField(name, this.state[name])
                    );
                }
            };
            reader.readAsDataURL(image);
        }
    };

    renderImage = (image) => <SquareImage cover={false} {...image} />;

    render() {
        const { data } = this.props;
        const dataId = data && data.get('id');
        const thumbnail = data && data.get('thumbnail');
        // Check if thumbnail has id (And therefore saved to DB)
        const savedThumbnail = thumbnail && Map.isMap(thumbnail) ? thumbnail : null;
        const thumbnailFile = thumbnail && savedThumbnail ? thumbnail.get('file') : null;

        return (
            <React.Fragment>
                <FormGroup>
                    <FlexContainer justifyContent="space-between" gutter="10">
                        <FlexItemContainer direction="column" justifyContent="center" collapsed>
                            {savedThumbnail ? (
                                <Thumbnail>
                                    <Image
                                        format="small"
                                        id={dataId}
                                        resource="collections"
                                        render={this.renderImage}
                                        type="image"
                                        width="160px"
                                    />
                                </Thumbnail>
                            ) : (
                                <Thumbnail square width="160px">
                                    {this.state.preview ? (
                                        <SquareImage cover={false} src={this.state.preview} />
                                    ) : (
                                        <FormattedMessage id="components.Media.editThumbnail" />
                                    )}
                                </Thumbnail>
                            )}
                        </FlexItemContainer>
                        <FlexItemContainer direction="column" justifyContent="flex-start">
                            <FlexContainer justifyContent="space-between" gutter="20">
                                <FileInput
                                    buttonId="components.CollectionThumbnailBlock.addFile"
                                    name="file"
                                    onChange={this.handleImagePreview('thumbnail')}
                                    disabled={this.props.isLoading}
                                    value={thumbnailFile && thumbnailFile.get('originalFilename')}
                                    inputless
                                />
                            </FlexContainer>
                            <LineBreak height="10px" />
                            <InputNotice subtle>
                                <FormattedMessage id="components.CollectionThumbnailBlock.notice" />
                            </InputNotice>
                        </FlexItemContainer>
                    </FlexContainer>
                </FormGroup>
            </React.Fragment>
        );
    }
}

export default CollectionThumbnailBlock;
