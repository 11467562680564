// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { List, fromJS } from 'immutable';

// Components
import ControlPanel from 'components/ControlPanel';
import SettingsForm from 'components/Forms/SettingsForm';

// Services
import userResource from 'services/User';

// Types
import type { IntlType } from 'types';

type Props = {
    /** triggers request to get user from backend */
    fetchUser: Function,
    /** provides access to current user */
    user: ?Object,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    /** provides access to current user's id */
    userId: ?number,
    /** triggers logout request to backend */
    onLogout: () => void,
};

/**
 * User Settings Page.
 *
 * User's profile/setting page to allow user to edit their own user
 *
 */

export class SettingsPage extends Component<Props> {
    /**
     * If userId, fetch user and provide to <SettingsForm />
     */
    componentDidMount() {
        // Retrieve user for display
        if (this.props.userId) {
            this.props.fetchUser(this.props.userId);
        }
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'containers.SettingsPage.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'containers.SettingsPage.pageTitle',
                        })}
                    />
                </Helmet>
                <ControlPanel
                    tabItemsList={List.of(
                        fromJS({
                            id: 1,
                            label: this.props.intl.formatMessage({
                                id: 'containers.SettingsPage.headerTab',
                            }),
                        })
                    )}
                    selectedTabItemId={1}
                    isClickable={false}
                    onLogout={this.props.onLogout}
                    shouldDisplayLogout
                    slim
                />
                <SettingsForm user={this.props.user} />
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    user: userResource()
        .selectors()
        .selectSingle(),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchUser: userResource().thunks().fetch,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(SettingsPage));
