// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

import { Link } from 'react-router-dom';

export const PrimaryButton = styled.button`
    display: inline-block;
    height: 30px;
    line-height: 14px;
    font-size: 14px;
    font-weight: 500;
    border: 0;
    cursor: pointer;
    color: ${(props) => (props.grayBackground ? colors.teal : colors.white)};
    background-color: ${(props) => (props.grayBackground ? colors.grayE1 : colors.teal)};
    transition: all 0.25s ease;
    white-space: nowrap;
    padding: 8px 15px;

    &:hover {
        background-color: ${colors.tealDark};
        color: ${colors.white};
        transition: all 0.25s ease;
    }

    a {
        display: block;
        text-decoration: none;
        color: ${colors.white};
    }

    ${(props) =>
        props.inline &&
        `
        display: inline-block;
        width: auto;
        padding: 0 20px;
        `};

    ${(props) =>
        props.disabled &&
        `
        background-color: ${colors.grayE1};
        color: ${colors.grayB3};
        cursor: default;
        &:hover {
            background-color: ${colors.grayE1};
            color: ${colors.grayB3};
        }
        `};
`;

export const PrimarySpan = PrimaryButton.withComponent('span');

export const SecondaryButton = PrimaryButton.extend`
    background-color: ${colors.tealLight};
`;

export const SecondarySpan = SecondaryButton.withComponent('span');

export const ReverseButton = PrimaryButton.extend`
    color: ${colors.teal};
    background-color: ${colors.grayED};

    ${(props) =>
        props.textOnly &&
        `
        background-color: transparent;
        color: ${colors.grayB3};
    `};

    ${(props) =>
        props.subtle &&
        `
        background-color: ${colors.grayFA};
        color: ${colors.tealLight};
    `};

    ${(props) =>
        props.disabled &&
        `
        color: ${colors.grayB3};
        background-color: ${colors.grayED};

        &:hover {
            color: ${colors.grayB3};
            background-color: ${colors.grayED};
        }

        cursor: default;
    `};
`;

export const ReverseLink = ReverseButton.withComponent(Link);
export const ReverseSpan = ReverseButton.withComponent('span');
