// @flow

import styled from 'styled-components';

import { BasicErrorMessage } from 'styles/common';
import colors from 'styles/colors';

const RFFErrorMessageContainer = BasicErrorMessage.extend`
`;

export { RFFErrorMessageContainer };
