// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

import Geosuggest from 'react-geosuggest';

import Caret from 'assets/icons/Caret/Dark/Down.svg';
import searchOnIcon from 'assets/icon-search-on.svg';
import searchOffIcon from 'assets/icon-search-off.svg';
import Cross from 'assets/icons/Cross/Default.svg';
import CrossHover from 'assets/icons/Cross/Hover.svg';

import { FlexRowContainer, FlexItem } from 'styles/common';

export const FormContainer = styled.div`
    padding-right: 20px;
    padding-left: 20px;
`;

export const FormGroup = styled.div`
    margin: ${(props) => (props.margin ? props.margin : '10px 0')};

    ${(props) =>
        props.validationState &&
        `
        input,
        select,
        textarea {
            border: 1px solid ${colors.redDark};
            color: ${colors.redDark};
        }
        input::placeholder {
            color: ${colors.redDark};
        }
        `};
`;

export const FormTitleGroup = styled.div`
    min-height: 34px;
    line-height: 18px;
    font-size: 16px;
    border-bottom: 1px solid ${colors.grayE1};
`;

export const FormTitle = styled.div`
    display: inline-block;
    padding-bottom: 15px;
    border-bottom: 1px solid ${colors.tealDark};
    margin-bottom: -1px;
`;

export const FormRow = styled.div`
    padding: 10px 20px;
    background-color: ${colors.white};
    border-bottom: 1px solid ${colors.grayED};
`;

export const Label = styled.label`
    display: block;
    margin-bottom: ${(props) => (props.marginless ? '0' : '10px')};
    color: ${colors.gray33};

    ${(props) =>
        props.disabled &&
        `
        color: ${colors.grayB3};
        `};
`;

export const LabelSmall = Label.extend`
    font-size: 12px;
    ${(props) =>
        props.subtle &&
        `
        color: ${colors.grayB3};
        `};
`;

export const InputNotice = LabelSmall.extend`
    margin-top: ${(props) => (props.marginTop ? props.marginTop : '5px')};
`;

export const LabelLarge = Label.extend`
    font-size: 16px;
`;

export const LabelSubtle = styled.span`
    color: ${colors.grayB3};
`;

export const SectionHeader = LabelLarge.extend`
    margin: ${(props) => (props.margin ? props.margin : '30px 0')};
    color: ${colors.tealDark};
`;

export const ButtonNotice = LabelSmall.extend`
    color: ${colors.gray64};
`;

// Don't use! Use 'components/Form/Input' instead
export const Input = styled.input`
    width: ${(props) => (props.width ? props.width : '100%')};
    height: 30px;
    font-size: 14px;
    line-height: 16px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid ${colors.grayE1};
    border-radius: 3px;

    &::placeholder {
        color: ${colors.gray64};
    }

    ${(props) =>
        props.disabled &&
        `
        color: ${colors.grayB3};
        &::placeholder {
            color: ${colors.grayB3};
        }
        `};

    ${(props) =>
        props.inset &&
        `
        border-top-width: 0;
        border-right-width: 0;
        border-left-width: 0;
        padding-left: 30px;
        padding-right: 30px;

        line-height: 50px;
        height: auto;
        `};
`;

export const SubmitActionContainer = styled.div`
    width: 110px;
    margin-top: 20px;
    margin-bottom: 5px;
`;

export const ModifyActionContainer = SubmitActionContainer.extend`
    margin-right: 20px;
`;

export const ResetActionContainer = SubmitActionContainer.extend`
    margin-left: 10px;
`;

export const ChangesAppliedMessageContainer = FlexRowContainer.extend`
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 5px;
`;

export const ErrorsContainer = styled.div`
    && > * {
        margin-top: 20px;
    }

    &&:last-child {
        color: ${colors.gray33};
    }
`;

export const SearchContainer = FlexItem.extend``;

export const SearchInner = FlexRowContainer.extend``;

export const AdvancedSearchContainer = FlexItem.extend``;

export const AdvancedSearchButton = styled.button`
    height: 28px;
    padding: 0 10px;

    border: 0 none;
    background-color: transparent;
    color: ${colors.gray33};
    font-size: 11px;

    cursor: pointer;

    ${(props) =>
        props.active &&
        `
        color: ${colors.teal};
        font-weight: 500;
    `};
`;

export const AdvancedSearchCountLabel = styled.span`
    padding: 0 10px;
    color: ${colors.gray64};
    font-size: 11px;
`;

export const SearchButton = styled.button`
    position: absolute;
    top: 0;
    left: 0;

    width: 30px;
    height: 30px;
    border: 0 none;

    background-color: transparent;
    background-size: auto 20px;
    background-position: center;

    cursor: pointer;

    transition: background-image 0.25s ease;

    background-image: url('${searchOffIcon}');
`;

export const SearchCancel = styled.button`
    position: absolute;
    top: 0;
    right: 0;

    width: 30px;
    height: 30px;
    border: 0 none;

    background-color: transparent;
    background-size: auto 20px;
    background-position: center;

    cursor: pointer;

    transition: background-image 0.25s ease;

    background-image: url('${Cross}');
    background-size: auto 16px;
    &:hover {
        background-image: url('${CrossHover}');
    }
`;

export const SearchForm = FlexItem.extend`
    position: relative;
    width: 100%;
    max-width: ${(props) => (props.full ? `none` : `210px`)};

    background-color: ${colors.white};
    border: 1px solid ${colors.grayE1};

    &:hover {
        > ${SearchButton} {
            background-image: url('${searchOnIcon}');
            transition: background-image 0.25s ease;
        }
    }

    + ${AdvancedSearchContainer} {
        margin-left: 10px;
    }
`;

export const SearchInput = styled.input`
    width: 100%;
    height: 28px;
    font-size: 14px;
    line-height: 16px;
    padding: 0 30px;
    background-color: transparent;
    border: none;
`;

export const Select = styled.select`
    background: url(${Caret}) no-repeat 98% center;
    background-color: ${colors.white};
    border: 1px solid ${colors.grayE1};
    border-radius: 3px;
    width: ${(props) => (props.full ? '100%' : 'auto')};
    height: 30px;
    line-height: 16px;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    padding: 5px 30px 5px 10px;

    option:first-child,
    option[value=''] {
        color: ${colors.gray64};
    }

    ${(props) =>
        props.empty &&
        `
        color: ${colors.gray64};
    `};

    ${(props) =>
        props.disabled &&
        `
        border: 1px solid ${colors.grayF4};
        color: ${colors.gray7F};
    `};

    ${(props) =>
        props.validationState &&
        `
            border: 1px solid ${colors.redDark};
            color: ${colors.redDark};
    `};
`;

export const Textarea = styled.textarea`
    border: 1px solid ${colors.grayE1};
    border-radius: 3px;
    padding: 10px;
    width: 100%;
    min-height: 50px;
    resize: vertical;
`;

export const FormNotice = styled.div`
    margin-top: ${(props) => (props.marginless ? '0' : '15px')};
    margin-bottom: ${(props) => (props.marginless ? '0' : '10px')};
    color: ${colors.gray4D};
    font-size: 14px;
    line-height: 20px;

    ${(props) =>
        props.error &&
        `
        color: ${colors.redDark};
        `};

    ${(props) =>
        props.subtle &&
        `
        color: ${colors.grayB3};
        `};
`;

export const SubmitContainer = styled.div`
    margin-top: 20px;
    margin-bottom: 5px;

    > button {
        display: inline-block;
        width: auto;
    }
`;

export const FormActionsContainer = FlexRowContainer.extend`
    background-color: ${colors.white};
    height: 70px;
`;

export const DefaultOption = styled.option`
    color: ${colors.gray64};
`;

export const TagList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;

    ${(props) =>
        props.disabled &&
        `
        li {
            color: ${colors.grayB3};
            border-color: ${colors.grayB3};
        }
    `};
`;

export const TagItem = styled.li`
    display: inline-block;
    margin: 2px;

    padding: 2px 15px;

    border: 1px solid ${(props) => (props.active ? colors.tealDark : colors.grayDD)};
    border-radius: 30px;

    color: ${colors.tealDark};

    font-size: 14px;
    line-height: 16px;
`;

export const StyledGeosuggest = styled(Geosuggest)`
    input {
        width: ${(props) => (props.width ? props.width : '100%')};
        height: 30px;
        font-size: 14px;
        line-height: 16px;
        padding-left: 10px;
        padding-right: 10px;
        border: 1px solid ${colors.grayE1};
        border-radius: 3px;

        &::placeholder {
            color: ${colors.gray64};
        }
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 10px;
        li {
            margin-bottom: 5px;
            &:last-child {
                margin: none;
            }

            cursor: pointer;
        }
    }
`;
