// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { Input } from 'styles/form';

// Types
import type { IntlType } from 'types';

type Props = {
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    placeholder?: string,
    type: string,
    value?: number | string,
};

/**
 * Shared input component
 */

class InputField extends React.PureComponent<Props> {
    static defaultProps = {
        placeholder: '',
        value: '',
    };

    render() {
        const { type, value, placeholder, intl, ...rest } = this.props;
        return (
            <Input
                key={rest.key || rest.name || rest.id}
                {...rest}
                type={type}
                value={value || ''}
                placeholder={placeholder && intl.formatMessage({ id: placeholder })}
            />
        );
    }
}

export default injectIntl(InputField);
