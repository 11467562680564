// @flow

import styled from 'styled-components';

import colors from 'styles/colors';

export const Paragraph = styled.p`
    margin: 5px 0;
    line-height: 18px;
    font-size: 14px;
    color: ${colors.redDark};
`;

export const Banner = Paragraph.extend`
    margin: 20px 0;
    padding: 10px 20px;
    background-color: ${colors.red};
    color: ${colors.white};
`;
