// @flow

import React from 'react';
import { DropTarget as dropTarget } from 'react-dnd';

// Styles
import { StyledThumbnail } from './styles';

// Types
import type { ReactNode } from 'types';

const boxTarget = {
    drop(props, monitor) {
        if (props.onDrop) {
            props.onDrop(props, monitor)(new Event('thumbnailDropped'));
        }
    },
};

type Props = {
    alt?: string,
    children?: ReactNode,
    maxWidth?: string,
    src?: string,
    square?: boolean,
    width?: string,
    connectDropTarget?: Function,
    isOver?: boolean,
    canDrop?: boolean,
    accepts?: Array<string>,
    onDrop?: Function,
};

/**
 * Shared thumbnail component with Drag & Drop via react-dnd
 */

const Thumbnail = (props: Props) => {
    const {
        alt,
        canDrop,
        children,
        connectDropTarget,
        isOver,
        maxWidth,
        src,
        square,
        width,
    } = props;
    let inner;
    if (src) {
        inner = <img src={src} alt={alt} />;
    } else if (children) {
        inner = children;
    }

    const isActive = canDrop && isOver;

    return connectDropTarget && inner
        ? connectDropTarget(
              <div>
                  <StyledThumbnail
                      maxWidth={maxWidth}
                      placeHolder={!src}
                      square={square}
                      width={width}
                      dragging={isActive}
                  >
                      {inner}
                  </StyledThumbnail>
              </div>
          )
        : null;
};

Thumbnail.defaultProps = {
    alt: '',
    children: null,
    maxWidth: '',
    src: '',
    square: false,
    width: '',
};

export default dropTarget((props) => props.accepts, boxTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop(),
}))(Thumbnail);
