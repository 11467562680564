// @flow

import pluralize from 'pluralize';
import request from 'request';
import { decamelize } from 'humps';

/*
 *
 * CRUDResource resources
 *
 * Basic function: call request() with expected paramaters
 *
 */

const crudResources = (resourceName: string) => ({
    /*
    * create: Create a single entry of the resource
    */
    create: (resource) =>
        request(`${pluralize(resourceName.toLowerCase())}`, {
            method: 'POST',
            data: resource,
        }),

    /*
    * fetch: Fetch a single entry of the resource by id
    */
    fetch: (id) => request(`${pluralize(resourceName.toLowerCase())}/${id}`, { method: 'GET' }),

    /*
    * fetchPaginatedBySearchCriteria: Fetch paginated entries of the resource with search criteria for filtering
    *
    * searchCriteriaUpdated: decamlizing sortBy to make backend happy
    */
    fetchPaginatedBySearchCriteria: (
        searchCriteria: Object,
        page: number = 1,
        perPage: number = 10
    ) => {
        let searchCriteriaUpdated;
        if (searchCriteria.sortBy) {
            searchCriteriaUpdated = {
                ...searchCriteria,
                sortBy: decamelize(searchCriteria.sortBy),
            };
        }
        return request(
            `${pluralize(resourceName.toLowerCase())}/fetchPaginatedBySearchCriteria`,
            {
                method: 'POST',
                data: searchCriteriaUpdated || searchCriteria,
            },
            { page, per_page: perPage }
        );
    },

    /*
    * genetate: Generate application key by name
    */
    generate: (name) =>
        request(
            `${pluralize(resourceName.toLowerCase())}/generate`,
            {
                method: 'POST',
            },
            { name }
        ),

    /*
    * update: Update a single entry of the resource by id
    */
    update: (id, resource) =>
        request(`${pluralize(resourceName.toLowerCase())}/${id}`, {
            method: 'PUT',
            data: resource,
        }),
});

export default crudResources;
