// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// Components
import Loading from 'components/Loading';
import Image from 'components/Image';
import CollectionThumbnail from 'components/Image/CollectionThumbnail';

// Styles
import { Line } from 'styles/common';
import { FormGroup } from 'styles/form';

import { Container, SingleItemContainer, CollectionName } from './styles';

// Types
import type { ImmutableMap } from 'types';

// Utils
import { sanitizeString } from 'utils';

type Props = {
    parentCollections: ?ImmutableMap<string, Object>,
};

const renderImage = (image) => <CollectionThumbnail {...image} />;

/**
 * Index of possible parent collections for media/collection forms
 */

function ParentCollectionsIndex(props: Props) {
    if (!props.parentCollections) {
        return <Loading loading />;
    } else if (props.parentCollections.isEmpty()) {
        return (
            <FormGroup>
                <FormattedMessage id="components.ParentCollectionsIndex.noParents" />
            </FormGroup>
        );
    }

    const outputList = props.parentCollections.map(
        (singleItem: ImmutableMap<string, Object>, index: number) => {
            const thumbnail = singleItem && singleItem.get('thumbnail');
            const thumbnailFile = thumbnail && thumbnail.get('file');

            const title =
                (singleItem.get('titleFr') && sanitizeString(singleItem.get('titleFr'))) ||
                (singleItem.get('titleEn') && sanitizeString(singleItem.get('titleEn'))) ||
                null;

            return (
                <div key={singleItem.get('id')}>
                    {index > 0 ? <Line light /> : null}
                    <Link to={`/collections/${singleItem.get('id')}`}>
                        <SingleItemContainer>
                            {thumbnailFile ? (
                                <Image
                                    alt={singleItem.get('titleFr') || singleItem.get('titleEn')}
                                    count={singleItem.get('childContentCount')}
                                    format="cropped"
                                    id={singleItem.get('id')}
                                    render={renderImage}
                                    resource="collections"
                                    type="image"
                                    width="70px"
                                />
                            ) : (
                                <CollectionThumbnail
                                    count={singleItem.get('childContentCount')}
                                    width="70px"
                                />
                            )}
                            <CollectionName>{title}</CollectionName>
                        </SingleItemContainer>
                    </Link>
                </div>
            );
        }
    );

    return <Container>{outputList}</Container>;
}

export default ParentCollectionsIndex;
