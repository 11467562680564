// @flow

import React from 'react';

import { Audio, AudioContainer } from './styles';

type Props = {
    src?: string,
};

/**
 * Audio player component
 */

const AudioPlayer = ({ src }: Props) =>
    src ? (
        <AudioContainer>
            <Audio
                width="100%"
                height="100%"
                controls
                controlsList="nodownload"
                crossOrigin="anonymous"
            >
                <source src={src} />
            </Audio>
        </AudioContainer>
    ) : null;

AudioPlayer.defaultProps = {
    src: null,
};

export default AudioPlayer;
