// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Button from 'components/Button';
import SortableTh from 'components/Table/SortableTh';

// Styles
import { TagList, TagItem } from 'styles/form';
import { ResourceIndexContainer, ResourceIndexTable, Tr } from '../styles';

// Types
import type { ImmutableList, ImmutableMap } from 'types';

type DefaultProps = {
    onListItemModifyAction: Function,
    onSortBy: Function,
    resourceList: ?ImmutableList<ImmutableMap<string, string>>,
};

type Props = DefaultProps & {
    currentSorting?: {
        sortBy?: string,
        sortOrder?: string,
    },
};

/**
 * Resource table content for Site Application index
 */

class ResourceApplicationsIndex extends React.PureComponent<Props> {
    render() {
        const thead = (
            <tr>
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Applications.tableHeaderFieldName"
                    name="name"
                    onClick={this.props.onSortBy}
                />
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Applications.tableHeaderFieldPermissions"
                    name="permissions"
                    onClick={this.props.onSortBy}
                />
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Applications.tableHeaderFieldContent"
                    name="invisibleContent"
                    onClick={this.props.onSortBy}
                />
                <th>
                    <FormattedMessage id="components.ResourceIndex.Applications.tableHeaderFieldActions" />
                </th>
            </tr>
        );

        const list =
            this.props.resourceList &&
            this.props.resourceList.map((singleItem: ImmutableMap<string, string>) => (
                <Tr key={singleItem.get('id')} inactive={singleItem.get('status') === 'INACTIVE'}>
                    <td>{singleItem.get('name')}</td>
                    <td>
                        <TagList inactive={singleItem.get('status') === 'INACTIVE'}>
                            {singleItem.get('read') ? (
                                <TagItem>
                                    <FormattedMessage
                                        id={
                                            singleItem.get('write') || singleItem.get('delete')
                                                ? 'components.ResourceIndex.Applications.permissionRead'
                                                : 'components.ResourceIndex.Applications.permissionReadOnly'
                                        }
                                    />
                                </TagItem>
                            ) : null}
                            {singleItem.get('write') ? (
                                <TagItem>
                                    <FormattedMessage id="components.ResourceIndex.Applications.permissionWrite" />
                                </TagItem>
                            ) : null}
                            {singleItem.get('delete') ? (
                                <TagItem>
                                    <FormattedMessage id="components.ResourceIndex.Applications.permissionDelete" />
                                </TagItem>
                            ) : null}
                        </TagList>
                    </td>
                    <td>
                        <FormattedMessage
                            id={
                                singleItem.get('invisibleContent') === 'ACTIVE'
                                    ? 'components.ResourceIndex.Applications.invisibleContentAuthorized'
                                    : 'components.ResourceIndex.Applications.invisibleContentNotAuthorized'
                            }
                        />
                    </td>
                    <td>
                        <Button onClick={this.props.onListItemModifyAction(singleItem)}>
                            <FormattedMessage id="components.ResourceManagement.actionsModify" />
                        </Button>
                    </td>
                </Tr>
            ));

        return (
            <ResourceIndexContainer>
                <ResourceIndexTable>
                    <thead>{thead}</thead>
                    <tbody>{list}</tbody>
                </ResourceIndexTable>
            </ResourceIndexContainer>
        );
    }
}

export default ResourceApplicationsIndex;
