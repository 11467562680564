// @flow

const constants = {
    mustBeAccepted: 'components.ErrorMessage.mustBeAccepted',
    invalidUrl: 'components.ErrorMessage.invalidUrl',
    mustBeAfterDate: 'components.ErrorMessage.mustBeAfterDate',
    onlyLettersAllowed: 'components.ErrorMessage.onlyLettersAllowed',
    onlyLettersNumbersDashesAllowed: 'components.ErrorMessage.onlyLettersNumbersDashesAllowed',
    onlyLettersNumbersAllowed: 'components.ErrorMessage.onlyLettersNumbersAllowed',
    mustBeAnArray: 'components.ErrorMessage.mustBeAnArray',
    mustBeBeforeDate: 'components.ErrorMessage.mustBeBeforeDate',
    mustBeBetweenMinMax: 'components.ErrorMessage.mustBeBetweenMinMax',
    mustBeTrueOrFalse: 'components.ErrorMessage.mustBeTrueOrFalse',
    confirmationDoesNotMatch: 'components.ErrorMessage.confirmationDoesNotMatch',
    notAValidDate: 'components.ErrorMessage.notAValidDate',
    invalidDateFormat: 'components.ErrorMessage.invalidDateFormat',
    mustBeDifferentThanOther: 'components.ErrorMessage.mustBeDifferentThanOther',
    mustBeNumberOfDigits: 'components.ErrorMessage.mustBeNumberOfDigits',
    mustBeBetweenMinMaxNumberOfDigits: 'components.ErrorMessage.mustBeBetweenMinMaxNumberOfDigits',
    invalidImageDimensions: 'components.ErrorMessage.invalidImageDimensions',
    hasDuplicateValue: 'components.ErrorMessage.hasDuplicateValue',
    invalidEmailAddressFormat: 'components.ErrorMessage.invalidEmailAddressFormat',
    invalidAttributeSelected: 'components.ErrorMessage.invalidAttributeSelected',
    notAFile: 'components.ErrorMessage.notAFile',
    fieldRequired: 'components.ErrorMessage.fieldRequired',
    notAnImage: 'components.ErrorMessage.notAnImage',
    fieldDoesNotExistIn: 'components.ErrorMessage.fieldDoesNotExistIn',
    notAnInteger: 'components.ErrorMessage.notAnInteger',
    invalidIpAddress: 'components.ErrorMessage.invalidIpAddress',
    invalidJsonString: 'components.ErrorMessage.invalidJsonString',
    mustNotBeGreaterThan: 'components.ErrorMessage.mustNotBeGreaterThan',
    invalidFileType: 'components.ErrorMessage.invalidFileType',
    mustBeAtLessThan: 'components.ErrorMessage.mustBeAtLessThan',
    notANumber: 'components.ErrorMessage.notANumber',
    fieldNotPresent: 'components.ErrorMessage.fieldNotPresent',
    invalidFormat: 'components.ErrorMessage.invalidFormat',
    fieldRequiredWhenOtherIsValue: 'components.ErrorMessage.fieldRequiredWhenOtherIsValue',
    fieldRequiredUnlessOtherIsInValues:
        'components.ErrorMessage.fieldRequiredUnlessOtherIsInValues',
    fieldRequiredWhenValuesIsPresent: 'components.ErrorMessage.fieldRequiredWhenValuesIsPresent',
    fieldRequiredWhenValuesIsNotPresent:
        'components.ErrorMessage.fieldRequiredWhenValuesIsNotPresent',
    fieldRequiredWhenNoneOfValuesArePresent:
        'components.ErrorMessage.fieldRequiredWhenNoneOfValuesArePresent',
    mustMatchOther: 'components.ErrorMessage.mustMatchOther',
    mustBeOfSize: 'components.ErrorMessage.mustBeOfSize',
    notAString: 'components.ErrorMessage.notAString',
    notAValidTimezone: 'components.ErrorMessage.notAValidTimezone',
    notUnique: 'components.ErrorMessage.notUnique',
    failedToUpload: 'components.ErrorMessage.failedToUpload',
    invalidCredentials: 'components.ErrorMessage.invalidCredentials',
    invalidAuthStatus: 'components.ErrorMessage.invalidAuthStatus',
    authUnauthorized: 'components.ErrorMessage.authUnauthorized',
    authThrottle: 'components.ErrorMessage.authThrottle',
    passwordResetInvalidToken: 'components.ErrorMessage.passwordResetInvalidToken',
    passwordResetInvalidPassword: 'components.ErrorMessage.passwordResetInvalidPassword',
    passwordResetInvalidUser: 'components.ErrorMessage.passwordResetInvalidUser',
    fileUnauthorized: 'components.ErrorMessage.fileUnauthorized',
    requiresAtLeastOneValue: 'components.ErrorMessage.requiredAtLeastOne',
    default: 'components.ErrorMessage.default',
};

export default constants;
