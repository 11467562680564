// @flow
import { fromJS } from 'immutable';

// Resource IDs
export const RESOURCE_IDS = {
    APPLICATION: 'application',
    COLLECTION: 'collection',
    MEDIA: 'media',
    USER: 'user',
};

// Protected Roles
export const protectedRoles = ['super_admin', 'admin'];

// Misc.
export const characterLimit = 210;
export const titles = ['M.', 'Mme'];

// Dates
export const dateFormat = 'DD MMM YYYY';
export const dateTimeFormat = 'DD MMM YYYY / HH:mm';

// Content Page Navigation

export const contentPageNavList = fromJS([
    {
        id: 1,
        resource: RESOURCE_IDS.MEDIA,
        string: 'components.ResourceIndex.Contents.AllMedia',
    },
    {
        id: 2,
        resource: RESOURCE_IDS.COLLECTION,
        string: 'components.ResourceIndex.Contents.Collections',
    },
    {
        id: 3,
        type: 'document',
        resource: RESOURCE_IDS.MEDIA,
        string: 'global.mediaType.documents',
    },
    {
        id: 4,
        type: 'image',
        resource: RESOURCE_IDS.MEDIA,
        string: 'global.mediaType.images',
    },
    {
        id: 5,
        type: 'video',
        resource: RESOURCE_IDS.MEDIA,
        string: 'global.mediaType.videos',
    },
    {
        id: 6,
        type: 'audio',
        resource: RESOURCE_IDS.MEDIA,
        string: 'global.mediaType.audio',
    },
    {
        id: 7,
        type: 'link',
        resource: RESOURCE_IDS.MEDIA,
        string: 'global.mediaType.links',
    },
    {
        id: 8,
        divided: true,
        adminOnly: true,
        route: '/import',
        resource: RESOURCE_IDS.MEDIA,
        string: 'components.ResourceIndex.Contents.MassImport',
    },
]);

// Collection & Media Values

export const displayTypeOptions = [
    {
        key: 'document',
        intlId: 'global.mediaType.singular.document',
    },
    {
        key: 'photo',
        intlId: 'global.mediaType.singular.photo',
    },
    {
        key: 'illustration',
        intlId: 'global.mediaType.singular.illustration',
    },
    {
        key: 'video',
        intlId: 'global.mediaType.singular.video',
    },
    {
        key: 'audio',
        intlId: 'global.mediaType.singular.audio',
    },
    {
        key: 'link',
        intlId: 'global.mediaType.singular.link',
    },
];

export const displayTypeImageOptions = [
    {
        key: 'photo',
        intlId: 'global.mediaType.singular.photo',
    },
    {
        key: 'illustration',
        intlId: 'global.mediaType.singular.illustration',
    },
];

export const basicLanguages = [
    {
        key: 'fr',
        intlId: 'global.french',
    },
    {
        key: 'en',
        intlId: 'global.english',
    },
];

export const cardLanguages = [
    {
        key: 'fr',
        intlId: 'global.french',
    },
    {
        key: 'en',
        intlId: 'global.english',
    },
    {
        key: 'fr/en',
        intlId: 'global.frenchEnglish',
    },
    {
        key: 'other',
        intlId: 'global.other',
    },
];

export const entityLanguages = [
    {
        key: 'fr',
        intlId: 'global.french',
    },
    {
        key: 'en',
        intlId: 'global.english',
    },
    {
        key: 'fr/en',
        intlId: 'global.frenchEnglish',
    },
    {
        key: 'other',
        intlId: 'global.other',
    },
    {
        key: 'none',
        intlId: 'global.none',
    },
];

export const orderOptions = [
    {
        key: JSON.stringify({ orderBy: 'title', order: 'asc' }),
        intlId: 'global.orderBy.titleAsc',
    },
    // {
    //     key: JSON.stringify({'orderBy':'author','order':'asc'}),
    //     intlId: 'global.orderBy.authorAsc',
    // },
    // {
    //     key: JSON.stringify({'orderBy':'country','order':'asc'}),
    //     intlId: 'global.orderBy.countryAsc',
    // },
    {
        key: JSON.stringify({ orderBy: 'date', order: 'asc' }),
        intlId: 'global.orderBy.dateAsc',
    },
    {
        key: JSON.stringify({ orderBy: 'date', order: 'desc' }),
        intlId: 'global.orderBy.dateDesc',
    },
    // {
    //     key: JSON.stringify({'orderBy':'approval_date','order':'asc'}),
    //     intlId: 'global.orderBy.approvalDateAsc',
    // },
    {
        key: JSON.stringify({ orderBy: 'displayType', order: 'asc' }),
        intlId: 'global.orderBy.typeAsc',
    },
    {
        key: JSON.stringify({ orderBy: 'custom' }),
        intlId: 'global.orderBy.custom',
    },
];

export const LOMContext = [
    {
        value: 'Éducation supérieure',
    },
    {
        value: 'Formation continue',
    },
    {
        value: 'Autre',
    },
];

export const LOMResourceType = [
    {
        value: 'Autoévaluation',
    },
    {
        value: 'Diagramme',
    },
    {
        value: 'Diapositive',
    },
    {
        value: "Énoncé d'un problème",
    },
    {
        value: 'Examen',
    },
    {
        value: 'Exercice',
    },
    {
        value: 'Expérience',
    },
    {
        value: 'Figure',
    },
    {
        value: 'Graphique',
    },
    {
        value: 'Index',
    },
    {
        value: 'Lecture',
    },
    {
        value: 'Questionnaire',
    },
    {
        value: 'Simulation',
    },
    {
        value: 'Tableau',
    },
    {
        value: 'Texte narratif',
    },
];

export const NormeticAggregateLevel = [
    {
        value: 'Données médias brutes et fragments',
    },
    {
        value: 'Une collection de REA de niveau 1',
    },
    {
        value: 'Une collection de REA de niveau 2',
    },
    {
        value: 'La granularité la plus élevée',
    },
];

export const NormeticContext = [
    {
        value: 'Éducation préscolaire',
    },
    {
        value: 'Éducation primaire',
    },
    {
        value: 'Éducation secondaire',
    },
    {
        value: 'Cégep',
    },
    {
        value: 'Université premier cycle',
    },
    {
        value: 'Université second cycle',
    },
    {
        value: 'Université troisième cycle',
    },
    {
        value: 'Formation professionnelle',
    },
    {
        value: 'Formation technique',
    },
    {
        value: 'Formation continue',
    },
    {
        value: 'Formation en entreprise',
    },
    {
        value: 'Autre',
    },
];

export const NormeticResourceType = [
    {
        value: 'Activité',
    },
    {
        value: 'Animation',
    },
    {
        value: 'Démonstration',
    },
    {
        value: 'Évaluation',
    },
    {
        value: 'Examen',
    },
    {
        value: 'Exercice',
    },
    {
        value: 'Expérience',
    },
    {
        value: 'Exploration',
    },
    {
        value: 'Glossaire',
    },
    {
        value: 'Guide',
    },
    {
        value: 'Lecture/présentation',
    },
    {
        value: 'Matériel de référence',
    },
    {
        value: 'Méthodologie',
    },
    {
        value: 'Outils',
    },
    {
        value: 'Questionnaire',
    },
    {
        value: 'Scénario pédagogique',
    },
    {
        value: 'Simulation',
    },
    {
        value: "Situation d'apprentissage et d'évaluation",
    },
    {
        value: 'Texte-document informatif',
    },
    {
        value: 'Tutoriel',
    },
];

export const copyRightOptions = [
    {
        value: '©',
    },
    {
        value: 'CC BY-NC-ND',
    },
    {
        value: 'CC BY-NC-SA',
    },
    {
        value: 'CC BY-SA',
    },
];

export const itunesCategories = [
    {
        value: 100,
        label: 'Business',
        group: true,
    },
    {
        value: 100100,
        label: 'Economics',
    },
    {
        value: 100101,
        label: 'Finance',
    },
    {
        value: 100102,
        label: 'Hospitality',
    },
    {
        value: 100103,
        label: 'Management',
    },
    {
        value: 100104,
        label: 'Marketing',
    },
    {
        value: 100105,
        label: 'Personal Finance',
    },
    {
        value: 100106,
        label: 'Real Estate',
    },
    {
        value: 101,
        label: 'Engineering',
        group: true,
    },
    {
        value: 101100,
        label: 'Chemical & Petroleum',
    },
    {
        value: 101101,
        label: 'Civil',
    },
    {
        value: 101102,
        label: 'Computer Science',
    },
    {
        value: 101103,
        label: 'Electrical',
    },
    {
        value: 101104,
        label: 'Environmental',
    },
    {
        value: 101105,
        label: 'Mechanical',
    },
    {
        value: 102,
        label: 'Fine Arts',
        group: true,
    },
    {
        value: 102100,
        label: 'Architecture',
    },
    {
        value: 102101,
        label: 'Art',
    },
    {
        value: 102102,
        label: 'Art History',
    },
    {
        value: 102103,
        label: 'Dance',
    },
    {
        value: 102104,
        label: 'Film',
    },
    {
        value: 102105,
        label: 'Graphic Design',
    },
    {
        value: 102106,
        label: 'Interior Design',
    },
    {
        value: 102107,
        label: 'Music',
    },
    {
        value: 102108,
        label: 'Theater',
    },
    {
        value: 103,
        label: 'Health & Medicine',
        group: true,
    },
    {
        value: 103100,
        label: 'Anatomy & Physiology',
    },
    {
        value: 103101,
        label: 'Behavioral Science',
    },
    {
        value: 103102,
        label: 'Dentistry',
    },
    {
        value: 103103,
        label: 'Diet & Nutrition',
    },
    {
        value: 103104,
        label: 'Emergency',
    },
    {
        value: 103105,
        label: 'Genetics',
    },
    {
        value: 103106,
        label: 'Gerontology',
    },
    {
        value: 103107,
        label: 'Health & Exercise Science',
    },
    {
        value: 103108,
        label: 'Immunology',
    },
    {
        value: 103109,
        label: 'Neuroscience',
    },
    {
        value: 103110,
        label: 'Pharmacology & Toxicology',
    },
    {
        value: 103111,
        label: 'Psychiatry',
    },
    {
        value: 103112,
        label: 'Public Health',
    },
    {
        value: 103113,
        label: 'Radiology',
    },
    {
        value: 104,
        label: 'History',
        group: true,
    },
    {
        value: 104100,
        label: 'Ancient',
    },
    {
        value: 104101,
        label: 'Medieval',
    },
    {
        value: 104102,
        label: 'Military',
    },
    {
        value: 104103,
        label: 'Modern',
    },
    {
        value: 104104,
        label: 'African',
    },
    {
        value: 104105,
        label: 'Asian',
    },
    {
        value: 104106,
        label: 'European',
    },
    {
        value: 104107,
        label: 'Middle Eastern',
    },
    {
        value: 104108,
        label: 'North American',
    },
    {
        value: 104109,
        label: 'South American',
    },
    {
        value: 105,
        label: 'Humanities',
        group: true,
    },
    {
        value: 105100,
        label: 'Communications',
    },
    {
        value: 105101,
        label: 'Philosophy',
    },
    {
        value: 105102,
        label: 'Religion',
    },
    {
        value: 106,
        label: 'Language',
    },
    {
        value: 106100,
        label: 'African',
    },
    {
        value: 106101,
        label: 'Ancient',
    },
    {
        value: 106102,
        label: 'Asian',
    },
    {
        value: 106103,
        label: 'Eastern European/Slavic',
    },
    {
        value: 106104,
        label: 'English',
    },
    {
        value: 106105,
        label: 'English Language Learners',
    },
    {
        value: 106106,
        label: 'French',
    },
    {
        value: 106107,
        label: 'German',
    },
    {
        value: 106108,
        label: 'Italian',
    },
    {
        value: 106109,
        label: 'Linguistics',
    },
    {
        value: 106110,
        label: 'Middle Eastern',
    },
    {
        value: 106111,
        label: 'Spanish & Portuguese',
    },
    {
        value: 106112,
        label: 'Speech Pathology',
    },
    {
        value: 107,
        label: 'Literature',
        group: true,
    },
    {
        value: 107100,
        label: 'Anthologies',
    },
    {
        value: 107101,
        label: 'Biography',
    },
    {
        value: 107102,
        label: 'Classics',
    },
    {
        value: 107103,
        label: 'Criticism',
    },
    {
        value: 107104,
        label: 'Fiction',
    },
    {
        value: 107105,
        label: 'Poetry',
    },
    {
        value: 108,
        label: 'Mathematics',
        group: true,
    },
    {
        value: 108100,
        label: 'Advanced Mathematics',
    },
    {
        value: 108101,
        label: 'Algebra',
    },
    {
        value: 108102,
        label: 'Arithmetic',
    },
    {
        value: 108103,
        label: 'Calculus',
    },
    {
        value: 108104,
        label: 'Geometry',
    },
    {
        value: 108105,
        label: 'Statistics',
    },
    {
        value: 109,
        label: 'Science',
        group: true,
    },
    {
        value: 109100,
        label: 'Agricultural',
    },
    {
        value: 109101,
        label: 'Astronomy',
    },
    {
        value: 109102,
        label: 'Atmospheric',
    },
    {
        value: 109103,
        label: 'Biology',
    },
    {
        value: 109104,
        label: 'Chemistry',
    },
    {
        value: 109105,
        label: 'Ecology',
    },
    {
        value: 109106,
        label: 'Geography',
    },
    {
        value: 109107,
        label: 'Geology',
    },
    {
        value: 109108,
        label: 'Physics',
    },
    {
        value: 110,
        label: 'Social Science',
        group: true,
    },
    {
        value: 110100,
        label: 'Law',
    },
    {
        value: 110101,
        label: 'Political Science',
    },
    {
        value: 110102,
        label: 'Public Administration',
    },
    {
        value: 110103,
        label: 'Psychology',
    },
    {
        value: 110104,
        label: 'Social Welfare',
    },
    {
        value: 110105,
        label: 'Sociology',
    },
    {
        value: 111,
        label: 'Society',
        group: true,
    },
    {
        value: 111100,
        label: 'African-American Studies',
    },
    {
        value: 111101,
        label: 'Asian Studies',
    },
    {
        value: 111102,
        label: 'European & Russian Studies',
    },
    {
        value: 111103,
        label: 'Indigenous Studies',
    },
    {
        value: 111104,
        label: 'Latin & Caribbean Studies',
    },
    {
        value: 111105,
        label: 'Middle Eastern Studies',
    },
    {
        value: 111106,
        label: 'Women’s Studies',
    },
    {
        value: 112,
        label: 'Teaching & Education',
        group: true,
    },
    {
        value: 112100,
        label: 'Curriculum & Teaching',
    },
    {
        value: 112101,
        label: 'Educational Leadership',
    },
    {
        value: 112102,
        label: 'Family & Childcare',
    },
    {
        value: 112103,
        label: 'Learning Resources',
    },
    {
        value: 112104,
        label: 'Psychology & Research',
    },
    {
        value: 112105,
        label: 'Special Education',
    },
];

export const itunesUMainVideoFormat = [
    {
        key: 'format-1',
        value: 'Format 1',
    },
    {
        key: 'format-2',
        value: 'Format 2',
    },
];

// Advanced Search

export const orientationOptions = [
    {
        id: 'horizontal',
    },
    {
        id: 'vertical',
    },
    {
        id: 'other',
    },
];

export const ratioOptions = [
    {
        id: '4x3',
    },
    {
        id: '3x2',
    },
    {
        id: '16x9',
    },
    {
        id: 'other',
    },
];

export const regionOptions = [
    {
        id: 'Abitibi-Témiscamingue',
    },
    {
        id: 'Bas-Saint-Laurent',
    },
    {
        id: 'Capitale-Nationale',
    },
    {
        id: 'Centre-du-Québec',
    },
    {
        id: 'Chaudière-Appalaches',
    },
    {
        id: 'Côte-Nord',
    },
    {
        id: 'Estrie',
    },
    {
        id: 'Gaspésie-Îles-de-la-Madeleine',
    },
    {
        id: 'Lanaudière',
    },
    {
        id: 'Laurentides',
    },
    {
        id: 'Laval',
    },
    {
        id: 'Mauricie',
    },
    {
        id: 'Montérégie',
    },
    {
        id: 'Montréal',
    },
    {
        id: 'Nord-du-Québec',
    },
    {
        id: 'Outaouais',
    },
    {
        id: 'Saguenay-Lac-Saint-Jean',
    },
];

export const typeOptions = [
    {
        id: 'photo',
    },
    {
        id: 'illustration',
    },
    {
        id: 'video',
    },
    {
        id: 'document',
    },
    {
        id: 'audio',
    },
    {
        id: 'link',
    },
];

export const quebecProvincesNameMapping = {
    Alberta: 'Alberta',
    'British Columbia': 'Colombie Britannique',
    Manitoba: 'Manitoba',
    'New Brunswick': 'Nouveau Brunswick',
    'Newfoundland and Labrador': 'Terre-Neuve-et-Labrador',
    'Northwest Territories': 'Territoires du Nord-Ouest',
    'Nova Scotia': 'Nouvelle Écosse',
    Nunavut: 'Nunavut',
    Ontario: 'Ontario',
    'Prince Edward Island': 'Île-du-Prince-Édouard',
    Québec: 'Québec',
    Saskatchewan: 'Saskatchewan',
    Yukon: 'Yukon',
};
