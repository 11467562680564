// @flow

/**
 *
 * Auth menu
 *
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { NavItem } from './styles';

function LoginLink() {
    return (
        <NavItem>
            <Link to="/login" data-test-id="loginLink">
                <FormattedMessage id="components.Header.login" />
            </Link>
        </NavItem>
    );
}

export default LoginLink;
