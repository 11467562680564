// @flow

import styled from 'styled-components';

import colors from 'styles/colors';

import { FlexContainer, FlexItemContainer } from 'styles/common';

export const ControlPanelContainer = styled.div`
    width: 100%;
    justify-content: space-between;
    height: ${(props) => (props.slim ? '70px' : '100%')};
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '30px')};
    padding-bottom: ${(props) => (props.slim ? '10px' : '20px')};
`;

export const Toolbar = FlexContainer.extend`
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding: ${(props) => (props.padding ? props.padding : '20px 20px 0')};
`;

export const Tabs = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    padding: ${(props) => (props.unpaddedTabs ? '0px' : '0 20px')};
    min-height: 40px;
    font-size: 16px;
    line-height: 36px;
    overflow: auto;
    color: ${colors.gray64};

    & > * {
        margin-right: 10px;
        margin-left: 10px;
    }
    & *:first-child {
        margin-left: 0;

        &::before {
            content: '';
            border-bottom: 1px solid ${colors.grayE1};
        }
    }
    & *:last-child {
        margin-right: 0;

        &::after {
            content: '';
            border-bottom: 1px solid ${colors.grayE1};
        }
    }
`;

export const SingleTab = styled.div`
    cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
    ${(props) =>
        props.selected
            ? `
                border-bottom: 1px solid ${colors.tealDark};
                z-index: 1;
            `
            : `border-bottom: 1px solid ${colors.grayE1};
        `};
`;

export const Arrow = styled.div`
    margin: 0;

    &:before {
        content: '>';
        min-height: 30px;

        font-size: 16px;
        line-height: 18px;
    }
`;

export const Actions = FlexItemContainer.extend`
    justify-content: flex-end;
`;

export const InlineContainer = FlexContainer.extend`
    height: 40px;
    margin: 30px 0 20px;
    padding: 0 20px;
    border-bottom: 1px solid ${colors.grayE1};

    ${Tabs} {
        height: 40px;
        line-height: 36px;

        & *:last-child {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
`;
