// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import Modal from 'components/Modal';
import ResourceManagement from 'components/ResourceManagement';
import UserForm from 'components/UserForm';

// Services
import userResource from 'services/User';
import roleResource from 'services/Role';

// Styles
import { PrimaryButton } from 'styles/buttons';

// Types
import type { IntlType, UserType } from 'types';

// Utils
import { RESOURCE_IDS } from 'utils/constants';

type Props = {
    /** injectIntl for formatMessage strings */
    intl: IntlType,
};

type State = {
    showUserModal: boolean,
    selectedUser: ?UserType,
};

/**
 * User Page.
 *
 * Index view to create & modify users.
 *
 */

class UsersPage extends React.Component<Props, State> {
    state = {
        showUserModal: false,
        selectedUser: null,
    };

    /**
     * Toggles the visibility of the user modal
     * @param nullifyUser as true to remove application's object from local state
     */
    handleShowModal = (showUserModal: boolean, nullifyUser?: boolean) => () => {
        this.setState({
            showUserModal,
        });

        if (nullifyUser) {
            this.setState({
                selectedUser: null,
            });
        }
    };

    handleOnItemModifyAction = (user: UserType) => {
        this.setState({
            selectedUser: user,
            showUserModal: true,
        });
    };

    renderListActions = () => (
        <PrimaryButton onClick={this.handleShowModal(true, true)}>
            {this.props.intl.formatMessage({
                id: 'containers.UsersPage.newAction',
            })}
        </PrimaryButton>
    );

    render() {
        return (
            <div>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'containers.UsersPage.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'containers.UsersPage.pageTitle',
                        })}
                    />
                </Helmet>

                <ResourceManagement
                    onItemModifyAction={this.handleOnItemModifyAction}
                    controls={this.renderListActions()}
                    resourceListFetchAction={userResource().thunks().fetchPaginatedBySearchCriteria}
                    resourceListSelector={userResource()
                        .selectors()
                        .selectPaginatedBySearchCriteria()}
                    resourceListIsFetchingSelector={userResource()
                        .selectors()
                        .selectPaginatedIsFetching()}
                    resourceType={RESOURCE_IDS.USER}
                    tabItemsListFetchAction={roleResource().thunks().fetchAll}
                    tabItemsListSelector={roleResource()
                        .selectors()
                        .selectAll()}
                />

                <Modal
                    flush
                    headerTitle={
                        <FormattedMessage
                            id={
                                this.state.selectedUser
                                    ? 'components.User.Edit'
                                    : 'components.User.Create'
                            }
                            values={{
                                name: this.state.selectedUser
                                    ? `${this.state.selectedUser.firstName}
                                        ${this.state.selectedUser.lastName}`
                                    : null,
                            }}
                        />
                    }
                    onModalClose={this.handleShowModal(false)}
                    show={this.state.showUserModal}
                >
                    <UserForm
                        userId={this.state.selectedUser && this.state.selectedUser.id}
                        onModalShow={this.handleShowModal}
                    />
                </Modal>
            </div>
        );
    }
}

export default injectIntl(UsersPage);
