// @flow

import React from 'react';

import { FlexRowGroup } from 'styles/common';

import {
    BrandImage,
    BasicHeaderContainer,
    IconGroupContainer,
    Navbar,
    BasicPageTitle,
} from './styles';

import mainLogo from 'assets/ccdmd-logo.png';
import docIcon from 'assets/icon-doc.svg';
import imagesIcon from 'assets/icon-images.svg';
import linkIcon from 'assets/icon-link.svg';
import soundsIcon from 'assets/icon-sounds.svg';
import videoIcon from 'assets/icon-video.svg';

type Props = {
    pageTitle: ?string,
};

function HeaderBasic(props: Props) {
    return (
        <BasicHeaderContainer>
            <Navbar>
                <FlexRowGroup>
                    <BrandImage>
                        <img src={mainLogo} alt="CCDMD" />
                    </BrandImage>
                    <BasicPageTitle>{props.pageTitle}</BasicPageTitle>
                </FlexRowGroup>
                <IconGroupContainer>
                    <img src={videoIcon} alt="video icon" />
                    <img src={imagesIcon} alt="images icon" />
                    <img src={docIcon} alt="doc icon" />
                    <img src={soundsIcon} alt="sounds icon" />
                    <img src={linkIcon} alt="link icon" />
                </IconGroupContainer>
            </Navbar>
        </BasicHeaderContainer>
    );
}

export default HeaderBasic;
