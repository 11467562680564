// @flow

import pluralize from 'pluralize';
import request from 'request';

/*
 *
 * CRUDResource resources
 *
 * Basic function: call request() with expected paramaters
 *
 */

const crudResources = (resourceName: string) => ({
    /*
    * fetchCounts: Fetch statistics by date
    */
    fetchByDate: (startDate?: string, endDate?: string) =>
        request(
            `${pluralize(resourceName.toLowerCase())}/query`,
            {
                method: 'GET',
            },
            {
                start_date: startDate,
                end_date: endDate,
            }
        ),

    /*
    * fetchCounts: Fetch static statistics
    */
    fetchCounts: () => request(`${pluralize(resourceName.toLowerCase())}`, { method: 'GET' }),
});

export default crudResources;
