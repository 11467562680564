// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { DragDropContext as dragDropContext, DragDropContextProvider } from 'react-dnd';
import HTML5Backend, { NativeTypes } from 'react-dnd-html5-backend';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { Map } from 'immutable';
import { camelize } from 'humps';

// Components
import Carousel from 'components/Image/Carousel';
import DragDropThumbnail from 'components/Image/DragDropThumbnail';
import FileInput from 'components/Form/FileInput';
import Input from 'components/Form/Input';
import Image from 'components/Image';
import Select from 'components/Form/Select';
import SquareImage from 'components/Image/SquareImage';
import Thumbnail from 'components/Image/Thumbnail';

import Modal from 'components/Modal';
import AudioPlayer from 'components/AudioPlayer';
import VideoPlayer from 'components/VideoPlayer';
import ClassicImage from 'components/Image/ClassicImage';

// Services
import mediaResource from 'services/Media';

// Styles
import { FormGroup, InputNotice } from 'styles/form';
import { FlexContainer, FlexItemContainer, LineBreak, Table, Td } from 'styles/common';

// Types
import type { ImmutableList, ImmutableMap, ReduxDispatch } from 'types';

// Utils
import { formatBytes } from 'utils';
import { displayTypeImageOptions } from 'utils/constants';

type Props = {
    fetchMimetypes: Function,
    fetchThumbnails: Function,
    isLoading: boolean,
    media?: ImmutableMap<string, mixed>,
    mimetypes: ?ImmutableList<ImmutableMap<string, string>>,
    onFileSelection: Function,
    onChangeField: Function,
    onThumbnailDrop: Function,
    onThumbnailSelection: Function,
    thumbnails: ?ImmutableList<ImmutableMap<string, string>>,
};

type State = {
    preview?: string,
    showFullscreenModal: boolean,
};

class FileUploadBlock extends React.Component<Props, State> {
    state = {
        preview: '',
        showFullscreenModal: false,
    };

    componentWillMount() {
        if (!this.props.mimetypes) {
            this.props.fetchMimetypes();
        }
        if (this.props.media && this.props.media.get('type') === 'video') {
            this.props.fetchThumbnails(this.props.media.get('id'));
        }
    }

    // Use FileReader to get base64 of uploaded image
    // Set to state for preview
    handleDragDropPreview = (monitor) => {
        const image = monitor && monitor.getItem().files[0];
        if (image) {
            const reader = new FileReader();
            reader.onload = (e: Event) => {
                const preview = e.target.result;
                if (preview.includes('image')) {
                    this.setState({
                        preview,
                    });
                }
            };
            reader.readAsDataURL(image);
        }
    };

    handleOnThumbnailClick = (event: Event) => {
        this.setState({
            showFullscreenModal: true,
        });
    };

    handleCloseFullscreenModal = () => {
        this.setState({
            showFullscreenModal: false,
        });
    };

    // On thumbnail drop, save to media object & show preview
    handleThumbnailDrop = (props, monitor) => (event) => {
        this.handleDragDropPreview(monitor);
        this.props.onThumbnailDrop(props, monitor)(event);
    };

    renderFullscreenImage = (image) => <ClassicImage {...image} fullScreen />;

    renderFullscreenVideo = (video) => <VideoPlayer {...video} />;

    renderFullscreenAudio = (sound) => <AudioPlayer {...sound} />;

    renderFullscreenModal = () => {
        const { media } = this.props;
        if (media) {
            let render = null;
            if (media.get('type') === 'video') {
                render = this.renderFullscreenVideo;
            } else if (media.get('type') === 'audio') {
                render = this.renderFullscreenAudio;
            } else {
                render = this.renderFullscreenImage;
            }

            return (
                <Modal
                    flush
                    fullScreen
                    onModalClose={this.handleCloseFullscreenModal}
                    show={this.state.showFullscreenModal}
                    large
                    simple
                >
                    <Image
                        alt={media.get('titleFr') || media.get('titleEn')}
                        id={media.get('id')}
                        render={render}
                        resource="media"
                        showPlaceholder={
                            media.get('type') !== 'image' &&
                            media.get('type') !== 'document' &&
                            media.get('type') !== 'video' &&
                            media.get('type') !== 'audio'
                        }
                        type={media.get('type')}
                    />
                </Modal>
            );
        } else {
            return null;
        }
    };

    renderImage = (image) => <SquareImage cover={false} {...image} />;

    render() {
        const { media, mimetypes, thumbnails } = this.props;

        let file;
        let originalFile;
        let fileName = '';
        let fileMimeType;
        let fileSize;
        let filePages;
        let fileLength;
        let readableMimetype;

        // Accepted files for drag and drop
        const { FILE } = NativeTypes;

        try {
            file = media && media.get('file');
            originalFile = media && media.get('originalFile');

            if (file && Map.isMap(file)) {
                fileName = file.get('originalFilename');
                fileMimeType = file.get('mimeType');
                fileSize = file.get('size');
                filePages = file.get('pages');
                fileLength = file.get('length');
            }

            if (originalFile && Map.isMap(originalFile)) {
                fileSize = originalFile.get('size');
                fileMimeType = camelize(originalFile.get('mimeType'));
            }
            readableMimetype = mimetypes && mimetypes.get(fileMimeType);
        } catch (e) {}

        /**
         * Thumbnail: if preview then show, else display editThumbnail message
         * Display if: none
         */
        let thumbnailComponent = (
            <Thumbnail square width="160px">
                {this.state.preview ? (
                    <SquareImage cover={false} src={this.state.preview} />
                ) : (
                    <FormattedMessage id="components.Media.editThumbnail" />
                )}
            </Thumbnail>
        );

        /**
         * DragDropThumbnail 1: if preview then show, else show thumbnail within D&D component
         * Display if: (Media is a video && thumbnails count is 1) or (if media is not video)
         */
        if (
            media &&
            media.has('id') &&
            ((media && media.get('type') === 'video' && thumbnails && thumbnails.size === 1) ||
                (media && media.get('type') !== 'video'))
        ) {
            thumbnailComponent = (
                <DragDropThumbnail
                    square
                    width="160px"
                    accepts={[FILE]}
                    onDrop={this.handleThumbnailDrop}
                >
                    {this.state.preview ? (
                        <SquareImage cover={false} src={this.state.preview} />
                    ) : (
                        <Image
                            format="small"
                            id={media.get('id') || null}
                            render={this.renderImage}
                            resource="media"
                            showPlaceholder={!media.get('thumbnailId') && !media.get('thumbnail')}
                            type={media.get(`type`)}
                            width="160px"
                        />
                    )}
                </DragDropThumbnail>
            );
        }

        /**
         * DragDropThumbnail 2: display editThumbnail message within D&D component
         * Display if: (Media has no id) or (if thumbnails size is 0)
         */
        if (media && (!media.has('id') || (thumbnails && thumbnails.size === 0))) {
            thumbnailComponent = (
                <DragDropThumbnail
                    square
                    width="160px"
                    accepts={[FILE]}
                    onDrop={this.handleThumbnailDrop}
                >
                    <FormattedMessage id="components.Media.editThumbnail" />
                </DragDropThumbnail>
            );
        }

        /**
         * Carousel: if preview, then show else show carousel of video thumbnails
         * Display if: Media is a video && thumbnails aren't empty
         */
        if (
            media &&
            media.has('id') &&
            media.get('type') === 'video' &&
            thumbnails &&
            !thumbnails.isEmpty()
        ) {
            thumbnailComponent = this.state.preview ? (
                <Thumbnail square width="160px">
                    <SquareImage cover={false} src={this.state.preview} />
                </Thumbnail>
            ) : (
                <Carousel
                    square
                    width="160px"
                    accepts={[FILE]}
                    onDrop={this.handleThumbnailDrop}
                    defaultIndex={
                        (thumbnails &&
                            thumbnails.findKey(
                                (image) => image.get('id') === media.get('thumbnailId')
                            )) ||
                        null
                    }
                    images={thumbnails}
                    onChange={this.props.onThumbnailSelection}
                />
            );
        }

        return (
            <React.Fragment>
                <FormGroup>
                    <FlexContainer justifyContent="space-between" gutter="20">
                        <DragDropContextProvider backend={HTML5Backend}>
                            <FlexItemContainer
                                direction="column"
                                justifyContent="flex-start"
                                collapsed
                                onClick={this.handleOnThumbnailClick}
                            >
                                {thumbnailComponent}
                            </FlexItemContainer>
                        </DragDropContextProvider>
                        <FlexItemContainer direction="column" justifyContent="flex-start">
                            <FlexContainer justifyContent="space-between" gutter="20">
                                <FileInput
                                    buttonId="components.Media.addFile"
                                    name="file"
                                    onChange={this.props.onFileSelection('file')}
                                    disabled={this.props.isLoading}
                                    value={fileName}
                                    filename={fileName}
                                />
                            </FlexContainer>
                            <LineBreak height="10px" />
                            <Table>
                                <thead>
                                    <tr>
                                        <th>
                                            <FormattedMessage id="components.Media.uploadTable.Type" />
                                        </th>
                                        <th>
                                            <FormattedMessage id="components.Media.uploadTable.Weight" />
                                        </th>
                                        <th>
                                            <FormattedMessage id="components.Media.uploadTable.Pages" />
                                        </th>
                                        <th>
                                            <FormattedMessage id="components.Media.uploadTable.Length" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <Td inactive={file && !fileMimeType}>
                                            {media && media.get('type') === 'image' ? (
                                                <Select
                                                    name="displayType"
                                                    onChange={this.props.onChangeField(
                                                        'displayType'
                                                    )}
                                                    disabled={this.props.isLoading}
                                                    value={media && media.get('displayType')}
                                                    options={displayTypeImageOptions}
                                                    defaultOption="components.Media.inputLabelValueDisplayType.placeholder"
                                                    full
                                                />
                                            ) : (
                                                readableMimetype || (
                                                    <FormattedMessage id="global.threeDash" />
                                                )
                                            )}
                                        </Td>
                                        <Td inactive={file && !fileSize}>
                                            {(file && fileSize && formatBytes(fileSize)) || (
                                                <FormattedMessage id="global.threeDash" />
                                            )}
                                        </Td>
                                        <Td inactive={file && !filePages}>
                                            {media && media.get('displayType') === 'document' ? (
                                                <Input
                                                    name="pageCount"
                                                    type="number"
                                                    placeholder={'global.threeDash'}
                                                    onChange={this.props.onChangeField('pageCount')}
                                                    disabled={this.props.isLoading}
                                                    value={media && media.get('pageCount')}
                                                    width="65px"
                                                />
                                            ) : (
                                                <FormattedMessage id="global.threeDash" />
                                            )}
                                        </Td>
                                        <Td inactive={file && !fileLength}>
                                            {(file && fileLength) || (
                                                <FormattedMessage id="global.threeDash" />
                                            )}
                                        </Td>
                                    </tr>
                                </tbody>
                            </Table>
                            <InputNotice>
                                <FormattedMessage
                                    id="components.Media.mediaNumber"
                                    values={{ number: (media && media.get('id')) || '- - -' }}
                                />
                            </InputNotice>
                        </FlexItemContainer>
                    </FlexContainer>
                </FormGroup>
                {this.renderFullscreenModal()}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) =>
    createStructuredSelector({
        mimetypes: mediaResource()
            .selectors()
            .selectReadableMimeTypes(),
        thumbnails: mediaResource()
            .selectors()
            .selectThumbnails(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            fetchMimetypes: mediaResource().thunks().fetchReadableMimeTypes,
            fetchThumbnails: mediaResource().thunks().fetchThumbnails,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(dragDropContext(HTML5Backend)(FileUploadBlock));
