// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

import Caret from 'assets/icons/Caret/Dark/Down.svg';

export const SortToggler = styled.span`
    margin-left: 5px;

    &:before,
    &:after {
        content: '';
        display: block;
        color: transparent;
        font-size: 8px;
        line-height: 1;
    }
    &:before {
        content: '▲';
        margin-bottom: 1px;
    }
    &:after {
        content: '▼';
        margin-top: 1px;
    }

    ${(props) => {
        let activeStyles = '&:before, &:after {';
        if (props.active) {
            activeStyles += `color: ${colors.grayE1}`;
        }
        return `${activeStyles} }`;
    }}

    ${(props) => {
        let beforeStyles = '&:before {';
        if (props.active) {
            if (props.asc) {
                beforeStyles += `color: ${colors.teal} !important;`;
            }
            if (props.desc) {
                beforeStyles += `color: ${colors.grayE1} !important;`;
            }
        }
        return `${beforeStyles} }`;
    }}

    ${(props) => {
        let afterStyles = '&:after {';
        if (props.active) {
            if (props.asc) {
                afterStyles += `color: ${colors.grayE1} !important;`;
            }
            if (props.desc) {
                afterStyles += `color: ${colors.teal} !important;`;
            }
        }
        return `${afterStyles} }`;
    }}
`;

export const SortToggle = styled.span`
    &:hover {
        cursor: pointer;
        ${SortToggler} {
            &:before,
            &:after {
                color: ${colors.grayE1};
            }
        }
    }

    vertical-align: middle;
    white-space: nowrap;

    > span {
        display: inline-block;
        vertical-align: inherit;
    }
`;

export const Toggle = styled.span`
    position: absolute;
    top: 0;
    left: 0;

    width: 30px;
    height: 100%;

    border-right: 1px solid ${colors.grayE1};

    cusor: pointer;

    &:after {
        content: '';

        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-image: url('${Caret}');
        background-size: 20px auto;
        background-position: center;

        transform: rotate(0deg);
        transition: transform ease 0.2s;
    }
`;

export const CustomThItem = styled.li`
    display: block;
    width: 100%;
    padding: 10px;

    border-top: 1px solid ${colors.grayE1};

    font-size: 14px;
`;

export const CustomThList = styled.ul`
    position: absolute;
    top: 100%;
    left: 0;

    display: none;
    width: 190px;
    margin: 0;
    padding: 0;

    background-color: ${colors.white};
    box-shadow: 5px 5px 15px 0 rgba(0, 0, 0, 0.1);

    list-style: none;
`;

export const CustomThWrapper = styled.th`
    position: relative;

    padding-left: 40px !important;

    background-color: transparent;
    border: 1px solid ${colors.grayE1};
    border-top-width: 0;
    border-bottom-width: 0;

    &:hover {
        background-color: ${colors.white};
        transition: transform ease 0.2s;

        ${Toggle} {
            &:after {
                transform: rotate(180deg);
                transition: transform ease 0.2s;
            }
        }

        ${CustomThList} {
            display: block;
        }
    }
`;
