// @flow

import pluralize from 'pluralize';
import request from 'request';
import { decamelize } from 'humps';

/*
 *
 * CRUDResource resources
 *
 * Basic function: call request() with expected paramaters
 *
 */

const crudResources = (resourceName: string) => ({
    /*
    * create: Create a single entry of the resource
    */
    create: (resource: Object) =>
        request(
            `${pluralize(resourceName.toLowerCase())}`,
            {
                method: 'POST',
                data: resource,
                headers: {
                    contentType: 'multipart/form-data',
                },
            },
            // eslint-disable-next-line no-undefined
            undefined,
            // eslint-disable-next-line no-undefined
            undefined,
            false
        ),

    /*
    * delete: Delete a single entry of the resource by id
    */
    delete: (id: number) =>
        request(`${pluralize(resourceName.toLowerCase())}/${id}`, { method: 'DELETE' }),

    /*
    * deleteRelatedEntity: Delete a single entry of a related entity
    */
    deleteRelatedEntity: (relatedEntityName: string, relatedEntityId: number) =>
        request(`${pluralize(relatedEntityName.toLowerCase())}/${relatedEntityId}`, {
            method: 'DELETE',
        }),

    /*
    * fetch: Fetch a single entry of the resource by id
    */
    fetch: (id: number) =>
        request(`${pluralize(resourceName.toLowerCase())}/${id}`, { method: 'GET' }),

    /*
    * fetchAll: Fetch all the entries of the resource
    */
    fetchAll: () => request(`${pluralize(resourceName.toLowerCase())}`, { method: 'GET' }),

    /*
    * fetchCountries: Fetch all the countries the media come from
    */
    fetchCountries: () =>
        request(`${pluralize(resourceName.toLowerCase())}/countries`, { method: 'GET' }),

    /*
    * fetchImports: Fetch all the import logs
    */
    fetchImports: () =>
        request(`${pluralize(resourceName.toLowerCase())}/imports`, { method: 'GET' }),

    /*
    * fetchPaginatedByListOfIds: Fetch paginated entries of the resource with search criteria for filtering
    */
    fetchPaginatedByListOfIds: (list: Array, page: number = 1, perPage: number = 10) =>
        request(
            `${pluralize(resourceName.toLowerCase())}/fetchPaginatedByListOfIds`,
            {
                method: 'POST',
            },
            {
                ids: list,
                page,
                per_page: perPage,
            }
        ),

    /*
    * fetchPaginatedBySearchCriteria: Fetch paginated entries of the resource with search criteria for filtering
    *
    * searchCriteriaUpdated: decamlizing sortBy to make backend happy
    */
    fetchPaginatedBySearchCriteria: (
        searchCriteria: Object,
        page: number = 1,
        perPage: number = 100
    ) => {
        let searchCriteriaUpdated;
        if (searchCriteria.sortBy) {
            searchCriteriaUpdated = {
                ...searchCriteria,
                sortBy: decamelize(searchCriteria.sortBy),
            };
        }
        return request(
            `${pluralize(resourceName.toLowerCase())}/fetchPaginatedBySearchCriteria`,
            {
                method: 'POST',
                data: searchCriteriaUpdated || searchCriteria,
            },
            { page, per_page: perPage }
        );
    },

    /*
    * fetchReadableMimeTypes:
    */
    fetchReadableMimeTypes: () =>
        request(`${pluralize(resourceName.toLowerCase())}/readableMimetypes`, { method: 'GET' }),

    /*
    * fetchThumbnails:
    */
    fetchThumbnails: (mediaId: number, format: string) =>
        request(
            `${pluralize(resourceName.toLowerCase())}/${mediaId}/thumbnails`,
            {
                method: 'GET',
            },
            { format }
        ),

    /*
    * importCSV: Import with file upload
    */
    importCSV: (resource: Object) =>
        request(
            `${pluralize(resourceName.toLowerCase())}/import`,
            {
                method: 'POST',
                data: resource,
                headers: {
                    contentType: 'multipart/form-data',
                },
            },
            // eslint-disable-next-line no-undefined
            undefined,
            // eslint-disable-next-line no-undefined
            undefined,
            false
        ),

    /*
    * update: Update a single entry of the resource by id
    */
    update: (id: number, resource: Object) =>
        request(
            `${pluralize(resourceName.toLowerCase())}/${id}`,
            {
                method: 'POST',
                data: resource,
                headers: {
                    contentType: 'multipart/form-data',
                },
            },
            // eslint-disable-next-line no-undefined
            undefined,
            // eslint-disable-next-line no-undefined
            undefined,
            false
        ),

    /*
    * updateRelatedEntity: Update a single entry of a related entity
    */
    updateRelatedEntity: (relatedEntityName: string, relatedEntityId: number, resource: Object) =>
        request(`${pluralize(relatedEntityName.toLowerCase())}/${relatedEntityId}`, {
            method: 'PUT',
            data: resource,
        }),
});

export default crudResources;
