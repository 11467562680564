// @flow

import styled from 'styled-components';

import colors from 'styles/colors';

const Container = styled.div``;

const SingleItemContainer = styled.div`
    height: 171px;
    width: 100%;
    background-color: ${colors.white};
    box-shadow: inset 0 -1px 0 0 ${colors.grayED};
    margin-bottom: 10px;
    padding: 10px;
`;

const AuthorName = styled.div`
    height: 30px;
    min-width: 119px;
    color: ${colors.gray33};
    font-size: 14px;
    line-height: 30px;
`;

const CreationDate = styled.div`
    height: 30px;
    min-width: 106px;
    color: ${colors.gray64};
    font-size: 11px;
    line-height: 30px;

    &::first-letter {
        text-transform: capitalize;
    }
`;

const Text = styled.textarea`
    height: 60px;
    width: 100%;
    color: ${colors.gray64};
    font-size: 14px;
    line-height: 20px;
    overflow: auto;
    margin: 10px 0px;
    border: none;
    resize: none;
    outline: none;
    ${(props) => (!props.readOnly ? `font-style: italic;` : null)};
`;

const ActionsContainer = styled.div`
    *:first-child {
        margin-right: 10px;
    }
`;

export { Container, SingleItemContainer, AuthorName, CreationDate, Text, ActionsContainer };
