// @flow

import styled from 'styled-components';

import colors from 'styles/colors';

import { FlexRowContainer } from 'styles/common';

const SingleMediaViewContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: calc(100vh - 150px);
    overflow-y: auto;
`;

const MediaInfoContainer = styled.div`
    width: 65%;
    margin: 20px 5px 20px 20px;
`;

const MediaParentCollectionsContainer = styled.div`
    width: 35%;
    margin: 20px 20px 20px 5px;
    padding: 10px 20px;
    background-color: ${colors.white};
`;

const MediaImageViewContainer = styled.div`
    height: 45vh;
    background-color: ${colors.white};
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
        height: 100%;
    }
`;

const MediaMidSectionContainer = FlexRowContainer.extend``;

const MediaBasics = styled.div`
    overflow: hidden;
`;

const MediaName = styled.div`
    width: 500px;
    color: ${colors.black};
    font-size: 24px;
    line-height: 25px;
    margin-bottom: 10px;
`;

const MediaShortDescription = styled.div`
    height: 30px;
    width: 500px;
    color: ${colors.gray64};
    font-size: 14px;
    line-height: 14px;
    font-style: italic;
`;

const InformationTabEntryLabel = styled.div`
    height: 20px;
    width: 500px;
    color: ${colors.gray64};
    font-size: 14px;
    line-height: 14px;
    font-style: italic;
`;

const InformationTabEntry = styled.div`
    min-height: 30px;
    font-size: 14px;
    line-height: 20px;
`;

const ParentCollectionsTitle = styled.div`
    min-height: 30px;
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 10px;
`;

export {
    SingleMediaViewContainer,
    MediaInfoContainer,
    MediaParentCollectionsContainer,
    MediaImageViewContainer,
    MediaMidSectionContainer,
    MediaBasics,
    MediaName,
    MediaShortDescription,
    InformationTabEntryLabel,
    InformationTabEntry,
    ParentCollectionsTitle,
};
