// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Assets
import mainLogo from 'assets/ccdmd-logo-bw.png';

// Components
import Header from 'components/Header';
import LocaleToggle from 'components/LocaleToggle';
import SideNavMenu from './SideNavMenu';

// Styles
import { BrandLink } from 'styles/common';
import { SideNavLayoutContainer, SideNavContainer, ContentContainer } from './styles';

// Types
import type { ImmutableMap, IntlType, UserType, ReactNode } from 'types';

type Props = {
    content: ReactNode | Function,
    handleToggleLocale: () => void,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    locale: string,
    onLogout: () => void,
    pageTitleTranslationKey: string,
    path: string,
    user: ?ImmutableMap<string, UserType>,
};

/**
 * Component to be used for wrapping the views with the side navigation layout. Build the layout wrapper component and display the Content component within the layout
 */

const SideNavLayout = ({ content: Content, ...props }: Props) => (
    <SideNavLayoutContainer>
        <SideNavContainer>
            <BrandLink to="/">
                <img src={mainLogo} alt="CCDMD" />
            </BrandLink>
            <SideNavMenu path={props.path} user={props.user} />
            <LocaleToggle locale={props.locale} onToggleLocale={props.handleToggleLocale} />
        </SideNavContainer>
        <ContentContainer>
            <Header
                pageTitle={props.intl.formatMessage({
                    id: props.pageTitleTranslationKey,
                })}
                user={props.user}
                onLogout={props.onLogout}
            />
            <Content {...props} />
        </ContentContainer>
    </SideNavLayoutContainer>
);

export default injectIntl(SideNavLayout);
