// @flow

import React from 'react';

// Styles
import { Input, Label, Span, Text } from './styles';

// Types
import type { InputEvent } from 'types';

type DefaultProps = {
    right?: boolean,
};

type Props = DefaultProps & {
    value: ?string,
    valueOn: string | number,
    valueOff: string | number,
    labelOn: string,
    labelOff?: string,
    disabled?: boolean,
    onChange: Function,
};

type State = {
    value: ?string,
};

/**
 * Shared toggle switch component for string/number
 */

export default class ToggleSwitch extends React.Component<Props, State> {
    static defaultProps: DefaultProps = {
        right: false,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            value: props.value || props.valueOff,
        };
    }

    handleChange = (event: InputEvent) => {
        const { valueOn, valueOff } = this.props;
        event.persist();
        this.setState(
            {
                value: this.state.value === valueOn ? valueOff : valueOn,
            },
            () => {
                event.target.value = this.state.value;
                this.props.onChange(event);
            }
        );
    };

    render() {
        const { disabled, labelOn, labelOff, value, valueOn, right } = this.props;
        return (
            <div>
                {right ? (
                    <React.Fragment>
                        <Label>
                            <Input
                                type="checkbox"
                                checked={value === valueOn}
                                onChange={this.handleChange}
                                disabled={disabled}
                            />
                            <Span />
                        </Label>
                        <Text disabled={disabled} right>
                            {value === valueOn || !labelOff ? labelOn : labelOff}
                        </Text>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Text disabled={disabled}>
                            {value === valueOn || !labelOff ? labelOn : labelOff}
                        </Text>
                        <Label>
                            <Input
                                type="checkbox"
                                checked={value === valueOn}
                                onChange={this.handleChange}
                                disabled={disabled}
                            />
                            <Span />
                        </Label>
                    </React.Fragment>
                )}
                <input type="hidden" value={this.state.value} />
            </div>
        );
    }
}
