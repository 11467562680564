// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { fromJS } from 'immutable';

// Components
import ErrorMessage from 'components/ErrorMessage';
import Input from 'components/Form/Input';

// Styles
import { ReverseButton } from 'styles/buttons';
import { FlexContainer, FlexItemContainer, LineBreak } from 'styles/common';
import { FormGroup, FormNotice, Label } from 'styles/form';

// Types
import type { ImmutableMap, ImmutableList } from 'types';

const targetData = 'authors';

type Props = {
    errors: ImmutableMap<string, any>,
    handleErrors: Function,
    handleValidationState: Function,
    isLoading: boolean,
    media?: ImmutableMap<string, mixed>,
    onChangeField: Function,
};

type State = {
    list: ?ImmutableList<string>,
};

export default class RepeatableAuthorBlock extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const authors =
            props.media && props.media.has(targetData) && !props.media.get(targetData).isEmpty()
                ? props.media.get(targetData)
                : null;
        this.state = {
            list:
                authors ||
                fromJS([
                    {
                        key: 'new-0',
                    },
                ]),
        };
    }

    handleAddRepeatableElement = (index: number) => () => {
        const { list } = this.state;
        const newIndex = index + 1;
        if (list) {
            const newList = list.setIn(
                [newIndex],
                fromJS({
                    key: `new-${newIndex}`,
                })
            );
            this.setState({
                list: newList,
            });
        }
    };

    handleChangeField = (name: string, index: number) => (event: Event) => {
        event.preventDefault();
        event.persist();
        const { list } = this.state;
        if (list) {
            const newList = list.setIn([index, name], event.target.value);
            this.setState(
                {
                    list: newList,
                },
                () => this.props.onChangeField(targetData, newList)
            );
        }
    };

    handleClearElement = () => (event: Event) => {
        event.preventDefault();
        event.persist();
        const { list } = this.state;
        if (list) {
            this.setState(
                {
                    list: fromJS([
                        {
                            key: 0,
                        },
                    ]),
                },
                () => {
                    this.props.onChangeField(targetData, []);
                }
            );
        }
    };

    handleDeleteElement = (id: number) => (event: Event) => {
        event.preventDefault();
        event.persist();
        const { list } = this.state;
        if (list) {
            const newList = list.filterNot((item: Object) => item.get('id') === id);
            this.setState(
                {
                    list: newList,
                },
                () => this.props.onChangeField(targetData, newList)
            );
        }
    };

    render() {
        const { list } = this.state;
        const listSize = list && list.size;
        const rows =
            list &&
            list.map((singleItem: ImmutableMap<string, mixed>, index: number) => {
                const isFilled =
                    singleItem &&
                    (singleItem.get('firstName') ||
                        singleItem.get('lastName') ||
                        singleItem.get('email'));
                const isLast = listSize && index === listSize - 1;
                return (
                    <React.Fragment key={singleItem.get('id') || singleItem.get('key')}>
                        <FlexContainer justifyContent="space-between" gutter="10">
                            <FlexItemContainer direction="column" justifyContent="flex-start">
                                <FormGroup
                                    validationState={this.props.handleValidationState(
                                        this.props.handleErrors('authors')
                                    )}
                                >
                                    <Label htmlFor="creator">
                                        <FormattedMessage id="components.Media.inputLabelValueAuthor" />{' '}
                                        *
                                    </Label>
                                    <Input
                                        name="firstName"
                                        type="text"
                                        placeholder={
                                            'components.Media.inputLabelValueAuthorFirstName.placeholder'
                                        }
                                        onChange={this.handleChangeField('firstName', index)}
                                        disabled={this.props.isLoading}
                                        value={singleItem && singleItem.get('firstName')}
                                    />
                                </FormGroup>
                            </FlexItemContainer>
                            <FlexItemContainer direction="column" justifyContent="flex-end">
                                <FormGroup
                                    validationState={this.props.handleValidationState(
                                        this.props.handleErrors('authors')
                                    )}
                                >
                                    <Input
                                        name="lastName"
                                        type="text"
                                        placeholder={
                                            'components.Media.inputLabelValueAuthorLastName.placeholder'
                                        }
                                        onChange={this.handleChangeField('lastName', index)}
                                        disabled={this.props.isLoading}
                                        value={singleItem && singleItem.get('lastName')}
                                    />
                                </FormGroup>
                            </FlexItemContainer>
                        </FlexContainer>
                        <FormGroup
                            validationState={this.props.handleValidationState(
                                this.props.handleErrors('authors') ||
                                    this.props.handleErrors(`authors.${index}.email`)
                            )}
                        >
                            <Label htmlFor="creatorEmailAddress">
                                <FormattedMessage id="components.Media.inputLabelValueAuthorEmailAddress" />
                            </Label>
                            <Input
                                name="email"
                                type="email"
                                placeholder={'global.writeHere'}
                                onChange={this.handleChangeField('email', index)}
                                disabled={this.props.isLoading}
                                value={singleItem && singleItem.get('email')}
                            />
                            <ErrorMessage
                                error={
                                    this.props.handleErrors(`authors.${index}.email`)
                                        ? this.props.errors.getIn(['data', 'errors', 'authors']) ||
                                          this.props.errors.getIn([
                                              'data',
                                              'errors',
                                              `authors.${index}.email`,
                                          ])
                                        : null
                                }
                                values={{
                                    attribute: 'components.Media.inputLabelValueAuthorEmailAddress',
                                }}
                            />
                            <ErrorMessage
                                error={
                                    this.props.handleErrors(`authors`)
                                        ? this.props.errors.getIn(['data', 'errors', 'authors']) ||
                                          this.props.errors.getIn(['data', 'errors', `authors`])
                                        : null
                                }
                                values={{
                                    attribute: 'components.Media.inputLabelValueAuthor',
                                }}
                            />
                        </FormGroup>
                        {isLast || singleItem.get('id') ? (
                            <React.Fragment>
                                <FlexContainer justifyContent="flex-start" gutter="10">
                                    {isLast && (
                                        <FlexItemContainer collapsed>
                                            <ReverseButton
                                                disabled={!isFilled || !isLast}
                                                onClick={
                                                    isFilled && isLast
                                                        ? this.handleAddRepeatableElement(index)
                                                        : null
                                                }
                                            >
                                                <FormattedMessage id="components.Media.addAuthor" />
                                            </ReverseButton>
                                        </FlexItemContainer>
                                    )}
                                    {singleItem.get('id') && (
                                        <FlexItemContainer collapsed>
                                            <ReverseButton
                                                onClick={
                                                    listSize === 1
                                                        ? this.handleClearElement()
                                                        : this.handleDeleteElement(
                                                              singleItem && singleItem.get('id')
                                                          )
                                                }
                                                subtle
                                            >
                                                <FormattedMessage
                                                    id={
                                                        listSize === 1
                                                            ? `global.clear`
                                                            : `global.delete`
                                                    }
                                                />
                                            </ReverseButton>
                                        </FlexItemContainer>
                                    )}
                                </FlexContainer>
                                <LineBreak height="10px" />
                            </React.Fragment>
                        ) : (
                            <LineBreak height="20px" />
                        )}
                    </React.Fragment>
                );
            });
        return <React.Fragment>{rows}</React.Fragment>;
    }
}
