// @flow

/*
 *
 * CRUDResource actions
 *
 * crudResourceActionTypes: Define constant types for each action
 * crudResourceActions: For each type, define action to pass data as payload
 *
 */

export const crudResourceActionTypes = (resourceName: string) => ({
    // Resource list
    FETCH_LIST_SUCCESS: `app/services/${resourceName}/FETCH_LIST_SUCCESS`,
    FETCH_LIST_FAILURE: `app/services/${resourceName}/FETCH_LIST_FAILURE`,
});

export const crudResourceActions = (resourceName: string) => ({
    receivedBatchAttachChildCollectionsSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string))
            .BATCH_ATTACH_CHILD_COLLECTIONS_SUCCESS,
        payload: { data },
    }),
    receivedBatchAttachChildCollectionsFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string))
            .BATCH_ATTACH_CHILD_COLLECTIONS_FAILURE,
        payload: { errors },
    }),
    receivedBatchAttachMediaSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).BATCH_ATTACH_MEDIA_SUCCESS,
        payload: { data },
    }),
    receivedBatchAttachMediaFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).BATCH_ATTACH_MEDIA_FAILURE,
        payload: { errors },
    }),
    receivedBatchDetachChildCollectionsSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string))
            .BATCH_DETACH_CHILD_COLLECTIONS_SUCCESS,
        payload: { data },
    }),
    receivedBatchDetachChildCollectionsFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string))
            .BATCH_DETACH_CHILD_COLLECTIONS_FAILURE,
        payload: { errors },
    }),
    receivedBatchDetachMediaSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).BATCH_DETACH_MEDIA_SUCCESS,
        payload: { data },
    }),
    receivedBatchDetachMediaFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).BATCH_DETACH_MEDIA_FAILURE,
        payload: { errors },
    }),
    receivedFetchListSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_LIST_SUCCESS,
        payload: { data },
    }),
    receivedFetchListFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_LIST_FAILURE,
        payload: { errors },
    }),
    receivedFetchReadableMimetypeSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_READABLE_MIMETYPE_SUCCESS,
        payload: { data },
    }),
    receivedFetchReadableMimetypeFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_READABLE_MIMETYPE_FAILURE,
        payload: { errors },
    }),
    receivedFetchThumbnailsSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_THUMBNAILS_SUCCESS,
        payload: { data },
    }),
    receivedFetchThumbnailsFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_THUMBNAILS_FAILURE,
        payload: { errors },
    }),
    receivedFetchPaginatedIsFetching: (isFetching) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_PAGINATED_IS_FETCHING,
        payload: { isFetching },
    }),
    receivedFetchPaginatedSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_PAGINATED_SUCCESS,
        payload: { data },
    }),
    receivedFetchPaginatedFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_PAGINATED_FAILURE,
        payload: { errors },
    }),
    receivedFetchPaginatedBySearchCriteriaSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string))
            .FETCH_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS,
        payload: { data },
    }),
    receivedFetchPaginatedBySearchCriteriaFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string))
            .FETCH_PAGINATED_BY_SEARCH_CRITERIA_FAILURE,
        payload: { errors },
    }),
    receivedFetchSecondaryPaginatedIsFetching: (isFetching) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_SECONDARY_PAGINATED_IS_FETCHING,
        payload: { isFetching },
    }),
    receivedFetchSecondaryPaginatedBySearchCriteriaSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string))
            .FETCH_SECONDARY_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS,
        payload: { data },
    }),
    receivedFetchSecondaryPaginatedBySearchCriteriaFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string))
            .FETCH_SECONDARY_PAGINATED_BY_SEARCH_CRITERIA_FAILURE,
        payload: { errors },
    }),
    receivedFetchSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_SUCCESS,
        payload: { data },
    }),
    receivedFetchFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_FAILURE,
        payload: { errors },
    }),
    receivedGenerateSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).GENERATE_SUCCESS,
        payload: { data },
    }),
    receivedGenerateFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).GENERATE_FAILURE,
        payload: { errors },
    }),
    receivedCreateSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).CREATE_SUCCESS,
        payload: { data },
    }),
    receivedCreateFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).CREATE_FAILURE,
        payload: { errors },
    }),
    receivedUpdateSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).UPDATE_SUCCESS,
        payload: { data },
    }),
    receivedUpdateFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).UPDATE_FAILURE,
        payload: { errors },
    }),
    receivedUpdateRelatedEntitySuccess: (resourceId, entityName, data) => ({
        type: crudResourceActionTypes((resourceName: string)).UPDATE_RELATED_ENTITY_SUCCESS,
        payload: { resourceId, entityName, data },
    }),
    receivedUpdateRelatedEntityFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).UPDATE_RELATED_ENTITY_FAILURE,
        payload: { errors },
    }),
    receivedDeleteSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).DELETE_SUCCESS,
        payload: { data },
    }),
    receivedDeleteFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).DELETE_FAILURE,
        payload: { errors },
    }),
    receivedDeleteRelatedEntitySuccess: (resourceId, entityName, entityId) => ({
        type: crudResourceActionTypes((resourceName: string)).DELETE_RELATED_ENTITY_SUCCESS,
        payload: { resourceId, entityName, entityId },
    }),
    receivedDeleteRelatedEntityFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).DELETE_RELATED_ENTITY_FAILURE,
        payload: { errors },
    }),
    deleteNew: () => ({
        type: crudResourceActionTypes((resourceName: string)).DELETE_NEW,
        payload: {},
    }),
    resetErrors: () => ({
        type: crudResourceActionTypes((resourceName: string)).RESET_ERRORS,
        payload: {},
    }),
});
