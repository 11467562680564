// @flow

import { crudResourceActionTypes, crudResourceActions } from './actions';
import crudServiceReducer from './reducer';
import crudResources from './resources';
import crudSelectors from './selectors';
import crudThunks from './thunks';

/*
 *
 * Collection Resource (REDUX)
 *
 * Includes:
 *  - actions & action types
 *  - reducers - Connects response from API to store
 *  - resources - API calls
 *  - selectors - Fetches data from store
 *  - thunks - Performs actions and deals with outcome of resources
 *
 * Usage instructions:
 *  - Resource Object name: Update to reflect the resource in question
 *  - resourceName attribute: Update to reflect the resource name
 */

export const collectionResource = () => {
    const resourceName = 'Collection';

    return {
        actionTypes: () => crudResourceActionTypes((resourceName: string)),
        actions: () => crudResourceActions((resourceName: string)),
        serviceReducer: () => crudServiceReducer((resourceName: string)),
        resources: () => crudResources((resourceName: string)),
        selectors: () => crudSelectors((resourceName: string)),
        thunks: () => crudThunks((resourceName: string)),
    };
};

export default collectionResource;
