// @flow

/*
 *
 * CRUDResource actions
 *
 * crudResourceActionTypes: Define constant types for each action
 * crudResourceActions: For each type, define action to pass data as payload
 *
 */
export const crudResourceActionTypes = (resourceName: string) => ({
    // Resource list
    FETCH_LIST_SUCCESS: `app/services/${resourceName}/FETCH_LIST_SUCCESS`,
    FETCH_LIST_FAILURE: `app/services/${resourceName}/FETCH_LIST_FAILURE`,
});

export const crudResourceActions = (resourceName: string) => ({
    receivedFetchListSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_LIST_SUCCESS,
        payload: { data },
    }),
    receivedFetchListFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_LIST_FAILURE,
        payload: { errors },
    }),
});
