// @flow

/*
 *
 * CRUDResource actions
 *
 * crudResourceActionTypes: Define constant types for each action
 * crudResourceActions: For each type, define action to pass data as payload
 *
 */

export const crudResourceActionTypes = (resourceName: string) => ({
    // Mimetype
    FETCH_READABLE_MIMETYPE_SUCCESS: `app/services/${resourceName}/FETCH_READABLE_MIMETYPE_SUCCESS`,
    FETCH_READABLE_MIMETYPE_FAILURE: `app/services/${resourceName}/FETCH_READABLE_MIMETYPE_FAILURE`,
    // Resource list
    FETCH_LIST_SUCCESS: `app/services/${resourceName}/FETCH_LIST_SUCCESS`,
    FETCH_LIST_FAILURE: `app/services/${resourceName}/FETCH_LIST_FAILURE`,
    // Thumbnails
    FETCH_THUMBNAILS_SUCCESS: `app/services/${resourceName}/FETCH_THUMBNAILS_SUCCESS`,
    FETCH_THUMBNAILS_FAILURE: `app/services/${resourceName}/FETCH_THUMBNAILS_FAILURE`,
    // Paginated Resource list
    FETCH_PAGINATED_IS_FETCHING: `app/services/${resourceName}/FETCH_PAGINATED_IS_FETCHING`,
    FETCH_PAGINATED_SUCCESS: `app/services/${resourceName}/FETCH_PAGINATED_SUCCESS`,
    FETCH_PAGINATED_FAILURE: `app/services/${resourceName}/FETCH_PAGINATED_FAILURE`,
    // Paginated Resource list filtered by search criteria
    FETCH_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS: `app/services/${resourceName}/FETCH_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS`,
    FETCH_PAGINATED_BY_SEARCH_CRITERIA_FAILURE: `app/services/${resourceName}/FETCH_PAGINATED_BY_SEARCH_CRITERIA_FAILURE`,
    // Secondary Paginated Resource list filtered by search criteria
    FETCH_SECONDARY_PAGINATED_IS_FETCHING: `app/services/${resourceName}/FETCH_SECONDARY_PAGINATED_IS_FETCHING`,
    FETCH_SECONDARY_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS: `app/services/${resourceName}/FETCH_SECONDARY_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS`,
    FETCH_SECONDARY_PAGINATED_BY_SEARCH_CRITERIA_FAILURE: `app/services/${resourceName}/FETCH_PAGINATED_BY_SEARCH_CRITERIA_FAILURE`,
    // Fetch single
    FETCH_SUCCESS: `app/services/${resourceName}/FETCH_SUCCESS`,
    FETCH_FAILURE: `app/services/${resourceName}/FETCH_FAILURE`,
    // Create draft
    DRAFT_SUCCESS: `app/services/${resourceName}/DRAFT_SUCCESS`,
    DRAFT_FAILURE: `app/services/${resourceName}/DRAFT_FAILURE`,
    // Generate new
    GENERATE_SUCCESS: `app/services/${resourceName}/GENERATE_SUCCESS`,
    GENERATE_FAILURE: `app/services/${resourceName}/GENERATE_FAILURE`,
    // Create new
    CREATE_SUCCESS: `app/services/${resourceName}/CREATE_SUCCESS`,
    CREATE_FAILURE: `app/services/${resourceName}/CREATE_FAILURE`,
    // Update single
    UPDATE_SUCCESS: `app/services/${resourceName}/UPDATE_SUCCESS`,
    UPDATE_FAILURE: `app/services/${resourceName}/UPDATE_FAILURE`,
    // Update single related entity
    UPDATE_RELATED_ENTITY_SUCCESS: `app/services/${resourceName}/UPDATE_RELATED_ENTITY_SUCCESS`,
    UPDATE_RELATED_ENTITY_FAILURE: `app/services/${resourceName}/UPDATE_RELATED_ENTITY_FAILURE`,
    // Delete single
    DELETE_SUCCESS: `app/services/${resourceName}/DELETE_SUCCESS`,
    DELETE_FAILURE: `app/services/${resourceName}/DELETE_FAILURE`,
    // Delete single related entity
    DELETE_RELATED_ENTITY_SUCCESS: `app/services/${resourceName}/DELETE_RELATED_ENTITY_SUCCESS`,
    DELETE_RELATED_ENTITY_FAILURE: `app/services/${resourceName}/DELETE_RELATED_ENTITY_FAILURE`,
    // Delete new
    DELETE_NEW: `app/services/${resourceName}/DELETE_NEW`,
    // Reset errors
    RESET_ERRORS: `app/services/${resourceName}/RESET_ERRORS`,
    // Collections
    COLLECTION_MEDIA_ORDER_SUCCESS: `app/services/${resourceName}/COLLECTION_MEDIA_ORDER_SUCCESS`,
    COLLECTION_MEDIA_ORDER_FAILURE: `app/services/${resourceName}/COLLECTION_MEDIA_ORDER_FAILURE`,
    // Import
    IMPORT_SUCCESS: `app/services/${resourceName}/IMPORT_SUCCESS`,
    IMPORT_FAILURE: `app/services/${resourceName}/IMPORT_FAILURE`,
    // Fetch imports
    FETCH_LIST_IMPORT_SUCCESS: `app/services/${resourceName}/FETCH_LIST_IMPORT_SUCCESS`,
    FETCH_LIST_IMPORT_FAILURE: `app/services/${resourceName}/FETCH_LIST_IMPORT_FAILURE`,
    // Fetch countries
    FETCH_LIST_COUNTRIES_SUCCESS: `app/services/${resourceName}/FETCH_LIST_COUNTRIES_SUCCESS`,
    FETCH_LIST_COUNTRIES_FAILURE: `app/services/${resourceName}/FETCH_LIST_COUNTRIES_FAILURE`,
});

export const crudResourceActions = (resourceName: string) => ({
    receivedFetchReadableMimetypeSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_READABLE_MIMETYPE_SUCCESS,
        payload: { data },
    }),
    receivedFetchReadableMimetypeFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_READABLE_MIMETYPE_FAILURE,
        payload: { errors },
    }),
    receivedFetchThumbnailsSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_THUMBNAILS_SUCCESS,
        payload: { data },
    }),
    receivedFetchThumbnailsFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_THUMBNAILS_FAILURE,
        payload: { errors },
    }),
    receivedFetchListSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_LIST_SUCCESS,
        payload: { data },
    }),
    receivedFetchListFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_LIST_FAILURE,
        payload: { errors },
    }),
    receivedFetchPaginatedSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_PAGINATED_SUCCESS,
        payload: { data },
    }),
    receivedFetchPaginatedFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_PAGINATED_FAILURE,
        payload: { errors },
    }),
    receivedFetchPaginatedIsFetching: (isFetching) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_PAGINATED_IS_FETCHING,
        payload: { isFetching },
    }),
    receivedFetchPaginatedBySearchCriteriaSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string))
            .FETCH_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS,
        payload: { data },
    }),
    receivedFetchPaginatedBySearchCriteriaFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string))
            .FETCH_PAGINATED_BY_SEARCH_CRITERIA_FAILURE,
        payload: { errors },
    }),
    receivedFetchSecondaryPaginatedIsFetching: (isFetching) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_SECONDARY_PAGINATED_IS_FETCHING,
        payload: { isFetching },
    }),
    receivedFetchSecondaryPaginatedBySearchCriteriaSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string))
            .FETCH_SECONDARY_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS,
        payload: { data },
    }),
    receivedFetchSecondaryPaginatedBySearchCriteriaFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string))
            .FETCH_SECONDARY_PAGINATED_BY_SEARCH_CRITERIA_FAILURE,
        payload: { errors },
    }),
    receivedFetchSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_SUCCESS,
        payload: { data },
    }),
    receivedFetchFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_FAILURE,
        payload: { errors },
    }),
    receivedCreateSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).CREATE_SUCCESS,
        payload: { data },
    }),
    receivedCreateFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).CREATE_FAILURE,
        payload: { errors },
    }),
    receivedUpdateSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).UPDATE_SUCCESS,
        payload: { data },
    }),
    receivedUpdateFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).UPDATE_FAILURE,
        payload: { errors },
    }),
    receivedUpdateRelatedEntitySuccess: (resourceId, entityName, data) => ({
        type: crudResourceActionTypes((resourceName: string)).UPDATE_RELATED_ENTITY_SUCCESS,
        payload: { resourceId, entityName, data },
    }),
    receivedUpdateRelatedEntityFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).UPDATE_RELATED_ENTITY_FAILURE,
        payload: { errors },
    }),
    receivedDeleteSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).DELETE_SUCCESS,
        payload: { data },
    }),
    receivedDeleteFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).DELETE_FAILURE,
        payload: { errors },
    }),
    receivedDeleteRelatedEntitySuccess: (resourceId, entityName, entityId) => ({
        type: crudResourceActionTypes((resourceName: string)).DELETE_RELATED_ENTITY_SUCCESS,
        payload: { resourceId, entityName, entityId },
    }),
    receivedDeleteRelatedEntityFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).DELETE_RELATED_ENTITY_FAILURE,
        payload: { errors },
    }),
    deleteNew: () => ({
        type: crudResourceActionTypes((resourceName: string)).DELETE_NEW,
        payload: {},
    }),
    resetErrors: () => ({
        type: crudResourceActionTypes((resourceName: string)).RESET_ERRORS,
        payload: {},
    }),
    receivedSetMediaOrderSuccess: (mediaId, data) => ({
        type: crudResourceActionTypes((resourceName: string)).COLLECTION_MEDIA_ORDER_SUCCESS,
        payload: { data },
        meta: { mediaId },
    }),
    receivedImportSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).IMPORT_SUCCESS,
        payload: { data },
    }),
    receivedImportFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).IMPORT_FAILURE,
        payload: { errors },
    }),
    receivedFetchImportListSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_LIST_IMPORT_SUCCESS,
        payload: { data },
    }),
    receivedFetchImportListFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_LIST_IMPORT_FAILURE,
        payload: { errors },
    }),
    receivedFetchCountriesListSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_LIST_COUNTRIES_SUCCESS,
        payload: { data },
    }),
    receivedFetchCountriesListFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_LIST_COUNTRIES_FAILURE,
        payload: { errors },
    }),
});
