// @flow

/*
 *
 * CRUDResource actions
 *
 * crudResourceActionTypes: Define constant types for each action
 * crudResourceActions: For each type, define action to pass data as payload
 *
 */

export const crudResourceActionTypes = (resourceName: string) => ({
    // Batch
    BATCH_ATTACH_CHILD_COLLECTIONS_SUCCESS: `app/services/${resourceName}/BATCH_ATTACH_CHILD_COLLECTIONS_SUCCESS`,
    BATCH_ATTACH_CHILD_COLLECTIONS_FAILURE: `app/services/${resourceName}/BATCH_ATTACH_CHILD_COLLECTIONS_FAILURE`,
    BATCH_ATTACH_MEDIA_SUCCESS: `app/services/${resourceName}/BATCH_ATTACH_MEDIA_SUCCESS`,
    BATCH_ATTACH_MEDIA_FAILURE: `app/services/${resourceName}/BATCH_ATTACH_MEDIA_FAILURE`,
    BATCH_DETACH_CHILD_COLLECTIONS_SUCCESS: `app/services/${resourceName}/BATCH_DETACH_CHILD_COLLECTIONS_SUCCESS`,
    BATCH_DETACH_CHILD_COLLECTIONS_FAILURE: `app/services/${resourceName}/BATCH_DETACH_CHILD_COLLECTIONS_FAILURE`,
    BATCH_DETACH_MEDIA_SUCCESS: `app/services/${resourceName}/BATCH_DETACH_MEDIA_SUCCESS`,
    BATCH_DETACH_MEDIA_FAILURE: `app/services/${resourceName}/BATCH_DETACH_MEDIA_FAILURE`,
    // Paginated Resource list
    FETCH_PAGINATED_IS_FETCHING: `app/services/${resourceName}/FETCH_PAGINATED_IS_FETCHING`,
    // Paginated Resource list filtered by search criteria
    FETCH_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS: `app/services/${resourceName}/FETCH_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS`,
    FETCH_PAGINATED_BY_SEARCH_CRITERIA_FAILURE: `app/services/${resourceName}/FETCH_PAGINATED_BY_SEARCH_CRITERIA_FAILURE`,
    // Secondary Paginated Resource list filtered by search criteria
    FETCH_SECONDARY_PAGINATED_IS_FETCHING: `app/services/${resourceName}/FETCH_SECONDARY_PAGINATED_IS_FETCHING`,
    FETCH_SECONDARY_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS: `app/services/${resourceName}/FETCH_SECONDARY_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS`,
    FETCH_SECONDARY_PAGINATED_BY_SEARCH_CRITERIA_FAILURE: `app/services/${resourceName}/FETCH_PAGINATED_BY_SEARCH_CRITERIA_FAILURE`,
    // Fetch single
    FETCH_SUCCESS: `app/services/${resourceName}/FETCH_SUCCESS`,
    FETCH_FAILURE: `app/services/${resourceName}/FETCH_FAILURE`,
    // Create new
    CREATE_SUCCESS: `app/services/${resourceName}/CREATE_SUCCESS`,
    CREATE_FAILURE: `app/services/${resourceName}/CREATE_FAILURE`,
    // Update single
    UPDATE_SUCCESS: `app/services/${resourceName}/UPDATE_SUCCESS`,
    UPDATE_FAILURE: `app/services/${resourceName}/UPDATE_FAILURE`,
    // Delete single
    DELETE_SUCCESS: `app/services/${resourceName}/DELETE_SUCCESS`,
    DELETE_FAILURE: `app/services/${resourceName}/DELETE_FAILURE`,
    // Delete new
    DELETE_NEW: `app/services/${resourceName}/DELETE_NEW`,
    // Reset errors
    RESET_ERRORS: `app/services/${resourceName}/RESET_ERRORS`,
    // Collection media ordering
    COLLECTION_MEDIA_ORDER_SUCCESS: `app/services/${resourceName}/COLLECTION_MEDIA_ORDER_SUCCESS`,
    COLLECTION_MEDIA_ORDER_FAILURE: `app/services/${resourceName}/COLLECTION_MEDIA_ORDER_FAILURE`,
    // Collection collections ordering
    COLLECTION_COLLECTION_ORDER_SUCCESS: `app/services/${resourceName}/COLLECTION_COLLECTION_ORDER_SUCCESS`,
    COLLECTION_COLLECTION_ORDER_FAILURE: `app/services/${resourceName}/COLLECTION_COLLECTION_ORDER_FAILURE`,
});

export const crudResourceActions = (resourceName: string) => ({
    receivedBatchAttachChildCollectionsSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string))
            .BATCH_ATTACH_CHILD_COLLECTIONS_SUCCESS,
        payload: { data },
    }),
    receivedBatchAttachChildCollectionsFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string))
            .BATCH_ATTACH_CHILD_COLLECTIONS_FAILURE,
        payload: { errors },
    }),
    receivedBatchAttachMediaSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).BATCH_ATTACH_MEDIA_SUCCESS,
        payload: { data },
    }),
    receivedBatchAttachMediaFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).BATCH_ATTACH_MEDIA_FAILURE,
        payload: { errors },
    }),
    receivedBatchDetachChildCollectionsSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string))
            .BATCH_DETACH_CHILD_COLLECTIONS_SUCCESS,
        payload: { data },
    }),
    receivedBatchDetachChildCollectionsFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string))
            .BATCH_DETACH_CHILD_COLLECTIONS_FAILURE,
        payload: { errors },
    }),
    receivedBatchDetachMediaSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).BATCH_DETACH_MEDIA_SUCCESS,
        payload: { data },
    }),
    receivedBatchDetachMediaFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).BATCH_DETACH_MEDIA_FAILURE,
        payload: { errors },
    }),
    receivedFetchPaginatedIsFetching: (isFetching) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_PAGINATED_IS_FETCHING,
        payload: { isFetching },
    }),
    receivedFetchPaginatedBySearchCriteriaSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string))
            .FETCH_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS,
        payload: { data },
    }),
    receivedFetchPaginatedBySearchCriteriaFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string))
            .FETCH_PAGINATED_BY_SEARCH_CRITERIA_FAILURE,
        payload: { errors },
    }),
    receivedFetchSecondaryPaginatedIsFetching: (isFetching) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_SECONDARY_PAGINATED_IS_FETCHING,
        payload: { isFetching },
    }),
    receivedFetchSecondaryPaginatedBySearchCriteriaSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string))
            .FETCH_SECONDARY_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS,
        payload: { data },
    }),
    receivedFetchSecondaryPaginatedBySearchCriteriaFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string))
            .FETCH_SECONDARY_PAGINATED_BY_SEARCH_CRITERIA_FAILURE,
        payload: { errors },
    }),
    receivedFetchSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_SUCCESS,
        payload: { data },
    }),
    receivedFetchFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_FAILURE,
        payload: { errors },
    }),
    receivedCreateSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).CREATE_SUCCESS,
        payload: { data },
    }),
    receivedCreateFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).CREATE_FAILURE,
        payload: { errors },
    }),
    receivedUpdateSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).UPDATE_SUCCESS,
        payload: { data },
    }),
    receivedUpdateFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).UPDATE_FAILURE,
        payload: { errors },
    }),
    receivedDeleteSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).DELETE_SUCCESS,
        payload: { data },
    }),
    receivedDeleteFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).DELETE_FAILURE,
        payload: { errors },
    }),
    resetErrors: () => ({
        type: crudResourceActionTypes((resourceName: string)).RESET_ERRORS,
        payload: {},
    }),
    receivedSetCollectionMediaOrderSuccess: (collectionId, data) => ({
        type: crudResourceActionTypes((resourceName: string)).COLLECTION_MEDIA_ORDER_SUCCESS,
        payload: { data },
        meta: { collectionId },
    }),
    receivedSetCollectionMediaOrderFailure: (collectionId, errors) => ({
        type: crudResourceActionTypes((resourceName: string)).COLLECTION_MEDIA_ORDER_FAILURE,
        payload: { errors },
        meta: { collectionId },
    }),
    receivedSetCollectionCollectionOrderSuccess: (
        collectionId: number,
        childCollectionId: number,
        data: Object
    ) => ({
        type: crudResourceActionTypes((resourceName: string)).COLLECTION_COLLECTION_ORDER_SUCCESS,
        payload: { data },
        meta: { collectionId, childCollectionId },
    }),
    receivedSetCollectionCollectionOrderFailure: (
        collectionId: number,
        childCollectionId: number,
        errors: Object
    ) => ({
        type: crudResourceActionTypes((resourceName: string)).COLLECTION_COLLECTION_ORDER_FAILURE,
        payload: { errors },
        meta: { collectionId, childCollectionId },
    }),
    deleteNew: () => ({
        type: crudResourceActionTypes((resourceName: string)).DELETE_NEW,
        payload: {},
    }),
});
