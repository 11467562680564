// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

import { FlexContainer, FlexItem, FlexItemContainer } from 'styles/common';

export const Item = styled.li`
    margin: 5px 10px;
    vertical-align: top;
`;

export const List = styled.ul`
    margin: -5px -10px;
    padding: 0;
    list-style: none;

    ${Item} + ${Item} {
        margin-top: 15px;
    }
`;

export const InlineItem = styled.li`
    display: inline-block;
    margin: 5px 10px;
    veritcal-align: middle;
`;

export const SearchCriteriaContainer = FlexContainer.extend``;

export const SearchCriteriaTabs = FlexItemContainer.extend`
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 33%;
`;

export const SearchCriteriaTabNote = styled.div`
    margin-bottom: -5px;
    color: ${colors.gray64};
    font-size: 10px;
    line-height: normal;
`;

export const SearchCriteriaTab = FlexItem.extend`
    display: flex;
    align-items: center;
    padding: 10px !important;
    min-height: 50px;

    background-color: ${colors.grayED};
    &:hover {
        background-color: ${colors.grayF4};
        cursor: pointer;
    }

    border-bottom: 1px solid ${colors.grayE1};

    color: ${colors.gray64};

    ${(props) =>
        props.active &&
        `
        background-color: ${colors.white} !important;
        color: ${colors.gray33};
    `};
`;

export const SearchCriteriaContent = FlexItemContainer.extend`
    justify-content: flex-start;
    padding: 0 20px;

    background-color: ${colors.white};
    border-bottom: 1px solid ${colors.grayE1};

    > div {
        width: 100%;
    }
`;

export const SearchCriteriaContentBody = styled.div`
    margin-bottom: 10px;
`;

export const SearchCriteriaContentTitle = FlexItem.extend`
    display: flex;
    align-items: center;

    padding: 10px 0;
    min-height: 50px;

    color: ${colors.gray64};
`;

export const SearchCriteriaContentTitleNote = styled.span`
    margin-left: 5px;
    font-size: 11px;
`;
