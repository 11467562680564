// @flow

import React from 'react';

// Styles
import {
    ModalContainer,
    ModalContentArea,
    ModalHeader,
    ModalHeaderTitle,
    ModalClose,
    ModalMainContent,
    ModalControls,
} from './styles';

// Types
import type { ReactNode } from 'types';

type Props = {
    children?: ReactNode,
    controls?: ReactNode, // TODO Remove this
    flush?: boolean,
    fullScreen?: boolean,
    headerTitle?: ReactNode | string,
    large?: boolean,
    onModalClose: Function,
    show: boolean,
    simple?: boolean,
    small?: boolean,
};

/**
 * Shared modal component
 */

const Modal = ({
    children,
    controls, // TODO Remove this
    flush,
    fullScreen,
    headerTitle,
    large,
    onModalClose,
    show,
    simple,
    small,
}: Props) => {
    if (!show) {
        return null;
    }

    return (
        <ModalContainer fullScreen={fullScreen}>
            <ModalContentArea fullScreen={fullScreen} small={small} large={large}>
                {!simple && (
                    <ModalHeader>
                        <ModalHeaderTitle>{headerTitle}</ModalHeaderTitle>
                    </ModalHeader>
                )}
                <ModalClose onClick={onModalClose} />
                <ModalMainContent controls={controls} flush={flush} simple={simple}>
                    {children}
                </ModalMainContent>
                {controls && <ModalControls right>{controls}</ModalControls>}
            </ModalContentArea>
        </ModalContainer>
    );
};

Modal.defaultProps = {
    controls: null, // TODO Remove this
    flush: false,
    fullScreen: false,
    large: false,
    simple: false,
    small: false,
    headerTitle: null,
};

export default Modal;
