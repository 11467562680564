// @flow

import React from 'react';

// Components
import VideoTrack from 'components/VideoTrack';

type Props = {
    src?: string,
    chapter?: Object,
    subtitles?: Object,
    thumbnail?: string,
};

/**
 * Video player component
 */

const VideoPlayer = ({ chapter, src, subtitles, thumbnail }: Props) =>
    src ? (
        /* eslint-disable jsx-a11y/media-has-caption */
        <video
            width="100%"
            height="100%"
            controlsList="nodownload"
            crossOrigin="anonymous"
            poster={thumbnail}
            controls
        >
            <source src={src} />
            {subtitles &&
                subtitles.map((sub, index) => (
                    <VideoTrack
                        key={sub.get('id')}
                        kind="subtitles"
                        language={sub.get('language')}
                        url={sub.get('url')}
                        default={index === 0}
                    />
                ))}
            {chapter && (
                <VideoTrack key={chapter.get('id')} kind="chapters" url={chapter.get('url')} />
            )}
        </video>
    ) : null;

VideoPlayer.defaultProps = {
    src: null,
    chapter: null,
    subtitles: null,
};

export default VideoPlayer;
