// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Components
import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import Input from 'components/Form/Input';

// Services
import { selectPasswordResetMessage, selectErrors } from 'services/Authentication/selectors';
import { userPasswordCreate, userPasswordReset } from 'services/Authentication/thunks';

// Styles
import { Container, Content } from './styles';
import { PrimaryButton } from 'styles/buttons';
import { Line } from 'styles/common';
import {
    FormGroup,
    FormTitleGroup,
    FormTitle,
    Label,
    SubmitContainer,
    ErrorsContainer,
} from 'styles/form';

// Types
import type { ImmutableMap, ReduxDispatch, InputEvent, IntlType } from 'types';
import type { UserPasswordCreateType, UserPasswordResetType } from 'services/Authentication/thunks';

// Utils
import { getRouteFromLocation } from 'utils';

type Props = {
    /** from withRouter: Allows us to check for route params.token */
    computedMatch: ?Object,
    /** ImmutableMap of errors from backend upon request */
    errors: ?ImmutableMap<string, Object>,
    /** from withRouter: current route's location */
    location?: Object,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    /** triggers password create request */
    userPasswordCreate: UserPasswordCreateType,
    /** triggers password reset request */
    userPasswordReset: UserPasswordResetType,
};

type State = {
    email: string,
    loading: boolean,
    password: string,
    passwordConfirmation: string,
    responseMessage: ?string,
    successMessageId: ?string,
};

/**
 * Password Create/Reset Page.
 *
 * Create/Reset your password view which allows users to reset/create (new user) their password
 *
 */

export class PasswordCreateResetPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        const search = props.location.search;
        const params = new URLSearchParams(search);
        const email = params.get('email') && decodeURI(params.get('email'));
        const route = props && props.location && getRouteFromLocation(props.location);

        this.state = {
            intlPrefix: `containers.PasswordCreateResetPage.${
                route === 'create-password' ? 'create' : 'reset'
            }`,
            email,
            loading: false,
            password: '',
            passwordConfirmation: '',
            responseMessage: null,
            successMessageId: null,
        };
    }

    /**
     * Define successMessageId based on backend errors if present
     */
    componentWillReceiveProps(nextProps: Props) {
        let successMessageId = null;

        if (nextProps.errors && nextProps.errors.get('reset')) {
            successMessageId = 'containers.ForgetPasswordPage.invalidToken';
        } else if (nextProps.responseMessage === 'password_reset_passwords_reset') {
            successMessageId = `${this.state.intlPrefix}.passwordResetConfirmed`;
        }

        this.setState({
            loading: nextProps.responseMessage === 'password_reset_passwords_reset',
            successMessageId,
        });
    }

    handleChangeField = (name: string) => ({ target: { value } }: InputEvent) => {
        this.setState({
            [name]: value,
        });
    };

    /**
     * Submit form to trigger password create/reset request based on location's route
     */
    handleSubmitForm = (evt: InputEvent) => {
        evt.preventDefault();

        const route =
            this.props && this.props.location && getRouteFromLocation(this.props.location);

        this.setState({
            loading: true,
        });

        if (
            this.state.email &&
            this.props.computedMatch.params.token &&
            this.state.password &&
            this.state.passwordConfirmation
        ) {
            if (route === 'create-password') {
                this.props.userPasswordCreate(
                    this.state.email,
                    this.props.computedMatch.params.token,
                    this.state.password,
                    this.state.passwordConfirmation
                );
            } else {
                this.props.userPasswordReset(
                    this.state.email,
                    this.props.computedMatch.params.token,
                    this.state.password,
                    this.state.passwordConfirmation
                );
            }
        }
    };

    getValidationState = (error: ?string) => (error ? 'error' : null);

    render() {
        let responseMessage;

        if (this.state.successMessageId) {
            responseMessage = (
                <p>
                    <FormattedMessage id={this.state.successMessageId} />
                </p>
            );
        }

        return (
            <Container>
                <Content>
                    <Helmet>
                        <title>
                            {this.props.intl.formatMessage({
                                id: `${this.state.intlPrefix}.helmetTitle`,
                            })}
                        </title>
                        <meta
                            name="description"
                            content={
                                <FormattedMessage id={`${this.state.intlPrefix}.helmetDesc`} />
                            }
                        />
                    </Helmet>
                    {this.state.loading ? (
                        <Loading loading />
                    ) : (
                        <div>
                            <FormTitleGroup>
                                <FormTitle data-test-id="headerText">
                                    <FormattedMessage id={`${this.state.intlPrefix}.pageTitle`} />
                                </FormTitle>
                            </FormTitleGroup>

                            <form onSubmit={this.handleSubmitForm}>
                                {!this.state.email ? (
                                    <FormGroup
                                        validationState={this.getValidationState(
                                            this.props.errors && this.props.errors.get('email')
                                        )}
                                    >
                                        <Label htmlFor="email">
                                            <FormattedMessage
                                                id={`${this.state.intlPrefix}.emailLabel`}
                                            />
                                        </Label>

                                        <Input
                                            disabled={this.state.loading}
                                            onChange={this.handleChangeField('email')}
                                            name="email"
                                            placeholder={`${
                                                this.state.intlPrefix
                                            }.emailPlaceholder`}
                                            type="email"
                                            value={this.state.email}
                                        />
                                    </FormGroup>
                                ) : null}

                                <FormGroup
                                    validationState={this.getValidationState(
                                        this.props.errors && this.props.errors.get('password')
                                    )}
                                >
                                    <Label htmlFor="password">
                                        <FormattedMessage
                                            id={`${this.state.intlPrefix}.passwordLabel`}
                                        />
                                    </Label>

                                    <Input
                                        disabled={this.state.loading}
                                        onChange={this.handleChangeField('password')}
                                        name="password"
                                        placeholder={`${this.state.intlPrefix}.passwordLabel`}
                                        type="password"
                                        value={this.state.password}
                                    />
                                </FormGroup>

                                <FormGroup
                                    validationState={this.getValidationState(
                                        this.props.errors &&
                                            this.props.errors.get('passwordConfirmation')
                                    )}
                                >
                                    <Label htmlFor="passwordConfirmation">
                                        <FormattedMessage
                                            id={`${
                                                this.state.intlPrefix
                                            }.passwordConfirmationLabel`}
                                        />
                                    </Label>

                                    <Input
                                        disabled={this.state.loading}
                                        onChange={this.handleChangeField('passwordConfirmation')}
                                        name="passwordConfirmation"
                                        placeholder={`${
                                            this.state.intlPrefix
                                        }.passwordConfirmationPlaceholder`}
                                        type="password"
                                        value={this.state.passwordConfirmation}
                                    />
                                </FormGroup>

                                <Line />

                                <SubmitContainer>
                                    <PrimaryButton
                                        disabled={
                                            this.state.loading ||
                                            !this.state.email ||
                                            !this.state.password ||
                                            !this.state.passwordConfirmation
                                        }
                                    >
                                        <FormattedMessage id={`${this.state.intlPrefix}.submit`} />
                                    </PrimaryButton>
                                </SubmitContainer>

                                <ErrorsContainer
                                    hidden={!this.props.errors || !this.props.errors.size}
                                >
                                    <ErrorMessage
                                        error={this.props.errors && this.props.errors.get('email')}
                                        values={{
                                            attribute: `${this.state.intlPrefix}.emailLabel`,
                                        }}
                                    />
                                    <ErrorMessage
                                        error={
                                            this.props.errors && this.props.errors.get('password')
                                        }
                                        values={{
                                            attribute: `${this.state.intlPrefix}.passwordLabel`,
                                        }}
                                    />
                                    <ErrorMessage
                                        error={
                                            this.props.errors &&
                                            this.props.errors.get('passwordConfirmation')
                                        }
                                        values={{
                                            attribute: `${
                                                this.state.intlPrefix
                                            }.passwordConfirmationLabel`,
                                        }}
                                    />
                                    {responseMessage}
                                    <p>
                                        <FormattedMessage id="components.ErrorMessage.tryAgain" />
                                    </p>
                                </ErrorsContainer>
                            </form>
                        </div>
                    )}
                </Content>
            </Container>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    responseMessage: selectPasswordResetMessage(),
    errors: selectErrors(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            userPasswordCreate,
            userPasswordReset,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(PasswordCreateResetPage))
);
