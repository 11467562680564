// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import Modal from '../Modal';

import { PrimaryButton, SecondaryButton } from 'styles/buttons';
import { NavItem } from './styles';

type Props = {
    onLogout: () => void,
};

type State = {
    isOpen: boolean,
};

class LogoutLink extends React.Component<Props, State> {
    state = {
        isOpen: false,
    };

    handleToggleModal = () => {
        this.setState((prevState: State) => ({ isOpen: !prevState.isOpen }));
    };

    renderButtons() {
        return (
            <div>
                <PrimaryButton inline onClick={this.props.onLogout} data-test-id="logoutLink">
                    <FormattedMessage id="components.Header.logout" />
                </PrimaryButton>
                <SecondaryButton inline onClick={this.handleToggleModal} data-test-id="logoutLink">
                    <FormattedMessage id="components.ErrorMessage.cancel" />
                </SecondaryButton>
            </div>
        );
    }

    render() {
        return (
            <NavItem>
                <button type="button" onClick={this.handleToggleModal}>
                    <FormattedMessage id="components.Header.logout" />
                </button>
                <Modal
                    show={this.state.isOpen}
                    onModalClose={this.handleToggleModal}
                    headerTitle={<FormattedMessage id="components.Header.logout" />}
                    controls={this.renderButtons()}
                    small
                >
                    <p>
                        <FormattedMessage id="components.Header.logout.confirm" />
                    </p>
                </Modal>
            </NavItem>
        );
    }
}

export default LogoutLink;
