// @flow

import { replace } from 'react-router-redux';

import type { ReduxDispatch, ResponseErrorType } from 'types';
import crudResources from './resources';
import { crudResourceActions } from './actions';

/*
 *
 * CRUDResource thunks
 *
 * Basic function: Check response and dispatch action to save data/error to redux store
 *
 */

const crudThunks = (resourceName: string) => ({
    /*
    * create: Create a single entry of the resource
    */
    create: (resource: Object) => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .create(resource)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedCreateFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedCreateSuccess(
                            response.data
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(crudResourceActions((resourceName: string)).receivedCreateFailure(error));
            }),

    /*
    * delete: Delete a single entry of the resource by id
    */
    delete: (id: string) => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .delete(id)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedDeleteFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedDeleteSuccess(
                            response.data
                        )
                    );
                    dispatch(replace(`/content`));
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(crudResourceActions((resourceName: string)).receivedDeleteFailure(error));
            }),

    /*
    * deleteComment: Delete one of the media's comments by id
    */
    deleteComment: (resourceId: string, relatedEntityId: string) => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .deleteRelatedEntity('comment', relatedEntityId)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedDeleteRelatedEntityFailure(response.data.errorMessage)
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedDeleteRelatedEntitySuccess(
                            resourceId,
                            'comments',
                            relatedEntityId
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedDeleteRelatedEntityFailure(
                        error
                    )
                );
            }),

    /*
    * deleteNew: Delete new entry
    */
    deleteNew: () => (dispatch: ReduxDispatch) =>
        dispatch(crudResourceActions((resourceName: string)).deleteNew()),

    /*
    * fetch: Fetch a single entry of the resource by id
    */
    fetch: (id: string) => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .fetch(id)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data.data || response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchSuccess(
                            response.data.data || response.data
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(crudResourceActions((resourceName: string)).receivedFetchFailure(error));
            }),

    /*
    * fetchAll: Fetch all the entries of the resource
    */
    fetchAll: () => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .fetchAll()
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchListFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data.length) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchListSuccess(
                            response.data
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchListFailure(error)
                );
            }),

    /*
    * fetchImports: Fetch all the imports
    */
    fetchCountries: () => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .fetchCountries()
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedFetchCountriesListFailure(response.data.errorMessage)
                    );
                } else if (response.data.length) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedFetchCountriesListSuccess(response.data)
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchCountriestListFailure(
                        error
                    )
                );
            }),

    /*
    * fetchImports: Fetch all the imports
    */
    fetchImports: () => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .fetchImports()
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchImportListFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data.length) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchImportListSuccess(
                            response.data
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchImportListFailure(
                        error
                    )
                );
            }),

    /*
    * fetchPaginated: Fetch paginated entries of the resource
    *
    * For the paginated API we keep the entire response.data so we keep the pagination response info,
    *           such as the total of pages for the fetch, not just the resource data list)
    */
    fetchPaginated: (page: number) => (dispatch: ReduxDispatch) => {
        dispatch(
            crudResourceActions((resourceName: string)).receivedFetchPaginatedIsFetching(true)
        );
        crudResources((resourceName: string))
            .fetchPaginated(page)
            .then((response) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchPaginatedIsFetching(
                        false
                    )
                );
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchPaginatedFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchPaginatedSuccess(
                            response.data
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchPaginatedIsFetching(
                        false
                    )
                );

                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchPaginatedFailure(error)
                );
            });
    },

    /*
    * fetchPaginatedByListOfIds: Fetch paginated entries of the resource with list of IDs for filtering
    *
    * For the paginated API we keep the entire response.data so we keep the pagination response info,
    *           such as the total of pages for the fetch, not just the resource data list)
    */
    fetchPaginatedByListOfIds: (list: Array, page: number, perPage: number) => (
        dispatch: ReduxDispatch
    ) => {
        dispatch(
            crudResourceActions((resourceName: string)).receivedFetchPaginatedIsFetching(true)
        );
        crudResources((resourceName: string))
            .fetchPaginatedByListOfIds(list, page, perPage)
            .then((response) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchPaginatedIsFetching(
                        false
                    )
                );
                if (response.data.error) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedFetchPaginatedByListOfIdsFailure(response.data.errorMessage)
                    );
                } else if (response.data.data) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedFetchPaginatedByListOfIdsSuccess(response.data)
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchPaginatedIsFetching(
                        false
                    )
                );

                dispatch(
                    crudResourceActions(
                        (resourceName: string)
                    ).receivedFetchPaginatedByListOfIdsFailure(error)
                );
            });
    },

    /*
    * fetchPaginatedBySearchCriteria: Fetch paginated entries of the resource with search criteria for filtering
    *
    * For the paginated API we keep the entire response.data so we keep the pagination response info,
    *           such as the total of pages for the fetch, not just the resource data list)
    */
    fetchPaginatedBySearchCriteria: (searchCriteria: Object, page: number, perPage: number) => (
        dispatch: ReduxDispatch
    ) => {
        dispatch(
            crudResourceActions((resourceName: string)).receivedFetchPaginatedIsFetching(true)
        );
        crudResources((resourceName: string))
            .fetchPaginatedBySearchCriteria(searchCriteria, page, perPage)
            .then((response) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchPaginatedIsFetching(
                        false
                    )
                );
                if (response.data.error) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedFetchPaginatedBySearchCriteriaFailure(response.data.errorMessage)
                    );
                } else if (response.data.data) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedFetchPaginatedBySearchCriteriaSuccess(response.data)
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchPaginatedIsFetching(
                        false
                    )
                );

                dispatch(
                    crudResourceActions(
                        (resourceName: string)
                    ).receivedFetchPaginatedBySearchCriteriaFailure(error)
                );
            });
    },

    /*
    * fetchSecondaryPaginatedBySearchCriteria: Fetch paginated entries of the resource with search criteria for filtering
    *   and saves it to secondary object in state
    *
    * For the paginated API we keep the entire response.data so we keep the pagination response info,
    *           such as the total of pages for the fetch, not just the resource data list)
    */
    fetchSecondaryPaginatedBySearchCriteria: (
        searchCriteria: Object,
        page: number,
        perPage: number
    ) => (dispatch: ReduxDispatch) => {
        dispatch(
            crudResourceActions((resourceName: string)).receivedFetchSecondaryPaginatedIsFetching(
                true
            )
        );
        crudResources((resourceName: string))
            .fetchPaginatedBySearchCriteria(searchCriteria, page, perPage)
            .then((response) => {
                dispatch(
                    crudResourceActions(
                        (resourceName: string)
                    ).receivedFetchSecondaryPaginatedIsFetching(false)
                );
                if (response.data.error) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedFetchPaginatedBySearchCriteriaFailure(response.data.errorMessage)
                    );
                } else if (response.data.data) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedFetchSecondaryPaginatedBySearchCriteriaSuccess(response.data)
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions(
                        (resourceName: string)
                    ).receivedFetchSecondaryPaginatedIsFetching(false)
                );

                dispatch(
                    crudResourceActions(
                        (resourceName: string)
                    ).receivedFetchSecondaryPaginatedBySearchCriteriaFailure(error)
                );
            });
    },

    /*
    * fetchReadableMimeTypes: Fetch readable mimetypes
    */
    fetchReadableMimeTypes: () => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .fetchReadableMimeTypes()
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedFetchReadableMimetypeFailure(response.data.errorMessage)
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedFetchReadableMimetypeSuccess(response.data)
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions(
                        (resourceName: string)
                    ).receivedFetchReadableMimetypeFailure(error)
                );
            }),

    /*
    * fetchThumbnails: Fetch media's thumbnails
    */
    fetchThumbnails: (mediaId: number, format: string) => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .fetchThumbnails(mediaId, format)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchThumbnailsFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchThumbnailsSuccess(
                            response.data
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchThumbnailsFailure(
                        error
                    )
                );
            }),

    /*
    * resetErrors: Reset errors
    */
    resetErrors: () => (dispatch: ReduxDispatch) =>
        dispatch(crudResourceActions((resourceName: string)).resetErrors()),

    /*
    * update: Update a single entry of the resource by id
    *
    * if closeModal is true, dispatch to passed /route else to /content
    */
    update: (id: string, resource: Object, route: string = '', closeModal: boolean = true) => (
        dispatch: ReduxDispatch
    ) =>
        crudResources((resourceName: string))
            .update(id, resource)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedUpdateFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedUpdateSuccess(
                            response.data
                        )
                    );
                    if (closeModal) {
                        dispatch(replace(route || `/content`));
                        dispatch(crudResourceActions((resourceName: string)).deleteNew());
                    }
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(crudResourceActions((resourceName: string)).receivedUpdateFailure(error));
            }),

    /*
    * updateComment: Update one of the media's comments
    */
    updateComment: (relatedEntityId: string, resource: Object) => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .updateRelatedEntity('comment', relatedEntityId, resource)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedUpdateRelatedEntityFailure(response.data.errorMessage)
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedUpdateRelatedEntitySuccess(
                            response.data.mediaId,
                            'comments',
                            response.data
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedUpdateRelatedEntityFailure(
                        error
                    )
                );
            }),

    /*
    * importCSV: Mass import with file upload
    */
    importCSV: (resource: Object) => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .importCSV(resource)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedImportFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedImportSuccess(
                            response.data
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(crudResourceActions((resourceName: string)).receivedImportFailure(error));
            }),
});

export default crudThunks;
