// @flow

import { crudResourceActionTypes, crudResourceActions } from 'services/Role/actions';
import crudServiceReducer from 'services/Role/reducer';
import crudResources from 'services/Role/resources';
import crudSelectors from 'services/Role/selectors';
import crudThunks from 'services/Role/thunks';

/*
 *
 * Media Resource (REDUX)
 *
 * Includes:
 *  - actions & action types
 *  - reducers - Connects response from API to store
 *  - resources - API calls
 *  - selectors - Fetches data from store
 *  - thunks - Performs actions and deals with outcome of resources
 *
 * Usage instructions:
 *  - Resource Object name: Update to reflect the resource in question
 *  - resourceName attribute: Update to reflect the resource name
 */

export const roleResource = () => {
    const resourceName = 'Role';

    return {
        actionTypes: () => crudResourceActionTypes((resourceName: string)),
        actions: () => crudResourceActions((resourceName: string)),
        serviceReducer: () => crudServiceReducer((resourceName: string)),
        resources: () => crudResources((resourceName: string)),
        selectors: () => crudSelectors((resourceName: string)),
        thunks: () => crudThunks((resourceName: string)),
    };
};

export default roleResource;
