// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import ErrorMessage from 'components/ErrorMessage';
import Select from 'components/Form/Select';

// Styles
import { FlexContainer, FlexItemContainer } from 'styles/common';
import { FormGroup, Label } from 'styles/form';

// Types
import type { ImmutableMap } from 'types';

// Utils
import { cardLanguages, entityLanguages } from 'utils/constants';

type Props = {
    errors: ImmutableMap<string, any>,
    onChangeField: Function,
    handleErrors: Function,
    handleValidationState: Function,
    isLoading: boolean,
    media?: ImmutableMap<string, mixed>,
    categories?: ImmutableMap<string, mixed>,
};

class MediaLanguageBlock extends React.PureComponent<Props> {
    render() {
        return (
            <React.Fragment>
                <FlexContainer justifyContent="space-between" gutter="10">
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup
                            validationState={this.props.handleValidationState(
                                this.props.handleErrors('categoryId')
                            )}
                        >
                            <Label htmlFor="category">
                                <FormattedMessage id="components.Media.inputLabelValueCategory" /> *
                            </Label>
                            <Select
                                name="categoryId"
                                onChange={this.props.onChangeField('categoryId')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('categoryId')}
                                options={
                                    this.props.categories &&
                                    this.props.categories.toJS().map((category) => ({
                                        key: category.id,
                                        value: category.name,
                                    }))
                                }
                                defaultOption={
                                    'components.Media.inputLabelValueCategory.placeholder'
                                }
                                full
                            />
                            <ErrorMessage
                                error={
                                    this.props.handleErrors('categoryId')
                                        ? this.props.errors.getIn(['data', 'errors', 'categoryId'])
                                        : null
                                }
                                values={{ attribute: 'components.Media.inputLabelValueCategory' }}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="center">
                        <FormGroup
                            validationState={this.props.handleValidationState(
                                this.props.handleErrors('mediaLanguage')
                            )}
                        >
                            <Label htmlFor="mediaLanguage">
                                <FormattedMessage id="components.Media.inputLabelValueMediaLanguage" />{' '}
                                *
                            </Label>
                            <Select
                                name="mediaLanguage"
                                onChange={this.props.onChangeField('mediaLanguage')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('mediaLanguage')}
                                options={entityLanguages.map((lang: Object) => ({
                                    key: lang.key,
                                    intlId: lang.intlId,
                                }))}
                                defaultOption={
                                    'components.Media.inputLabelValueMediaLanguage.placeholder'
                                }
                                full
                            />
                            <ErrorMessage
                                error={
                                    this.props.handleErrors('mediaLanguage')
                                        ? this.props.errors.getIn([
                                              'data',
                                              'errors',
                                              'mediaLanguage',
                                          ])
                                        : null
                                }
                                values={{
                                    attribute: 'components.Media.inputLabelValueMediaLanguage',
                                }}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="center">
                        <FormGroup
                            validationState={this.props.handleValidationState(
                                this.props.handleErrors('cardLanguage')
                            )}
                        >
                            <Label htmlFor="cardLanguage">
                                <FormattedMessage id="components.Media.inputLabelValueFormLanguage" />{' '}
                                *
                            </Label>
                            <Select
                                name="cardLanguage"
                                onChange={this.props.onChangeField('cardLanguage')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('cardLanguage')}
                                options={cardLanguages.map((lang: Object) => ({
                                    key: lang.key,
                                    intlId: lang.intlId,
                                }))}
                                defaultOption={
                                    'components.Media.inputLabelValueFormLanguage.placeholder'
                                }
                                full
                            />
                            <ErrorMessage
                                error={
                                    this.props.handleErrors('cardLanguage')
                                        ? this.props.errors.getIn([
                                              'data',
                                              'errors',
                                              'cardLanguage',
                                          ])
                                        : null
                                }
                                values={{
                                    attribute: 'components.Media.inputLabelValueFormLanguage',
                                }}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
            </React.Fragment>
        );
    }
}

export default MediaLanguageBlock;
