// @flow

import React from 'react';

import { Container, Radiobutton, Hidden, TextState } from 'components/Form/RadioButton/styles';

import { FormRow } from 'styles/form';
import { FlexContainer, FlexItemContainer } from 'styles/common';

import type { ReactNode } from 'types';

type DefaultProps = {
    value: ?string,
};

type Props = DefaultProps & {
    /** Object of options to display  */
    options: Object,
    /** onChange event fired when click on an option  */
    onChange: Function,
    /** Optional controls to display on the right of each option  */
    controls?: ReactNode,
};

type State = {
    /** Currently selected permission value */
    value: ?string,
};

/**
 * Subcomponent: List of radiobuttons for Site Application permissions
 */

export default class PermissionSelector extends React.Component<Props, State> {
    static defaultProps: DefaultProps = {
        value: null,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            value: props.value,
        };
    }

    handleChange = (event: Event) => {
        event.persist();
        this.setState(
            {
                value: event.target.value,
            },
            this.props.onChange(event)
        );
    };

    render() {
        const { options, controls } = this.props;

        let right;
        if (controls) {
            right = (
                <FlexItemContainer direction="column" justifyContent="center" textAlign="right">
                    {controls}
                </FlexItemContainer>
            );
        }

        const permissions = Object.keys(options).reduce((acc: any, key: string) => {
            acc.push(
                <FormRow key={key}>
                    <FlexContainer justifyContent="space-between">
                        <FlexItemContainer direction="column" justifyContent="flex-start">
                            <Container>
                                <Radiobutton checked={this.state.value === key} />
                                <TextState checked={this.state.value === key}>
                                    {options[key]}
                                </TextState>
                                <Hidden
                                    checked={this.state.value === key}
                                    onChange={this.handleChange}
                                    type="radio"
                                    value={key}
                                />
                            </Container>
                        </FlexItemContainer>
                        {this.state.value === key ? right : null}
                    </FlexContainer>
                </FormRow>
            );
            return acc;
        }, []);

        return (
            <div>
                {permissions}
                <input type="hidden" value={this.state.value ? this.state.value : ''} />
            </div>
        );
    }
}
