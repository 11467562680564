// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { List, fromJS } from 'immutable';

import ReactHtmlParser from 'react-html-parser';

// Services
import mediaResource from 'services/Media';
import categoryResource from 'services/Category';

// Utils
import { formatDate, sanitizeString } from 'utils';

// Components
import AudioPlayer from 'components/AudioPlayer';
import Loading from 'components/Loading';
import BackgroundImage from 'components/Image/BackgroundImage';
import ControlPanel from 'components/ControlPanel';
import Image from 'components/Image';
import VideoPlayer from 'components/VideoPlayer';
import Download from 'components/Download';
import ControlPanelBreadCrumb from 'components/ControlPanel/breadCrumb';
import Comments from 'components/Comments';
import Modal from 'components/Modal';
import Media from 'components/Media';
import ParentCollectionsIndex from 'components/ParentCollectionsIndex';

// Styles
import { FlexContainer, FlexItemContainer, Line, ActionsContainer } from 'styles/common';
import { PrimaryButton, ReverseButton, SecondaryButton } from 'styles/buttons';
import { LabelSmall } from 'styles/form';
import {
    SingleMediaViewContainer,
    MediaInfoContainer,
    MediaParentCollectionsContainer,
    MediaImageViewContainer,
    MediaMidSectionContainer,
    MediaBasics,
    MediaName,
    MediaShortDescription,
    InformationTabEntryLabel,
    InformationTabEntry,
    ParentCollectionsTitle,
} from './styles';

// Types
import type { ImmutableMap, IntlType, CategoryType, MediaType } from 'types';

type Props = {
    /** provides access to collection/media categories */
    categories: ImmutableMap<string, CategoryType>,
    /** from withRouter: Allows us to check for route params.id */
    computedMatch: Object,
    /** triggers delete comment request to backend */
    deleteComment: Function,
    /** ImmutableMap of errors from backend upon request */
    errors: ?ImmutableMap<string, Object>,
    /** triggers fetch request to backend */
    fetchCategories: Function,
    /** triggers fetch request to backend */
    fetchMedia: Function,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    /** provides access to current media */
    media: ?MediaType,
    /** triggers update comment request to backend */
    updateComment: Function,
};

type State = {
    resourceListCurrentPage: number,
    showModifyResourceModal: boolean,
    selectedTabItemId: number,
    search?: string,
    showDeleteModal: boolean,
    showMediaModal: boolean,
};

/**
 * Single Media Page.
 *
 * Single media view to view & edit media
 *
 */

export class SingleMediaPage extends React.Component<Props, State> {
    static reactHtmlParser;

    constructor(props: Props) {
        super(props);
        this.reactHtmlParser = ReactHtmlParser;
    }

    state = {
        selectedTabItemId: 1,
        resourceListCurrentPage: 1,
        showModifyResourceModal: false,
        showDeleteModal: false,
        showMediaModal: false,
    };

    /**
     * Check for collection/media categories, if empty trigger fetch request
     * Fetch media based on params.id (Requested media id)
     */
    componentDidMount() {
        // If the ID is passed in the query string / present
        if (
            this.props.computedMatch &&
            this.props.computedMatch.params &&
            this.props.computedMatch.params.id
        ) {
            // Retrieve media categories
            if (this.props.categories.isEmpty()) {
                this.props.fetchCategories();
            }
            // Retrieve media for display
            this.props.fetchMedia(this.props.computedMatch.params.id);
        }
    }

    // Causing loss in error messages on failed update; keeping commented out for future reference
    // componentWillReceiveProps(nextProps: Props) {
    //     const { errors } = nextProps;
    //     if (!errors.isEmpty()) {
    //         // If errors were raised for the media re-fetch to assure the display of the correct information
    //         this.props.fetchMedia(this.props.computedMatch.params.id, true);
    //     }
    // }

    handleCloseContentModal = () => {
        this.setState({
            showDeleteModal: false,
            showMediaModal: false,
        });
    };

    /**
     * Change between tab items (on bottom section selection)
     *
     * @param {string} selectedTabItemId
     */
    handleControlPanelItemClick = (selectedTabItemId: number) => () => {
        this.setState({ selectedTabItemId });
    };

    handleDeleteSelectedContent = () => {
        this.content.handleDelete(event);
    };

    handleOnMediaModifyAction = () => {
        this.setState({
            showMediaModal: true,
        });
    };

    /**
     * Sets up ref between parent and child component
     */
    handleOnRef = () => (ref) => {
        this.content = ref;
    };

    handleOnShowMediaModal = () => {
        this.setState({
            showDeleteModal: false,
            showMediaModal: true,
        });
    };

    handleOnSubmitContentModal = (event: Event) => {
        this.content.handleOnSubmitForm(true)(event);
    };

    handleShowDeleteModal = () => {
        this.setState({
            showDeleteModal: true,
            showMediaModal: false,
        });
    };

    renderImage = (image) => <BackgroundImage {...image} />;

    renderVideo = (video) => <VideoPlayer {...video} />;

    renderAudio = (sound) => <AudioPlayer {...sound} />;

    /**
     * Render's modal's controls for interaction with resource/content
     */
    renderContentModalActions = () => {
        const updatedAt = this.props.media && this.props.media.updatedAt;
        const timestamp = updatedAt && formatDate(updatedAt);
        return (
            <FlexContainer justifyContent="space-between" gutter="30">
                {timestamp ? (
                    <FlexItemContainer direction="column" textAlign="left">
                        <LabelSmall marginless>
                            <FormattedMessage id="global.lastUpdate" values={{ time: timestamp }} />
                        </LabelSmall>
                    </FlexItemContainer>
                ) : null}
                <ActionsContainer flexGrown="1.5">
                    <PrimaryButton
                        disabled={!this.props.media}
                        onClick={this.handleOnSubmitContentModal}
                    >
                        {this.props.intl.formatMessage({
                            id: 'global.saveChanges',
                        })}
                    </PrimaryButton>
                    <SecondaryButton onClick={this.handleCloseContentModal}>
                        {this.props.intl.formatMessage({
                            id: 'global.cancel',
                        })}
                    </SecondaryButton>
                </ActionsContainer>
            </FlexContainer>
        );
    };

    /**
     * Render delete confirmation modal
     * Looks at collection from store, returns null if not found
     */
    renderDeleteContentModal = () => {
        const { media } = this.props;
        if (media) {
            let title = (media && (media.get('titleFr') || media.get('titleEn'))) || null;
            title = (title && sanitizeString(title)) || title;

            return (
                <Modal
                    controls={
                        <ActionsContainer flexGrown="1.5">
                            <PrimaryButton onClick={this.handleDeleteSelectedContent}>
                                {this.props.intl.formatMessage({
                                    id: 'components.Media.delete.action',
                                })}
                            </PrimaryButton>
                            <SecondaryButton onClick={this.handleOnShowMediaModal}>
                                {this.props.intl.formatMessage({
                                    id: 'global.cancel',
                                })}
                            </SecondaryButton>
                        </ActionsContainer>
                    }
                    headerTitle={
                        <FormattedMessage id="components.Media.delete" values={{ title }} />
                    }
                    onModalClose={this.handleCloseContentModal}
                    show={this.state.showDeleteModal}
                >
                    <FormattedMessage
                        id="components.Media.delete.message"
                        values={{
                            title: <strong>{`"${title}"`}</strong>,
                        }}
                    />
                </Modal>
            );
        } else {
            return null;
        }
    };

    renderListActions = () => (
        <ReverseButton onClick={this.handleOnShowMediaModal}>
            {this.props.intl.formatMessage({
                id: 'views.SingleMediaPage.editMedia',
            })}
        </ReverseButton>
    );

    renderMediaModal = () => {
        const { media } = this.props;
        if (media) {
            const mediaId = media && media.get('id');
            let title = (media && (media.get('titleFr') || media.get('titleEn'))) || null;
            title = (title && sanitizeString(title)) || title;

            return (
                <Modal
                    controls={this.renderContentModalActions()}
                    headerTitle={
                        <FormattedMessage
                            id={media ? 'components.Media.edit' : 'components.Media.create'}
                            values={{ title }}
                        />
                    }
                    flush
                    onModalClose={this.handleCloseContentModal}
                    show={this.state.showMediaModal}
                    large
                >
                    <Media
                        mediaId={mediaId}
                        onDelete={this.handleShowDeleteModal}
                        onMediaCreation={this.handleOnMediaModifyAction}
                        onRef={this.handleOnRef()}
                        onUpdateRouting={`/media/${mediaId}`}
                    />
                </Modal>
            );
        } else {
            return null;
        }
    };

    render() {
        const { computedMatch, intl, media } = this.props;
        // If still mounting display spinner
        if (!media || (media && media.get('id') !== parseInt(computedMatch.params.id))) {
            return <Loading loading />;
        }

        let render = null;

        if (media && media.get('type') === 'video') {
            render = this.renderVideo;
        } else if (media && media.get('type') === 'audio') {
            render = this.renderAudio;
        } else {
            render = this.renderImage;
        }

        const title = media && (media.get('titleFr') || media.get('titleEn'));
        const sanitizedTitle = (title && sanitizeString(title)) || null;
        const parsedTitle = (title && this.reactHtmlParser(title)) || null;

        return (
            <div>
                <Helmet>
                    <title>
                        {intl.formatMessage({
                            id: 'views.SingleMediaPage.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={intl.formatMessage({
                            id: 'views.SingleMediaPage.pageTitle',
                        })}
                    />
                </Helmet>
                <ControlPanelBreadCrumb
                    controls={this.renderListActions()}
                    title={sanitizedTitle}
                    backTitleId="views.SingleMediaPage.allMedia"
                    backTitleRoute="/content"
                    unpaddedTabs
                />
                <SingleMediaViewContainer>
                    <MediaInfoContainer>
                        <MediaImageViewContainer>
                            <Image
                                alt={media && (media.get('titleFr') || media.get('titleEn'))}
                                changeOfResourceIdInProcess={
                                    media && media.get('id') !== parseInt(computedMatch.params.id)
                                }
                                id={media && media.get('id')}
                                render={render}
                                resource="media"
                                showPlaceholder={
                                    media &&
                                    (media.get('type') !== 'image' &&
                                        media.get('type') !== 'document' &&
                                        media.get('type') !== 'video' &&
                                        media.get('type') !== 'audio')
                                }
                                type={media && media.get('type')}
                                thumbnail={media && media.getIn(['thumbnail', 'url'])}
                                chapter={
                                    (media &&
                                        media.get('type') === 'video' &&
                                        media.get('chapter')) ||
                                    null
                                }
                                subtitles={
                                    (media &&
                                        media.get('type') === 'video' &&
                                        media.get('subtitles')) ||
                                    null
                                }
                            />
                        </MediaImageViewContainer>
                        <MediaMidSectionContainer justifyContent="space-between">
                            <MediaBasics>
                                <MediaName>{parsedTitle}</MediaName>
                                <MediaShortDescription>
                                    <FormattedMessage
                                        id="views.SingleMediaPage.mediaShortDescription"
                                        values={{
                                            name: `${media.getIn([
                                                'contributor',
                                                'firstName',
                                            ])} ${media.getIn(['contributor', 'lastName'])}`,
                                            date: `${intl.formatDate(
                                                new Date(media.get('createdAt')),
                                                { year: 'numeric', month: 'long', day: '2-digit' }
                                            )}`,
                                        }}
                                    />
                                </MediaShortDescription>
                            </MediaBasics>
                            <Download
                                id={media && media.get('id')}
                                type={media && media.get('type')}
                                name={media && media.get('titleFr')}
                                label={intl.formatMessage({
                                    id: 'views.SingleMediaPage.download',
                                })}
                                declinations={media && media.get('declinations')}
                                originalFile={media && media.get('originalFile')}
                                changeOfResourceIdInProcess={
                                    media && media.get('id') !== parseInt(computedMatch.params.id)
                                }
                            />
                        </MediaMidSectionContainer>
                        <ControlPanel
                            tabItemsList={List.of(
                                fromJS({
                                    id: 1,
                                    label: intl.formatMessage({
                                        id: 'views.SingleMediaPage.information',
                                    }),
                                }),
                                fromJS({
                                    id: 2,
                                    label: intl.formatMessage({
                                        id: 'views.SingleMediaPage.comments',
                                    }),
                                })
                            )}
                            selectedTabItemId={this.state.selectedTabItemId}
                            isClickable
                            onItemClick={this.handleControlPanelItemClick}
                            slim
                            paddingTop="20px"
                            unpaddedTabs
                        />
                        <div hidden={this.state.selectedTabItemId !== 1}>
                            <InformationTabEntryLabel>
                                <FormattedMessage id="views.SingleMediaPage.description" />
                            </InformationTabEntryLabel>
                            <InformationTabEntry>
                                {media &&
                                    this.reactHtmlParser(this.props.media.get('descriptionFr'))}
                            </InformationTabEntry>
                            <InformationTabEntryLabel>
                                <FormattedMessage id="views.SingleMediaPage.category" />
                            </InformationTabEntryLabel>
                            <InformationTabEntry>
                                {this.props.categories &&
                                    this.props.media &&
                                    !this.props.categories.isEmpty() &&
                                    this.props.categories
                                        .find(
                                            (element) =>
                                                element.get('id') ===
                                                this.props.media.get('categoryId')
                                        )
                                        .get('name')}
                            </InformationTabEntry>
                            <InformationTabEntryLabel>
                                <FormattedMessage id="views.SingleMediaPage.keywords" />
                            </InformationTabEntryLabel>
                            <InformationTabEntry>
                                {this.props.media && this.props.media.get('keywordsFr')}
                            </InformationTabEntry>
                        </div>
                        <div hidden={this.state.selectedTabItemId !== 2}>
                            <Comments
                                comments={this.props.media.get('comments')}
                                updateCommentHandler={this.props.updateComment}
                                deleteCommentHandler={this.props.deleteComment}
                            />
                        </div>
                    </MediaInfoContainer>
                    <MediaParentCollectionsContainer>
                        <ParentCollectionsTitle>
                            <FormattedMessage id="views.SingleMediaPage.collectionsContaining" />
                        </ParentCollectionsTitle>
                        <Line />
                        <ParentCollectionsIndex
                            parentCollections={
                                this.props.media && this.props.media.get('collections')
                            }
                        />
                    </MediaParentCollectionsContainer>
                </SingleMediaViewContainer>
                {this.renderDeleteContentModal()}
                {this.renderMediaModal()}
            </div>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    media: mediaResource()
        .selectors()
        .selectSingle(),
    categories: categoryResource()
        .selectors()
        .selectAll(),
    errors: mediaResource()
        .selectors()
        .selectErrors(),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            fetchMedia: mediaResource().thunks().fetch,
            updateComment: mediaResource().thunks().updateComment,
            deleteComment: mediaResource().thunks().deleteComment,
            fetchCategories: categoryResource().thunks().fetchAll,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(SingleMediaPage));
