// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import RadioButton from 'components/Form/RadioButton';
import SortableTh from 'components/Table/SortableTh';

// Styles
import { CustomThWrapper, CustomThList, CustomThItem, Toggle } from './styles';

// Types
import type { ImmutableList, ImmutableMap } from 'types';

type DefaultProps = {
    name: number | string,
    onChange: Function,
    options: ImmutableList<ImmutableMap<string, string>>,
};

type Props = DefaultProps & {
    currentSorting?: {
        sortBy?: string,
        sortOrder?: string,
    },
    onSortBy?: Function,
    selectedId?: number,
};

/**
 * Custom th component for alternative data display, featuring optional SotrableTh
 */

class CustomTh extends React.PureComponent<Props> {
    render() {
        const { currentSorting, name, onChange, onSortBy, options, selectedId } = this.props;
        if (!selectedId) {
            return null;
        }

        // Map options & build list of CustomThItem's
        const list =
            options &&
            options.map((option: ImmutableMap<string, string>) => {
                const id = option && option.get('id');
                const intl = option && option.get('intl');
                if (selectedId !== id) {
                    return (
                        <CustomThItem key={id}>
                            <RadioButton
                                checked={selectedId === id}
                                label={intl}
                                name="column"
                                onChange={onChange(name)}
                                value={id}
                            />
                        </CustomThItem>
                    );
                } else {
                    return null;
                }
            });

        // Find currently selected option based on past prop
        const selectedOption = options.find(
            (option: ImmutableMap<string, string>) => option.get('id') === selectedId
        );

        // Return customTh component with child of SortableTh or simple label if !sortable
        return (
            <CustomThWrapper>
                <Toggle />
                {currentSorting && onSortBy && selectedOption.get('sortable') ? (
                    <SortableTh
                        currentSorting={currentSorting}
                        id={selectedOption && selectedOption.get('intl')}
                        name={selectedOption && selectedOption.get('key')}
                        onClick={onSortBy}
                        thead={false}
                    />
                ) : (
                    <FormattedMessage id={selectedOption && selectedOption.get('intl')} />
                )}
                <CustomThList>{list}</CustomThList>
            </CustomThWrapper>
        );
    }
}

export default CustomTh;
