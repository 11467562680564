// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Components
import ControlPanelCollectionContent from 'components/ControlPanel/collectionContent';
import Loading from 'components/Loading';
import ResourceCollectionIndex from './Resources/ResourceCollectionIndex';
import ResourceMediaIndex from './Resources/ResourceMediaIndex';
import PaginationBar from './PaginationBar';

// Services
import collectionResource from 'services/Collection';

// Styles
import { ResourceIndexContainer, ResourceIndexMessage } from './styles';

// Types
import type { ReactNode, ReduxDispatch } from 'types';

// Utils
import { RESOURCE_IDS } from 'utils/constants';

type DefaultProps = {
    empty?: boolean,
    order?: string,
};

type Props = DefaultProps & {
    collectionId: number,
    controls: ?ReactNode,
    fetchResourceList: Function,
    setCollectionOrder: Function,
    onSelectChildrenToBeDeleted: Function,
    resourceListFetchAction: Function,
    resourceListIsFetching: ?boolean,
    resourceListIsFetchingSelector: Function,
    resourceListSelector: Function,
    resourceList: ?Object,
    resourceType: ?string,
};

type State = {
    resourceListCurrentPage: number,
    searchCriteria: {
        order?: string,
        orderBy?: string,
        parentCollectionId: number,
        search?: string,
        sortBy: string,
        sortOrder: string,
    },
};

/**
 * Collection children index for Single Collection View
 */

class CollectionManagement extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            resourceListCurrentPage: 1,
            searchCriteria: {
                orderBy: this.props.order && JSON.parse(this.props.order).orderBy,
                order: this.props.order && JSON.parse(this.props.order).order,
                parentCollectionId: this.props.collectionId,
                search: '',
                sortBy: '',
                sortOrder: 'inactive',
            },
        };
    }

    componentDidMount() {
        this.handleFetchResourceList();
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.props.order !== nextProps.order) {
            this.setState(
                (prevState: State) => ({
                    ...prevState,
                    searchCriteria: {
                        ...prevState.searchCriteria,
                        orderBy: nextProps.order && JSON.parse(nextProps.order).orderBy,
                        order: nextProps.order && JSON.parse(nextProps.order).order,
                    },
                }),
                () => this.handleFetchResourceList()
            );
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (
            this.state.resourceListCurrentPage !== prevState.resourceListCurrentPage ||
            this.props.resourceType !== prevProps.resourceType
        ) {
            // If page selection of search criteria or resource type changes occur the data needs to be re-fetched
            this.handleFetchResourceList();
        }
    }

    // Check if component is loading (to decide if spinner should be shown for the entire page)
    isLoading = () => this.props.resourceListIsFetching;

    handleFetchResourceList = () => {
        this.props.fetchResourceList(this.state.searchCriteria, this.state.resourceListCurrentPage);
    };

    handleSearch = (value: string) => {
        this.setState(
            (prevState: Object) => ({
                searchCriteria: {
                    ...prevState.searchCriteria,
                    search: value,
                },
            }),
            () => this.handleFetchResourceList()
        );
    };

    handleSortBy = (sortBy: string, sortOrder: string) => {
        this.setState(
            (prevState: Object) => ({
                searchCriteria: {
                    ...prevState.searchCriteria,
                    sortBy: sortOrder === 'inactive' ? null : sortBy,
                    sortOrder: sortOrder === 'inactive' ? null : sortOrder,
                },
            }),
            () => this.handleFetchResourceList()
        );
    };

    handleOnSetMediaOrder = (mediaId: number) => ({ target: { value }, type }: Event) => {
        this.props.setMediaOrder(this.props.collectionId, mediaId, value);
    };

    handleOnSetCollectionOrder = (collectionId: number) => ({ target: { value }, type }: Event) => {
        this.props.setCollectionOrder(this.props.collectionId, collectionId, value);
    };

    // Change between pages of results for the resource index/list
    handlePaginationBarPageSelection = (
        resourceListCurrentPage: number,
        resourceListPagesTotal: number
    ) => () =>
        resourceListCurrentPage > 0 && resourceListCurrentPage <= resourceListPagesTotal
            ? this.setState({ resourceListCurrentPage })
            : null;

    render() {
        const { empty, order, resourceList, resourceListIsFetching, resourceType } = this.props;

        let content;
        if (this.isLoading() || resourceListIsFetching) {
            content = <Loading loading />;
        } else if (
            empty ||
            (resourceList && resourceList.get('data') && resourceList.get('data').isEmpty())
        ) {
            content = (
                <ResourceIndexContainer alignItems="center">
                    <ResourceIndexMessage>
                        <FormattedMessage id="components.CollectionManagement.emptyCollection" />
                    </ResourceIndexMessage>
                </ResourceIndexContainer>
            );
        } else {
            content = (
                <React.Fragment>
                    {resourceType === RESOURCE_IDS.MEDIA ? (
                        <ResourceMediaIndex
                            currentSorting={this.state.searchCriteria}
                            order={order}
                            onSetMediaOrder={this.handleOnSetMediaOrder}
                            onSelectItemsToBeDeleted={this.props.onSelectChildrenToBeDeleted}
                            onSortBy={this.handleSortBy}
                            resourceList={resourceList && resourceList.get('data')}
                        />
                    ) : (
                        <ResourceCollectionIndex
                            currentSorting={this.state.searchCriteria}
                            order={order}
                            onSetCollectionOrder={this.handleOnSetCollectionOrder}
                            onSelectItemsToBeDeleted={this.props.onSelectChildrenToBeDeleted}
                            onSortBy={this.handleSortBy}
                            resourceList={resourceList && resourceList.get('data')}
                        />
                    )}
                    <PaginationBar
                        currentPage={this.state.resourceListCurrentPage}
                        pagesTotal={resourceList && resourceList.get('lastPage')}
                        onPageSelection={this.handlePaginationBarPageSelection}
                    />
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <ControlPanelCollectionContent
                    controls={this.props.controls}
                    onSearch={this.handleSearch}
                    {...this.props}
                />
                {content}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    tabItemsList: ownProps.tabItemsListSelector && ownProps.tabItemsListSelector(state),
    resourceList: ownProps.resourceListSelector(state),
    resourceListIsFetching:
        ownProps.resourceListIsFetchingSelector && ownProps.resourceListIsFetchingSelector(state),
});

const mapDispatchToProps = (dispatch, ownProps) =>
    bindActionCreators(
        {
            fetchResourceList: ownProps.resourceListFetchAction,
            setMediaOrder: collectionResource().thunks().setMediaOrder,
            setCollectionOrder: collectionResource().thunks().setCollectionOrder,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(CollectionManagement);
