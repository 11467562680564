// @flow

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { Flex, Box } from 'grid-styled';
import { Map } from 'immutable';

// Components
import CheckboxList from 'components/Form/CheckboxList';
import Loading from 'components/Loading';
import Search from 'components/Form/Search';

// Styles
import { FormGroup, Label } from 'styles/form';

// Types
import type { ImmutableMap, ReduxDispatch } from 'types';

type Props = {
    collectionList: ?Object,
    collectionListIsFetching: ?boolean,
    fetchCollectionList: Function,
    data?: ImmutableMap<string, mixed>,
    onChangeFieldFromState: Function,
    parent: number,
};

type State = {
    collectionListCurrentPage: number,
    loading: boolean,
    perPage: number,
    searchCriteria: Object,
    search?: string,
};

class RelatedCollectionBlock extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            collectionListCurrentPage: 1,
            searchCriteria: {
                parent: props.parent,
                search: '',
            },
            perPage: 99,
        };
    }

    handleCollectionsSearch = (value: string) => {
        this.setState(
            (prevState: State) => ({
                searchCriteria: {
                    ...prevState.searchCriteria,
                    search: value,
                },
            }),
            () => this.handleFetchCollectionList()
        );
    };

    handleFetchCollectionList = () => {
        this.props.fetchCollectionList(
            this.state.searchCriteria,
            this.state.collectionListCurrentPage,
            this.state.perPage
        );
    };

    handleRelatedCollectionsArray = (mapOfCollections: ImmutableMap<string, string>) =>
        mapOfCollections.reduce((acc: Array<number>, singleCollection: Object) => {
            if (singleCollection && Map.isMap(singleCollection)) {
                acc.push(singleCollection.get('id'));
            }
            return acc;
        }, []);

    handleSelectedCollections = (name: string, mapOfCollections: ImmutableMap<string, string>) => {
        const arrayOfCollections =
            mapOfCollections && this.handleRelatedCollectionsArray(mapOfCollections);
        this.setState(
            {
                [name]: mapOfCollections,
            },
            () => this.props.onChangeFieldFromState(name, arrayOfCollections)
        );
    };

    isLoading = () => this.props.collectionListIsFetching;

    render() {
        return (
            <FormGroup>
                <Label htmlFor="relatedCollections">
                    <FormattedMessage id="components.RelatedCollectionBlock.label" />
                </Label>
                <Flex>
                    <Box width={5 / 12}>
                        <Search onSearch={this.handleCollectionsSearch} full />
                    </Box>
                    <Box pl={3} width={7 / 12}>
                        {this.isLoading() ? (
                            <Loading inline loading small />
                        ) : (
                            <CheckboxList
                                emptyString="components.RelatedCollectionBlock.placeholder"
                                data={this.props.data}
                                list={
                                    this.state.searchCriteria.search && this.props.collectionList
                                        ? this.props.collectionList
                                        : null
                                }
                                name="relatedCollections"
                                noResultsString="components.RelatedCollectionBlock.noResults"
                                onChange={this.handleSelectedCollections}
                                visibleCheckedLimit={6}
                                visibleUncheckedLimit={6}
                            />
                        )}
                    </Box>
                </Flex>
            </FormGroup>
        );
    }
}

const mapStateToProps = (state: State, ownProps) => ({
    tabItemsList: ownProps.tabItemsListSelector && ownProps.tabItemsListSelector(state),
    collectionList: ownProps.collectionListSelector(state),
    collectionListIsFetching:
        ownProps.collectionListIsFetchingSelector &&
        ownProps.collectionListIsFetchingSelector(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch, ownProps) =>
    bindActionCreators(
        {
            fetchCollectionList: ownProps.collectionListFetchAction,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RelatedCollectionBlock);
