// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

// Styles
import { Actions, Arrow, InlineContainer, SingleTab, Tabs } from './styles';
import { FlexItem } from 'styles/common';

type DefaultProps = {
    unpaddedTabs: boolean,
};

type Props = DefaultProps & {
    backTitleId?: string,
    backTitleRoute?: string,
    controls?: Object,
    title?: string,
    unpaddedTabs: boolean,
};

/**
 * Control Panel Variation (Content Page) component with back link & controls
 */

export default class ControlPanelBreadCrumb extends React.PureComponent<Props> {
    static defaultProps: DefaultProps = {
        unpaddedTabs: false,
    };

    render() {
        const { backTitleId, backTitleRoute, controls, title, unpaddedTabs } = this.props;

        let backTitle;
        if (backTitleId && backTitleRoute) {
            backTitle = (
                <Link to={backTitleRoute}>
                    <FormattedMessage id={backTitleId} />
                </Link>
            );
        } else if (backTitleId) {
            backTitle = (
                <SingleTab>
                    <FormattedMessage id={backTitleId} />
                </SingleTab>
            );
        }

        return (
            <InlineContainer>
                <FlexItem>
                    <Tabs unpaddedTabs={unpaddedTabs}>
                        {backTitle}
                        <Arrow />
                        <SingleTab selected>{title}</SingleTab>
                    </Tabs>
                </FlexItem>
                <FlexItem>{controls && <Actions>{controls}</Actions>}</FlexItem>
            </InlineContainer>
        );
    }
}
