// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { SortToggler, SortToggle } from './styles';

// Types
import type { IntlType } from 'types';

type Props = {
    currentSorting: {
        sortBy?: string,
        sortOrder?: string,
    },
    id: string,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    name: string,
    onClick?: Function,
    thead?: boolean,
};

type State = {
    sortBy: string,
    sortOrder: string,
};

/**
 * Sortable th component for table sorting
 */

class SortableTh extends React.PureComponent<Props, State> {
    static defaultProps = {
        thead: true,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            sortBy: (props.currentSorting && props.currentSorting.sortBy) || '',
            sortOrder: (props.currentSorting && props.currentSorting.sortOrder) || 'inactive',
        };
    }

    handleSortToggle = (sortBy: string, sortOrder: string) => () => {
        // Possible toggle states
        const states = ['asc', 'desc', 'inactive'];
        // If less then the length of states && sortBy has not changed, add one else set to 0
        let i = states.indexOf(sortOrder);
        if (i < states.length - 1 && this.state.sortBy === sortBy) {
            ++i;
        } else {
            i = 0;
        }

        this.setState(
            {
                sortOrder: states[i],
            },
            () => this.props.onClick && this.props.onClick(sortBy, states[i])
        );
    };

    render() {
        const { currentSorting, id, intl, name, onClick, thead } = this.props;
        const { sortOrder } = this.state;

        let content;
        // If onClick prop is passed, build out markup for SortableTh,
        // else return basic
        if (onClick) {
            content = (
                <SortToggle onClick={this.handleSortToggle(name, sortOrder)}>
                    <span>{intl.formatMessage({ id })}</span>
                    <SortToggler
                        active={currentSorting.sortBy === name}
                        asc={sortOrder === 'asc'}
                        desc={sortOrder === 'desc'}
                    />
                </SortToggle>
            );
        } else {
            content = intl.formatMessage({ id });
        }

        return thead ? <th>{content}</th> : content;
    }
}

export default injectIntl(SortableTh);
