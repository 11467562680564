// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { fromJS } from 'immutable';

// Components
import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import SectionTabs from 'components/SectionTabs';
import SubSectionTitle from 'components/SubSectionTitle';
import ToggleSwitchBoolean from 'components/Form/ToggleSwitchBoolean';
import { ReverseSpan } from 'styles/buttons';

// Form Blocks
import BasicInformationBlock from 'components/Forms/BasicInformationBlock';
import DateInformationBlock from 'components/Forms/DateInformationBlock';
import FileUploadBlock from 'components/Forms/FileUploadBlock';
import InternalNotesBlock from 'components/Forms/InternalNotesBlock';
import LocationInformationBlock from 'components/Forms/LocationInformationBlock';
import MediaLanguageBlock from 'components/Forms/MediaLanguageBlock';
import MediaMetaDataBlock from 'components/Forms/MediaMetaDataBlock';
import MediaPerformanceBlock from 'components/Forms/MediaPerformanceBlock';
import MediaPrivacyBlock from 'components/Forms/MediaPrivacyBlock';
import NormeticMetaDataBlock from 'components/Forms/NormeticMetaDataBlock';
import RelatedCollectionBlock from 'components/Forms/RelatedCollectionBlock';
import RepeatableAuthorBlock from 'components/Forms/RepeatableAuthorBlock';
import RepeatableCollaboratorBlock from 'components/Forms/RepeatableCollaboratorBlock';
import RepeatableEditorBlock from 'components/Forms/RepeatableEditorBlock';
import RevisionTranslationBlock from 'components/Forms/RevisionTranslationBlock';
import VideoMetaDataBlock from 'components/Forms/VideoMetaDataBlock';
import VideoChapterBlock from 'components/Forms/VideoChapterBlock';
import VideoSubtitleBlock from 'components/Forms/VideoSubtitleBlock';

// Services
import { selectUser } from 'services/Authentication/selectors';
import mediaResource from 'services/Media';
import categoryResource from 'services/Category';
import collectionResource from 'services/Collection';
import normeticDisciplineResource from 'services/NormeticDiscipline';

// Styles
import { FormGroup, LabelSubtle, SectionHeader } from 'styles/form';
import { FlexContainer, FlexItemContainer, Indented, LineBreak, WhiteBlock } from 'styles/common';
import { MediaContainer } from './styles';

// Types
import type {
    CategoryType,
    ImmutableMap,
    InputEvent,
    IntlType,
    ReduxDispatch,
    MediaType,
    NormeticDisciplinesType,
    UserType,
} from 'types';

// Utils
import { createFormData } from 'utils';
import { isAdminOrAbove } from 'utils/authentication';

type DefaultProps = {
    onUpdateRouting?: string,
};

type Props = DefaultProps & {
    categories: ImmutableMap<string, CategoryType>,
    deleteMedia: Function,
    deleteNew: Function,
    draft?: ImmutableMap<string, mixed>,
    errors: ?ImmutableMap<string, Object>,
    fetchMedia: Function,
    generated?: ImmutableMap<string, mixed>,
    getCategories: Function,
    getNormeticDisciplines: Function,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    mediaId?: number,
    media?: ImmutableMap<string, mixed>,
    normeticDisciplines?: ImmutableMap<string, NormeticDisciplinesType>,
    onDelete: Function,
    onMediaCreation: Function,
    onRef: Function,
    resetErrors: Function,
    updateMedia: Function,
    user: ?ImmutableMap<string, UserType>,
};

type State = {
    loading: boolean,
    media: ?MediaType,
    selectedTabItemId: string,
};

/**
 * Media create/edit/delete form
 */

class Media extends React.Component<Props, State> {
    static defaultProps: DefaultProps = {
        onUpdateRouting: null,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            selectedTabItemId: 'general',
            media:
                props.media ||
                fromJS({
                    cardLanguage: 'fr/en',
                    contributorId: props.user && props.user.get('id'),
                    isPublished: false,
                    isDraft: true,
                    isVisible: true,
                    isPrivate: false,
                    mediaLanguage: 'none',
                    showMap: false,
                }),
        };
    }

    componentDidMount() {
        this.props.resetErrors();

        // Retrieve media categories
        if (this.props.categories.isEmpty()) {
            this.props.getCategories();
        }

        // Retrieve normetic disciples values
        if (
            !this.props.normeticDisciplines ||
            (this.props.normeticDisciplines && this.props.normeticDisciplines.isEmpty())
        ) {
            this.props.getNormeticDisciplines();
        }

        if (this.props.mediaId && !this.props.media) {
            this.props.fetchMedia(this.props.mediaId);
        }

        this.props.onRef(this);
    }

    componentWillReceiveProps(nextProps: Props) {
        const { errors, media } = nextProps;

        // If new media has been created,
        // no errors (unauhorized file type) &
        // if there is no current media; trigger onMediaCreation()
        if (
            media &&
            !media.isEmpty() &&
            errors.isEmpty() &&
            (!this.props.media || this.props.media.isEmpty())
        ) {
            this.props.onMediaCreation(media);
        }

        // If new media & is different from current media,
        // replace state
        if (media && media !== this.props.media) {
            this.setState({
                media,
            });
        }

        // Set loading to false after setting new media to state
        this.setState({
            loading: false,
        });
    }

    handleDelete = () => {
        if (this.props.media && this.props.media.get('id')) {
            this.props.deleteMedia(this.props.media.get('id'));
        }
    };

    handleChangeRepeatableField = (name: string, map: ImmutableMap<string, mixed>) => {
        const { media } = this.state;
        if (media) {
            const newMedia = media.setIn([name], map);
            this.setState({
                media: newMedia,
            });
        }
    };

    handleTabItemClick = (key: string) => () => {
        this.setState({
            selectedTabItemId: key,
        });
    };

    handleChangeField = (name: string, isCheckbox: boolean = false) => (event: Event) => {
        const { media } = this.state;
        let value = null;
        if (event.target) {
            if (event.target.files) {
                value = event.target.files[0];
            } else if (typeof event.target.value !== 'undefined') {
                value = event.target.value;
            } else if (event.target.innerHTML) {
                value = event.target.innerHTML;
            } else if (typeof event === 'string') {
                value = event;
            }
        }

        if (media) {
            const newMedia = media.setIn([name], isCheckbox ? event.target.checked : value);
            this.setState({
                media: newMedia,
            });
        }
    };

    handleChangeFieldFromStateMultiple = (obj: Object) => {
        const { media } = this.state;
        if (media) {
            const newMedia = media.merge(obj);
            this.setState({ media: newMedia });
        }
    };

    // todo: Look into why above handler does not work. InputEvent
    handleChangeFieldFromState = (name: string, value: any) => {
        const { media } = this.state;
        if (media) {
            const newMedia = media.setIn([name], value);
            this.setState({
                media: newMedia,
            });
        }
    };

    handleFileSelection = (name: string) => (event: InputEvent) => {
        this.props.resetErrors();

        if (name && event.target.files) {
            this.setState({
                loading: true,
            });

            const { media } = this.state;
            const newMedia = media.setIn([name], event.target.files[0]);
            this.setState(
                {
                    media: newMedia,
                },
                () => {
                    if (this.props.media && this.props.media.has('id')) {
                        this.handleOnSubmitForm(false)(event);
                    } else {
                        const formData = createFormData(this.state.media);
                        this.props.createMediaDraft(formData);
                    }
                }
            );
        }
    };

    handleThumbnailDrop = (item, monitor) => (event) => {
        if (monitor) {
            const { media } = this.state;
            const newMedia = media
                ? media.set('droppedThumbnail', monitor.getItem().files[0])
                : null;
            this.setState({
                media: newMedia,
            });
        }
    };

    handleThumbnailSelection = (id: number) => {
        const { media } = this.state;
        const newMedia = media.set('selectedThumbnailId', id);
        this.setState({ media: newMedia });
    };

    handleOnCancel = (event: Event) => {
        event.preventDefault();
        const { media } = this.props;
        // Delete new entity within store
        this.props.deleteNew();
        // If media was created, but left as draft, delete it
        if (media && media.has('id') && media.get('isDraft')) {
            this.props.deleteMedia(media.get('id'));
        }
        // Reset local media state
        this.setState({
            media: null,
        });
    };

    handleOnSubmitForm = (closeModal: boolean = true) => (event: Event) => {
        event.preventDefault();

        this.setState({
            loading: true,
        });

        const mediaId = this.props.media && this.props.media.get('id');
        // Only send back new information
        // const mediaDiff = deepDiff(
        //     this.props.media && this.props.media.toJS(),
        //     this.state.media && this.state.media.toJS(),
        //     true
        // );
        if (mediaId) {
            const formData = createFormData(this.state.media);
            this.props.updateMedia(mediaId, formData, this.props.onUpdateRouting, closeModal);
        }
    };

    getValidationState = (error: ?boolean) => (error ? 'error' : null);

    getProcessedErrorResponseForField = (fieldName: string) => {
        const { errors } = this.props;
        return errors && errors.size && errors.getIn(['data', 'errors', fieldName, '0', 'code']);
    };

    renderCollectionsSearchResults = () => {
        const { media } = this.state;
        return media;
    };

    renderTabContent = () => {
        const { errors, user } = this.props;
        const { media, loading } = this.state;
        const role = user && user.get('role');

        let content;
        switch (this.state.selectedTabItemId) {
            default:
            case 'general':
                content = (
                    <Indented>
                        <FileUploadBlock
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            handleValidationState={this.getValidationState}
                            isLoading={loading}
                            onChangeField={this.handleChangeField}
                            onFileSelection={this.handleFileSelection}
                            onThumbnailDrop={this.handleThumbnailDrop}
                            onThumbnailSelection={this.handleThumbnailSelection}
                            media={media}
                        />
                        <SectionHeader>
                            <FormattedMessage id="components.Media.mediaInformation" />
                        </SectionHeader>
                        <BasicInformationBlock
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            handleValidationState={this.getValidationState}
                            isLoading={loading}
                            onChangeField={this.handleChangeField}
                            media={media}
                        />
                        <hr />
                        <MediaLanguageBlock
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            handleValidationState={this.getValidationState}
                            isLoading={loading}
                            onChangeField={this.handleChangeField}
                            media={media}
                            categories={this.props.categories}
                        />
                        <hr />
                        <RelatedCollectionBlock
                            isLoading={loading}
                            onChangeFieldFromState={this.handleChangeFieldFromState}
                            data={media}
                            collectionListFetchAction={
                                collectionResource().thunks()
                                    .fetchSecondaryPaginatedBySearchCriteria
                            }
                            collectionListSelector={collectionResource()
                                .selectors()
                                .selectSecondaryPaginatedBySearchCriteria()}
                            collectionListIsFetchingSelector={collectionResource()
                                .selectors()
                                .selectSecondaryPaginatedIsFetching()}
                        />
                        <hr />
                        <LocationInformationBlock
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            isLoading={loading}
                            onChangeField={this.handleChangeField}
                            onChangeFieldFromStateMultiple={this.handleChangeFieldFromStateMultiple}
                            media={media}
                        />
                        <hr />
                        <DateInformationBlock
                            data={media}
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            handleValidationState={this.getValidationState}
                            isLoading={loading}
                            onChangeField={this.handleChangeField}
                        />
                        <SectionHeader>
                            <FormattedMessage id="components.Media.mediaPerformances" />
                        </SectionHeader>
                        <MediaPerformanceBlock media={media} />
                        <SectionHeader>
                            <FormattedMessage id="components.Media.contributorInformation" />
                        </SectionHeader>
                        <RepeatableAuthorBlock
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            handleValidationState={this.getValidationState}
                            isLoading={loading}
                            onChangeField={this.handleChangeRepeatableField}
                            media={media}
                        />
                        <hr />
                        <RepeatableEditorBlock
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            handleValidationState={this.getValidationState}
                            isLoading={loading}
                            onChangeField={this.handleChangeRepeatableField}
                            media={media}
                        />
                        <hr />
                        <RepeatableCollaboratorBlock
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            handleValidationState={this.getValidationState}
                            isLoading={loading}
                            onChangeField={this.handleChangeRepeatableField}
                            media={media}
                        />
                    </Indented>
                );
                break;
            case 'technical':
                content = (
                    <Indented>
                        <SubSectionTitle
                            text={this.props.intl.formatMessage({
                                id: 'components.Media.mediaMetadata',
                            })}
                        />
                        <MediaMetaDataBlock
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            isLoading={loading}
                            onChangeField={this.handleChangeField}
                            media={media}
                        />
                        <hr />
                        <VideoMetaDataBlock
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            isLoading={loading}
                            onChangeField={this.handleChangeField}
                            media={media}
                        />
                        <SubSectionTitle
                            text={this.props.intl.formatMessage({
                                id: 'components.Media.normeticMetadata',
                            })}
                        >
                            <ToggleSwitchBoolean
                                labelOn="components.Collection.isPublished.active"
                                labelOff="components.Collection.isPublished.inactive"
                                name="metadataNormeticIsPublished"
                                onChange={this.handleChangeField(
                                    'metadataNormeticIsPublished',
                                    true
                                )}
                                right
                                value={
                                    this.props.media &&
                                    this.props.media.get('metadataNormeticIsPublished')
                                }
                            />
                        </SubSectionTitle>
                        <NormeticMetaDataBlock
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            isLoading={loading}
                            onChangeField={this.handleChangeField}
                            onChangeFieldFromState={this.handleChangeFieldFromState}
                            data={media}
                            normeticDisciplines={this.props.normeticDisciplines}
                        />
                    </Indented>
                );
                break;
            case 'options':
                content = (
                    <React.Fragment>
                        <Indented>
                            <SectionHeader>
                                <FormattedMessage id="components.Media.fileParameters" />
                            </SectionHeader>
                        </Indented>
                        <RevisionTranslationBlock
                            data={media}
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            isLoading={loading}
                            onChangeFieldFromState={this.handleChangeFieldFromState}
                            onChangeFieldFromStateMultiple={this.handleChangeFieldFromStateMultiple}
                            onChangeField={this.handleChangeField}
                            user={user}
                            {...this.props}
                        />
                        <MediaPrivacyBlock
                            data={media}
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            handleValidationState={this.getValidationState}
                            isLoading={loading}
                            onChangeField={this.handleChangeField}
                            user={user}
                        />
                        <Indented>
                            <SectionHeader>
                                <FormattedMessage id="components.Media.videoParameters" />
                            </SectionHeader>
                            <VideoChapterBlock
                                errors={errors}
                                handleErrors={this.getProcessedErrorResponseForField}
                                handleValidationState={this.getValidationState}
                                isLoading={loading}
                                onChange={this.handleChangeField}
                                media={media}
                            />
                            <LineBreak height="10px" />
                            <VideoSubtitleBlock
                                errors={errors}
                                handleErrors={this.getProcessedErrorResponseForField}
                                handleValidationState={this.getValidationState}
                                isLoading={loading}
                                onChangeField={this.handleChangeRepeatableField}
                                media={media}
                            />
                            <hr />
                            <InternalNotesBlock
                                isLoading={loading}
                                onChangeField={this.handleChangeField}
                                media={media}
                            />
                            <hr />
                        </Indented>
                        <WhiteBlock>
                            <Indented>
                                <FlexContainer justifyContent="space-between" gutter="20">
                                    <FlexItemContainer
                                        direction="column"
                                        justifyContent="flex-start"
                                        collapsed
                                    >
                                        <FormGroup>
                                            <ReverseSpan
                                                onClick={
                                                    role && isAdminOrAbove(role)
                                                        ? this.props.onDelete
                                                        : null
                                                }
                                                disabled={!role || (role && !isAdminOrAbove(role))}
                                            >
                                                <FormattedMessage id="components.Media.deleteMedia" />
                                            </ReverseSpan>
                                        </FormGroup>
                                    </FlexItemContainer>
                                    <FlexItemContainer direction="column" justifyContent="center">
                                        <FormGroup>
                                            <LabelSubtle>
                                                <FormattedMessage id="components.Media.deleteMedia.notice" />
                                            </LabelSubtle>
                                        </FormGroup>
                                    </FlexItemContainer>
                                </FlexContainer>
                            </Indented>
                        </WhiteBlock>
                    </React.Fragment>
                );
                break;
        }
        return content;
    };

    render() {
        const { user } = this.props;
        const role = user && user.get('role');

        if (
            (this.state.loading && this.props.errors && this.props.errors.isEmpty()) ||
            (this.props.mediaId && !this.props.media)
        ) {
            return <Loading />;
        }

        return (
            <MediaContainer>
                <form>
                    <SectionTabs
                        onItemClick={this.handleTabItemClick}
                        selectedTabItemId={this.state.selectedTabItemId}
                        tabs={fromJS([
                            {
                                id: 'general',
                                string: 'components.Media.tabGeneralInformation',
                            },
                            {
                                id: 'technical',
                                string: 'components.Media.tabTechnicalDetails',
                            },
                            {
                                id: 'options',
                                string: 'components.Media.tabOptions',
                            },
                        ])}
                    >
                        <ToggleSwitchBoolean
                            disabled={!role || (role && !isAdminOrAbove(role))}
                            labelOn={'components.Media.isPublished.active'}
                            labelOff={'components.Media.isPublished.inactive'}
                            name="isPublished"
                            onChange={this.handleChangeField('isPublished', true)}
                            value={this.props.media && this.props.media.get('isPublished')}
                        />
                    </SectionTabs>
                    <ErrorMessage
                        banner
                        error={
                            this.getProcessedErrorResponseForField('media.file')
                                ? this.props.errors.getIn(['data', 'errors', 'media.file'])
                                : null
                        }
                    />
                    {this.renderTabContent()}
                </form>
            </MediaContainer>
        );
    }
}

const mapStateToProps = (state, ownProps) =>
    createStructuredSelector({
        categories: categoryResource()
            .selectors()
            .selectAll(),
        deleted: mediaResource()
            .selectors()
            .selectDeleted(),
        errors: mediaResource()
            .selectors()
            .selectErrors(),
        media: ownProps.mediaId
            ? mediaResource()
                  .selectors()
                  .select(ownProps.mediaId)
            : mediaResource()
                  .selectors()
                  .selectNew(),
        normeticDisciplines: normeticDisciplineResource()
            .selectors()
            .selectAll(),
        user: selectUser(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            createMediaDraft: mediaResource().thunks().create,
            deleteMedia: mediaResource().thunks().delete,
            deleteNew: mediaResource().thunks().deleteNew,
            getCategories: categoryResource().thunks().fetchAll,
            fetchMedia: mediaResource().thunks().fetch,
            getNormeticDisciplines: normeticDisciplineResource().thunks().fetchAll,
            resetErrors: mediaResource().thunks().resetErrors,
            updateMedia: mediaResource().thunks().update,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Media));
