// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

import folderIcon from 'assets/icons/Folder/Folder.svg';

export const Background = styled.div`
    width: ${(props) => props.width || `100%`};
    height: ${(props) => props.height || `100%`};

    background-color: ${colors.white};
    background-image: ${(props) => props.image && `url('${props.image}')`};
    background-position: center;
    background-size: ${(props) => (props.cover ? `cover` : `contain`)};
`;

export const CollectionCount = styled.span`
    width: 30px;
    height: 24px;

    background-image: url('${folderIcon}');
    background-position: center;
    background-size: cover;

    color: ${colors.tealLight};

    font-size: 11px;
    font-weight: 500;

    line-height: 30px;
    text-align: center;
`;

export const Square = Background.extend`
    &:after {
        content: '';
        display: block;
        padding-bottom: 100%;
    }
`;

export const StyledCollectionThumbnail = styled.div`
    position: relative;

    width: ${(props) => props.width || `100%`};
    min-width: ${(props) => props.minWidth || `70px`};
    max-width: ${(props) => props.maxWidth || `none`};
    height: ${(props) => props.height || `40px`};
    min-height: ${(props) => props.minHeight || `40px`};
    max-height: ${(props) => props.maxHeight || `none`};

    background-color: ${colors.grayED};
    color: ${colors.teal};

    > img {
        position: relative;
        width: 100%;
        z-index: 0;
    }

    ${(props) =>
        props.empty &&
        `
        &:after {
            content: '';
            display: block;
            padding-bottom: 57.15%%;
        }
    `};

    > ${CollectionCount} {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -12px;
        margin-left: -15px;

        z-index: 1;
    }
`;

export const StyledThumbnail = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    width: ${(props) => props.width || `100%`};
    min-width: ${(props) => props.minWidth || `auto`};
    max-width: ${(props) => props.maxWidth || `none`};
    height: ${(props) => props.height || `auto`};
    min-height: ${(props) => props.minHeight || `auto`};
    max-height: ${(props) => props.maxHeight || `none`};

    background-color: ${colors.grayED};
    ${(props) =>
        props.placeHolder &&
        `
        background-color: ${colors.white};
        border: 1px solid ${colors.grayE1};
    `};

    color: ${colors.grayB3};

    font-size: 14px;

    overflow: hidden;

    > img {
        width: 100%;
    }

    ${(props) =>
        props.square &&
        `
        &:after {
            content: '';
            display: block;
            padding-bottom: 100%;
        }
    `};

    > span {
        padding: 30px;
        text-align: center;
    }
`;

export const CarouselContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

export const NavigationButton = styled.div`
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: ${colors.white};
    box-shadow: inset 0 -1px 0 0 ${colors.grayED};
    ${(props: Object): string =>
        props.navigationSense === 'left' ? 'margin-right: 10px;' : 'margin-left: 10px;'} img {
        height: 20px;
        width: 20px;
        ${(props) =>
            props.navigationSense === 'left'
                ? 'transform: rotate(0.25turn); margin: 5px 0 0 4px;'
                : 'transform: rotate(0.75turn);margin: 5px 0 0 6px;'};
    }
`;

export const NavigationContainer = styled.div`
    width: calc(100% + 20px);
    display: flex;
    justify-content: space-between;
    position: absolute;
    z-index: 1;
    left: -10px;
    margin-top: -15px;
    top: 50%;
`;

export const Image = styled.img`
    max-width: 100%;
    max-height: 100%;

    ${(props) => (!props.loaded ? 'display: none;' : 'display: block;')} ${(props) =>
        props.fullScreen &&
        `
        max-height: 100vh;
        max-width: 100vw;
        `};
`;
