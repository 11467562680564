// @flow

import styled from 'styled-components';

import colors from 'styles/colors';
import { FlexRowContainer } from 'styles/common';

export const ModalContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    ${(props) => (props.fullScreen ? 'padding: 0;' : 'padding: 30px;')} z-index: 2;
    overflow: auto;
    background-color: ${colors.blackSemiTransparent};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ModalContentArea = styled.div`
    min-width: 400px;
    ${(props) => !props.fullScreen && 'width: 100%;'}
    ${(props) => {
        let maxWidth = 'max-width: ';
        if (props.fullScreen) {
            maxWidth += `100vw`;
        } else if (props.small) {
            maxWidth += `470px`;
        } else if (props.large) {
            maxWidth += `800px`;
        } else {
            maxWidth += `670px`;
        }
        return `${maxWidth};`;
    }}

    ${(props) => (props.fullScreen ? 'margin: 0;' : 'margin: 50px auto auto auto;')}

    ${(props) => props.fullScreen && 'max-height: 100vh;'}
    ${(props) => !props.fullScreen && 'border-radius: 2px;'}

    background-color: ${colors.grayFA};
    overflow: hidden;
    position: relative;
`;

export const ModalHeader = FlexRowContainer.extend`
    min-height: 70px;
    justify-content: space-between;
    background-color: ${colors.gray15};
    color: ${colors.white};
    padding: 20px;
    align-items: center;
`;

export const ModalHeaderTitle = styled.div`
    line-height: 24px;
    font-size: 24px;
`;

export const ModalClose = styled.div`
    position: absolute;
    height: 30px;
    width: 30px;
    right: 20px;
    top: 20px;
    cursor: pointer;
    z-index: 1;
    mix-blend-mode: difference;

    &&:before,
    &&:after {
        position: absolute;
        content: '';
        height: 14px;

        top: 50%;
        left: 50%;
        margin-left: -1px;
        margin-top: -7px;
    }

    &&:before {
        border-left: 2px solid ${colors.white};
        transform: rotate(-45deg);
        margin-left: 7px;
    }

    &&:after {
        border-right: 2px solid ${colors.white};
        transform: rotate(45deg);
        margin-left: 7px;
    }
`;

export const ModalMainContent = styled.div`
    ${(props) => {
        let margin = 'margin: ';
        if (props.simple) {
            margin += '0';
        } else {
            if (props.flush && !props.controls) {
                margin += `20px 0 0 0`;
            } else if (props.flush) {
                margin += `20px 0`;
            } else {
                margin += `20px`;
            }
        }
        return `${margin};`;
    }};
`;

export const ModalControls = styled.div`
    min-height: 70px;
    margin-top: 20px;
    padding: 20px;
    background-color: ${colors.white};

    ${(props) => {
        let textAlign = 'text-align: ';
        if (props.right) {
            textAlign += `right`;
        } else if (props.center) {
            textAlign += `center`;
        } else {
            textAlign += `left`;
        }
        return `${textAlign};`;
    }}

    button {
        margin: 5px;

        &:first-child {
            margin-left 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
`;
