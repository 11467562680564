// @flow

import type { ReduxDispatch, ResponseErrorType } from 'types';
import crudResources from './resources';
import { crudResourceActions } from './actions';

/*
 *
 * CRUDResource thunks
 *
 * Basic function: Check response and dispatch action to save data/error to redux store
 *
 */

const crudThunks = (resourceName: string) => ({
    /*
    * fetchAll: Fetch all the entries of the resource
    */
    fetchAll: () => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .fetchAll()
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchListFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data.length) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchListSuccess(
                            response.data
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchListFailure(error)
                );
            }),
});

export default crudThunks;
