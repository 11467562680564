// @flow

import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { compose, lifecycle, withProps } from 'recompose';

// Components
import Loading from 'components/Loading';

// Styles
import { MapContainer } from 'styles/common';

// Utils
import { GOOGLE_API_KEY } from 'env';

type Props = {
    address?: string,
    onChangeMarkerPosition?: () => void,
};

/**
 * Shared map component with react-google-maps & recompose
 */

class Map extends React.PureComponent<Props> {
    static defaultProps: Props = {
        address: null,
        onChangeMarkerPosition: () => null,
    };

    _map = null;

    handleMapRef = (ref) => {
        this._map = ref;
    };

    handleOnDragEnd = async (event: Event) => {
        const position = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        };
        const results = await this.reverseGeocode(position);
        if (results) {
            const mostRelevantAddress = results[0].address_components;
            this.props.onChangeMarkerPosition(mostRelevantAddress);
        }
    };

    getCurrentCoordinates = async () => {
        let coordinates = null;
        if (navigator.geolocation) {
            coordinates = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition((position) => {
                    resolve({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    });
                });
            });
        } else {
            throw new Error('Geolocation not available');
        }
        return coordinates;
    };

    reverseGeocode = async (position) => {
        const geocoder = new window.google.maps.Geocoder();
        const results = await new Promise((resolve, reject) => {
            geocoder.geocode({ latLng: position }, (data: Object, status: string) => {
                if (status === 'OK') {
                    resolve(data);
                } else {
                    reject(new Error('Unable to get geocoding result'));
                }
            });
        });
        return results;
    };

    geocode = async (address) => {
        const geocoder = new window.google.maps.Geocoder();
        if (address) {
            const result = await new Promise((resolve, reject) => {
                geocoder.geocode({ address }, (results: Array<Object>, status: string) => {
                    if (status === 'OK') {
                        resolve({
                            lat: results[0].geometry.location.lat(),
                            lng: results[0].geometry.location.lng(),
                        });
                    } else {
                        reject(false);
                    }
                });
            });
            return result;
        }
        return false;
    };

    render() {
        // This is to be able to reach Map functions in MyMapComponent
        const parent = this;

        const fetchMapAddress = lifecycle({
            async componentDidMount() {
                let coordinates;

                if (this.props.mapAddress) {
                    coordinates = await parent.geocode(this.props.mapAddress);
                } else {
                    coordinates = await parent.getCurrentCoordinates();
                }
                if (coordinates) {
                    const { lat, lng } = coordinates;
                    // Check https://github.com/airbnb/javascript/issues/684#issuecomment-264094930
                    /* eslint-disable-next-line react/no-did-mount-set-state */
                    this.setState({ map: { lat, lng } });
                }
            },
        });

        const MyMapComponent = compose(
            withProps({
                mapAddress: this.props.address || null,
                containerElement: <MapContainer />,
                googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
                isMarkerShown: true,
                loadingElement: <Loading absolute />,
                mapElement: <div style={{ height: '100%' }} />,
            }),
            withScriptjs,
            withGoogleMap,
            fetchMapAddress
        )((props: Object) => {
            const mapLat = (props.map && props.map.lat) || 53.4604406;
            const mapLng = (props.map && props.map.lng) || -77.3993955;
            const mapZoom = props.map ? 15 : 5;
            if (props.map) {
                return (
                    <GoogleMap
                        ref={this.handleMapRef}
                        defaultZoom={mapZoom}
                        defaultCenter={new window.google.maps.LatLng(mapLat, mapLng)}
                    >
                        {props.isMarkerShown && (
                            <Marker
                                draggable
                                onDragEnd={this.handleOnDragEnd}
                                position={{
                                    lat: mapLat,
                                    lng: mapLng,
                                }}
                            />
                        )}
                    </GoogleMap>
                );
            } else {
                return null;
            }
        });

        return <MyMapComponent />;
    }
}

export default Map;
