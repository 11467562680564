// @flow

import styled from 'styled-components';

import colors from 'styles/colors';

import { FlexContainer } from 'styles/common';

export const CollectionContainer = FlexContainer.extend`
    flex-direction: column;
`;
