// @flow

import pluralize from 'pluralize';
import { fromJS } from 'immutable';

import { crudResourceActionTypes } from './actions';

import type { ReduxActionType } from 'types';

/*
 *
 * CRUDResource reducer
 *
 * crudServiceReducer: Switch statement to set state based on current action type
 *
 */

const crudServiceReducer = (resourceName: string) => (
    state: Object = fromJS({
        list: {},
        listCountries: {},
        listImports: {},
        errors: {},
        new: null,
    }),
    action: ReduxActionType
) => {
    switch (action.type) {
        case crudResourceActionTypes((resourceName: string)).FETCH_READABLE_MIMETYPE_SUCCESS: {
            const list = action.payload.data;
            return state.set('mimetype', fromJS(list)).set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string)).FETCH_READABLE_MIMETYPE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        case crudResourceActionTypes((resourceName: string)).FETCH_THUMBNAILS_SUCCESS: {
            const list = action.payload.data;
            return state.set('thumbnails', fromJS(list)).set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string)).FETCH_THUMBNAILS_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        case crudResourceActionTypes((resourceName: string)).FETCH_PAGINATED_IS_FETCHING:
            return state.set('paginatedListIsFetching', action.payload.isFetching);
        case crudResourceActionTypes((resourceName: string))
            .FETCH_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS: {
            const paginatedBySearchCriteriaList = action.payload.data;
            return state
                .set('paginatedBySearchCriteriaList', fromJS(paginatedBySearchCriteriaList))
                .set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string))
            .FETCH_PAGINATED_BY_SEARCH_CRITERIA_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        case crudResourceActionTypes((resourceName: string)).FETCH_SECONDARY_PAGINATED_IS_FETCHING:
            return state.set('secondaryPaginatedListIsFetching', action.payload.isFetching);
        case crudResourceActionTypes((resourceName: string))
            .FETCH_SECONDARY_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS: {
            const secondaryPaginatedBySearchCriteriaList = action.payload.data;
            return state
                .set(
                    'secondaryPaginatedBySearchCriteriaList',
                    fromJS(secondaryPaginatedBySearchCriteriaList)
                )
                .set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string))
            .FETCH_SECONDARY_PAGINATED_BY_SEARCH_CRITERIA_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        case crudResourceActionTypes((resourceName: string)).FETCH_SUCCESS: {
            const single = action.payload.data;
            return state
                .set('single', fromJS(single))
                .setIn(['list', single.id], fromJS(single))
                .set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string)).FETCH_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        case crudResourceActionTypes((resourceName: string)).CREATE_SUCCESS: {
            const newMedia = action.payload.data;
            return state.set('new', fromJS(newMedia)).set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string)).CREATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        case crudResourceActionTypes((resourceName: string)).UPDATE_SUCCESS: {
            const updatedSingle = action.payload.data;
            return state
                .set('single', fromJS(updatedSingle))
                .setIn(['list', updatedSingle.id], fromJS(updatedSingle))
                .set('new', fromJS(updatedSingle))
                .set('errors', fromJS({}))
                .set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string)).UPDATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        case crudResourceActionTypes((resourceName: string)).UPDATE_RELATED_ENTITY_SUCCESS: {
            const resourceId = action.payload.resourceId;
            const entityName = action.payload.entityName;
            const updatedSingle = action.payload.data;

            return state
                .updateIn(['single', `${pluralize(entityName.toLowerCase())}`], (list) => {
                    const idx = list.findIndex((item) => item.get('id') === updatedSingle.id);

                    return list.setIn([idx], fromJS(updatedSingle));
                })
                .updateIn(
                    ['list', resourceId, `${pluralize(entityName.toLowerCase())}`],
                    (list) => {
                        const idx = list.findIndex((item) => item.get('id') === updatedSingle.id);

                        return list.setIn([idx], fromJS(updatedSingle));
                    }
                )
                .set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string)).UPDATE_RELATED_ENTITY_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        case crudResourceActionTypes((resourceName: string)).DELETE_SUCCESS: {
            const deletedItem = action.payload.data;
            return state.set('deleted', fromJS(deletedItem)).set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string)).DELETE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        case crudResourceActionTypes((resourceName: string)).DELETE_RELATED_ENTITY_SUCCESS: {
            const resourceId = action.payload.resourceId;
            const entityName = action.payload.entityName;
            const entityId = action.payload.entityId;

            return state
                .updateIn(['single', `${pluralize(entityName.toLowerCase())}`], (list) => {
                    const idx = list.findIndex((item) => item.get('id') === entityId);

                    return list.delete(idx);
                })
                .updateIn(
                    ['list', resourceId, `${pluralize(entityName.toLowerCase())}`],
                    (list) => {
                        const idx = list.findIndex((item) => item.get('id') === entityId);

                        return list.delete(idx);
                    }
                );
        }
        case crudResourceActionTypes((resourceName: string)).DELETE_RELATED_ENTITY_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        case crudResourceActionTypes((resourceName: string)).DELETE_NEW:
            return state.set('new', fromJS(null));
        case crudResourceActionTypes((resourceName: string)).RESET_ERRORS:
            return state.set('errors', fromJS({}));
        case crudResourceActionTypes((resourceName: string)).COLLECTION_MEDIA_ORDER_SUCCESS: {
            const data = action.payload.data;
            const { mediaId } = action.meta;
            const mediaIndex = state
                .getIn(['paginatedBySearchCriteriaList', 'data'])
                .findIndex((media) => media.get('id') === mediaId);
            return state.setIn(
                ['paginatedBySearchCriteriaList', 'data', mediaIndex, 'order'],
                data
            );
        }
        case crudResourceActionTypes((resourceName: string)).IMPORT_SUCCESS: {
            const lastKey = [...state.get('listImports').keys()].pop();
            return state
                .setIn(['listImports', lastKey + 1], fromJS(action.payload.data))
                .set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string)).IMPORT_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        case crudResourceActionTypes((resourceName: string)).FETCH_LIST_IMPORT_SUCCESS: {
            const list = action.payload.data;
            return state.set('listImports', fromJS(list)).set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string)).FETCH_LIST_IMPORT_FAILURE:
            return state;
        case crudResourceActionTypes((resourceName: string)).FETCH_LIST_COUNTRIES_SUCCESS: {
            return state
                .set('listCountries', fromJS(action.payload.data))
                .set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string)).FETCH_LIST_COUNTRIES_FAILURE:
            return state
                .set('listCountries', fromJS({}))
                .set('errors', fromJS(action.payload.errors));
        default:
            return state;
    }
};

export default crudServiceReducer;
