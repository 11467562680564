// @flow

import React from 'react';

import Loading from 'components/Loading';

// Styles
import { Image } from './styles';

type State = {
    isLoading: boolean,
};

type Props = {
    src?: string,
};

/**
 * Shared background image component
 */

class ClassicImage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isLoading: true,
        };
    }

    handleOnLoad = (event: Event) => {
        this.setState({ isLoading: false });
    };

    render() {
        const { src, ...rest } = this.props;

        return (
            <React.Fragment>
                {this.state.isLoading && <Loading />}
                <Image
                    loaded={!this.state.isLoading}
                    onLoad={this.handleOnLoad}
                    src={src}
                    {...rest}
                />
            </React.Fragment>
        );
    }
}

ClassicImage.defaultProps = {
    src: '',
};

export default ClassicImage;
