// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

// Components
import LinkGroup from './LinkGroup';

// Styles
import { NavItemWithActionContainer } from './styles';

// Types
import type { UserType } from 'types';

/**
 * isItemSelected - Function to determine if the navigation item in question is selected/being displayed (for display purposes)
 *
 * @param  {string} path: path prop with url being visited
 * @param  {string} item: item name which will be present as the main subject of the url when item in question is clicked/selected
 * @return {boolean} returns true if the item passed in is part of the main subject of the url being visited and passed in through the path prop
 */
function isItemSelected(path: string, item: string) {
    const viewSubject = /^(\/)([^/?#&]+)/.exec(path);

    return viewSubject && viewSubject[2] && viewSubject[2] === item;
}

type Props = {
    path: string,
    user?: UserType,
};

/**
 * SideNav menu wrapper
 */

const SideNavMenu = (props: Props) => (
    <div>
        <LinkGroup handleIsItemSelected={isItemSelected} path={props.path} user={props.user} />
        <NavItemWithActionContainer selected={isItemSelected(props.path, 'settings')}>
            <Link to="/profile" data-test-id="settingsLink">
                <FormattedMessage id="containers.SettingsPage.NavLinkText" />
            </Link>
        </NavItemWithActionContainer>
    </div>
);

SideNavMenu.defaultProps = {
    user: null,
};

export default SideNavMenu;
