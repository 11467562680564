// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import { fromJS } from 'immutable';

// Components
import ControlPanel from 'components/ControlPanel';
import Loading from 'components/Loading';
import Select from 'components/Form/Select';

// Services
import statisticResource from 'services/Statistic';

// Styles
import { PrimaryButton, ReverseButton } from 'styles/buttons';
import {
    ActionsContainer,
    Bold,
    FlexContainer,
    FlexItemContainer,
    Indented,
    LineBreak,
    Table,
    WhiteBlock,
    NoWrap,
} from 'styles/common';
import { SectionHeader } from 'styles/form';

// Types
import type { ImmutableMap, InputEvent, IntlType } from 'types';

// Utils
import { arrayOfDaysFromMonth, arrayOfMonths, arrayOfYears } from 'utils';

type Props = {
    /** trigger fetch request to backend */
    getStatistic: Function,
    /** trigger fetch by date request to backend */
    getStatisticByDate: Function,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    /** provides access to statistics */
    statistic?: ImmutableMap<string, string>,
    /** provides access to statistics by date */
    statisticByDate?: ImmutableMap<string, string>,
};

type State = {
    endDay: string,
    endMonth: string,
    endYear: string,
    invalidFields?: ImmutableList<string>,
    loading: boolean,
    startDay: string,
    startMonth: string,
    startYear: string,
    statisticByDate?: ImmutableMap<string, string>,
};

const yearString = new Date().getFullYear().toString();

const initialDateRange = {
    endDay: '31',
    endMonth: '12',
    endYear: yearString,
    startDay: '01',
    startMonth: '01',
    startYear: yearString,
};

/**
 * Statistics Page.
 *
 * Statistics page to view statistics
 *
 */

export class StatisticsPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            ...initialDateRange,
            invalidFields: fromJS({}),
            loading: false,
            statistic: props.statistic || null,
            statisticByDate: props.statisticByDate || null,
        };
    }

    /**
     * Checks for statistics (and those by date), if none triggers fetch from backend
     */
    componentDidMount() {
        // Retrieve statistic
        const { statistic, statisticByDate } = this.props;
        if (!statistic || (statistic && statistic.isEmpty())) {
            this.props.getStatistic();
        }

        if (!statisticByDate || (statisticByDate && statisticByDate.isEmpty())) {
            this.handleGetStatisticsByDate();
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        const { statisticByDate } = nextProps;
        if (statisticByDate && statisticByDate !== this.props.statisticByDate) {
            this.setState({
                loading: false,
                statisticByDate,
            });
        }
    }

    handleChangeField = (name: string) => ({ target: { value } }: InputEvent) => {
        this.setState(
            {
                [name]: value,
            },
            () => {
                const { endDay, endMonth, endYear, startDay, startMonth, startYear } = this.state;
                const startDate = moment(`${startYear}-${startMonth}-${startDay}`);
                const endDate = moment(`${endYear}-${endMonth}-${endDay}`);
                const newInvalidFields =
                    this.state.invalidFields &&
                    this.state.invalidFields.setIn(['endDate'], endDate.isBefore(startDate));
                this.setState({
                    invalidFields: newInvalidFields,
                });
            }
        );
    };

    handleFormSubmit = () => {
        this.setState(
            {
                loading: true,
            },
            () => this.handleGetStatisticsByDate()
        );
    };

    /**
     * Replace state with initialDateRange to reset dates
     */
    handleFormReset = () => {
        this.setState((prevState: State) => ({
            ...prevState,
            ...initialDateRange,
        }));
    };

    /**
     * Submits request to fetch statistics by user inputted date
     */
    handleGetStatisticsByDate = () => {
        const { endDay, endMonth, endYear, startDay, startMonth, startYear } = this.state;

        this.props.getStatisticByDate(
            `${startYear}-${startMonth}-${startDay}`,
            `${endYear}-${endMonth}-${endDay}`
        );
    };

    renderCollectionTable = () => {
        const { statistic } = this.props;
        const collections = statistic && statistic.get('collections');

        if (!collections) {
            return null;
        }

        return (
            <Table tight>
                <thead>
                    <tr>
                        <th>
                            <FormattedMessage id="containers.StatisticsPage.collection" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {this.props.intl.formatMessage({
                                id: 'containers.StatisticsPage.collectionTotal',
                            })}
                            <Bold>{collections.get('total') || 0}</Bold>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {this.props.intl.formatMessage({
                                id: 'containers.StatisticsPage.collectionPublicTotal',
                            })}
                            <Bold>{collections.get('public') || 0}</Bold>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {this.props.intl.formatMessage({
                                id: 'containers.StatisticsPage.collectionNormeticPublicTotal',
                            })}
                            <Bold>{collections.get('normetic') || 0}</Bold>
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    };

    renderMediaTypeTable = (titleIntl: string, totalIntl: string, counts?: Object) => {
        if (!counts) {
            return null;
        }

        return (
            <Table tight>
                <thead>
                    <tr>
                        <th>{this.props.intl.formatMessage({ id: titleIntl })}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <strong>{this.props.intl.formatMessage({ id: totalIntl })} </strong>
                            <Bold>{(counts && counts.get('total')) || 0}</Bold>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Indented>
                                <FormattedMessage
                                    id="containers.StatisticsPage.lineItem"
                                    values={{
                                        label: this.props.intl.formatMessage({
                                            id: 'global.mediaType.photos',
                                        }),
                                    }}
                                />
                                <Bold>{(counts && counts.get('photos')) || 0}</Bold>
                            </Indented>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Indented>
                                <FormattedMessage
                                    id="containers.StatisticsPage.lineItem"
                                    values={{
                                        label: this.props.intl.formatMessage({
                                            id: 'global.mediaType.illustrations',
                                        }),
                                    }}
                                />
                                <Bold>{(counts && counts.get('illustrations')) || 0}</Bold>
                            </Indented>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Indented>
                                <FormattedMessage
                                    id="containers.StatisticsPage.lineItem"
                                    values={{
                                        label: this.props.intl.formatMessage({
                                            id: 'global.mediaType.documents',
                                        }),
                                    }}
                                />
                                <Bold>{(counts && counts.get('documents')) || 0}</Bold>
                            </Indented>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Indented>
                                <FormattedMessage
                                    id="containers.StatisticsPage.lineItem"
                                    values={{
                                        label: this.props.intl.formatMessage({
                                            id: 'global.mediaType.videos',
                                        }),
                                    }}
                                />
                                <Bold>{(counts && counts.get('videos')) || 0}</Bold>
                            </Indented>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Indented>
                                <FormattedMessage
                                    id="containers.StatisticsPage.lineItem"
                                    values={{
                                        label: this.props.intl.formatMessage({
                                            id: 'global.mediaType.audio',
                                        }),
                                    }}
                                />
                                <Bold>{(counts && counts.get('audios')) || 0}</Bold>
                            </Indented>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Indented>
                                <FormattedMessage
                                    id="containers.StatisticsPage.lineItem"
                                    values={{
                                        label: this.props.intl.formatMessage({
                                            id: 'global.mediaType.links',
                                        }),
                                    }}
                                />
                                <Bold>{(counts && counts.get('links')) || 0}</Bold>
                            </Indented>
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    };

    renderPeriodSelector = () => (
        <WhiteBlock>
            <Indented margin="10px 20px">
                <FlexContainer justifyContent="flex-start">
                    <FlexItemContainer direction="column" flexGrown="2" justifyContent="flex-start">
                        <FlexContainer
                            alignContent="center"
                            gutter="10"
                            justifyContent="flex-start"
                        >
                            <FlexItemContainer
                                alignItems="center"
                                collapsed
                                justifyContent="flex-start"
                            >
                                <NoWrap>
                                    {this.props.intl.formatMessage({
                                        id: 'containers.StatisticsPage.periodPrefix',
                                    })}
                                </NoWrap>
                            </FlexItemContainer>
                            <FlexItemContainer alignItems="center" collapsed>
                                <Select
                                    name="startYear"
                                    onChange={this.handleChangeField('startYear')}
                                    value={this.state && this.state.startYear}
                                    options={arrayOfYears().map((year: number) => ({
                                        key: year,
                                        value: year,
                                    }))}
                                />
                            </FlexItemContainer>
                            <FlexItemContainer alignItems="center" collapsed>
                                <Select
                                    name="startMonth"
                                    onChange={this.handleChangeField('startMonth')}
                                    value={this.state && this.state.startMonth}
                                    options={arrayOfMonths.map(
                                        (month: { key: number, intlId: string }) => ({
                                            key: month.key,
                                            intlId: month.intlId,
                                        })
                                    )}
                                />
                            </FlexItemContainer>
                            <FlexItemContainer alignItems="center" collapsed>
                                <Select
                                    name="startDay"
                                    onChange={this.handleChangeField('startDay')}
                                    value={this.state && this.state.startDay}
                                    options={arrayOfDaysFromMonth(
                                        parseInt(this.state.startMonth)
                                    ).map((day: { key: number, value: number }) => ({
                                        key: day.key,
                                        value: day.value,
                                    }))}
                                />
                            </FlexItemContainer>
                            <FlexItemContainer alignItems="center" collapsed>
                                {this.props.intl.formatMessage({
                                    id: 'containers.StatisticsPage.periodJoin',
                                })}
                            </FlexItemContainer>
                            <FlexItemContainer alignItems="center" collapsed>
                                <Select
                                    name="endYear"
                                    onChange={this.handleChangeField('endYear')}
                                    value={this.state && this.state.endYear}
                                    validationState={
                                        this.state.invalidFields &&
                                        this.state.invalidFields.get('endDate')
                                    }
                                    options={arrayOfYears().map((year: number) => ({
                                        key: year,
                                        value: year,
                                    }))}
                                />
                            </FlexItemContainer>
                            <FlexItemContainer alignItems="center" collapsed>
                                <Select
                                    name="endMonth"
                                    onChange={this.handleChangeField('endMonth')}
                                    value={this.state && this.state.endMonth}
                                    validationState={
                                        this.state.invalidFields &&
                                        this.state.invalidFields.get('endDate')
                                    }
                                    options={arrayOfMonths.map(
                                        (month: { key: number, intlId: string }) => ({
                                            key: month.key,
                                            intlId: month.intlId,
                                        })
                                    )}
                                />
                            </FlexItemContainer>
                            <FlexItemContainer alignItems="center" collapsed>
                                <Select
                                    name="endDay"
                                    onChange={this.handleChangeField('endDay')}
                                    value={this.state && this.state.endDay}
                                    validationState={
                                        this.state.invalidFields &&
                                        this.state.invalidFields.get('endDate')
                                    }
                                    options={arrayOfDaysFromMonth(
                                        parseInt(this.state.endMonth)
                                    ).map((day: { key: number, value: number }) => ({
                                        key: day.key,
                                        value: day.value,
                                    }))}
                                />
                            </FlexItemContainer>
                        </FlexContainer>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" flexGrown="1" justifyContent="flex-start">
                        <ActionsContainer>
                            <PrimaryButton
                                disabled={
                                    this.state.invalidFields &&
                                    this.state.invalidFields.includes(true)
                                }
                                onClick={this.handleFormSubmit}
                            >
                                {this.props.intl.formatMessage({
                                    id: 'containers.StatisticsPage.submit',
                                })}
                            </PrimaryButton>
                            <ReverseButton onClick={this.handleFormReset}>
                                {this.props.intl.formatMessage({
                                    id: 'containers.StatisticsPage.restart',
                                })}
                            </ReverseButton>
                        </ActionsContainer>
                    </FlexItemContainer>
                </FlexContainer>
            </Indented>
        </WhiteBlock>
    );

    renderResultsTable = () => {
        const { loading, statisticByDate } = this.state;

        let content;
        if (!statisticByDate || (statisticByDate && statisticByDate.isEmpty()) || loading) {
            content = <Loading height="247px" />;
        } else {
            const startDate = statisticByDate && statisticByDate.getIn(['period', 'startDate']);
            const endDate = statisticByDate && statisticByDate.getIn(['period', 'endDate']);
            content = (
                <React.Fragment>
                    <Indented>
                        <SectionHeader margin="30px 0 15px">
                            {this.props.intl.formatMessage({
                                id: 'containers.StatisticsPage.results',
                            })}
                        </SectionHeader>
                    </Indented>
                    <Table tight>
                        <thead>
                            <tr>
                                <th>
                                    {startDate &&
                                        endDate && (
                                            <FormattedMessage
                                                id="containers.StatisticsPage.resultsTableHeader"
                                                values={{
                                                    start: startDate,
                                                    end: endDate,
                                                }}
                                            />
                                        )}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {this.props.intl.formatMessage({
                                        id: 'containers.StatisticsPage.vistorCount',
                                    })}
                                    <Bold>
                                        {(statisticByDate && statisticByDate.get('vistors')) || 0}
                                    </Bold>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {this.props.intl.formatMessage({
                                        id: 'containers.StatisticsPage.uniqueVistorCount',
                                    })}
                                    <Bold>
                                        {(statisticByDate &&
                                            statisticByDate.get('uniqueVisitors')) ||
                                            0}
                                    </Bold>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {this.props.intl.formatMessage({
                                        id: 'containers.StatisticsPage.mediaViewCount',
                                    })}
                                    <Bold>
                                        {(statisticByDate && statisticByDate.get('mediaViews')) ||
                                            0}
                                    </Bold>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {this.props.intl.formatMessage({
                                        id: 'containers.StatisticsPage.collectionViewCount',
                                    })}
                                    <Bold>
                                        {(statisticByDate &&
                                            statisticByDate.get('collectionViews')) ||
                                            0}
                                    </Bold>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {this.props.intl.formatMessage({
                                        id: 'containers.StatisticsPage.downloadCount',
                                    })}
                                    <Bold>
                                        {(statisticByDate && statisticByDate.get('downloads')) || 0}
                                    </Bold>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </React.Fragment>
            );
        }

        return content;
    };

    renderUserTable = () => {
        const { statistic } = this.props;
        const users = statistic && statistic.get('users');

        if (!users) {
            return null;
        }

        return (
            <Table tight>
                <thead>
                    <tr>
                        <th>
                            <FormattedMessage id="containers.StatisticsPage.users" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            {this.props.intl.formatMessage({
                                id: 'containers.StatisticsPage.usersTotal',
                            })}
                            <Bold>{users.get('total') || 0}</Bold>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {this.props.intl.formatMessage({
                                id: 'containers.StatisticsPage.usersActiveTotal',
                            })}
                            <Bold>{users.get('active') || 0}</Bold>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Indented>
                                <FormattedMessage
                                    id="containers.StatisticsPage.lineItem"
                                    values={{
                                        label: this.props.intl.formatMessage({
                                            id: 'global.userRoles.superAdmin',
                                        }),
                                    }}
                                />
                                <Bold>{users.get('superAdmins') || 0}</Bold>
                            </Indented>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Indented>
                                <FormattedMessage
                                    id="containers.StatisticsPage.lineItem"
                                    values={{
                                        label: this.props.intl.formatMessage({
                                            id: 'global.userRoles.admin',
                                        }),
                                    }}
                                />
                                <Bold>{users.get('admins') || 0}</Bold>
                            </Indented>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Indented>
                                <FormattedMessage
                                    id="containers.StatisticsPage.lineItem"
                                    values={{
                                        label: this.props.intl.formatMessage({
                                            id: 'global.userRoles.contributor',
                                        }),
                                    }}
                                />
                                <Bold>{users.get('contributors') || 0}</Bold>
                            </Indented>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Indented>
                                <FormattedMessage
                                    id="containers.StatisticsPage.lineItem"
                                    values={{
                                        label: this.props.intl.formatMessage({
                                            id: 'global.userRoles.translator',
                                        }),
                                    }}
                                />
                                <Bold>{users.get('translators') || 0}</Bold>
                            </Indented>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Indented>
                                <FormattedMessage
                                    id="containers.StatisticsPage.lineItem"
                                    values={{
                                        label: this.props.intl.formatMessage({
                                            id: 'global.userRoles.reviewer',
                                        }),
                                    }}
                                />
                                <Bold>{users.get('reviewers') || 0}</Bold>
                            </Indented>
                        </td>
                    </tr>
                </tbody>
            </Table>
        );
    };

    render() {
        const { statistic } = this.props;

        return (
            <div>
                <Helmet>
                    <title>
                        {this.props.intl.formatMessage({
                            id: 'containers.StatisticPage.helmetTitle',
                        })}
                    </title>
                    <meta
                        name="description"
                        content={this.props.intl.formatMessage({
                            id: 'containers.StatisticPage.pageTitle',
                        })}
                    />
                </Helmet>
                <ControlPanel resourceTitleId={`containers.StatisticsPage.title`} slim />
                <LineBreak />
                {this.renderPeriodSelector()}
                {this.renderResultsTable()}
                {statistic && (
                    <Indented>
                        <SectionHeader margin="30px 0 15px">
                            {this.props.intl.formatMessage({
                                id: 'containers.StatisticsPage.history',
                            })}
                        </SectionHeader>
                    </Indented>
                )}
                {this.renderMediaTypeTable(
                    'containers.StatisticsPage.mediaTableHeader',
                    'containers.StatisticsPage.mediaTotal',
                    statistic && statistic.get('media')
                )}
                <LineBreak />
                {this.renderMediaTypeTable(
                    'containers.StatisticsPage.revisionTableHeader',
                    'containers.StatisticsPage.revisionTotal',
                    statistic && statistic.get('revisions')
                )}
                <LineBreak />
                {this.renderMediaTypeTable(
                    'containers.StatisticsPage.translationTableHeader',
                    'containers.StatisticsPage.translationTotal',
                    statistic && statistic.get('translations')
                )}
                <LineBreak />
                {this.renderCollectionTable()}
                <LineBreak />
                {this.renderUserTable()}
                <LineBreak height="40px" />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) =>
    createStructuredSelector({
        statistic: statisticResource()
            .selectors()
            .selectCounts(),
        statisticByDate: statisticResource()
            .selectors()
            .selectByDate(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            getStatistic: statisticResource().thunks().fetchCounts,
            getStatisticByDate: statisticResource().thunks().fetchByDate,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(StatisticsPage));
