// @flow

import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

import Routes from 'routes';

// Components
import Header from 'components/Header';

// Services
import { selectUser } from 'services/Authentication/selectors';
import { changeLocale } from 'services/Language/actions';
import { selectLocale } from 'services/Language/selectors';
import { whoami, logoutUser } from 'services/Authentication/thunks';

// Styles
import { AppWrapper } from 'styles/common';

// Types
import type { ImmutableMap, ReduxDispatch, UserType } from 'types';
import type { WhoamiType, LogoutUserType } from 'services/Authentication/thunks';

// Utils
import { GOOGLE_API_KEY } from 'env';

type Props = {
    changeLocale: () => void,
    locale: string,
    logoutUser: LogoutUserType,
    user: ?ImmutableMap<string, UserType>,
    whoami: WhoamiType,
};

/**
 * App's container with defined routes
 */

class App extends React.PureComponent<Props> {
    componentDidMount() {
        /**
         * If a user api token exists we'll retrieve the user by dispatching
         * the whoami action and store the user in the global state
         */
        if (localStorage.getItem('api_token')) {
            this.props.whoami();
        }
    }

    render() {
        return (
            <AppWrapper>
                <Helmet titleTemplate="%s - CCDMD - Médiathèque" defaultTitle="CCDMD - Médiathèque">
                    <meta name="description" content="CCDMD - Médiathèque" />
                    <script
                        src={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`}
                    />
                </Helmet>
                <Routes
                    user={this.props.user}
                    locale={this.props.locale}
                    handleToggleLocale={this.props.changeLocale}
                    onLogout={this.props.logoutUser}
                />
            </AppWrapper>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    locale: selectLocale(),
    user: selectUser(),
});

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            changeLocale,
            whoami,
            logoutUser,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(App)
);
