// @flow

import styled from 'styled-components';

import colors from 'styles/colors';

import { FlexRowContainer } from 'styles/common';

export const Container = styled.div`
    position: relative;
`;

export const ContentContainer = styled.div`
    position: relative;
`;

export const InformationContainer = styled.div`
    position: relative;
`;
