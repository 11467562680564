// @flow

import { injectGlobal } from 'styled-components';
import colors from 'styles/colors';

// eslint-disable-next-line
injectGlobal`
    html,
    body {
        height: 100%;
        width: 100%;
        min-width: 1280px;
        margin: 0;
        padding: 0;
    }

    body {
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    input,
    textarea,
    select,
    button {
        font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    body.fontLoaded {
        font-family: 'Ubuntu', sans-serif;
    }

    #app {
        min-height: 100%;
        min-width: 100%;
    }

    hr {
        border .5px solid ${colors.grayE1};
        margin: 20px 0;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    .mce-notification-warning, .tox-notifications-container {
        display: none;
    }
`;
