// @flow

import pluralize from 'pluralize';
import request from 'request';

/*
 *
 * CRUDResource resources
 *
 * Basic function: call request() with expected paramaters
 *
 */

const crudResources = (resourceName: string) => ({
    /*
    * fetchAll: Fetch all the entries of the resource
    */
    fetchAll: () => request(`${pluralize(resourceName.toLowerCase())}`, { method: 'GET' }),
});

export default crudResources;
