// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { fromJS } from 'immutable';

// Components
import Application from 'components/Application';
import Loading from 'components/Loading';
import ToggleSwitch from 'components/Form/ToggleSwitch';

// Services
import applicationResource from 'services/Application';

// Styles
import { PrimaryButton, SecondaryButton } from 'styles/buttons';
import { LabelSmall, TagList, TagItem } from 'styles/form';
import {
    FlexContainer,
    FlexItemContainer,
    ActionsContainer,
    ModalActionsWrapper,
} from 'styles/common';

// Types
import type { ImmutableMap, InputEvent, ReduxDispatch, ApplicationType, IntlType } from 'types';

// Utils
import { formatDate } from 'utils';

type Props = {
    applicationId?: number,
    application?: ImmutableMap<string, any>,
    createApplication: Function,
    errors: ?ImmutableMap<string, Object>,
    generated?: ImmutableMap<string, any>,
    generateApplication: Function,
    getApplication: Function,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    onModalShow: Function,
    resetErrors: Function,
    updateApplication: Function,
};

type State = {
    application: ?ApplicationType,
    loading: boolean,
};

/**
 * Site Application Form: <Application /> wrapper with model controls
 */

class ApplicationForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            /** Decides whether to display loading animation or not */
            loading: false,
            /** Site Application object, pulls from store or defaults to new application */
            application:
                props.application ||
                fromJS({
                    status: 'INACTIVE',
                    name: null,
                    permission: null,
                    invisibleContent: 'INACTIVE',
                    account: null,
                    key: null,
                }),
        };
    }

    componentDidMount() {
        this.props.resetErrors();
        if (this.props.applicationId && !this.props.application) {
            this.props.getApplication(this.props.applicationId);
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        const { application, generated } = nextProps;

        // Modifying application, set prop.application to state
        if (application) {
            this.setState({
                application,
            });
        }

        // Once handleGenerateApplication() has been called;
        // Get current application from state (constructor) or since edited application;
        const currentApplication = this.state.application;
        // If currentApplication has name, merge in generated account & key
        if (currentApplication && currentApplication.get('name') && generated) {
            // Merge generated application (account & key) into state
            const newApplication = currentApplication.merge(generated);
            this.setState({
                application: newApplication,
            });
        }

        this.setState({
            loading: false,
        });
    }

    handleChangeField = (name: string, isCheckbox: boolean = false) => ({
        target: { value, checked },
    }: InputEvent) => {
        const { application } = this.state;
        if (application) {
            const newApplication = application.setIn([name], isCheckbox ? checked : value);
            this.setState({
                application: newApplication,
            });
        }
    };

    handleGenerateApplication = (event: Event) => {
        event.preventDefault();
        const { application } = this.state;
        const name = application && application.get('name');
        if (application && name) {
            this.props.generateApplication(name);
        }
    };

    handlePermissionsSelect = ({ target: { value } }: InputEvent) => {
        let permissions;
        let permission;
        switch (value) {
            case 'read':
                permissions = {
                    read: true,
                    write: false,
                    delete: false,
                };
                permission = 'read';
                break;
            case 'write':
                permissions = {
                    read: true,
                    write: true,
                    delete: false,
                };
                permission = 'write';
                break;
            case 'delete':
                permissions = {
                    read: true,
                    write: true,
                    delete: true,
                };
                permission = 'delete';
                break;
            default:
                permissions = {
                    read: false,
                    write: false,
                    delete: false,
                };
                permission = null;
        }

        const { application: currentApplicationState } = this.state;
        if (currentApplicationState && permissions) {
            const newApplication = currentApplicationState.merge(
                fromJS({
                    ...permissions,
                    permission,
                    invisibleContent: 'INACTIVE',
                })
            );
            this.setState({
                application: newApplication,
            });
        }
    };

    handleOnSubmit = (event) => {
        this.setState({
            loading: true,
        });

        const { application } = this.state;
        const applicationObj = application && application.toJS();
        if (application && application.has('id')) {
            this.props.updateApplication(this.props.application.get('id'), applicationObj);
        } else {
            this.props.createApplication(applicationObj);
        }

        // Closes model if there are errors and therefore doesn't save.
        // this.props.onModalShow(false)(event);
    };

    handleOnCancel = (event) => {
        if (this.props.onModalShow) {
            this.props.onModalShow(false, true)(event);
        }
    };

    getCurrentPermissions = () => {
        let permission = null;
        if (this.props.application && this.props.application.get('delete')) {
            permission = 'delete';
        } else if (this.props.application && this.props.application.get('write')) {
            permission = 'write';
        } else if (this.props.application && this.props.application.get('read')) {
            permission = 'read';
        }
        return permission;
    };

    getValidationState = (error: ?boolean) => (error ? 'error' : null);

    getProcessedErrorResponseForField = (fieldName: string) => {
        const { errors } = this.props;
        return errors && errors.size && errors.getIn(['data', 'errors', fieldName, '0', 'code']);
    };

    renderInvisibleContentToggle = () => (
        <ToggleSwitch
            value={this.state.application && this.state.application.get('invisibleContent')}
            labelOn={this.props.intl.formatMessage({
                id: 'components.ResourceIndex.Applications.invisibleContentAuthorized',
            })}
            labelOff={this.props.intl.formatMessage({
                id: 'components.ResourceIndex.Applications.invisibleContentNotAuthorized',
            })}
            valueOn={this.props.intl.formatMessage({
                id: 'components.ResourceIndex.Applications.value.invisibleContentAuthorized',
            })}
            valueOff={this.props.intl.formatMessage({
                id: 'components.ResourceIndex.Applications.value.invisibleContentNotAuthorized',
            })}
            onChange={this.handleChangeField('invisibleContent')}
            disabled={false}
        />
    );

    renderPermissionOptions = () => ({
        read: (
            <TagList>
                <TagItem>
                    <FormattedMessage id="components.ResourceIndex.Applications.permissionRead" />
                </TagItem>
            </TagList>
        ),
        write: (
            <TagList>
                <TagItem>
                    <FormattedMessage id="components.ResourceIndex.Applications.permissionRead" />
                </TagItem>
                <TagItem>
                    <FormattedMessage id="components.ResourceIndex.Applications.permissionWrite" />
                </TagItem>
            </TagList>
        ),
        delete: (
            <TagList>
                <TagItem>
                    <FormattedMessage id="components.ResourceIndex.Applications.permissionRead" />
                </TagItem>
                <TagItem>
                    <FormattedMessage id="components.ResourceIndex.Applications.permissionWrite" />
                </TagItem>
                <TagItem>
                    <FormattedMessage id="components.ResourceIndex.Applications.permissionDelete" />
                </TagItem>
            </TagList>
        ),
    });

    render() {
        const { application } = this.state;

        if (
            (this.state.loading && !this.props.errors && this.props.errors.size) ||
            (this.props.applicationId && !this.props.application)
        ) {
            return <Loading />;
        }

        const updatedAt = application && application.get('updatedAt');
        let timestamp = null;
        if (updatedAt) {
            timestamp = formatDate(updatedAt);
        }

        return (
            <div>
                <Application
                    mode={application && application.has('id') ? 'edit' : 'create'}
                    application={application}
                    handleChangeField={this.handleChangeField}
                    handleGenerateApplication={this.handleGenerateApplication}
                    handlePermissionsSelect={this.handlePermissionsSelect}
                    errors={this.props.errors}
                    loading={this.state.loading}
                />
                <ModalActionsWrapper>
                    <FlexContainer justifyContent="space-between" gutter="30">
                        {timestamp ? (
                            <FlexItemContainer direction="column" textAlign="left">
                                <LabelSmall marginless>
                                    <FormattedMessage
                                        id="global.lastUpdate"
                                        values={{ time: timestamp }}
                                    />
                                </LabelSmall>
                            </FlexItemContainer>
                        ) : null}
                        <ActionsContainer flexGrown="1.5">
                            <PrimaryButton onClick={this.handleOnSubmit}>
                                {this.props.intl.formatMessage({
                                    id: 'global.saveChanges',
                                })}
                            </PrimaryButton>
                            <SecondaryButton onClick={this.handleOnCancel}>
                                {this.props.intl.formatMessage({
                                    id: 'global.cancel',
                                })}
                            </SecondaryButton>
                        </ActionsContainer>
                    </FlexContainer>
                </ModalActionsWrapper>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) =>
    createStructuredSelector({
        ...(ownProps && ownProps.applicationId
            ? {
                  application: applicationResource()
                      .selectors()
                      .select(ownProps.applicationId),
              }
            : {}),
        generated: applicationResource()
            .selectors()
            .selectGenerated(),
        errors: applicationResource()
            .selectors()
            .selectErrors(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            createApplication: applicationResource().thunks().create,
            generateApplication: applicationResource().thunks().generate,
            updateApplication: applicationResource().thunks().update,
            getApplication: applicationResource().thunks().fetch,
            resetErrors: applicationResource().thunks().resetErrors,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ApplicationForm));
