// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import type { ImmutableList, ImmutableMap } from 'types';

import Button from 'components/Button';
import { ResourceIndexContainer, ResourceIndexTable } from '../styles';
import SortableTh from 'components/Table/SortableTh';

type DefaultProps = {
    resourceList: ?ImmutableList<ImmutableMap<string, string>>,
    onListItemModifyAction: Function,
    onSortBy: Function,
};

type Props = DefaultProps & {
    currentSorting?: {
        sortBy?: string,
        sortOrder?: string,
    },
};

/**
 * Resource table content for User index
 */

class ResourceUsersIndex extends React.PureComponent<Props> {
    render() {
        const thead = (
            <tr>
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Users.tableHeaderFieldName"
                    name="firstName"
                    onClick={this.props.onSortBy}
                />
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Users.tableHeaderFieldStatus"
                    name="status"
                    onClick={this.props.onSortBy}
                />
                <th>
                    <FormattedMessage id="components.ResourceIndex.Users.tableHeaderFieldActions" />
                </th>
            </tr>
        );

        const list =
            this.props.resourceList &&
            this.props.resourceList.map((singleItem: ImmutableMap<string, string>) => (
                <tr key={singleItem.get('id')}>
                    <td>{`${singleItem.get('firstName')} ${singleItem.get('lastName')}`}</td>
                    <td>
                        <FormattedMessage
                            id={`components.User.status.${
                                singleItem.get('status')
                                    ? singleItem.get('status').toLowerCase()
                                    : ''
                            }.short`}
                        />{' '}
                    </td>
                    <td>
                        <Button onClick={this.props.onListItemModifyAction(singleItem)}>
                            <FormattedMessage id="components.ResourceManagement.actionsModify" />
                        </Button>
                    </td>
                </tr>
            ));

        return (
            <ResourceIndexContainer>
                <ResourceIndexTable>
                    <thead>{thead}</thead>
                    <tbody>{list}</tbody>
                </ResourceIndexTable>
            </ResourceIndexContainer>
        );
    }
}

export default ResourceUsersIndex;
