// @flow

import React from 'react';

// Styles
import { StyledThumbnail } from './styles';

// types
import type { ReactNode } from 'types';

type Props = {
    alt?: string,
    children?: ReactNode,
    src?: string,
    square?: boolean,
};

/**
 * Shared thumbnail component
 */

const Thumbnail = ({ alt, children, src, square, ...rest }: Props) => {
    let inner;
    if (src) {
        inner = <img src={src} alt={alt} />;
    } else if (children) {
        inner = children;
    }

    return inner ? (
        <StyledThumbnail placeHolder={!src} square={square} {...rest}>
            {inner}
        </StyledThumbnail>
    ) : null;
};

Thumbnail.defaultProps = {
    alt: '',
    children: null,
    src: '',
    square: false,
};

export default Thumbnail;
