// @flow
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { fromJS } from 'immutable';
import moment from 'moment';

// Components
import Loading from 'components/Loading';
import SectionTabs from 'components/SectionTabs';
import SubSectionTitle from 'components/SubSectionTitle';
import ToggleSwitchBoolean from 'components/Form/ToggleSwitchBoolean';
import { ReverseSpan } from 'styles/buttons';

// Form Blocks
import BasicInformationBlock from 'components/Forms/BasicInformationBlock';
import InternalNotesBlock from 'components/Forms/InternalNotesBlock';
import CollectionLanguageBlock from 'components/Forms/CollectionLanguageBlock';
import CollectionOrderBlock from 'components/Forms/CollectionOrderBlock';
import CollectionThumbnailBlock from 'components/Forms/CollectionThumbnailBlock';
import ItunesUBlock from 'components/Forms/ItunesUBlock';
import MediaPrivacyBlock from 'components/Forms/MediaPrivacyBlock';
import NormeticMetaDataBlock from 'components/Forms/NormeticMetaDataBlock';
import RelatedCollectionBlock from 'components/Forms/RelatedCollectionBlock';
import RepeatableAuthorBlock from 'components/Forms/RepeatableAuthorBlock';
import RepeatableCollaboratorBlock from 'components/Forms/RepeatableCollaboratorBlock';
import RepeatableEditorBlock from 'components/Forms/RepeatableEditorBlock';
import RevisionTranslationBlock from 'components/Forms/RevisionTranslationBlock';

// Services
import { selectUser } from 'services/Authentication/selectors';
import collectionResource from 'services/Collection';
import categoryResource from 'services/Category';
import normeticDisciplineResource from 'services/NormeticDiscipline';

// Styles
import { SectionHeader } from 'styles/form';
import { FlexContainer, FlexItemContainer, Indented, WhiteBlock } from 'styles/common';
import { CollectionContainer } from './styles';

// Types
import type {
    ImmutableMap,
    IntlType,
    ReduxDispatch,
    CollectionType,
    CategoryType,
    NormeticDisciplinesType,
    UserType,
} from 'types';

// Utils
import { createFormData } from 'utils';
import { isAdminOrAbove } from 'utils/authentication';

type DefaultProps = {
    collection?: ImmutableMap<string, mixed>,
    onUpdateRouting?: string,
};

type Props = DefaultProps & {
    categories: ImmutableMap<string, CategoryType>,
    collectionId: ?number,
    createCollection: Function,
    deleteCollection: Function,
    deleteNew: Function,
    errors: ?ImmutableMap<string, Object>,
    getCategories: Function,
    getCollection: Function,
    getNormeticDisciplines: Function,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    normeticDisciplines?: ImmutableMap<string, NormeticDisciplinesType>,
    onDelete: Function,
    onRef: Function,
    resetErrors: Function,
    updateCollection: Function,
    user: ?ImmutableMap<string, UserType>,
};

type State = {
    loading: boolean,
    collection: ?CollectionType,
    selectedTabItemId: string,
};

/**
 * Collection create/edit/delete form
 */

class Collection extends React.Component<Props, State> {
    static defaultProps: DefaultProps = {
        collection: null,
        onUpdateRouting: '',
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            collection:
                props.collection ||
                fromJS({
                    contributorId: props.user && props.user.get('id'),
                    deleteContent: false,
                    isPrivate: false,
                    isPublished: false,
                    isVisible: false,
                    itunesUIsPublished: false,
                    itunesUExplicitContent: false,
                    metadataNormeticIsPublished: false,
                    creationDateDay: moment().format('DD'),
                    creationDateMonth: moment().format('MM'),
                    creationDateYear: moment().format('YYYY'),
                }),
            loading: false,
            selectedTabItemId: 'general',
        };
    }

    componentDidMount() {
        this.props.resetErrors();

        const { categories, collection, collectionId, normeticDisciplines } = this.props;

        if (!categories || (categories && categories.isEmpty())) {
            this.props.getCategories();
        }

        if (collectionId && !collection) {
            this.props.getCollection(collectionId);
        }

        // Retrieve normetic disciples values
        if (!normeticDisciplines || (normeticDisciplines && normeticDisciplines.isEmpty())) {
            this.props.getNormeticDisciplines();
        }

        this.props.onRef(this);
    }

    componentWillReceiveProps(nextProps: Props) {
        const { collection } = nextProps;

        if (collection && collection !== this.props.collection) {
            this.setState({
                collection,
            });
        }

        this.setState({
            loading: false,
        });
    }

    handleDelete = () => {
        const { collection } = this.props;
        const deleteContent =
            (this.state.collection && this.state.collection.get('deleteContent')) || false;
        if (collection && collection.get('id')) {
            this.props.deleteCollection(collection.get('id'), deleteContent);
        }
    };

    handleChangeRepeatableField = (name: string, map: ImmutableMap<string, mixed>) => {
        const { collection } = this.state;
        if (collection) {
            const newCollection = collection.setIn([name], map);
            this.setState({
                collection: newCollection,
            });
        }
    };

    handleTabItemClick = (key: string) => () => {
        this.setState({
            selectedTabItemId: key,
        });
    };

    handleChangeField = (name: string, isCheckbox: boolean = false) => (event: Event) => {
        const { collection } = this.state;
        let value = null;
        if (event.target) {
            if (event.target.files) {
                value = event.target.files[0];
            } else if (typeof event.target.value !== 'undefined') {
                value = event.target.value;
            } else if (event.target.innerHTML) {
                value = event.target.innerHTML;
            } else if (typeof event === 'string') {
                value = event;
            }
        }

        if (collection) {
            const newCollection = collection.setIn(
                [name],
                isCheckbox ? event.target.checked : value
            );
            this.setState({
                collection: newCollection,
            });
        }
    };

    handleChangeFieldFromStateMultiple = (obj: Object) => {
        const { collection } = this.state;
        if (collection) {
            const newCollection = collection.merge(obj);
            this.setState({
                collection: newCollection,
            });
        }
    };

    handleChangeFieldFromState = (name: string, value: any) => {
        const { collection } = this.state;
        if (collection) {
            const newCollection = collection.setIn([name], value);
            this.setState({
                collection: newCollection,
            });
        }
    };

    handleOnCancel = (event: Event) => {
        event.preventDefault();
        // Delete new entity within store
        this.props.deleteNew();
        this.setState({
            collection: null,
        });
    };

    handleOnSubmitForm = (closeModal: boolean = true) => (event: Event) => {
        event.preventDefault();

        this.setState({
            loading: true,
        });

        const id = this.props.collection && this.props.collection.get('id');
        if (this.state.collection) {
            const formData = createFormData(this.state.collection);
            if (id) {
                this.props.updateCollection(id, formData, this.props.onUpdateRouting, closeModal);
            } else {
                this.props.createCollection(formData, this.props.onUpdateRouting, closeModal);
            }
        }
    };

    getValidationState = (error: ?boolean) => (error ? 'error' : null);

    getProcessedErrorResponseForField = (fieldName: string) => {
        const { errors } = this.props;
        return errors && errors.size && errors.getIn(['data', 'errors', fieldName, '0', 'code']);
    };

    renderCollectionsSearchResults = () => {
        const { collection } = this.state;
        return collection;
    };

    renderTabContent = () => {
        const { collectionId, errors, user } = this.props;
        const { collection, loading } = this.state;
        const deleteContent = (collection && collection.get('deleteContent')) || false;
        const role = user && user.get('role');

        let content;
        switch (this.state.selectedTabItemId) {
            default:
            case 'general':
                content = (
                    <Indented>
                        <SubSectionTitle
                            text={this.props.intl.formatMessage({
                                id: 'components.Collection.collectionInformation',
                            })}
                        />
                        <BasicInformationBlock
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            handleValidationState={this.getValidationState}
                            isLoading={loading}
                            onChangeField={this.handleChangeField}
                            media={collection}
                        />
                        <hr />
                        <CollectionLanguageBlock
                            data={collection}
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            handleValidationState={this.getValidationState}
                            isLoading={loading}
                            onChangeField={this.handleChangeField}
                        />
                        <hr />
                        <RelatedCollectionBlock
                            isLoading={loading}
                            onChangeFieldFromState={this.handleChangeFieldFromState}
                            data={collection}
                            parent={collectionId}
                            collectionListFetchAction={
                                collectionResource().thunks()
                                    .fetchSecondaryPaginatedBySearchCriteria
                            }
                            collectionListSelector={collectionResource()
                                .selectors()
                                .selectSecondaryPaginatedBySearchCriteria()}
                            collectionListIsFetchingSelector={collectionResource()
                                .selectors()
                                .selectSecondaryPaginatedIsFetching()}
                        />
                        <hr />
                        <CollectionThumbnailBlock
                            isLoading={loading}
                            onChangeField={this.handleChangeFieldFromState}
                            data={collection}
                        />
                        <SectionHeader>
                            <FormattedMessage id="components.Collection.mediaOrder" />
                        </SectionHeader>
                        <CollectionOrderBlock
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            isLoading={loading}
                            onChangeField={this.handleChangeField}
                            data={collection}
                        />
                        <SectionHeader>
                            <FormattedMessage id="components.Collection.contributorInformation" />
                        </SectionHeader>
                        <RepeatableAuthorBlock
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            handleValidationState={this.getValidationState}
                            isLoading={loading}
                            onChangeField={this.handleChangeRepeatableField}
                            media={collection}
                        />
                        <hr />
                        <RepeatableEditorBlock
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            handleValidationState={this.getValidationState}
                            isLoading={loading}
                            onChangeField={this.handleChangeRepeatableField}
                            media={collection}
                        />
                        <hr />
                        <RepeatableCollaboratorBlock
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            handleValidationState={this.getValidationState}
                            isLoading={loading}
                            onChangeField={this.handleChangeRepeatableField}
                            media={collection}
                        />
                    </Indented>
                );
                break;
            case 'technical':
                content = (
                    <React.Fragment>
                        <Indented>
                            <SubSectionTitle
                                text={this.props.intl.formatMessage({
                                    id: 'components.Collection.normeticMetadata',
                                })}
                            >
                                <ToggleSwitchBoolean
                                    labelOn="components.Collection.isPublished.active"
                                    labelOff="components.Collection.isPublished.inactive"
                                    name="metadataNormeticIsPublished"
                                    onChange={this.handleChangeField(
                                        'metadataNormeticIsPublished',
                                        true
                                    )}
                                    right
                                    value={
                                        this.props.collection &&
                                        this.props.collection.get('metadataNormeticIsPublished')
                                    }
                                />
                            </SubSectionTitle>
                            <NormeticMetaDataBlock
                                errors={errors}
                                handleErrors={this.getProcessedErrorResponseForField}
                                isLoading={loading}
                                onChangeField={this.handleChangeField}
                                onChangeFieldFromState={this.handleChangeFieldFromState}
                                data={collection}
                                normeticDisciplines={this.props.normeticDisciplines}
                            />
                            <SubSectionTitle
                                text={this.props.intl.formatMessage({
                                    id: 'components.Collection.iTunesU',
                                })}
                            >
                                <ToggleSwitchBoolean
                                    labelOn="components.Collection.isPublished.active"
                                    labelOff="components.Collection.isPublished.inactive"
                                    name="itunesUIsPublished"
                                    onChange={this.handleChangeField('itunesUIsPublished', true)}
                                    right
                                    value={
                                        this.props.collection &&
                                        this.props.collection.get('itunesUIsPublished')
                                    }
                                />
                            </SubSectionTitle>
                        </Indented>
                        <ItunesUBlock
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            isLoading={loading}
                            onChangeField={this.handleChangeField}
                            onChangeFieldFromState={this.handleChangeFieldFromState}
                            data={collection}
                        />
                    </React.Fragment>
                );
                break;
            case 'options':
                content = (
                    <React.Fragment>
                        <Indented>
                            <SectionHeader>
                                <FormattedMessage id="components.Collection.fileParameters" />
                            </SectionHeader>
                        </Indented>
                        <RevisionTranslationBlock
                            data={collection}
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            isLoading={loading}
                            onChangeFieldFromState={this.handleChangeFieldFromState}
                            onChangeFieldFromStateMultiple={this.handleChangeFieldFromStateMultiple}
                            onChangeField={this.handleChangeField}
                            user={user}
                            {...this.props}
                        />
                        <MediaPrivacyBlock
                            collection
                            data={collection}
                            errors={errors}
                            handleErrors={this.getProcessedErrorResponseForField}
                            isLoading={loading}
                            onChangeField={this.handleChangeField}
                            user={user}
                        />
                        <Indented>
                            <InternalNotesBlock
                                errors={errors}
                                handleErrors={this.getProcessedErrorResponseForField}
                                isLoading={loading}
                                onChangeField={this.handleChangeField}
                                media={collection}
                                collection
                            />
                            <hr />
                        </Indented>
                        <WhiteBlock>
                            <Indented>
                                <FlexContainer justifyContent="space-between" gutter="20">
                                    <FlexItemContainer
                                        direction="column"
                                        justifyContent="center"
                                        collapsed
                                    >
                                        <ReverseSpan
                                            onClick={
                                                role && isAdminOrAbove(role)
                                                    ? this.props.onDelete
                                                    : null
                                            }
                                            disabled={!role || (role && !isAdminOrAbove(role))}
                                        >
                                            <FormattedMessage id="components.Collection.delete.action" />
                                        </ReverseSpan>
                                    </FlexItemContainer>
                                    <FlexItemContainer
                                        direction="column"
                                        justifyContent="center"
                                        textAlign="right"
                                    >
                                        <ToggleSwitchBoolean
                                            disabled={!role || (role && !isAdminOrAbove(role))}
                                            labelOn="components.Collection.deleteContent"
                                            labelOff="components.Collection.deleteContent"
                                            name="deleteContent"
                                            onChange={this.handleChangeField('deleteContent', true)}
                                            value={deleteContent}
                                        />
                                    </FlexItemContainer>
                                </FlexContainer>
                            </Indented>
                        </WhiteBlock>
                    </React.Fragment>
                );
                break;
        }
        return content;
    };

    render() {
        const { user } = this.props;
        const role = user && user.get('role');

        if (
            (this.state.loading && !this.props.errors.size) ||
            (this.props.collectionId && !this.props.collection)
        ) {
            return <Loading />;
        }

        return (
            <CollectionContainer>
                <form>
                    <SectionTabs
                        onItemClick={this.handleTabItemClick}
                        selectedTabItemId={this.state.selectedTabItemId}
                        tabs={fromJS([
                            {
                                id: 'general',
                                string: 'components.Collection.tabGeneralInformation',
                            },
                            {
                                id: 'technical',
                                string: 'components.Collection.tabTechnicalDetails',
                            },
                            {
                                id: 'options',
                                string: 'components.Collection.tabOptions',
                            },
                        ])}
                    >
                        <ToggleSwitchBoolean
                            disabled={!role || (role && !isAdminOrAbove(role))}
                            labelOn="components.Collection.isPublished.active"
                            labelOff="components.Collection.isPublished.inactive"
                            name="isPublished"
                            onChange={this.handleChangeField('isPublished', true)}
                            value={
                                this.props.collection && this.props.collection.get('isPublished')
                            }
                        />
                    </SectionTabs>
                    {this.renderTabContent()}
                </form>
            </CollectionContainer>
        );
    }
}

const mapStateToProps = (state, ownProps) =>
    createStructuredSelector({
        categories: categoryResource()
            .selectors()
            .selectAll(),
        deleted: collectionResource()
            .selectors()
            .selectDeleted(),
        errors: collectionResource()
            .selectors()
            .selectErrors(),
        collection: ownProps.collectionId
            ? collectionResource()
                  .selectors()
                  .select(ownProps.collectionId)
            : collectionResource()
                  .selectors()
                  .selectNew(),
        normeticDisciplines: normeticDisciplineResource()
            .selectors()
            .selectAll(),
        user: selectUser(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            createCollection: collectionResource().thunks().create,
            deleteCollection: collectionResource().thunks().delete,
            deleteNew: collectionResource().thunks().deleteNew,
            getAllCollection: collectionResource().thunks().fetchAll,
            getCategories: categoryResource().thunks().fetchAll,
            getCollection: collectionResource().thunks().fetch,
            getNormeticDisciplines: normeticDisciplineResource().thunks().fetchAll,
            resetErrors: collectionResource().thunks().resetErrors,
            updateCollection: collectionResource().thunks().update,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(Collection));
