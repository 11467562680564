// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Input from 'components/Form/Input';

// Styles
import { FormGroup, Label } from 'styles/form';
import { FlexContainer, FlexItemContainer } from 'styles/common';

// Types
import type { ImmutableMap } from 'types';

type Props = {
    onChangeField: Function,
    isLoading: boolean,
    media?: ImmutableMap<string, mixed>,
};

export default class VideoMetaDataBlock extends React.PureComponent<Props> {
    render() {
        return (
            <React.Fragment>
                <FlexContainer justifyContent="space-between" gutter="20">
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="videoProcessingStatus">
                                <FormattedMessage id="components.Media.inputLabelValueVideoProcessingStatus" />
                            </Label>
                            <Input
                                name="videoProcessingStatus"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={this.props.onChangeField('videoProcessingStatus')}
                                disabled={this.props.isLoading}
                                value={
                                    this.props.media &&
                                    this.props.media.get('videoProcessingStatus')
                                }
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="videoBitrate">
                                <FormattedMessage id="components.Media.inputLabelValueVideoBitrate" />
                            </Label>
                            <Input
                                name="videoBitrate"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={this.props.onChangeField('videoBitrate')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('videoBitrate')}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
                <FlexContainer justifyContent="space-between" gutter="20">
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="videoSpecifications">
                                <FormattedMessage id="components.Media.inputLabelValueVideoSpecifications" />
                            </Label>
                            <Input
                                name="videoSpecifications"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={this.props.onChangeField('videoSpecifications')}
                                disabled={this.props.isLoading}
                                value={
                                    this.props.media && this.props.media.get('videoSpecifications')
                                }
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="audioSpecifications">
                                <FormattedMessage id="components.Media.inputLabelValueAudioSpecifications" />
                            </Label>
                            <Input
                                name="audioSpecifications"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={this.props.onChangeField('audioSpecifications')}
                                disabled={this.props.isLoading}
                                value={
                                    this.props.media && this.props.media.get('audioSpecifications')
                                }
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
                <FlexContainer justifyContent="space-between" gutter="20">
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="ratio">
                                <FormattedMessage id="components.Media.inputLabelValueRatio" />
                            </Label>
                            <Input
                                name="ratio"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={this.props.onChangeField('ratio')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('ratio')}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
            </React.Fragment>
        );
    }
}
