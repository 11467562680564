// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';

// Styles
import { RFFErrorMessageContainer } from './styles';

type Props = {
    name: string,
    fieldIdText: ?string,
};

/**
 * Shared error message component used by final form
 */

function RFFErrorMessage(props: Props) {
    if (!props.name) {
        return null;
    }

    return (
        <RFFErrorMessageContainer>
            <Field
                name={props.name}
                subscription={{ touched: true, error: true }}
                render={({ meta: { touched, error } }) =>
                    touched && error ? (
                        <div>
                            {props.fieldIdText ? (
                                <span>
                                    <FormattedMessage id={props.fieldIdText} />
                                    <span> - </span>
                                </span>
                            ) : null}
                            <FormattedMessage id={error} />
                        </div>
                    ) : null
                }
            />
        </RFFErrorMessageContainer>
    );
}

export default RFFErrorMessage;
