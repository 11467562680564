// @flow

import React from 'react';

// Assets
import caret from 'assets/icon-caret-black.svg';

// Styles
import { PaginationControlsContainer, PaginationNavigationButton, SinglePage } from './styles';
import { Ellipsis } from 'styles/common';

type Props = {
    /** Current page number */
    currentPage: number,
    /** Total page numbers */
    pagesTotal: ?number,
    /** Determines if on selected page */
    onPageSelection: Function,
};

/**
 * Pagination controls, used by PaginationBar
 */

const PaginationControls = (props: Props) => {
    if (!props) {
        return null;
    }

    const maxPagesToDisplay = 3;

    // Create the page range to be displayed based on the page at hand and the total vs the maximum pages to be displayed at once in the pagination control
    const pageRange = [
        ...Array(
            props.pagesTotal <= maxPagesToDisplay ? props.pagesTotal : maxPagesToDisplay
        ).keys(),
    ].map((currentValue: number) => {
        // Cases when there is now overflow of pages or where are viewing the leftmost pages (starting at 1)
        if (
            props.pagesTotal <= maxPagesToDisplay ||
            props.currentPage <= Math.floor(maxPagesToDisplay / 2) + 1
        ) {
            return currentValue + 1;
            // Case when there is overflow of pages and where are viewing the rightmost pages (ending at the very last page)
        } else if (
            props.currentPage >=
            props.pagesTotal -
                Math.floor(maxPagesToDisplay / 2) +
                (maxPagesToDisplay % 2 === 0 ? 1 : 0)
        ) {
            return (
                props.pagesTotal -
                Math.floor(maxPagesToDisplay / 2) +
                currentValue -
                Math.floor(maxPagesToDisplay / 2) +
                (maxPagesToDisplay % 2 === 0 ? 1 : 0)
            );
            // Case when there is overflow of pages and where are viewing a mid-section range of pages (not any of the edges)
        } else {
            return (
                props.currentPage +
                currentValue -
                Math.floor(maxPagesToDisplay / 2) +
                (props.currentPage <= Math.floor(maxPagesToDisplay / 2)
                    ? Math.floor(maxPagesToDisplay / 2)
                    : 0)
            );
        }
    });

    const pageList = pageRange.map((singleItem, index) => (
        <SinglePage
            key={singleItem}
            selected={singleItem === props.currentPage}
            includeSeparator={index !== 0}
            onClick={props.onPageSelection(singleItem, props.pagesTotal)}
        >
            {singleItem}
        </SinglePage>
    ));

    return (
        <PaginationControlsContainer>
            <PaginationNavigationButton
                navigationSense="left"
                onClick={props.onPageSelection(props.currentPage - 1, props.pagesTotal)}
            >
                <img src={caret} alt="caret" />
            </PaginationNavigationButton>
            <Ellipsis
                hidden={
                    props.pagesTotal <= maxPagesToDisplay ||
                    props.currentPage <= Math.floor(maxPagesToDisplay / 2) + 1
                }
                paddingRight
            />
            {pageList}
            <Ellipsis
                hidden={
                    props.currentPage >=
                    props.pagesTotal -
                        Math.floor(maxPagesToDisplay / 2) +
                        (maxPagesToDisplay % 2 === 0 ? 1 : 0)
                }
                paddingLeft
            />
            <PaginationNavigationButton
                navigationSense="right"
                onClick={props.onPageSelection(props.currentPage + 1, props.pagesTotal)}
            >
                <img src={caret} alt="caret" />
            </PaginationNavigationButton>
        </PaginationControlsContainer>
    );
};

export default PaginationControls;
