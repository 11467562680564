// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Input from 'components/Form/Input';

// Styles
import { FormGroup, Label, LabelSmall } from 'styles/form';
import { FlexContainer, FlexItemContainer } from 'styles/common';

// Types
import type { ImmutableMap } from 'types';

type Props = {
    onChangeField: Function,
    isLoading: boolean,
    media?: ImmutableMap<string, mixed>,
};

export default class MediaMetaDataBlock extends React.PureComponent<Props> {
    render() {
        return (
            <React.Fragment>
                <FlexContainer justifyContent="space-between" gutter="20">
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <FlexContainer justifyContent="space-between" gutter="20">
                                <FlexItemContainer direction="column" justifyContent="flex-start">
                                    <Label htmlFor="originalSupportFr">
                                        <FormattedMessage id="components.Media.inputLabelValueOriginalSupport" />
                                    </Label>
                                </FlexItemContainer>
                                <FlexItemContainer
                                    direction="column"
                                    justifyContent="flex-end"
                                    textAlign="right"
                                >
                                    <LabelSmall subtle>
                                        <FormattedMessage id="global.french" />
                                    </LabelSmall>
                                </FlexItemContainer>
                            </FlexContainer>
                            <Input
                                name="originalSupportFr"
                                type="text"
                                placeholder={'global.writeHere.fr'}
                                onChange={this.props.onChangeField('originalSupportFr')}
                                disabled={this.props.isLoading}
                                value={
                                    this.props.media && this.props.media.get('originalSupportFr')
                                }
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <FlexItemContainer
                                direction="column"
                                justifyContent="flex-end"
                                textAlign="right"
                            >
                                <LabelSmall subtle>
                                    <FormattedMessage id="global.english" />
                                </LabelSmall>
                            </FlexItemContainer>
                            <Input
                                name="originalSupportEn"
                                type="text"
                                placeholder={'global.writeHere.en'}
                                onChange={this.props.onChangeField('originalSupportEn')}
                                disabled={this.props.isLoading}
                                value={
                                    this.props.media && this.props.media.get('originalSupportEn')
                                }
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
                <FlexContainer justifyContent="space-between" gutter="20">
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="exifExposureTime">
                                <FormattedMessage id="components.Media.inputLabelValueExposureTime" />
                            </Label>
                            <Input
                                name="exifExposureTime"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={this.props.onChangeField('exifExposureTime')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('exifExposureTime')}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="exifExposureTime">
                                <FormattedMessage id="components.Media.inputLabelValueAperture" />
                            </Label>
                            <Input
                                name="exifAperture"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={this.props.onChangeField('exifAperture')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('exifAperture')}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
                <FlexContainer justifyContent="space-between" gutter="20">
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="exifFiletype">
                                <FormattedMessage id="components.Media.inputLabelValueFileType" />
                            </Label>
                            <Input
                                name="exifFiletype"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={this.props.onChangeField('exifFiletype')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('exifFiletype')}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="exifExposureProgram">
                                <FormattedMessage id="components.Media.inputLabelValueExposureProgram" />
                            </Label>
                            <Input
                                name="exifExposureProgram"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={this.props.onChangeField('exifExposureProgram')}
                                disabled={this.props.isLoading}
                                value={
                                    this.props.media && this.props.media.get('exifExposureProgram')
                                }
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
                <FlexContainer justifyContent="space-between" gutter="20">
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="exifCamera">
                                <FormattedMessage id="components.Media.inputLabelValueCamera" />
                            </Label>
                            <Input
                                name="exifCamera"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={this.props.onChangeField('exifCamera')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('exifCamera')}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="exifLens">
                                <FormattedMessage id="components.Media.inputLabelValueLens" />
                            </Label>
                            <Input
                                name="exifLens"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={this.props.onChangeField('exifLens')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('exifLens')}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
                <FlexContainer justifyContent="space-between" gutter="20">
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="exifWhiteBalance">
                                <FormattedMessage id="components.Media.inputLabelValueWhiteBalance" />
                            </Label>
                            <Input
                                name="exifWhiteBalance"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={this.props.onChangeField('exifWhiteBalance')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('exifWhiteBalance')}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="exifLongFocale">
                                <FormattedMessage id="components.Media.inputLabelValueLongFocale" />
                            </Label>
                            <Input
                                name="exifLongFocale"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={this.props.onChangeField('exifLongFocale')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('exifLongFocale')}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
                <FlexContainer justifyContent="space-between" gutter="20">
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="exifResolutionX">
                                <FormattedMessage id="components.Media.inputLabelValueResolutionX" />
                            </Label>
                            <Input
                                name="exifResolutionX"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={this.props.onChangeField('exifResolutionX')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('exifResolutionX')}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="exifResolutionY">
                                <FormattedMessage id="components.Media.inputLabelValueResolutionY" />
                            </Label>
                            <Input
                                name="exifResolutionY"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={this.props.onChangeField('exifResolutionY')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('exifResolutionY')}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
            </React.Fragment>
        );
    }
}
