// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

import { FlexRowGroup } from 'styles/common';

export const BasicHeaderContainer = styled.header`
    width: 100%;
    height: 90px;
    background-color: ${colors.gray15};
    box-shadow: inset 0 -1px 0 0 ${colors.gray33};
`;

export const BasicPageTitle = styled.span`
    line-height: 48px;
    font-size: 40px;
    color: ${colors.white};
    text-align: center;
    margin-left: 20px;
`;

export const IconGroupContainer = FlexRowGroup.extend`
    img:not(:first-child) {
        margin-left: 35px;
    }
`;

export const BrandImage = styled.div`
    height: 70px;

    img {
        max-width: 100%;
        max-height: 100%;
    }
`;

export const HeaderContainer = styled.header`
    width: 100%;
    height: 60px;
    background-color: ${colors.white};
    box-shadow: inset 0 -1px 0 0 ${colors.grayE1};
`;

export const Navbar = styled.nav`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    padding-left: 20px;
    padding-right: 45px;
`;

export const PageTitle = styled.span`
    line-height: 24px;
    font-size: 24px;
    color: ${colors.black};
`;

export const Navigation = styled.div`
    float: right;
`;

export const NavList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;
`;

export const NavItem = styled.li`
    padding: 0 15px;
    display: inline;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }

    a {
        color: ${colors.black};
    }

    > button {
        background-color: inherit;
        border: 0;
        color: ${colors.black};
        text-decoration: underline;
        cursor: pointer;
    }
`;
