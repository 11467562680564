// @flow

import styled from 'styled-components';

import colors from 'styles/colors';

import { FlexRowContainer } from 'styles/common';

export const TabsContainer = styled.div`
    display: flex;
    height: 30px;
    margin-bottom: ${(props) => (props.small ? '0' : '20px')};
    &:not(:first-child) {
        margin-top: 20px;
    }

    ${(props) => {
        let padding = 'padding: ';

        if (props.unpaddedTabs) {
            padding += '0px';
        }

        if (props.small) {
            padding += '0 10px';
        } else {
            padding += '0 20px';
        }

        return `${padding};`;
    }}

    background-color: ${(props) => (props.white ? colors.white : 'transparent')};
    border-bottom: 1px solid ${colors.grayE1};
`;

export const Tabs = FlexRowContainer.extend`
    justify-content: flex-start;
    min-height: 30px;
    font-size: ${(props) => (props.small ? '12px' : '16px')};
    line-height: ${(props) => (props.small ? '28px' : '18px')};
    overflow: auto;
    color: ${colors.gray64};

    & > * {
        margin-right: 10px;
        margin-left: 10px;
    }
    & *:first-child {
        margin-left: 0;

        &::before {
            content: '';
            border-bottom: 1px solid ${colors.grayE1};
        }
    }
    & *:last-child {
        margin-right: 0;

        &::after {
            content: '';
            border-bottom: 1px solid ${colors.grayE1};
        }
    }
`;

export const SingleTab = styled.div`
    cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
    ${(props) =>
        props.selected
            ? `
                border-bottom: 1px solid ${colors.tealDark};
                z-index: 1;
            `
            : `border-bottom: 1px solid ${colors.grayE1};`};
    ${(props) =>
        props.divided &&
        `
            &::before {
                content: '';
                margin-right: 20px;
                border-left: 1px solid ${colors.grayE1};
            }
            `};
`;
