// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Flex, Box } from 'grid-styled';

// Components
import Input from 'components/Form/Input';
import ToggleSwitchBoolean from 'components/Form/ToggleSwitchBoolean';
import ErrorMessage from 'components/ErrorMessage';

// Styles
import { Indented, LineHeightFix, WhiteBlock } from 'styles/common';
import { Label, LabelSmall, LabelSubtle } from 'styles/form';

// Types
import type { ImmutableMap, UserType } from 'types';

// Utils
import { isAdminOrAbove } from 'utils/authentication';

type Props = {
    errors: ImmutableMap<string, any>,
    handleErrors: Function,
    collection?: boolean,
    data?: ImmutableMap<string, mixed>,
    isLoading: boolean,
    onChangeField: Function,
    user?: UserType,
};

class MediaPrivacyBlock extends React.PureComponent<Props> {
    static defaultProps = {
        collection: false,
    };

    render() {
        const { collection, data, isLoading, onChangeField, user } = this.props;

        const isPrivate = data && data.get('isPrivate');
        const password = (isPrivate && data && data.get('password')) || '';
        const role = user && user.get('role');

        return (
            <React.Fragment>
                <WhiteBlock>
                    <Indented>
                        <Flex>
                            <Box width={2 / 10}>
                                <Label
                                    disabled={!role || (role && !isAdminOrAbove(role))}
                                    marginless
                                >
                                    <LineHeightFix>
                                        <FormattedMessage
                                            id={
                                                collection
                                                    ? `components.Collection.inputLabelValueVisiblity`
                                                    : `components.Media.inputLabelValueVisiblity`
                                            }
                                        />
                                    </LineHeightFix>
                                </Label>
                            </Box>
                            <Box width={8 / 10}>
                                <Flex>
                                    <Box py={1} width={3 / 12}>
                                        <ToggleSwitchBoolean
                                            disabled={!role || (role && !isAdminOrAbove(role))}
                                            labelOn={'global.yes'}
                                            labelOff={'global.no'}
                                            name="isVisible"
                                            onChange={onChangeField('isVisible', true)}
                                            onOrOff
                                            value={data && data.get('isVisible')}
                                        />
                                    </Box>
                                    <Box width={9 / 12}>
                                        <LabelSubtle>
                                            <FormattedMessage
                                                id={
                                                    collection
                                                        ? `components.Collection.inputLabelValueVisiblity.notice`
                                                        : `components.Media.inputLabelValueVisiblity.notice`
                                                }
                                            />
                                        </LabelSubtle>
                                    </Box>
                                </Flex>
                            </Box>
                        </Flex>
                    </Indented>
                </WhiteBlock>
                <WhiteBlock>
                    <Indented>
                        <Flex>
                            <Box width={2 / 10}>
                                <Label
                                    disabled={!role || (role && !isAdminOrAbove(role))}
                                    marginless
                                >
                                    <LineHeightFix>
                                        <FormattedMessage id="components.Media.inputLabelValuePrivatisation" />
                                    </LineHeightFix>
                                </Label>
                            </Box>
                            <Box width={8 / 10}>
                                <Flex>
                                    <Box py={1} width={3 / 12}>
                                        <ToggleSwitchBoolean
                                            disabled={!role || (role && !isAdminOrAbove(role))}
                                            labelOn={'global.yes'}
                                            labelOff={'global.no'}
                                            name="isPrivate"
                                            onChange={onChangeField('isPrivate', true)}
                                            onOrOff
                                            value={isPrivate}
                                        />
                                    </Box>
                                    <Box width={9 / 12}>
                                        <Flex>
                                            <Box pr={2} width={4 / 12}>
                                                <Input
                                                    name="password"
                                                    type="text"
                                                    placeholder={
                                                        'components.Media.inputLabelValuePrivatisation.placeholder'
                                                    }
                                                    onChange={onChangeField('password')}
                                                    disabled={
                                                        !isPrivate ||
                                                        isLoading ||
                                                        !role ||
                                                        (role && !isAdminOrAbove(role))
                                                    }
                                                    value={password}
                                                />
                                            </Box>
                                            <Box width={8 / 12}>
                                                <LabelSmall marginless subtle>
                                                    <FormattedMessage
                                                        id={
                                                            collection
                                                                ? `components.Collection.inputLabelValuePrivatisation.notice`
                                                                : `components.Media.inputLabelValuePrivatisation.notice`
                                                        }
                                                    />
                                                </LabelSmall>
                                                <ErrorMessage
                                                    error={
                                                        this.props.handleErrors('password')
                                                            ? this.props.errors.getIn([
                                                                  'data',
                                                                  'errors',
                                                                  'password',
                                                              ])
                                                            : null
                                                    }
                                                    values={{
                                                        attribute:
                                                            'components.Media.inputLabelValuePrivatisation.placeholder',
                                                        value: this.props.intl.formatMessage({
                                                            id:
                                                                'components.Media.inputLabelValuePrivatisation.errorMessage',
                                                        }),
                                                        other: this.props.intl.formatMessage({
                                                            id:
                                                                'components.Media.inputLabelValuePrivatisation',
                                                        }),
                                                    }}
                                                />
                                            </Box>
                                        </Flex>
                                    </Box>
                                </Flex>
                            </Box>
                        </Flex>
                    </Indented>
                </WhiteBlock>
            </React.Fragment>
        );
    }
}

export default injectIntl(MediaPrivacyBlock);
