// @flow

import React from 'react';

// Components
import Search from 'components/Form/Search';

// Styles
import { Actions, ControlPanelContainer, Toolbar } from './styles';

// Types
import type { ReactNode } from 'types';

type Props = {
    controls: ?ReactNode,
    onSearch: ?Function,
};

/**
 * Control Panel Variation (Single Collection View) component with controls
 */

export default class ControlPanelCollectionContent extends React.PureComponent<Props> {
    render() {
        return (
            <ControlPanelContainer paddingTop="0px">
                <Toolbar padding="0 20px">
                    {this.props.onSearch && (
                        <Search onSearch={this.props.onSearch} advancedSearch />
                    )}
                    {this.props.controls && <Actions>{this.props.controls}</Actions>}
                </Toolbar>
            </ControlPanelContainer>
        );
    }
}
