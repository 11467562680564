// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Components
import ToggleSwitchBoolean from 'components/Form/ToggleSwitchBoolean';

// Styles
import {
    Container,
    SingleItemContainer,
    AuthorName,
    CreationDate,
    Text,
    ActionsContainer,
} from './styles';
import { ReverseButton } from 'styles/buttons';
import { FlexRowContainer, Line } from 'styles/common';

// Types
import type { ImmutableMap, IntlType } from 'types';

type Props = {
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    comments: ?ImmutableMap<string, Object>,
    updateCommentHandler: Function,
    deleteCommentHandler: Function,
};

type State = {
    editingComment: boolean,
    editingCommentId: ?number,
};

/**
 * Comment edit/delete list
 */

export class Comments extends React.Component<Props, State> {
    state = {
        editingComment: false,
        editingCommentId: null,
    };

    handleCommentPublicationToggleChange = (id, publicationStatus) => () =>
        this.props.updateCommentHandler(id, {
            id,
            isPublished: publicationStatus ? 0 : 1,
        });

    setCommentTextInnerRef = (element, id) => {
        if (!this.textElement) {
            this.textElement = [];
        }

        this.textElement[id] = element;
    };

    handleCommentTextBlur = (id) => (event: Event) => {
        if (this.state.editingComment && event.target.defaultValue !== event.target.value) {
            this.props.updateCommentHandler(id, {
                id,
                text: event.target.value,
            });
        }
        this.setState((prevState: Object) => ({
            editingComment: false,
            editingCommentId: null,
        }));
    };

    handleCommentEditClick = (id) => () => {
        this.setState((prevState: Object) => ({
            editingComment: true,
            editingCommentId: id,
        }));

        this.textElement[id].focus();
    };

    handleCommentDeleteClick = (mediaId, commentId) => () => {
        this.props.deleteCommentHandler(mediaId, commentId);
    };

    render() {
        if (!this.props.comments || this.props.comments.isEmpty()) {
            return <div>{'N/A'}</div>;
        }

        const outputList = this.props.comments.map((singleItem) => (
            <SingleItemContainer key={singleItem.get('id')}>
                <FlexRowContainer justifyContent="space-between">
                    <AuthorName>{singleItem.get('authorName')}</AuthorName>
                    <ToggleSwitchBoolean
                        changeToggleAfterHandlerExecution
                        labelOn={'global.published'}
                        labelOff={'global.notPublished'}
                        name="isPublished"
                        onChange={this.handleCommentPublicationToggleChange(
                            singleItem.get('id'),
                            singleItem.get('isPublished')
                        )}
                        right
                        value={parseInt(singleItem.get('isPublished'))}
                    />
                </FlexRowContainer>
                <Text
                    innerRef={(element) =>
                        this.setCommentTextInnerRef(element, singleItem.get('id'))
                    }
                    onBlur={this.handleCommentTextBlur(singleItem.get('id'))}
                    defaultValue={singleItem.get('text')}
                    readOnly={
                        !this.state.editingComment ||
                        this.state.editingCommentId !== singleItem.get('id')
                    }
                />
                <Line />
                <FlexRowContainer justifyContent="space-between" paddingTop="10px">
                    <CreationDate>
                        {this.props.intl.formatDate(new Date(singleItem.get('createdAt')), {
                            weekday: 'long',
                            year: 'numeric',
                            month: 'long',
                            day: '2-digit',
                        })}
                    </CreationDate>
                    <ActionsContainer>
                        <ReverseButton
                            onClick={this.handleCommentEditClick(singleItem.get('id'))}
                            disabled={this.state.editingComment}
                        >
                            {this.props.intl.formatMessage({
                                id: 'global.edit',
                            })}
                        </ReverseButton>
                        <ReverseButton
                            onClick={this.handleCommentDeleteClick(
                                singleItem.get('mediaId'),
                                singleItem.get('id')
                            )}
                        >
                            {this.props.intl.formatMessage({
                                id: 'global.delete',
                            })}
                        </ReverseButton>
                    </ActionsContainer>
                </FlexRowContainer>
            </SingleItemContainer>
        ));

        return <Container>{outputList}</Container>;
    }
}

export default injectIntl(Comments);
