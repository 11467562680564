// @flow

import React from 'react';

// Styles
import { Square } from './styles';

type Props = {
    src?: string,
    cover?: boolean,
};

/**
 * Shared image (square) component
 */
const SquareImage = ({ cover, src, ...rest }: Props) =>
    src ? <Square image={src} cover={cover} {...rest} /> : null;

SquareImage.defaultProps = {
    cover: false,
    src: '',
};

export default SquareImage;
