// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import ErrorMessage from 'components/ErrorMessage';
import Input from 'components/Form/Input';
import TinyMCEInput from 'components/Form/TinyMCEInput';
import Textarea from 'components/Form/Textarea';
import InputTag from 'components/Form/InputTag';

// Styles
import { FormGroup, Label } from 'styles/form';
import { FlexItemContainer } from 'styles/common';

// Types
import type { ImmutableMap, IntlType } from 'types';

type Props = {
    errors: ImmutableMap<string, any>,
    onChangeField: Function,
    handleErrors: Function,
    handleValidationState: Function,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    isLoading: boolean,
    media?: ImmutableMap<string, mixed>,
};

class BasicInformationBlock extends React.PureComponent<Props> {
    render() {
        return (
            <React.Fragment>
                <FormGroup
                    validationState={this.props.handleValidationState(
                        this.props.handleErrors('titleFr')
                    )}
                >
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <Label htmlFor="title">
                            <FormattedMessage id="components.Media.inputLabelValueTitle" /> *
                        </Label>
                        <InputTag
                            tag={this.props.intl.formatMessage({
                                id: 'global.french',
                            })}
                        >
                            <TinyMCEInput
                                name="titleFr"
                                onChange={this.props.onChangeField('titleFr')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('titleFr')}
                            />
                        </InputTag>
                        <ErrorMessage
                            error={
                                this.props.handleErrors('titleFr')
                                    ? this.props.errors.getIn(['data', 'errors', 'titleFr'])
                                    : null
                            }
                            values={{
                                attribute: 'components.Media.inputLabelValueTitle',
                                values: this.props.intl.formatMessage({
                                    id: 'components.Media.inputLabelValueTitleEnglish',
                                }),
                            }}
                        />
                    </FlexItemContainer>
                </FormGroup>
                <FormGroup
                    validationState={this.props.handleValidationState(
                        this.props.handleErrors('titleEn')
                    )}
                >
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <InputTag
                            tag={this.props.intl.formatMessage({
                                id: 'global.english',
                            })}
                        >
                            <TinyMCEInput
                                name="titleEn"
                                onChange={this.props.onChangeField('titleEn')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('titleEn')}
                            />
                        </InputTag>
                        <ErrorMessage
                            error={
                                this.props.handleErrors('titleEn')
                                    ? this.props.errors.getIn(['data', 'errors', 'titleEn'])
                                    : null
                            }
                            values={{
                                attribute: 'components.Media.inputLabelValueTitle',
                                values: this.props.intl.formatMessage({
                                    id: 'components.Media.inputLabelValueTitleFrench',
                                }),
                            }}
                        />
                    </FlexItemContainer>
                </FormGroup>
                <hr />
                <FormGroup
                    validationState={this.props.handleValidationState(
                        this.props.handleErrors('descriptionFr')
                    )}
                >
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <Label htmlFor="descriptionFr">
                            <FormattedMessage id="components.Media.inputLabelValueDescription" /> *
                        </Label>
                        <InputTag
                            tag={this.props.intl.formatMessage({
                                id: 'global.french',
                            })}
                        >
                            <TinyMCEInput
                                name="descriptionFr"
                                onChange={this.props.onChangeField('descriptionFr')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('descriptionFr')}
                            />
                        </InputTag>
                        <ErrorMessage
                            error={
                                this.props.handleErrors('descriptionFr')
                                    ? this.props.errors &&
                                      this.props.errors.getIn(['data', 'errors', 'descriptionFr'])
                                    : null
                            }
                            values={{
                                attribute: 'components.Media.inputLabelValueDescription',
                                values: this.props.intl.formatMessage({
                                    id: 'components.Media.inputLabelValueDescriptionEnglish',
                                }),
                            }}
                        />
                    </FlexItemContainer>
                </FormGroup>
                <FormGroup
                    validationState={this.props.handleValidationState(
                        this.props.handleErrors('descriptionEn')
                    )}
                >
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <InputTag
                            tag={this.props.intl.formatMessage({
                                id: 'global.english',
                            })}
                        >
                            <TinyMCEInput
                                name="descriptionEn"
                                onChange={this.props.onChangeField('descriptionEn')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('descriptionEn')}
                            />
                        </InputTag>
                        <ErrorMessage
                            error={
                                this.props.handleErrors('descriptionEn')
                                    ? this.props.errors.getIn(['data', 'errors', 'descriptionEn'])
                                    : null
                            }
                            values={{
                                attribute: 'components.Media.inputLabelValueDescription',
                                values: this.props.intl.formatMessage({
                                    id: 'components.Media.inputLabelValueDescriptionFrench',
                                }),
                            }}
                        />
                    </FlexItemContainer>
                </FormGroup>
                <hr />
                <FormGroup
                    validationState={this.props.handleValidationState(
                        this.props.handleErrors('keywordsFr')
                    )}
                >
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <Label htmlFor="keywords">
                            <FormattedMessage id="components.Media.inputLabelValueKeywords" /> *
                        </Label>
                        <InputTag
                            tag={this.props.intl.formatMessage({
                                id: 'global.french',
                            })}
                        >
                            <Textarea
                                name="keywordsFr"
                                placeholder="global.writeHere.fr"
                                onChange={this.props.onChangeField('keywordsFr')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('keywordsFr')}
                            />
                        </InputTag>
                        <ErrorMessage
                            error={
                                this.props.handleErrors('keywordsFr')
                                    ? this.props.errors.getIn(['data', 'errors', 'keywordsFr'])
                                    : null
                            }
                            values={{
                                attribute: 'components.Media.inputLabelValueKeywords',
                                values: this.props.intl.formatMessage({
                                    id: 'components.Media.inputLabelValueKeywordsEnglish',
                                }),
                            }}
                        />
                    </FlexItemContainer>
                </FormGroup>
                <FormGroup
                    validationState={this.props.handleValidationState(
                        this.props.handleErrors('keywordsEn')
                    )}
                >
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <InputTag
                            tag={this.props.intl.formatMessage({
                                id: 'global.english',
                            })}
                        >
                            <Textarea
                                name="keywordsEn"
                                placeholder="global.writeHere.en"
                                onChange={this.props.onChangeField('keywordsEn')}
                                disabled={this.props.isLoading}
                                value={this.props.media && this.props.media.get('keywordsEn')}
                            />
                        </InputTag>
                        <ErrorMessage
                            error={
                                this.props.handleErrors('keywordsEn')
                                    ? this.props.errors.getIn(['data', 'errors', 'keywordsEn'])
                                    : null
                            }
                            values={{
                                attribute: 'components.Media.inputLabelValueKeywords',
                                values: this.props.intl.formatMessage({
                                    id: 'components.Media.inputLabelValueKeywordsFrench',
                                }),
                            }}
                        />
                    </FlexItemContainer>
                </FormGroup>
            </React.Fragment>
        );
    }
}

export default injectIntl(BasicInformationBlock);
