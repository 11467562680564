// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { fromJS, Map } from 'immutable';

// Components
import Button from 'components/Button';
import Checkbox from 'components/Form/Checkbox';
import CustomTh from 'components/Table/CustomTh';
import Image from 'components/Image';
import SortableTh from 'components/Table/SortableTh';
import Thumbnail from 'components/Image/Thumbnail';

// Services
import mediaResource from 'services/Media';

// Styles
import { Table, Tr, Th, Td } from 'styles/common';
import { ResourceIndexContainer, StyledContentEditable } from '../styles';

// Types
import type { ImmutableList, ImmutableMap, ReduxDispatch } from 'types';

// Utils
import {
    formatDate,
    getReviewStatus,
    getTranslationStatus,
    sanitizeString,
    sentenceCase,
} from 'utils';

import { displayTypeOptions } from 'utils/constants';

type DefaultProps = {
    getMimetype: Function,
    mimetype: ?ImmutableList<ImmutableMap<string, string>>,
    onSortBy: Function,
    resourceList: ?ImmutableList<ImmutableMap<string, string>>,
};

type Props = DefaultProps & {
    currentSorting?: {
        sortBy?: string,
        sortOrder?: string,
    },
    customColumns?: {
        typeDates: number,
        languages: number,
    },
    onCustomColumnChange?: Function,
    order?: string,
    onListItemModifyAction?: Function,
    onSelectItemsToBeDeleted?: Function,
    onSetMediaOrder?: Function,
};

type State = {
    checkAll: boolean,
    customColumns?: {
        typeDates: number,
    },
    selectedItemsToDelete: ?ImmutableList<ImmutableMap<string, string>>,
    order: string | null,
};

/**
 * Resource table content for Media index
 */

class ResourceMediaIndex extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            checkAll: true,
            order: null,
            selectedItemsToDelete: fromJS([]),
            customColumns: props.customColumns,
        };
    }

    componentWillMount() {
        if (!this.props.mimetype) {
            this.props.getMimetype();
        }
        if (this.props.order) {
            this.setState({
                order: JSON.parse(this.props.order),
            });
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.resourceList) {
            this.setState({ updating: false });
        }
    }

    handleSelectToDelete = (event: Event) => {
        event.persist();
        const { value } = event.target;
        const { selectedItemsToDelete } = this.state;
        if (selectedItemsToDelete) {
            const indexOfItemToDelete = selectedItemsToDelete.findIndex(
                (item: number) => item === value
            );
            const newSelectedItemsToDelete =
                indexOfItemToDelete !== -1
                    ? selectedItemsToDelete.delete(indexOfItemToDelete)
                    : selectedItemsToDelete.push(value);
            this.setState(
                {
                    selectedItemsToDelete: newSelectedItemsToDelete,
                },
                () =>
                    this.props.onSelectItemsToBeDeleted &&
                    this.props.onSelectItemsToBeDeleted(this.state.selectedItemsToDelete)
            );
        }
    };

    handleCheckAllToDelete = (checkAll: boolean) => () => {
        if (!checkAll) {
            this.setState(
                {
                    checkAll: true,
                    selectedItemsToDelete: fromJS([]),
                },
                () =>
                    this.props.onSelectItemsToBeDeleted &&
                    this.props.onSelectItemsToBeDeleted(this.state.selectedItemsToDelete)
            );
        } else {
            const { resourceList } = this.props;
            const newlySelectedItemsToDelete = [];
            if (resourceList) {
                resourceList.map((singleItem: ImmutableMap<string, string>) =>
                    newlySelectedItemsToDelete.push(singleItem.get('id').toString())
                );
                this.setState(
                    {
                        checkAll: false,
                        selectedItemsToDelete: fromJS(newlySelectedItemsToDelete),
                    },
                    () =>
                        this.props.onSelectItemsToBeDeleted &&
                        this.props.onSelectItemsToBeDeleted(this.state.selectedItemsToDelete)
                );
            }
        }
    };

    // Save customColumn to state, send to parent for persistence
    // shouldFetch: sortedByCustomOption based on currentSorting.sortBy === typeDatesOptions.key
    handleCustomColumn = (name: number | string) => ({ target: { value } }: InputEvent) => {
        const { onCustomColumnChange } = this.props;
        let options;
        if (name === 'typeDates') {
            options = this.renderTypeDatesColumnOptions();
        } else if (name === 'languages') {
            options = this.renderLanguagesColumnOptions();
        }
        const sortedByCustomOption =
            options &&
            !options
                .filter(
                    (option: ImmutableMap<string, string>) =>
                        option.get('key') === this.props.currentSorting.sortBy
                )
                .isEmpty();

        this.setState(
            (prevState: State) => ({
                customColumns: {
                    ...prevState.customColumns,
                    [name]: parseInt(value),
                },
            }),
            () =>
                onCustomColumnChange &&
                onCustomColumnChange(name, parseInt(value), sortedByCustomOption)
        );
    };

    handleOnOrderChange = (mediaId: number) => (event: Event) => {
        const {
            target: { innerHTML, value },
            key,
            type,
        } = event;
        let order = innerHTML || value;
        order = (order && Number(order.replace(/<(?:.|\n)*?>/gm, ''))) || null;
        const isNotUsed =
            !this.props.resourceList.map((media) => media.get('order')).includes(order) ||
            order === null;
        if (
            (key === 'Enter' || type === 'blur') &&
            isNotUsed &&
            this.props.onSetMediaOrder &&
            (!isNaN(order) || order === null) &&
            !this.state.updating
        ) {
            const newEvent = {
                ...event,
                target: {
                    ...event.target,
                    value: order,
                },
            };
            this.setState({ updating: true }, () => {
                document.activeElement.blur();
                this.props.onSetMediaOrder(mediaId)(newEvent);
            });
        } else if (key === 'Enter' && this.props.resourceList) {
            const oldOrder = this.props.resourceList
                .find((item) => item.get('id') === mediaId)
                .get('order');
            document.activeElement.innerHTML = oldOrder;
            document.activeElement.blur();
        }
    };

    renderImage = (image) => <Thumbnail {...image} />;

    // Build out List of options for Custom Column: TypeDates
    renderTypeDatesColumnOptions = () =>
        fromJS([
            {
                id: 1,
                intl: 'components.ResourceIndex.Contents.tableHeaderFieldType',
                key: 'displayType',
                sortable: true,
            },
            {
                id: 2,
                intl: 'components.ResourceIndex.Contents.tableHeaderFieldCreationDate',
                key: 'createdAt',
                sortable: true,
            },
            {
                id: 3,
                intl: 'components.ResourceIndex.Contents.tableHeaderFieldModifiedDate',
                key: 'updatedAt',
                sortable: true,
            },
        ]);

    // Build out List of options for Custom Column: Languages
    renderLanguagesColumnOptions = () =>
        fromJS([
            {
                id: 1,
                intl: 'components.ResourceIndex.Contents.tableHeaderFieldCategory',
                key: 'category',
                sortable: true,
            },
            {
                id: 2,
                intl: 'components.ResourceIndex.Contents.tableHeaderFieldAuthors',
                key: 'authorsFormatted',
                sortable: true,
            },
            {
                id: 3,
                intl: 'components.ResourceIndex.Contents.tableHeaderFieldMediaLanguage',
                key: 'mediaLanguage',
                sortable: true,
            },
            {
                id: 4,
                intl: 'components.ResourceIndex.Contents.tableHeaderFieldCardLanguage',
                key: 'cardLanguage',
                sortable: true,
            },
            {
                id: 5,
                intl: 'components.ResourceIndex.Contents.tableHeaderFieldCountry',
                key: 'locationCountry',
                sortable: true,
            },
            {
                id: 6,
                intl: 'components.ResourceIndex.Contents.tableHeaderFieldRegion',
                key: 'locationRegion',
                sortable: true,
            },
        ]);

    render() {
        if (!this.props.resourceList) {
            return null;
        }
        const { checkAll, customColumns, selectedItemsToDelete } = this.state;

        // First custom column
        const typeDatesColumnOptions = this.renderTypeDatesColumnOptions();
        const selectedTypeDatesColumnOption =
            (customColumns &&
                typeDatesColumnOptions.find(
                    (option: ImmutableMap<string, string>) =>
                        option.get('id') === customColumns.typeDates
                )) ||
            typeDatesColumnOptions.first();

        const selectedTypeDatesColumnKey =
            selectedTypeDatesColumnOption && selectedTypeDatesColumnOption.get('key');

        // Second custom column
        const languagesColumnOptions = this.renderLanguagesColumnOptions();
        const selectedLanguagesColumnOption =
            (customColumns &&
                languagesColumnOptions.find(
                    (option: ImmutableMap<string, string>) =>
                        option.get('id') === customColumns.languages
                )) ||
            languagesColumnOptions.first();

        const selectedLanguagesColumnKey =
            selectedLanguagesColumnOption && selectedLanguagesColumnOption.get('key');

        const thead = (
            <tr>
                {this.props.onSelectItemsToBeDeleted && (
                    <Th padding="5px 0 5px 20px" width="50px">
                        <Checkbox
                            name="selectedMedia"
                            onChange={this.handleCheckAllToDelete(checkAll)}
                            checked={!checkAll}
                            value={checkAll}
                        />
                    </Th>
                )}
                <th>&nbsp;</th>
                {this.state.order &&
                    this.state.order.orderBy === 'custom' && (
                        <SortableTh
                            currentSorting={this.props.currentSorting}
                            id="components.ResourceIndex.Contents.tableHeaderFieldOrder"
                            name="order"
                            onClick={this.props.onSortBy}
                        />
                    )}
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Contents.tableHeaderFieldTitle"
                    name="titleFr"
                    onClick={this.props.onSortBy}
                />
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Contents.tableHeaderFieldId"
                    name="id"
                    onClick={this.props.onSortBy}
                />
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Contents.tableHeaderFieldContributor"
                    name="contributor"
                    onClick={this.props.onSortBy}
                />
                <CustomTh
                    currentSorting={this.props.currentSorting}
                    name="typeDates"
                    onChange={this.handleCustomColumn}
                    onSortBy={this.props.onSortBy}
                    options={this.renderTypeDatesColumnOptions()}
                    selectedId={selectedTypeDatesColumnOption.get('id')}
                />
                <CustomTh
                    currentSorting={this.props.currentSorting}
                    name="languages"
                    onChange={this.handleCustomColumn}
                    onSortBy={this.props.onSortBy}
                    options={this.renderLanguagesColumnOptions()}
                    selectedId={selectedLanguagesColumnOption.get('id')}
                />
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Contents.tableHeaderFieldStatus"
                    name="isPublished"
                    onClick={this.props.onSortBy}
                />
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Contents.tableHeaderFieldTranslated"
                    name="isTranslated"
                    // onClick={this.props.onSortBy} // Temporarily removing sorting due to backend complexity
                />
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Contents.tableHeaderFieldRevised"
                    name="isReviewed"
                    // onClick={this.props.onSortBy} // Temporarily removing sorting due to backend complexity
                />
                {this.props.onListItemModifyAction && (
                    <th>
                        <FormattedMessage id="components.ResourceIndex.Contents.tableHeaderFieldActions" />
                    </th>
                )}
            </tr>
        );

        const list =
            this.props.resourceList &&
            this.props.resourceList.map((singleItem: Object) => {
                if (!singleItem || !Map.isMap(singleItem)) {
                    return null;
                }

                const file = singleItem && singleItem.get('file');
                const mimetype = file && Map.isMap(file) && file.get('mimeType');
                const readableMimetype =
                    (this.props.mimetype && this.props.mimetype.get(mimetype)) || mimetype;

                // Set Custom Column Value
                let typeDatesColumnValue = '';
                if (selectedTypeDatesColumnKey === 'displayType') {
                    // If type, display readableMimetype for user experience or type directly
                    const displayOption = displayTypeOptions.find(
                        (option) => option.key === singleItem.get('displayType')
                    );
                    const formattedDisplayOption = this.props.intl.formatMessage({
                        id: displayOption.intlId,
                    });
                    typeDatesColumnValue = readableMimetype || formattedDisplayOption;
                } else if (
                    selectedTypeDatesColumnKey === 'createdAt' ||
                    selectedTypeDatesColumnKey === 'updatedAt'
                ) {
                    // If createdAt or updatedAt (timestamps), display in proper format
                    typeDatesColumnValue = formatDate(singleItem.get(selectedTypeDatesColumnKey));
                } else if (singleItem && singleItem.has(selectedTypeDatesColumnKey)) {
                    // If key is present in singleItem, display value
                    typeDatesColumnValue = singleItem.get(selectedTypeDatesColumnKey);
                }

                // Set Second Custom Column Value
                let languagesColumnValue = '';
                if (selectedLanguagesColumnKey === 'authors') {
                    languagesColumnValue = singleItem.get('authorsFormatted');
                } else if (singleItem && singleItem.has(selectedLanguagesColumnKey)) {
                    // If key is present in singleItem, display value
                    languagesColumnValue = singleItem.get(selectedLanguagesColumnKey);
                }

                const isChecked =
                    (selectedItemsToDelete &&
                        selectedItemsToDelete.includes(singleItem.get('id').toString())) ||
                    false;

                let title = singleItem.get(`titleFr`) || singleItem.get('titleEn') || '';
                title = sanitizeString(title);

                return (
                    <Tr
                        key={singleItem.get('id')}
                        inactive={singleItem.get('status') === 'INACTIVE'}
                    >
                        {this.props.onSelectItemsToBeDeleted && (
                            <Td padding="5px 0 5px 20px" width="50px">
                                <Checkbox
                                    name="selectedMedia"
                                    onChange={this.handleSelectToDelete}
                                    checked={isChecked}
                                    value={singleItem.get('id')}
                                />
                            </Td>
                        )}
                        <Td padding="5px 0 5px 10px" width="80px">
                            <Link to={`/media/${singleItem.get('id')}`}>
                                <Image
                                    alt={singleItem.get(`titleFr`) || singleItem.get(`titleEn`)}
                                    format="cropped"
                                    id={singleItem.get('id')}
                                    render={this.renderImage}
                                    resource="media"
                                    type={singleItem.get(`type`)}
                                    showPlaceholder={
                                        !singleItem.get('thumbnailId') &&
                                        !singleItem.get('thumbnail')
                                    }
                                    width="70px"
                                />
                            </Link>
                        </Td>
                        {this.state.order &&
                            this.state.order.orderBy === 'custom' && (
                                <td>
                                    <StyledContentEditable
                                        contentEditable
                                        disabled={false}
                                        onChange={this.handleOnOrderChange(singleItem.get('id'))}
                                        onBlur={this.handleOnOrderChange(singleItem.get('id'))}
                                    >
                                        {singleItem.get('order') || ''}
                                    </StyledContentEditable>
                                </td>
                            )}
                        <td>
                            <Link to={`/media/${singleItem.get('id')}`}>{title}</Link>
                        </td>
                        <td>{singleItem.get('id')}</td>
                        <td>
                            {singleItem.get('contributor') ? (
                                `${singleItem.get('contributor').get('firstName')} ${singleItem
                                    .get('contributor')
                                    .get('lastName')}`
                            ) : (
                                <FormattedMessage id="global.threeDash" />
                            )}
                        </td>
                        <Td padding="5px 20px 5px 2px">{typeDatesColumnValue}</Td>
                        <Td padding="5px 20px 5px 2px">{languagesColumnValue}</Td>
                        <td>
                            <FormattedMessage
                                id={
                                    singleItem.get('isPublished')
                                        ? 'components.ResourceIndex.Contents.isPublic'
                                        : 'components.ResourceIndex.Contents.isNotPublic'
                                }
                            />
                        </td>
                        <td>
                            <FormattedMessage id={getTranslationStatus(singleItem)} />
                        </td>
                        <td>
                            <FormattedMessage id={getReviewStatus(singleItem)} />
                        </td>
                        {this.props.onListItemModifyAction && (
                            <td>
                                <Button onClick={this.props.onListItemModifyAction(singleItem)}>
                                    <FormattedMessage id="components.ResourceManagement.actionsModify" />
                                </Button>
                            </td>
                        )}
                    </Tr>
                );
            });

        return (
            <ResourceIndexContainer>
                <Table>
                    <thead>{thead}</thead>
                    <tbody>{list}</tbody>
                </Table>
            </ResourceIndexContainer>
        );
    }
}

const mapStateToProps = () =>
    createStructuredSelector({
        mimetype: mediaResource()
            .selectors()
            .selectReadableMimeTypes(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            getMimetype: mediaResource().thunks().fetchReadableMimeTypes,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ResourceMediaIndex));
