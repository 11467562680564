// @flow

import { fromJS } from 'immutable';
import { crudResourceActionTypes } from './actions';
import type { ReduxActionType } from 'types';

/*
 *
 * CRUDResource reducer
 *
 * crudServiceReducer: Switch statement to set state based on current action type
 *
 */

const crudServiceReducer = (resourceName: string) => (
    state: Object = fromJS({
        list: {},
        errors: {},
    }),
    action: ReduxActionType
) => {
    switch (action.type) {
        case crudResourceActionTypes((resourceName: string)).FETCH_PAGINATED_IS_FETCHING:
            return state.set('paginatedListIsFetching', action.payload.isFetching);
        case crudResourceActionTypes((resourceName: string))
            .FETCH_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS: {
            const paginatedBySearchCriteriaList = action.payload.data;
            return state
                .set('paginatedBySearchCriteriaList', fromJS(paginatedBySearchCriteriaList))
                .set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string))
            .FETCH_PAGINATED_BY_SEARCH_CRITERIA_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        case crudResourceActionTypes((resourceName: string)).FETCH_SUCCESS: {
            const single = action.payload.data;
            return state
                .set('single', fromJS(single))
                .setIn(['list', single.id], fromJS(single))
                .set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string)).FETCH_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        case crudResourceActionTypes((resourceName: string)).CREATE_SUCCESS: {
            const newUser = action.payload.data;
            return state.set('new', fromJS(newUser)).set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string)).CREATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        case crudResourceActionTypes((resourceName: string)).UPDATE_SUCCESS: {
            const updatedSingle = action.payload.data;
            return state
                .set('single', fromJS(updatedSingle))
                .setIn(['list', updatedSingle.id], fromJS(updatedSingle))
                .set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string)).UPDATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        case crudResourceActionTypes((resourceName: string)).RESET_ERRORS:
            return state.set('errors', fromJS({}));
        default:
            return state;
    }
};

export default crudServiceReducer;
