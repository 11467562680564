// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';

// Components
import ErrorMessage from 'components/ErrorMessage';
import FileInput from 'components/Form/FileInput';

// Styles
import { FormGroup, Label } from 'styles/form';

// Types
import type { ImmutableMap } from 'types';

type Props = {
    errors: ImmutableMap<string, any>,
    handleErrors: Function,
    handleValidationState: Function,
    isLoading: boolean,
    media?: ImmutableMap<string, mixed>,
    onChange: Function,
};

export default class VideoChapterBlock extends React.PureComponent<Props> {
    render() {
        const chapter =
            this.props.media && this.props.media.get('chapter') && this.props.media.get('chapter');
        const file = chapter && Map.isMap(chapter) ? chapter.get('file') : null;
        const filename = file && Map.isMap(file) ? file.get('originalFilename') : null;
        return (
            <FormGroup
                validationState={this.props.handleValidationState(
                    this.props.handleErrors(`chapterInvalidFile`)
                )}
            >
                <Label htmlFor="chapter">
                    <FormattedMessage id="components.Media.inputLabelValueChapters" />
                </Label>
                <FileInput
                    buttonId="global.add"
                    name="chapter"
                    onChange={this.props.onChange('chapter')}
                    disabled={this.props.isLoading}
                    gutter={10}
                    filename={filename}
                />
                <ErrorMessage
                    error={
                        this.props.handleErrors(`chapterInvalidFile`)
                            ? this.props.errors.getIn(['data', 'errors', `chapterInvalidFile`])
                            : null
                    }
                    values={{
                        attribute: 'components.Media.inputLabelValueSubTitles',
                        values: 'vtt',
                    }}
                />
            </FormGroup>
        );
    }
}
