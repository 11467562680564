// @flow

import React from 'react';

// Styles
import { Background } from './styles';

type Props = {
    src?: string,
    cover?: boolean,
};

/**
 * Shared background image component
 */

const BackgroundImage = ({ cover, src, ...rest }: Props) =>
    src ? <Background image={src} cover={cover} {...rest} /> : null;

BackgroundImage.defaultProps = {
    cover: false,
    src: '',
};

export default BackgroundImage;
