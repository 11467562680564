// @flow

import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fromJS, Map } from 'immutable';
import pluralize from 'pluralize';

// Components
import CollectionThumbnail from 'components/Image/CollectionThumbnail';
import Checkbox from 'components/Form/Checkbox';
import Image from 'components/Image';
import Loading from 'components/Loading';
import PaginationBar from 'components/ResourceManagement/PaginationBar';
import Search from 'components/Form/Search';
import SectionTitle from 'components/SectionTitle';
import SortableTh from 'components/Table/SortableTh';
import Thumbnail from 'components/Image/Thumbnail';

// Resources
import mediaResource from 'services/Media';

// Styles
import { Table, Tr, Th, Td } from 'styles/common';
import { ReverseLink } from 'styles/buttons';
import { FormGroup } from 'styles/form';

// Types
import type { CollectionType, ImmutableList, ImmutableMap, IntlType, ReduxDispatch } from 'types';

// Utils
import { sanitizeString, sentenceCase } from 'utils';

type Props = {
    collection: ?CollectionType,
    fetchResourceList: Function,
    fetchMimetypes: Function,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    mimetype: ?ImmutableList<ImmutableMap<string, string>>,
    onChange: Function,
    resourceList: ?Object,
    resourceListIsFetching: ?boolean,
    resource: string,
    value: ?ImmutableList<ImmutableMap<string, string>>,
};

type State = {
    checkAll: boolean,
    loading: boolean,
    resourceListCurrentPage: number,
    searchCriteria: {
        search?: string,
        sortBy: string,
        sortOrder: string,
    },
    selectedItems: ?ImmutableList<ImmutableMap<string, string>>,
};

/**
 * Shared resource index with selectable items
 */

class ResourceSelector extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            checkAll: true,
            loading: false,
            resourceListCurrentPage: 1,
            searchCriteria: {
                search: '',
                sortBy: '',
                sortOrder: 'inactive',
            },
            selectedItems: props.value || fromJS([]),
        };
    }

    componentDidMount() {
        this.handleFetchResourceList();
        if (!this.props.mimetype) {
            this.props.fetchMimetypes();
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.state.resourceListCurrentPage !== prevState.resourceListCurrentPage) {
            /**
             * If resourceListCurrentPage change occurs the data needs to be re-fetched
             */
            this.handleFetchResourceList();
        }
    }

    handleChange = (event: Event) => {
        event.persist();
        const { value } = event.target;
        const { selectedItems } = this.state;
        if (selectedItems) {
            const indexOfItemToDelete = selectedItems.findIndex((item: number) => item === value);
            const newSelectedItems =
                indexOfItemToDelete !== -1
                    ? selectedItems.delete(indexOfItemToDelete)
                    : selectedItems.push(value);
            this.setState(
                {
                    selectedItems: newSelectedItems,
                },
                () => this.props.onChange(this.state.selectedItems)
            );
        }
    };

    handleCheckAll = (checkAll: boolean) => (event: Event) => {
        if (!checkAll) {
            this.setState(
                {
                    checkAll: true,
                    selectedItems: fromJS([]),
                },
                () => this.props.onChange(this.state.selectedItems)
            );
        } else {
            const { resourceList } = this.props;
            const resourceListData = resourceList && resourceList.get('data');
            const newlySelectedItems = [];
            if (resourceListData) {
                resourceListData.map((singleItem) =>
                    newlySelectedItems.push(singleItem.get('id').toString())
                );
                this.setState(
                    {
                        checkAll: false,
                        selectedItems: fromJS(newlySelectedItems),
                    },
                    () => this.props.onChange(this.state.selectedItems)
                );
            }
        }
    };

    handleFetchResourceList = () => {
        this.props.fetchResourceList(this.state.searchCriteria, this.state.resourceListCurrentPage);
    };

    handleResourceSearch = (value: string) => {
        this.setState(
            (prevState: State) => ({
                checkAll: true, // Reset checkAll
                searchCriteria: {
                    ...prevState.searchCriteria,
                    search: value,
                },
                selectedItems: fromJS([]), // Reset checked item
            }),
            () => {
                this.props.onChange(this.state.selectedItems);
                this.handleFetchResourceList();
            }
        );
    };

    handleSortBy = (sortBy: string, sortOrder: string) => {
        this.setState(
            (prevState: Object) => ({
                searchCriteria: {
                    ...prevState.searchCriteria,
                    sortBy: sortOrder === 'inactive' ? null : sortBy,
                    sortOrder: sortOrder === 'inactive' ? null : sortOrder,
                },
            }),
            () => this.handleFetchResourceList()
        );
    };

    isLoading = () => this.props.resourceListIsFetching;

    // Change between pages of results for the resource index/list
    handlePaginationBarPageSelection = (
        resourceListCurrentPage: number,
        resourceListPagesTotal: number
    ) => () =>
        resourceListCurrentPage > 0 && resourceListCurrentPage <= resourceListPagesTotal
            ? this.setState({ resourceListCurrentPage })
            : null;

    renderCollectionThumbnail = (image) => <CollectionThumbnail {...image} />;

    renderImage = (image) => <Thumbnail {...image} />;

    render() {
        const { selectedItems } = this.state;
        const { collection, mimetype, resource, resourceList } = this.props;
        const isMedia = resource === 'media';
        const targetData = isMedia ? 'media' : 'childCollections';
        const currentChildren = collection && collection.get(targetData);
        const resourceListDataRaw = resourceList && resourceList.get('data');

        // Filter out current collection to avoid loop
        const resourceListData =
            resourceListDataRaw &&
            resourceListDataRaw.filter(
                (singleItem: Object) =>
                    singleItem.get('id') !== (collection && collection.get('id'))
            );

        let currentChildrenArray = [];
        if (currentChildren && resourceListData) {
            // Build array of id's of child media/collections
            currentChildrenArray = currentChildren.reduce(
                (acc: Array<number>, singleItem: Object) => {
                    if (singleItem && Map.isMap(singleItem)) {
                        acc.push(singleItem.get('id'));
                    }
                    return acc;
                },
                []
            );
        }

        // Filter out children media/collections
        const unassociatedChildren =
            resourceListData &&
            resourceListData.filter(
                (singleItem: Object) => !currentChildrenArray.includes(singleItem.get('id'))
            );

        const list =
            unassociatedChildren &&
            unassociatedChildren.map((singleItem: Object) => {
                const mimeType = singleItem.getIn(['file', 'mimeType']);
                const readableMimetype = (mimetype && mimetype.get(mimeType)) || mimeType;
                const isChecked =
                    (selectedItems && selectedItems.includes(singleItem.get('id').toString())) ||
                    false;
                let title = singleItem.get('titleFr') || singleItem.get('titleEn') || null;
                title = sanitizeString(title);

                let image;
                if (isMedia || (!isMedia && singleItem.hasIn(['thumbnail', 'id']))) {
                    image = (
                        <Image
                            alt={title}
                            count={
                                singleItem.has('childContentCount') &&
                                singleItem.get('childContentCount')
                            }
                            format="cropped"
                            id={singleItem.get('id')}
                            render={
                                singleItem.has('childContentCount')
                                    ? this.renderCollectionThumbnail
                                    : this.renderImage
                            }
                            resource={resource}
                            showPlaceholder={
                                !singleItem.get('thumbnailId') && !singleItem.get('thumbnail')
                            }
                            type={singleItem.get(`type`)}
                            width="70px"
                        />
                    );
                } else if (!isMedia) {
                    image = (
                        <CollectionThumbnail
                            count={singleItem.get('childContentCount')}
                            width="70px"
                        />
                    );
                }

                return (
                    <Tr key={singleItem.get('id')}>
                        <Td padding="5px 0 5px 20px" width="50px">
                            <Checkbox
                                name={`selected${resource}`}
                                onChange={this.handleChange}
                                checked={isChecked}
                                value={singleItem.get('id')}
                            />
                        </Td>
                        <Td padding="5px 0px" width="70px">
                            {image}
                        </Td>
                        <td>{title}</td>
                        {isMedia && (
                            <td>
                                {readableMimetype || sentenceCase(singleItem.get('displayType'))}
                            </td>
                        )}
                    </Tr>
                );
            });

        return (
            <React.Fragment>
                <SectionTitle
                    main
                    text={this.props.intl.formatMessage({
                        id: isMedia
                            ? 'components.ResourceSelector.selectMedia'
                            : 'components.ResourceSelector.selectCollection',
                    })}
                >
                    <ReverseLink to={`/content/${pluralize.singular(resource)}`}>
                        <FormattedMessage
                            id={
                                isMedia
                                    ? 'components.ResourceSelector.createMedia'
                                    : 'components.ResourceSelector.createCollection'
                            }
                        />
                    </ReverseLink>
                </SectionTitle>
                <FormGroup margin="20px 0">
                    <Search onSearch={this.handleResourceSearch} full />
                </FormGroup>
                {this.isLoading() ? (
                    <Loading loading />
                ) : (
                    <Table fixedHeader>
                        <thead>
                            <tr>
                                <Th padding="5px 0 5px 20px" width="50px">
                                    <Checkbox
                                        name={`selected${resource}`}
                                        onChange={this.handleCheckAll(this.state.checkAll)}
                                        checked={!this.state.checkAll}
                                        value={this.state.checkAll}
                                    />
                                </Th>
                                <Th padding="5px 0px" width="70px">
                                    &nbsp;
                                </Th>
                                <SortableTh
                                    currentSorting={this.state.searchCriteria}
                                    id="components.ResourceSelector.title"
                                    name="titleFr"
                                    onClick={this.handleSortBy}
                                />
                                {isMedia && (
                                    <SortableTh
                                        currentSorting={this.state.searchCriteria}
                                        id="components.ResourceSelector.type"
                                        name="type"
                                        onClick={this.handleSortBy}
                                    />
                                )}
                            </tr>
                        </thead>
                        <tbody>{list}</tbody>
                    </Table>
                )}
                <PaginationBar
                    currentPage={this.state.resourceListCurrentPage}
                    pagesTotal={resourceList && resourceList.get('lastPage')}
                    onPageSelection={this.handlePaginationBarPageSelection}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    resourceList: ownProps.resourceListSelector(state),
    resourceListIsFetching:
        ownProps.resourceListIsFetchingSelector && ownProps.resourceListIsFetchingSelector(state),
    mimetype: ownProps.selectReadableMimeTypes && ownProps.selectReadableMimeTypes(state),
});

const mapDispatchToProps = (dispatch: ReduxDispatch, ownProps) =>
    bindActionCreators(
        {
            fetchResourceList: ownProps.resourceListFetchAction,
            fetchMimetypes: mediaResource().thunks().fetchReadableMimeTypes,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(ResourceSelector));
