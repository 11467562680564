// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

export const CopyFieldContainer = styled.div`
    position: relative;
    cursor: pointer;
`;

export const CopyFieldButton = styled.div`
    position: absolute;
    top: 0;
    right: 0;

    padding: 0 30px 0 10px;

    background-color: ${colors.white};
    color: ${colors.grayB3};

    line-height: 50px;
`;
