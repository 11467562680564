// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fromJS, Map } from 'immutable';

// Components
import ErrorMessage from 'components/ErrorMessage';
import FileInput from 'components/Form/FileInput';
import Select from 'components/Form/Select';

// Styles
import { ReverseButton } from 'styles/buttons';
import { FlexContainer, FlexItemContainer, LineBreak } from 'styles/common';
import { FormGroup, Label } from 'styles/form';

// Types
import type { ImmutableMap, ImmutableList, IntlType } from 'types';

// Utils
import { basicLanguages } from 'utils/constants';

const targetData = 'subtitles';

type Props = {
    errors: ImmutableMap<string, any>,
    handleErrors: Function,
    handleValidationState: Function,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    isLoading: boolean,
    media?: ImmutableMap<string, mixed>,
    onChangeField: Function,
};

type State = {
    list: ?ImmutableList<string>,
};

class VideoSubtitleBlock extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const subtitles =
            props.media && props.media.has(targetData) && !props.media.get(targetData).isEmpty()
                ? props.media.get(targetData)
                : null;
        this.state = {
            list:
                subtitles ||
                fromJS([
                    {
                        key: 0,
                    },
                ]),
        };
    }

    handleChangeField = (name: string, index: number) => (event: Event) => {
        event.persist();
        const { list } = this.state;
        const { files, value } = event.target;
        const file = files && files[0];
        if (list) {
            const newList = list.setIn([index, name], file || value);
            this.setState(
                {
                    list: newList,
                },
                () => {
                    this.props.onChangeField(targetData, newList);
                }
            );
        }
    };

    handleAddRepeatableElement = (index: number) => (event: Event) => {
        event.preventDefault();
        const { list } = this.state;
        const newIndex = index + 1;
        if (list) {
            const newList = list.push(
                fromJS({
                    key: newIndex,
                })
            );
            this.setState({
                list: newList,
            });
        }
    };

    handleClearElement = () => (event: Event) => {
        event.preventDefault();
        event.persist();
        const { list } = this.state;
        if (list) {
            this.setState(
                {
                    list: fromJS([
                        {
                            key: 0,
                        },
                    ]),
                },
                () => {
                    this.props.onChangeField(targetData, []);
                }
            );
        }
    };

    handleDeleteElement = (id: number) => (event: Event) => {
        event.preventDefault();
        event.persist();
        const { list } = this.state;
        if (list) {
            const newList = list.filterNot((item: Object) => item.get('id') === id);
            this.setState(
                {
                    list: newList,
                },
                () => {
                    this.props.onChangeField(targetData, this.state.list);
                }
            );
        }
    };

    render() {
        const { list } = this.state;
        const listSize = list && list.size;
        const hasLangOptions = basicLanguages && basicLanguages.length > listSize;

        const rows =
            list &&
            list.map((singleItem: ImmutableMap<string, mixed>, index: number) => {
                const file = singleItem && singleItem.get('file');
                const isFilled = file && singleItem.get('language');
                const isLast = listSize && index === listSize - 1;
                const key = singleItem.get('id')
                    ? `s${singleItem.get('id')}`
                    : singleItem.get('key');
                let filename = '';
                if (file && Map.isMap(file)) {
                    filename = file.get('originalFilename');
                } else if (file) {
                    filename = file && file.name;
                }

                return (
                    <FormGroup
                        key={key}
                        validationState={this.props.handleValidationState(
                            this.props.handleErrors('subtitles') ||
                                this.props.handleErrors(`subtitleInvalidFile`) ||
                                this.props.handleErrors(`subtitles.${index}.file`)
                        )}
                    >
                        <FlexContainer justifyContent="space-between" gutter="10">
                            <FlexItemContainer direction="column">
                                <FileInput
                                    accept=".vtt"
                                    name={key.toString() || index.toString()}
                                    onChange={this.handleChangeField('file', index)}
                                    disabled={Boolean(singleItem.get('id')) || this.props.isLoading}
                                    filename={filename}
                                />
                            </FlexItemContainer>
                            <FlexItemContainer direction="column" collapsed>
                                <Select
                                    name="language"
                                    onChange={this.handleChangeField('language', index)}
                                    disabled={Boolean(singleItem.get('id')) || this.props.isLoading}
                                    value={singleItem && singleItem.get('language')}
                                    options={basicLanguages.map((lang: Object) => ({
                                        key: lang.key,
                                        intlId: lang.intlId,
                                        disabled:
                                            list &&
                                            list.some(
                                                (item: Object) => item.get('language') === lang.key
                                            ),
                                    }))}
                                    defaultOption={
                                        'components.Media.inputLabelValueSubTitlesLanguage.placeholder'
                                    }
                                />
                            </FlexItemContainer>
                        </FlexContainer>
                        <ErrorMessage
                            error={
                                this.props.handleErrors(`subtitleInvalidFile`)
                                    ? this.props.errors.getIn([
                                          'data',
                                          'errors',
                                          `subtitleInvalidFile`,
                                      ])
                                    : null
                            }
                            values={{
                                attribute: 'components.Media.inputLabelValueSubTitles',
                                values: 'vtt',
                            }}
                        />
                        <ErrorMessage
                            error={
                                this.props.handleErrors(`subtitles.${index}.language`)
                                    ? this.props.errors.getIn([
                                          'data',
                                          'errors',
                                          `subtitles.${index}.language`,
                                      ])
                                    : null
                            }
                            values={{
                                attribute: 'components.Media.inputLabelValueSubTitlesLanguage',
                                values: this.props.intl.formatMessage({
                                    id: 'components.Media.inputLabelValueSubTitles.errorValue',
                                }),
                            }}
                        />
                        {isLast || singleItem.get('id') ? (
                            <FormGroup>
                                <FlexContainer justifyContent="flex-start" gutter="10">
                                    {isLast && (
                                        <ReverseButton
                                            disabled={!isFilled || !hasLangOptions}
                                            onClick={
                                                isFilled && hasLangOptions
                                                    ? this.handleAddRepeatableElement(
                                                          singleItem.get('key') || index
                                                      )
                                                    : null
                                            }
                                        >
                                            <FormattedMessage id="global.add" />
                                        </ReverseButton>
                                    )}
                                    {singleItem.get('id') && (
                                        <FlexItemContainer collapsed>
                                            <ReverseButton
                                                onClick={
                                                    listSize === 1
                                                        ? this.handleClearElement()
                                                        : this.handleDeleteElement(
                                                              singleItem && singleItem.get('id')
                                                          )
                                                }
                                                subtle
                                            >
                                                <FormattedMessage
                                                    id={
                                                        listSize === 1
                                                            ? `global.clear`
                                                            : `global.delete`
                                                    }
                                                />
                                            </ReverseButton>
                                        </FlexItemContainer>
                                    )}
                                </FlexContainer>
                            </FormGroup>
                        ) : (
                            <LineBreak height="10px" />
                        )}
                    </FormGroup>
                );
            });

        return (
            <React.Fragment>
                <Label htmlFor="videoSubtitlesVttFileId">
                    <FormattedMessage id="components.Media.inputLabelValueSubTitles" />
                </Label>
                {rows}
            </React.Fragment>
        );
    }
}

export default injectIntl(VideoSubtitleBlock);
