// @flow

import React from 'react';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fromJS } from 'immutable';

// Components
import ErrorMessage from 'components/ErrorMessage';
import Input from 'components/Form/Input';
import InputTag from 'components/Form/InputTag';
import Map from 'components/Map';
import Select from 'components/Form/Select';
import ToggleSwitchBoolean from 'components/Form/ToggleSwitchBoolean';

// Styles
import { FormGroup, Label, InputNotice, StyledGeosuggest } from 'styles/form';
import { FlexContainer, FlexItemContainer } from 'styles/common';

// Types
import type { ImmutableMap, IntlType } from 'types';

// Utils
import { quebecProvincesNameMapping, regionOptions } from 'utils/constants';

type Props = {
    errors: ImmutableMap<string, any>,
    onChangeField: Function,
    onChangeFieldFromStateMultiple: Function,
    handleErrors: Function,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    isLoading: boolean,
    media?: ImmutableMap<string, mixed>,
};

type State = {
    mapAddress?: string,
    location: {
        locationStreet?: string,
        locationPostalCode?: string,
        locationState?: string,
        locationCountry?: string,
    },
};

class LocationInformationBlock extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            location: fromJS({
                locationStreet:
                    (props.media && props.media.get('locationStreetAddressFr')) ||
                    props.media.get('locationStreetAddressEn') ||
                    '',
                locationState: (props.media && props.media.get('locationState')) || '',
                locationPostalCode: (props.media && props.media.get('locationPostalCode')) || '',
                locationCountry: (props.media && props.media.get('locationCountry')) || '',
            }),
        };
        const {
            locationCountry,
            locationPostalCode,
            locationState,
            locationStreet,
        } = this.state.location.toJS();
        this.state.mapAddress = `${locationStreet} ${locationPostalCode} ${locationState} ${locationCountry}`.trim();
    }

    setMapAddress = () => {
        const {
            locationCountry,
            locationPostalCode,
            locationState,
            locationStreet,
        } = this.state.location.toJS();
        this.setState({
            mapAddress: `${locationStreet} ${locationPostalCode} ${locationState} ${locationCountry}`.trim(),
        });
    };

    handleChangeCountry = (value: string) => {
        const { location } = this.state;
        if (location) {
            const newState = location && location.setIn(['locationCountry'], value);
            this.setState(
                {
                    location: newState,
                },
                () => {
                    this.setMapAddress();
                    this.props.onChangeFieldFromStateMultiple(this.state.location.toJS());
                }
            );
        }
    };

    handleChangeState = (value: string) => {
        const { location } = this.state;
        if (location) {
            const newState = location && location.setIn(['locationState'], value);
            this.setState(
                {
                    location: newState,
                },
                () => {
                    this.setMapAddress();
                    this.props.onChangeFieldFromStateMultiple(this.state.location.toJS());
                }
            );
        }
    };

    handleChangeAddress = (value: string) => {
        const { location } = this.state;
        if (location) {
            const newState = location && location.setIn(['locationStreet'], value);
            this.setState(
                {
                    location: newState,
                },
                () => {
                    this.setMapAddress();
                    this.props.onChangeField('locationStreetAddressFr')(value);
                }
            );
        }
    };

    getLocationFromGoogleMapsAddressComponents = (parts) => {
        const streetNumber = (parts.find((part: Object) => part.types.includes('street_number'))
            ? parts.find((part: Object) => part.types.includes('street_number')).long_name
            : ''
        ).trim();

        const streetRoute = (parts.find((part: Object) => part.types.includes('route'))
            ? parts.find((part: Object) => part.types.includes('route')).long_name
            : ''
        ).trim();

        const locality = (parts.find((part: Object) => part.types.includes('locality'))
            ? parts.find((part: Object) => part.types.includes('locality')).long_name
            : ''
        ).trim();

        const locationStreetAddressFr = (
            (streetRoute && locality && `${streetNumber} ${streetRoute}, ${locality}`) ||
            locality ||
            ''
        ).trim();

        const locationStreet = locationStreetAddressFr;

        const locationPostalCode = parts.find((part: Object) => part.types.includes('postal_code'))
            ? parts.find((part: Object) => part.types.includes('postal_code')).long_name
            : '';
        let locationState = parts.find((part: Object) =>
            part.types.includes('administrative_area_level_1')
        )
            ? parts.find((part: Object) => part.types.includes('administrative_area_level_1'))
                  .long_name
            : '';
        locationState = quebecProvincesNameMapping[locationState] || locationState;

        const locationCountry = parts.find((part: Object) => part.types.includes('country'))
            ? parts.find((part: Object) => part.types.includes('country')).long_name
            : '';

        const location = {
            streetNumber,
            locationCountry,
            locationPostalCode,
            locationState,
            locationStreet,
            locationStreetAddressFr,
        };
        return location;
    };

    handleChangeMarkerPosition = (newAddress) => {
        const location = this.getLocationFromGoogleMapsAddressComponents(newAddress);
        this.setState({ location: fromJS(location) }, () => {
            setTimeout(() => this.props.onChangeFieldFromStateMultiple(location), 0);
        });
    };

    handleSuggestSelect = (suggest) => {
        if (!suggest) return;

        const parts = suggest.gmaps.address_components;
        const location = this.getLocationFromGoogleMapsAddressComponents(parts);

        this.setState({ location: fromJS(location) }, () => {
            this.setMapAddress();
            setTimeout(() => this.props.onChangeFieldFromStateMultiple(location), 0);
        });
    };

    render() {
        return (
            <React.Fragment>
                <FormGroup>
                    <Label htmlFor="locationStreetAddressFr">
                        <FormattedMessage id="components.Media.inputLabelValueLocation" />
                    </Label>
                    <InputTag
                        tag={this.props.intl.formatMessage({
                            id: 'global.french',
                        })}
                        inputHeight
                    >
                        <StyledGeosuggest
                            ref
                            disabled={this.props.isLoading}
                            onSuggestSelect={this.handleSuggestSelect}
                            onChange={this.handleChangeAddress}
                            name="locationStreetAddressFr"
                            placeholder={this.props.intl.formatMessage({
                                id: 'global.writeAddressHere.fr',
                            })}
                            initialValue={
                                (this.props.media &&
                                    this.props.media.get('locationStreetAddressFr')) ||
                                ''
                            }
                        />
                    </InputTag>
                    <InputNotice>
                        <FormattedMessage id="components.Media.inputLabelValueLocation.example.fr" />
                    </InputNotice>
                    <ErrorMessage
                        error={
                            this.props.handleErrors('locationStreetAddressFr')
                                ? this.props.errors.getIn([
                                      'data',
                                      'errors',
                                      'locationStreetAddressFr',
                                  ])
                                : null
                        }
                        values={{ attribute: 'components.Media.inputLabelValueLocation' }}
                    />
                    <InputTag
                        tag={this.props.intl.formatMessage({
                            id: 'global.english',
                        })}
                    >
                        <Input
                            name="locationStreetAddressEn"
                            type="text"
                            placeholder={'global.writeAddressHere.en'}
                            onChange={this.props.onChangeField('locationStreetAddressEn')}
                            disabled={this.props.isLoading}
                            value={
                                this.props.media && this.props.media.get('locationStreetAddressEn')
                            }
                        />
                    </InputTag>
                    <InputNotice>
                        <FormattedMessage id="components.Media.inputLabelValueLocation.example.en" />
                    </InputNotice>
                    <ErrorMessage
                        error={
                            this.props.handleErrors('locationStreetAddressEn')
                                ? this.props.errors.getIn([
                                      'data',
                                      'errors',
                                      'locationStreetAddressEn',
                                  ])
                                : null
                        }
                        values={{ attribute: 'components.Media.inputLabelValueLocation' }}
                    />
                    <InputTag>
                        <FlexContainer
                            alignItems="flex-start"
                            gutter="10"
                            justifyContent="space-between"
                        >
                            <FlexItemContainer direction="column" justifyContent="flex-start">
                                <Input
                                    name="locationPostalCode"
                                    type="text"
                                    placeholder={
                                        'components.Media.inputLabelValueLocationPostalCode.placeholder'
                                    }
                                    onChange={this.props.onChangeField('locationPostalCode')}
                                    disabled={this.props.isLoading}
                                    value={
                                        this.props.media &&
                                        this.props.media.get('locationPostalCode')
                                    }
                                />
                                <ErrorMessage
                                    error={
                                        this.props.handleErrors('locationPostalCode')
                                            ? this.props.errors.getIn([
                                                  'data',
                                                  'errors',
                                                  'locationPostalCode',
                                              ])
                                            : null
                                    }
                                    values={{
                                        attribute:
                                            'components.Media.inputLabelValueLocationPostalCode.placeholder',
                                    }}
                                />
                                <FormGroup>
                                    <Select
                                        name="locationRegion"
                                        onChange={this.props.onChangeField('locationRegion')}
                                        disabled={
                                            this.props.isLoading ||
                                            (this.props.media &&
                                                this.props.media.get('locationState') !== 'Québec')
                                        }
                                        value={
                                            this.props.media &&
                                            this.props.media.get('locationRegion')
                                        }
                                        options={
                                            regionOptions &&
                                            regionOptions.map((option: { id: string }) => ({
                                                key: option.id,
                                                value: option.id,
                                            }))
                                        }
                                        defaultOption="components.Media.inputLabelValueLocationRegion.placeholder"
                                        full
                                    />
                                    <ErrorMessage
                                        error={
                                            this.props.handleErrors('locationRegion')
                                                ? this.props.errors.getIn([
                                                      'data',
                                                      'errors',
                                                      'locationRegion',
                                                  ])
                                                : null
                                        }
                                        values={{
                                            attribute:
                                                'components.Media.inputLabelValueLocationRegion.placeholder',
                                        }}
                                    />
                                </FormGroup>
                            </FlexItemContainer>
                            <FlexItemContainer direction="column" justifyContent="center">
                                <RegionDropdown
                                    country={
                                        this.props.media && this.props.media.get('locationCountry')
                                    }
                                    value={
                                        (this.props.media &&
                                            this.props.media.get('locationState')) ||
                                        ''
                                    }
                                    onChange={this.handleChangeState}
                                    classes="selectInput"
                                    defaultOptionLabel={this.props.intl.formatMessage({
                                        id:
                                            'components.Media.inputLabelValueLocationState.placeholder',
                                    })}
                                    full
                                />
                                <ErrorMessage
                                    error={
                                        this.props.handleErrors('locationState')
                                            ? this.props.errors.getIn([
                                                  'data',
                                                  'errors',
                                                  'locationState',
                                              ])
                                            : null
                                    }
                                    values={{
                                        attribute:
                                            'components.Media.inputLabelValueLocationState.placeholder',
                                    }}
                                />
                            </FlexItemContainer>
                            <FlexItemContainer direction="column" justifyContent="center">
                                <CountryDropdown
                                    value={
                                        (this.props.media &&
                                            this.props.media.get('locationCountry')) ||
                                        ''
                                    }
                                    onChange={this.handleChangeCountry}
                                    classes="selectInput"
                                    defaultOptionLabel={this.props.intl.formatMessage({
                                        id:
                                            'components.Media.inputLabelValueLocationCountry.placeholder',
                                    })}
                                    full
                                />
                                <ErrorMessage
                                    error={
                                        this.props.handleErrors('locationCountry')
                                            ? this.props.errors.getIn([
                                                  'data',
                                                  'errors',
                                                  'locationCountry',
                                              ])
                                            : null
                                    }
                                    values={{
                                        attribute:
                                            'components.Media.inputLabelValueLocationCountry.placeholder',
                                    }}
                                />
                            </FlexItemContainer>
                        </FlexContainer>
                    </InputTag>
                </FormGroup>
                <FormGroup>
                    <ToggleSwitchBoolean
                        labelOn={'components.Media.inputLabelValueshowMap'}
                        labelOff={'components.Media.inputLabelValueshowMap'}
                        name="showMap"
                        onChange={this.props.onChangeField('showMap', true)}
                        right
                        value={this.props.media && this.props.media.get('showMap')}
                    />
                </FormGroup>
                {this.props.media &&
                    this.props.media.get('showMap') && (
                        <FormGroup>
                            <Map
                                address={this.state.mapAddress}
                                onChangeMarkerPosition={this.handleChangeMarkerPosition}
                            />
                        </FormGroup>
                    )}
            </React.Fragment>
        );
    }
}

export default injectIntl(LocationInformationBlock);
