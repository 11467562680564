// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Textarea from 'components/Form/Textarea';

// Styles
import { FormGroup, Label, LabelSubtle, InputNotice } from 'styles/form';

// Types
import type { ImmutableMap } from 'types';

type Props = {
    collection: boolean,
    onChangeField: Function,
    isLoading: boolean,
    media?: ImmutableMap<string, mixed>,
};

class InternalNotesBlock extends React.PureComponent<Props> {
    static defaultProps = {
        collection: false,
    };

    render() {
        return (
            <FormGroup margin="20px 0">
                <Label htmlFor="internalNotes">
                    <FormattedMessage id="components.Media.inputLabelValueInternalNotes" />
                </Label>
                <Textarea
                    name="internalNotes"
                    placeholder="global.writeHere"
                    onChange={this.props.onChangeField('internalNotes')}
                    disabled={this.props.isLoading}
                    value={this.props.media && this.props.media.get('internalNotes')}
                />
                <InputNotice>
                    <Label>
                        <FormattedMessage
                            id={
                                this.props.collection
                                    ? `components.Collection.inputLabelValueInternalNotes.notice`
                                    : `components.Media.inputLabelValueInternalNotes.notice`
                            }
                        />
                    </Label>
                </InputNotice>
            </FormGroup>
        );
    }
}

export default InternalNotesBlock;
