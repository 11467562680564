// @flow

import { replace } from 'react-router-redux';

import type { ReduxDispatch, ResponseErrorType } from 'types';
import crudResources from './resources';
import { crudResourceActions } from './actions';
import { crudResourceActions as mediaActions } from '../Media/actions';

/*
 *
 * CRUDResource thunks
 *
 * Basic function: Check response and dispatch action to save data/error to redux store
 *
 */

const crudThunks = (resourceName: string) => ({
    /*
    * batchAttachChildCollections: Batch attach children collections to collection by id
    *
    * if route is passed, redirect to it on success, else redirect to /collections/id
    */
    batchAttachChildCollections: (id: string, resource: Object, route: string) => (
        dispatch: ReduxDispatch
    ) =>
        crudResources((resourceName: string))
            .batchAttachChildCollections(id, resource)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedBatchAttachMediaFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedBatchAttachMediaSuccess(
                            response.data
                        )
                    );
                    dispatch(replace(route || `/collections/${id}`));
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedBatchAttachMediaFailure(
                        error
                    )
                );
            }),

    /*
    * batchAttachMedia: Batch attach media to collection
    *
    * if route is passed, redirect to it on success, else redirect to /collections/id
    */
    batchAttachMedia: (id: string, resource: Object, route: string) => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .batchAttachMedia(id, resource)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedBatchAttachMediaFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedBatchAttachMediaSuccess(
                            response.data
                        )
                    );
                    dispatch(replace(route || `/collections/${id}`));
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedBatchAttachMediaFailure(
                        error
                    )
                );
            }),

    /*
    * batchDetachChildCollections: Batch detach children collections to collection
    *
    * if route is passed, redirect to it on success, else redirect to /collections/id
    */
    batchDetachChildCollections: (id: string, resource: Object, route: string) => (
        dispatch: ReduxDispatch
    ) =>
        crudResources((resourceName: string))
            .batchDetachChildCollections(id, resource)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedBatchDetachMediaFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedBatchDetachMediaSuccess(
                            response.data
                        )
                    );
                    dispatch(replace(route || `/collections/${id}`));
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedBatchDetachMediaFailure(
                        error
                    )
                );
            }),

    /*
    * batchDetachMedia: Batch detach media to collection
    *
    * if route is passed, redirect to it on success, else redirect to /collections/id
    */
    batchDetachMedia: (id: string, resource: Object, route: string) => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .batchDetachMedia(id, resource)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedBatchDetachMediaFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedBatchDetachMediaSuccess(
                            response.data
                        )
                    );
                    dispatch(replace(route || `/collections/${id}`));
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedBatchDetachMediaFailure(
                        error
                    )
                );
            }),

    /*
    * create: Create a single entry of the resource
    *
    * if closeModal is true, dispatch to /content
    */
    create: (resource: Object, route: string, closeModal: boolean = true) => (
        dispatch: ReduxDispatch
    ) =>
        crudResources((resourceName: string))
            .create(resource)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedCreateFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedCreateSuccess(
                            response.data
                        )
                    );
                    dispatch(crudResourceActions((resourceName: string)).deleteNew());
                    if (closeModal) {
                        dispatch(replace(route || `/content`));
                    }
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(crudResourceActions((resourceName: string)).receivedCreateFailure(error));
            }),

    /*
    * delete: Delete a single entry of the resource by id
    *
    * if deleteContent is true, delete child collection/media
    */
    delete: (id: string, deleteContent: boolean = false) => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .delete(id, deleteContent)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedDeleteFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedDeleteSuccess(
                            response.data
                        )
                    );
                    dispatch(replace(`/content`));
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(crudResourceActions((resourceName: string)).receivedDeleteFailure(error));
            }),

    /*
    * deleteNew: Delete new entry
    */
    deleteNew: () => (dispatch: ReduxDispatch) =>
        dispatch(crudResourceActions((resourceName: string)).deleteNew()),

    /*
    * fetch: Fetch a single entry of the resource by id
    */
    fetch: (id: string) => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .fetch(id)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data.data || response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchSuccess(
                            response.data.data || response.data
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(crudResourceActions((resourceName: string)).receivedFetchFailure(error));
            }),

    /*
    * fetchAll: Fetch all the entries of the resource
    */
    fetchAll: () => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .fetchAll()
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchListFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data.length) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchListSuccess(
                            response.data
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchListFailure(error)
                );
            }),

    /*
    * fetchPaginatedBySearchCriteria: Fetch paginated entries of the resource with search criteria for filtering
    *
    * For the paginated API we keep the entire response.data so we keep the pagination response info,
    *           such as the total of pages for the fetch, not just the resource data list)
    */
    fetchPaginatedBySearchCriteria: (searchCriteria: Object, page: number, perPage: number) => (
        dispatch: ReduxDispatch
    ) => {
        dispatch(
            crudResourceActions((resourceName: string)).receivedFetchPaginatedIsFetching(true)
        );

        crudResources((resourceName: string))
            .fetchPaginatedBySearchCriteria(searchCriteria, page, perPage)
            .then((response) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchPaginatedIsFetching(
                        false
                    )
                );
                if (response.data.error) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedFetchPaginatedBySearchCriteriaFailure(response.data.errorMessage)
                    );
                } else if (response.data.data) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedFetchPaginatedBySearchCriteriaSuccess(response.data)
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchPaginatedIsFetching(
                        false
                    )
                );

                dispatch(
                    crudResourceActions(
                        (resourceName: string)
                    ).receivedFetchPaginatedBySearchCriteriaFailure(error)
                );
            });
    },

    /*
    * fetchSecondaryPaginatedBySearchCriteria: Fetch paginated entries of the resource with search criteria for filtering
    *   and saves it to secondary object in state
    *
    * For the paginated API we keep the entire response.data so we keep the pagination response info,
    *           such as the total of pages for the fetch, not just the resource data list)
    */
    fetchSecondaryPaginatedBySearchCriteria: (
        searchCriteria: Object,
        page: number,
        perPage: number
    ) => (dispatch: ReduxDispatch) => {
        dispatch(
            crudResourceActions((resourceName: string)).receivedFetchSecondaryPaginatedIsFetching(
                true
            )
        );

        crudResources((resourceName: string))
            .fetchPaginatedBySearchCriteria(searchCriteria, page, perPage)
            .then((response) => {
                dispatch(
                    crudResourceActions(
                        (resourceName: string)
                    ).receivedFetchSecondaryPaginatedIsFetching(false)
                );
                if (response.data.error) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedFetchPaginatedBySearchCriteriaFailure(response.data.errorMessage)
                    );
                } else if (response.data.data) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedFetchSecondaryPaginatedBySearchCriteriaSuccess(response.data)
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions(
                        (resourceName: string)
                    ).receivedFetchSecondaryPaginatedIsFetching(false)
                );

                dispatch(
                    crudResourceActions(
                        (resourceName: string)
                    ).receivedFetchSecondaryPaginatedBySearchCriteriaFailure(error)
                );
            });
    },

    /*
    * resetErrors: Reset errors
    */
    resetErrors: () => (dispatch: ReduxDispatch) =>
        dispatch(crudResourceActions((resourceName: string)).resetErrors()),

    /*
    * setCollectionOrder: Set the order for a media that as part of a specific collection
    */
    setCollectionOrder: (collectionId: number, childCollectionId: number, order: number) => (
        dispatch: ReduxDispatch
    ) =>
        crudResources((resourceName: string))
            .postCollectionOrder(collectionId, childCollectionId, order)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedSetCollectionCollectionOrderFailure(
                            collectionId,
                            childCollectionId,
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedSetCollectionCollectionOrderSuccess(
                            collectionId,
                            childCollectionId,
                            response.data
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions(
                        (resourceName: string)
                    ).receivedSetCollectionCollectionOrderFailure(
                        collectionId,
                        childCollectionId,
                        error
                    )
                );
            }),

    /*
    * setMediaOrder: Set the order for a media that as part of a specific collection
    */
    setMediaOrder: (collectionId: number, mediaId: number, order: number) => (
        dispatch: ReduxDispatch
    ) =>
        crudResources((resourceName: string))
            .postMediaOrder(collectionId, mediaId, order)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedSetCollectionMediaOrderFailure(
                            collectionId,
                            mediaId,
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        mediaActions('Media').receivedSetMediaOrderSuccess(
                            mediaId,
                            response.data.media.find((media) => media.id === mediaId).order
                        )
                    );
                    dispatch(
                        crudResourceActions(
                            (resourceName: string)
                        ).receivedSetCollectionMediaOrderSuccess(collectionId, response.data)
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions(
                        (resourceName: string)
                    ).receivedSetCollectionMediaOrderFailure(collectionId, mediaId, error)
                );
            }),

    /*
    * update: Update a single entry of the resource by id
    *
    * if closeModal is true, dispatch to passed /route else to /content
    */
    update: (id: string, resource: Object, route: string, closeModal: boolean = true) => (
        dispatch: ReduxDispatch
    ) =>
        crudResources((resourceName: string))
            .update(id, resource)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedUpdateFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedUpdateSuccess(
                            response.data
                        )
                    );
                    if (closeModal) {
                        dispatch(replace(route || `/content`));
                    }
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(crudResourceActions((resourceName: string)).receivedUpdateFailure(error));
                dispatch(replace(route || `/collections/${id}`));
            }),
});

export default crudThunks;
