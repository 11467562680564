// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

import HeaderBasic from 'components/Header/basic';
import LocaleToggle from 'components/LocaleToggle';

import { BasicLayoutContainer, ContentContainer } from './styles';

/**
 * Component to be used for wrapping the views with the basic layout. Build the layout wrapped component and display the Content component within the layout
 */

const BasicLayout = ({ content: Content, ...props }: Object) => (
    <BasicLayoutContainer>
        <HeaderBasic pageTitle="La Médiathèque" />
        <ContentContainer>
            <Content {...props} />
            <LocaleToggle locale={props.locale} onToggleLocale={props.handleToggleLocale} footer />
        </ContentContainer>
    </BasicLayoutContainer>
);

export default injectIntl(BasicLayout);
