// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Components
import Input from 'components/Form/Input';

// Styles
import { CopyFieldContainer, CopyFieldButton } from './styles';
import { ReverseSpan } from 'styles/buttons';

type DefaultProps = {
    value?: ?string,
};

type Props = DefaultProps & {
    type: string,
};

type State = {
    copied: boolean,
};

/**
 * Shared copy (on click) component
 */

export default class Copy extends React.Component<Props, State> {
    static defaultProps: DefaultProps = {
        value: '',
    };

    state = {
        copied: false,
    };

    handleCopyState = () => {
        this.setState({ copied: true });
    };

    renderCopyButton = () => {
        let markup;
        if (this.props.value) {
            markup = (
                <CopyFieldButton>
                    <CopyToClipboard text={this.props.value} onCopy={this.handleCopyState}>
                        <ReverseSpan>
                            <FormattedMessage
                                id={
                                    this.state && this.state.copied
                                        ? 'global.copied'
                                        : 'global.copy'
                                }
                            />
                        </ReverseSpan>
                    </CopyToClipboard>
                </CopyFieldButton>
            );
        }
        return markup;
    };

    render() {
        const { type, value, ...rest } = this.props;
        return (
            <CopyFieldContainer>
                <Input {...rest} type={type} value={value || ''} disabled inset />
                {this.renderCopyButton()}
            </CopyFieldContainer>
        );
    }
}
