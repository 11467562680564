// @flow

import styled from 'styled-components';

const Container = styled.div`
    min-height: ${(props) => (props.inline || props.height ? `0px` : `382px`)};
    height: ${(props) => (props.height ? props.height : `auto`)};
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
`;

const Content = styled.div``;

const LoadingImg = styled.img`
    width: ${(props) => props.small && `30px`};
`;

export { Container, Content, LoadingImg };
