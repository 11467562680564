// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { fromJS, Map } from 'immutable';

// Components
import SectionTabs from 'components/SectionTabs';

// Styles
import { InputNotice } from 'styles/form';
import {
    CheckBoxList,
    CheckboxListContainer,
    CheckBoxListItem,
    Hidden,
    Label,
    StyledCheckBox,
} from './Checkbox/styles';

// Utils
import { sanitizeString } from 'utils';

// Types
import type { ImmutableList, ImmutableMap } from 'types';

type DefaultProps = {
    data?: ImmutableMap<string, string>,
};

type Props = DefaultProps & {
    emptyString: string,
    list: ?ImmutableList<ImmutableMap<string, string>>,
    name: string,
    noResultsString: string,
    onChange: Function,
    visibleCheckedLimit: number,
    visibleUncheckedLimit: number,
};

type State = {
    selectedItems: ?ImmutableList<ImmutableMap<string, string>>,
    selectedTabItemId: string,
};

/**
 * Shared list of checkboxs
 */

export default class CheckboxList extends React.PureComponent<Props, State> {
    static defaultProps: DefaultProps = {
        visibleCheckedLimit: 12,
        visibleUncheckedLimit: 12,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedItems: (props.data && props.name && props.data.get(props.name)) || fromJS({}),
            selectedTabItemId: 'unchecked',
        };
    }

    handleChange = (name: string) => (event: Event) => {
        event.persist();
        const value = event.target.value;
        const { selectedItems } = this.state;
        const { list } = this.props;
        const listData = list && list.get('data');
        const currentEntity =
            listData &&
            listData
                .filter(
                    (singleItem: ImmutableMap<string, string>) =>
                        singleItem.get('id').toString() === value
                )
                .first();

        if (selectedItems && value) {
            const hasItem = selectedItems.has(value);
            const newSelectedItems = hasItem
                ? selectedItems.delete(value)
                : selectedItems.set(
                      value,
                      fromJS({
                          id: currentEntity && currentEntity.get('id'),
                          titleFr: currentEntity && currentEntity.get('titleFr'),
                          titleEn: currentEntity && currentEntity.get('titleEn'),
                      })
                  );

            this.setState(
                {
                    selectedItems: newSelectedItems,
                },
                () => {
                    this.props.onChange(name, newSelectedItems);
                }
            );
        }
    };

    handleTabItemClick = (key: string) => () => {
        this.setState({
            selectedTabItemId: key,
        });
    };

    renderCheckBoxMarkup = (
        singleItem: ImmutableMap<string, string>,
        name: string,
        isChecked: boolean
    ) => {
        if (!singleItem) {
            return null;
        }

        let title = singleItem.get('titleFr') || singleItem.get('titleEn') || '';
        title = sanitizeString(title);

        return (
            <CheckBoxListItem key={singleItem.get('id')}>
                <Label>
                    <StyledCheckBox checked={isChecked} />
                    <Hidden
                        id={singleItem.get('id')}
                        name={name}
                        checked={isChecked}
                        onChange={this.handleChange(name)}
                        value={singleItem.get('id')}
                        type="checkbox"
                    />
                    {title}
                </Label>
            </CheckBoxListItem>
        );
    };

    render() {
        const { selectedItems } = this.state;
        const { data, list, name, visibleCheckedLimit, visibleUncheckedLimit } = this.props;

        let currentlyBelongedToCollections = fromJS([]);
        let currentCollectionId;

        // Media
        if (data && data.has('collections')) {
            currentlyBelongedToCollections = data && data.get('collections');
        }
        // Collection
        if (data && data.has('parentCollections')) {
            currentlyBelongedToCollections = data && data.get('parentCollections');
            currentCollectionId = data && data.get('id');
        }

        // Build array of currentlyBelongedToCollections' ids
        const currentlyBelongedToCollectionsArray = currentlyBelongedToCollections.reduce(
            (acc: Array<number>, singleCollection: Object) => {
                if (singleCollection && Map.isMap(singleCollection)) {
                    acc.push(singleCollection.get('id'));
                }
                return acc;
            },
            []
        );

        // Filter out currentlyBelongedToCollections && self if collection
        const filteredList =
            list &&
            list
                .get('data')
                .filter(
                    (singleItem: ImmutableMap<string, string>) =>
                        !currentlyBelongedToCollectionsArray.includes(singleItem.get('id')) &&
                        singleItem.get('id') !== currentCollectionId
                );

        const checkedItemsList = [];
        const uncheckItemsList = [];
        if (selectedItems) {
            selectedItems.map((singleItem: ImmutableMap<string, string>) => {
                const itemMarkup = this.renderCheckBoxMarkup(singleItem, name, true);
                return checkedItemsList.push(itemMarkup);
            });
        }
        if (filteredList) {
            filteredList.map((singleItem: ImmutableMap<string, string>) => {
                const isChecked =
                    (selectedItems && selectedItems.has(singleItem.get('id').toString())) || false;
                const itemMarkup = this.renderCheckBoxMarkup(singleItem, name, isChecked);
                if (!isChecked) {
                    return uncheckItemsList.push(itemMarkup);
                } else {
                    return null;
                }
            });
        }

        // Get list sizes for conditional display
        const listSize = list && list.size;
        const filteredListSize = filteredList && filteredList.size;
        const checkedItemsListLength = checkedItemsList && checkedItemsList.length;
        const uncheckedItemsListLength = uncheckItemsList && uncheckItemsList.length;

        let content;
        if (!listSize && checkedItemsListLength === 0) {
            // If no list (search), show emptyString (No search)
            content = (
                <CheckBoxListItem inactive>
                    <StyledCheckBox disabled />
                    <FormattedMessage id={this.props.emptyString} />
                </CheckBoxListItem>
            );
        } else if (!filteredListSize && checkedItemsListLength === 0) {
            // If filteredList size is 0, show noResultsString (No results)
            content = (
                <InputNotice marginTop="8px">
                    <FormattedMessage id={this.props.noResultsString} />
                </InputNotice>
            );
        } else {
            // Else: show checkboxlists: checked/unchecked
            content = (
                <CheckboxListContainer>
                    <SectionTabs
                        onItemClick={this.handleTabItemClick}
                        selectedTabItemId={this.state.selectedTabItemId}
                        small
                        tabs={fromJS([
                            {
                                id: 'unchecked',
                                string: 'components.CheckboxList.tabUnchecked',
                                values: {
                                    count: uncheckedItemsListLength,
                                },
                            },
                            {
                                id: 'checked',
                                string: 'components.CheckboxList.tabChecked',
                                values: {
                                    count: checkedItemsListLength,
                                },
                            },
                        ])}
                        white
                    />
                    {this.state.selectedTabItemId === 'unchecked' ? (
                        <CheckBoxList limit={visibleUncheckedLimit}>
                            {uncheckItemsList}
                        </CheckBoxList>
                    ) : (
                        <CheckBoxList limit={visibleCheckedLimit}>{checkedItemsList}</CheckBoxList>
                    )}
                </CheckboxListContainer>
            );
        }

        return content;
    }
}
