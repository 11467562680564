// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

import checkIcon from 'assets/icons/Checkbox/Checked.svg';
import checkHoverIcon from 'assets/icons/Checkbox/Hover.svg';

export const InnerLabel = styled.span`
    display: inline-block;
    vertical-align: middle;
`;

export const Label = styled.label`
    position: relative;
    display: block;

    cursor: pointer;

    ${(props) =>
        props.checked
            ? `
        ${InnerLabel} {
            color: ${colors.gray33};
        }
    `
            : `
        ${InnerLabel} {
            color: ${colors.gray64};
        }
    `};
`;

export const Hidden = styled.input`
    display: none;
`;

export const StyledCheckBox = styled.span`
    position: relative;
    display: inline-block;
    vertical-align: middle;

    margin-right: 10px;

    width: 16px;
    height: 16px;

    border: 1px solid ${(props) => (props.disabled ? colors.grayB3 : colors.teal)};
    border-radius: 3px;

    cursor: ${(props) => (props.disabled ? `default` : `pointer`)};

    ${(props) =>
        !props.checked &&
        !props.disabled &&
        `
        &:hover {
            &:after {
                background-image: url('${checkHoverIcon}');
                transition: background-image 0.25s ease;
            }
        }
    `};

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;

        display: block;

        width: 14px;
        height: 14px;

        ${(props) => {
            let backgroundImage = `background-image: url('`;

            if (props.checked && props.disabled) {
                backgroundImage += checkHoverIcon;
            } else if (props.checked) {
                backgroundImage += checkIcon;
            }

            return backgroundImage && `${backgroundImage}');`;
        }} background-position: center;
        background-size: contain;

        transition: background-image 0.25s ease;
    }
`;

export const CheckBoxList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;

    max-height: ${(props) => (props.limit ? `${props.limit * 32}px` : 'auto')};
    overflow: auto;
`;

export const CheckboxListContainer = styled.div`
    border: 1px solid ${colors.grayED};
    border-bottom-width: 0;
`;

export const CheckBoxListItem = styled.li`
    display: block;
    padding: 8px 10px 7px;
    min-height: 32px;

    padding-left: 36px;

    background-color: ${colors.white};
    border-bottom: 1px solid ${colors.grayED};

    box-sizing: border-box;

    > * {
        display: inline-block;
        vertical-align: middle;
    }

    ${StyledCheckBox} {
        float: left;
        margin-left: -26px;
    }

    ${(props) =>
        props.inactive &&
        `
        color: ${colors.grayB3}
    `};
`;
