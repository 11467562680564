// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { fromJS } from 'immutable';

// Components
import Checkbox from 'components/Form/Checkbox';
import Input from 'components/Form/Input';
import RadioButton from 'components/Form/RadioButton';
import Search from 'components/Form/Search';
import Select from 'components/Form/Select';

// Services
import { selectUser } from 'services/Authentication/selectors';
import categoryResource from 'services/Category';
import mediaResource from 'services/Media';

// Styles
import { PrimaryButton, SecondaryButton } from 'styles/buttons';
import {
    FlexContainer,
    FlexItemContainer,
    Indented,
    ActionsContainer,
    ModalActionsWrapper,
    WhiteBlock,
} from 'styles/common';
import { FormGroup, SectionHeader } from 'styles/form';
import {
    Item,
    InlineItem,
    List,
    SearchCriteriaContainer,
    SearchCriteriaContent,
    SearchCriteriaContentBody,
    SearchCriteriaContentTitle,
    SearchCriteriaContentTitleNote,
    SearchCriteriaTabs,
    SearchCriteriaTab,
    SearchCriteriaTabNote,
} from './styles';

// Types
import type {
    CategoryType,
    ImmutableList,
    ImmutableMap,
    InputEvent,
    IntlType,
    UserType,
} from 'types';

// Utils
import { isValidDate, sentenceCase } from 'utils';
import { orientationOptions, ratioOptions, regionOptions, typeOptions } from 'utils/constants';

type DefaultProps = {
    advancedSearchValues?: Object,
    searchValue?: string,
    currentUser?: UserType,
};

type Props = DefaultProps & {
    categories: ImmutableMap<string, CategoryType>,
    countries: ImmutableMap<string, CategoryType>,
    getCategories: Function,
    getCountries: Function,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    onAdvancedSearchFormSubmit: Function,
    onAdvancedSearchModal: Function,
    resourceType: string,
};

type State = {
    advancedSearchModal: boolean,
    advancedSearch?: ImmutableMap<string, string>,
    invalidFields?: ImmutableList<string>,
    searchCriteriaTab: string,
    search?: string,
};

class AdvancedSearchForm extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            advancedSearchModal: false,
            advancedSearch:
                props.advancedSearchValues ||
                fromJS({
                    limitSearch: 'all',
                    userId: props.currentUser && props.currentUser.get('id'),
                    role: props.currentUser && props.currentUser.get('role'),
                }),
            invalidFields: fromJS({}),
            search: props.searchValue || '',
            searchCriteriaTab: props.resourceType === 'collection' ? 'date' : 'localization',
        };
    }

    componentDidMount() {
        // Retrieve categories
        if (this.props.categories.isEmpty()) {
            this.props.getCategories();
        }
        this.props.getCountries();
    }

    handleAdvancedSearchSubmitForm = (event: InputEvent) => {
        event.preventDefault();
        if (this.props.onAdvancedSearchFormSubmit) {
            this.props.onAdvancedSearchFormSubmit(this.state.search, this.state.advancedSearch)(
                event
            );
        }
    };

    handleClearAdvancedSearch = () => {
        this.setState({
            advancedSearch: fromJS({
                limitSearch: 'all',
                userId: this.props.currentUser && this.props.currentUser.get('id'),
                role: this.props.currentUser && this.props.currentUser.get('role'),
            }),
            search: '',
        });
    };

    handleChangeField = (name: string, isCheckbox: boolean = false) => ({
        target: { value },
    }: InputEvent) => {
        const { advancedSearch } = this.state;

        // Ensure advancedSearch (state) has a value
        if (advancedSearch) {
            // If isCheckbox, find targeted objected within advancedSearch (state),
            // If not found default to empty immutable map
            // Find index of value from event & toggle within targeted object
            let newTargetItems;
            if (isCheckbox) {
                const targetObj = advancedSearch.get(name) || fromJS([]);
                const indexOfItem = targetObj.findIndex((item: string) => item === value);
                newTargetItems =
                    indexOfItem === -1 || targetObj.isEmpty()
                        ? targetObj.push(value)
                        : targetObj.delete(indexOfItem);
            }

            // SetIn new state into advancedSearch
            const newAdvancedSearch = advancedSearch.setIn(
                [name],
                isCheckbox ? newTargetItems : value
            );

            this.setState({
                advancedSearch: newAdvancedSearch,
            });
        }
    };

    handleChangeSearchCriteriaTab = (searchCriteriaTab: string) => () => {
        this.setState({
            searchCriteriaTab,
        });
    };

    handleOnCancel = (event: Event) => {
        this.setState(
            {
                advancedSearch: fromJS({}),
            },
            () => this.props.onAdvancedSearchModal()
        );
    };

    handleSearchChange = (search: string) => {
        this.setState({
            search,
        });
    };

    handleValidateYear = (name: string) => ({ target: { value } }: InputEvent) => {
        // Set true or false on date inputs if value is set & is not valid
        const newInvalidFields =
            this.state.invalidFields &&
            this.state.invalidFields.setIn(
                [name],
                (value && !isValidDate(value, 'YYYY', true)) || false
            );
        this.setState({
            invalidFields: newInvalidFields,
        });
    };

    renderSearchCriteriaLocalizationBlock = () => (
        <div>
            <SearchCriteriaContentTitle>
                <FormattedMessage id="components.AdvancedSearch.searchCriteria.selectLocalization" />
            </SearchCriteriaContentTitle>
            <SearchCriteriaContentBody>
                <List>
                    <InlineItem>
                        <RadioButton
                            checked={
                                (this.state.advancedSearch &&
                                    this.state.advancedSearch.get('localization') ===
                                        'international') ||
                                false
                            }
                            label="components.AdvancedSearch.searchCriteria.selectLocalization.international"
                            name="localization"
                            onChange={this.handleChangeField('localization')}
                            value="international"
                        />
                    </InlineItem>
                    <InlineItem>
                        <RadioButton
                            checked={
                                (this.state.advancedSearch &&
                                    this.state.advancedSearch.get('localization') === 'quebec') ||
                                false
                            }
                            label="components.AdvancedSearch.searchCriteria.selectLocalization.quebec"
                            name="localization"
                            onChange={this.handleChangeField('localization')}
                            value="quebec"
                        />
                    </InlineItem>
                </List>
                <FormGroup>
                    {this.state.advancedSearch.get('localization') === 'international' && (
                        <Select
                            defaultOption={
                                'components.AdvancedSearch.searchCriteria.selectLocalization.allCountries'
                            }
                            full
                            name="country"
                            onChange={this.handleChangeField('country')}
                            options={
                                this.props.countries &&
                                this.props.countries.toJS().map((option) => ({
                                    key: option,
                                    value: option,
                                }))
                            }
                            value={
                                this.state.advancedSearch &&
                                this.state.advancedSearch.get('country')
                            }
                        />
                    )}
                    {this.state.advancedSearch.get('localization') === 'quebec' && (
                        <Select
                            defaultOption={
                                'components.AdvancedSearch.searchCriteria.selectLocalization.allRegions'
                            }
                            full
                            name="region"
                            onChange={this.handleChangeField('region')}
                            options={
                                regionOptions &&
                                regionOptions.map((option: { id: string }) => ({
                                    key: option.id,
                                    value: option.id,
                                }))
                            }
                            value={
                                this.state.advancedSearch && this.state.advancedSearch.get('region')
                            }
                        />
                    )}
                </FormGroup>
            </SearchCriteriaContentBody>
        </div>
    );

    renderSearchCriteriaDateBlock = () => (
        <div>
            <SearchCriteriaContentTitle>
                <FormattedMessage id="components.AdvancedSearch.searchCriteria.selectDateRange" />
                <SearchCriteriaContentTitleNote>
                    <FormattedMessage id="components.AdvancedSearch.searchCriteria.selectDateRange.note" />
                </SearchCriteriaContentTitleNote>
            </SearchCriteriaContentTitle>
            <SearchCriteriaContentBody>
                <FlexContainer justifyContent="flex-start" gutter="10">
                    <FlexItemContainer direction="column" justifyContent="center" collapsed>
                        <FormattedMessage id="components.AdvancedSearch.searchCriteria.selectDateRange.from" />
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="center">
                        <FormGroup
                            validationState={
                                this.state.invalidFields &&
                                this.state.invalidFields.get('startYear')
                            }
                        >
                            <Input
                                name="startYear"
                                type="text"
                                placeholder={'global.yearPlaceholder'}
                                onChange={this.handleChangeField('startYear')}
                                disabled={false}
                                value={
                                    this.state.advancedSearch &&
                                    this.state.advancedSearch.get('startYear')
                                }
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="center" collapsed>
                        <FormattedMessage id="components.AdvancedSearch.searchCriteria.selectDateRange.to" />
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="center">
                        <FormGroup
                            validationState={
                                this.state.invalidFields && this.state.invalidFields.get('endYear')
                            }
                        >
                            <Input
                                name="endYear"
                                type="text"
                                placeholder={'global.yearPlaceholder'}
                                onBlur={this.handleValidateYear('endYear')}
                                onChange={this.handleChangeField('endYear')}
                                disabled={false}
                                value={
                                    this.state.advancedSearch &&
                                    this.state.advancedSearch.get('endYear')
                                }
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
            </SearchCriteriaContentBody>
        </div>
    );

    renderSearchCriteriaCategoryBlock = () => {
        const { categories } = this.props;
        const oddCategories =
            categories &&
            categories
                .toJS()
                .filter(
                    (category: { id: number, name: string }, i: number) => (i % 2 ? null : category)
                );
        const evenCategories =
            categories &&
            categories
                .toJS()
                .filter(
                    (category: { id: number, name: string }, i: number) => (i % 2 ? category : null)
                );
        return (
            <div>
                <SearchCriteriaContentTitle>
                    <FormattedMessage id="components.AdvancedSearch.searchCriteria.selectCategory" />
                </SearchCriteriaContentTitle>
                <SearchCriteriaContentBody>
                    <FlexContainer justifyContent="flex-start" gutter="10">
                        <FlexItemContainer
                            direction="column"
                            justifyContent="flex-start"
                            maxWidth="40%"
                        >
                            <List>
                                {oddCategories &&
                                    oddCategories.map((category: { id: number, name: string }) => (
                                        <Item key={category.id}>
                                            <Checkbox
                                                checked={
                                                    (this.state.advancedSearch &&
                                                        this.state.advancedSearch.get(
                                                            'categories'
                                                        ) &&
                                                        this.state.advancedSearch
                                                            .get('categories')
                                                            .includes(category.id.toString())) ||
                                                    false
                                                }
                                                label={category.name}
                                                name="categories"
                                                onChange={this.handleChangeField(
                                                    'categories',
                                                    true
                                                )}
                                                value={category.id}
                                            />
                                        </Item>
                                    ))}
                            </List>
                        </FlexItemContainer>
                        <FlexItemContainer
                            direction="column"
                            justifyContent="flex-start"
                            maxWidth="60%"
                        >
                            <List>
                                {evenCategories &&
                                    evenCategories.map((category: { id: number, name: string }) => (
                                        <Item key={category.id}>
                                            <Checkbox
                                                checked={
                                                    (this.state.advancedSearch &&
                                                        this.state.advancedSearch.get(
                                                            'categories'
                                                        ) &&
                                                        this.state.advancedSearch
                                                            .get('categories')
                                                            .includes(category.id.toString())) ||
                                                    false
                                                }
                                                label={category.name}
                                                name="categories"
                                                onChange={this.handleChangeField(
                                                    'categories',
                                                    true
                                                )}
                                                value={category.id}
                                            />
                                        </Item>
                                    ))}
                            </List>
                        </FlexItemContainer>
                    </FlexContainer>
                </SearchCriteriaContentBody>
            </div>
        );
    };

    renderSearchCriteriaOrientationBlock = () => (
        <div>
            <SearchCriteriaContentTitle>
                <FormattedMessage id="components.AdvancedSearch.searchCriteria.selectOrientation" />
                <SearchCriteriaContentTitleNote>
                    <FormattedMessage id="components.AdvancedSearch.searchCriteria.selectOrientation.note" />
                </SearchCriteriaContentTitleNote>
            </SearchCriteriaContentTitle>
            <SearchCriteriaContentBody>
                <List>
                    {orientationOptions &&
                        orientationOptions.map((option: { id: string }) => (
                            <Item key={option.id}>
                                <RadioButton
                                    checked={
                                        (this.state.advancedSearch &&
                                            this.state.advancedSearch.get('orientation') &&
                                            this.state.advancedSearch
                                                .get('orientation')
                                                .includes(option.id)) ||
                                        false
                                    }
                                    label={`components.AdvancedSearch.searchCriteria.selectOrientation.${
                                        option.id
                                    }`}
                                    name="orientation"
                                    onChange={this.handleChangeField('orientation')}
                                    value={option.id}
                                />
                            </Item>
                        ))}
                </List>
            </SearchCriteriaContentBody>
        </div>
    );

    renderSearchCriteriaRatioBlock = () => (
        <div>
            <SearchCriteriaContentTitle>
                <FormattedMessage id="components.AdvancedSearch.searchCriteria.selectRatio" />
                <SearchCriteriaContentTitleNote>
                    <FormattedMessage id="components.AdvancedSearch.searchCriteria.selectRatio.note" />
                </SearchCriteriaContentTitleNote>
            </SearchCriteriaContentTitle>
            <SearchCriteriaContentBody>
                <List>
                    {ratioOptions &&
                        ratioOptions.map((option: { id: string }) => (
                            <Item key={option.id}>
                                <RadioButton
                                    checked={
                                        (this.state.advancedSearch &&
                                            this.state.advancedSearch.get('ratio') &&
                                            this.state.advancedSearch
                                                .get('ratio')
                                                .includes(option.id)) ||
                                        false
                                    }
                                    label={`components.AdvancedSearch.searchCriteria.selectRatio.${
                                        option.id
                                    }`}
                                    name="ratio"
                                    onChange={this.handleChangeField('ratio')}
                                    value={option.id}
                                />
                            </Item>
                        ))}
                </List>
            </SearchCriteriaContentBody>
        </div>
    );

    renderSearchCriteriaTypeBlock = () => (
        <div>
            <SearchCriteriaContentTitle>
                <FormattedMessage id="components.AdvancedSearch.searchCriteria.selectType" />
            </SearchCriteriaContentTitle>
            <SearchCriteriaContentBody>
                <List>
                    {typeOptions &&
                        typeOptions.map((option: { id: string }) => (
                            <Item key={option.id}>
                                <Checkbox
                                    checked={
                                        (this.state.advancedSearch &&
                                            this.state.advancedSearch.get('types') &&
                                            this.state.advancedSearch
                                                .get('types')
                                                .includes(option.id)) ||
                                        false
                                    }
                                    intlLabel={`components.AdvancedSearch.searchCriteria.selectType.${
                                        option.id
                                    }`}
                                    name="types"
                                    onChange={this.handleChangeField('types', true)}
                                    value={option.id}
                                />
                            </Item>
                        ))}
                </List>
            </SearchCriteriaContentBody>
        </div>
    );

    renderSearchCriteriaTabNote = (
        key: string,
        intlId: string,
        optionLength: number,
        limit: number = 3
    ) => {
        const { advancedSearch } = this.state;
        const selected = advancedSearch && advancedSearch.get(key);
        if (intlId && selected && selected.size === optionLength) {
            // Return "all" message if selected size is size of options
            return this.props.intl.formatMessage({
                id: `${intlId}.all`,
            });
        } else if (intlId && selected && selected.size < limit) {
            // Return message list of selected items if selected size is not 0 & lower then provided limit
            return selected
                .map((option: string) =>
                    this.props.intl.formatMessage({
                        id: `${intlId}.${option}`,
                    })
                )
                .join(', ');
        } else if (selected && selected.size) {
            // Return message with count of selected size
            return (
                <FormattedMessage
                    id="components.AdvancedSearch.searchCriteria.selectedCount"
                    values={{
                        count: selected.size,
                    }}
                />
            );
        } else {
            return null;
        }
    };

    renderSearchCriteriaBlock = () => {
        const { advancedSearch } = this.state;

        const tabs = [
            {
                intlId: 'components.AdvancedSearch.searchCriteria.localization',
                id: 'localization',
                note: () => {
                    const array = [];
                    if (advancedSearch && advancedSearch.get('localization')) {
                        array.push(
                            this.props.intl.formatMessage({
                                id: `components.AdvancedSearch.searchCriteria.selectLocalization.${advancedSearch.get(
                                    'localization'
                                )}`,
                            })
                        );
                    }
                    if (
                        advancedSearch &&
                        advancedSearch.get('localization') === 'quebec' &&
                        advancedSearch.get('region')
                    ) {
                        array.push(advancedSearch.get('region'));
                    } else if (
                        advancedSearch &&
                        advancedSearch.get('localization') === 'international' &&
                        advancedSearch.get('country')
                    ) {
                        array.push(advancedSearch.get('country'));
                    }
                    return array && array.length > 0 && array.join(', ');
                },
                excludeFor: ['collection'],
            },
            {
                intlId: 'components.AdvancedSearch.searchCriteria.date',
                id: 'date',
                note: () => {
                    const array = [];
                    if (advancedSearch && advancedSearch.get('startYear')) {
                        array.push(
                            `${this.props.intl.formatMessage({
                                id: 'components.AdvancedSearch.searchCriteria.selectDateRange.from',
                            })} ${advancedSearch.get('startYear')}`
                        );
                    }
                    if (advancedSearch && advancedSearch.get('endYear')) {
                        array.push(
                            `${this.props.intl.formatMessage({
                                id: 'components.AdvancedSearch.searchCriteria.selectDateRange.to',
                            })} ${advancedSearch.get('endYear')}`
                        );
                    }
                    return array && array.length > 0 && array.join(' ');
                },
            },
            {
                intlId: 'components.AdvancedSearch.searchCriteria.category',
                id: 'category',
                note: () =>
                    this.renderSearchCriteriaTabNote(
                        'categories',
                        '',
                        this.props.categories && this.props.categories.size,
                        3
                    ),
                excludeFor: ['collection'],
            },
            {
                intlId: 'components.AdvancedSearch.searchCriteria.orientation',
                id: 'orientation',
                note: () =>
                    advancedSearch &&
                    advancedSearch.get('orientation') &&
                    sentenceCase(advancedSearch.get('orientation')),
                excludeFor: ['collection'],
            },
            {
                intlId: 'components.AdvancedSearch.searchCriteria.ratio',
                id: 'ratio',
                note: () =>
                    advancedSearch &&
                    advancedSearch.get('ratio') &&
                    sentenceCase(advancedSearch.get('ratio')),
                excludeFor: ['collection'],
            },
            {
                intlId: 'components.AdvancedSearch.searchCriteria.type',
                id: 'type',
                note: () =>
                    this.renderSearchCriteriaTabNote(
                        'types',
                        'components.AdvancedSearch.searchCriteria.selectType',
                        typeOptions && typeOptions.length,
                        5
                    ),
                excludeFor: ['collection'],
            },
        ];

        let content;
        switch (this.state.searchCriteriaTab) {
            default:
            case 'localization':
                content = this.renderSearchCriteriaLocalizationBlock();
                break;
            case 'date':
                content = this.renderSearchCriteriaDateBlock();
                break;
            case 'category':
                content = this.renderSearchCriteriaCategoryBlock();
                break;
            case 'orientation':
                content = this.renderSearchCriteriaOrientationBlock();
                break;
            case 'ratio':
                content = this.renderSearchCriteriaRatioBlock();
                break;
            case 'type':
                content = this.renderSearchCriteriaTypeBlock();
                break;
        }

        return (
            <SearchCriteriaContainer>
                <SearchCriteriaTabs direction="column">
                    {tabs &&
                        tabs.map(
                            (tab: {
                                excludeFor?: array,
                                intlId: string,
                                id: string,
                                note?: any,
                            }) => {
                                if (
                                    !tab.excludeFor ||
                                    (tab.excludeFor &&
                                        !tab.excludeFor.includes(this.props.resourceType))
                                ) {
                                    return (
                                        <SearchCriteriaTab
                                            key={tab.id}
                                            active={this.state.searchCriteriaTab === tab.id}
                                            onClick={this.handleChangeSearchCriteriaTab(tab.id)}
                                        >
                                            <div>
                                                {this.props.intl.formatMessage({
                                                    id: tab.intlId,
                                                })}
                                                {tab.note &&
                                                    typeof tab.note === 'function' && (
                                                        <SearchCriteriaTabNote>
                                                            {tab.note()}
                                                        </SearchCriteriaTabNote>
                                                    )}
                                            </div>
                                        </SearchCriteriaTab>
                                    );
                                } else {
                                    return null;
                                }
                            }
                        )}
                </SearchCriteriaTabs>
                <SearchCriteriaContent>{content}</SearchCriteriaContent>
            </SearchCriteriaContainer>
        );
    };

    render() {
        const { advancedSearch } = this.state;

        return (
            <React.Fragment>
                <Indented>
                    <SectionHeader>
                        <FormattedMessage id="components.AdvancedSearch.keywordSearch" />
                    </SectionHeader>
                </Indented>
                <WhiteBlock>
                    <Indented>
                        <FormGroup margin="10px 0">
                            <Search
                                onSearchChange={this.handleSearchChange}
                                placeholder="global.writeHere"
                                searchValue={this.state.search}
                                onClearAdvancedSearch={this.handleClearAdvancedSearch}
                                clearAdvancedSearch
                                full
                            />
                        </FormGroup>
                        <List>
                            <InlineItem>
                                <RadioButton
                                    checked={
                                        (advancedSearch &&
                                            advancedSearch.get('limitSearch') === 'all') ||
                                        false
                                    }
                                    label="components.AdvancedSearch.searchLimitation.allWords"
                                    name="limitSearch"
                                    onChange={this.handleChangeField('limitSearch')}
                                    value="all"
                                />
                            </InlineItem>
                            <InlineItem>
                                <RadioButton
                                    checked={
                                        (advancedSearch &&
                                            advancedSearch.get('limitSearch') === 'one') ||
                                        false
                                    }
                                    label="components.AdvancedSearch.searchLimitation.atLeastOneWord"
                                    name="limitSearch"
                                    onChange={this.handleChangeField('limitSearch')}
                                    value="one"
                                />
                            </InlineItem>
                            <InlineItem>
                                <RadioButton
                                    checked={
                                        (advancedSearch &&
                                            advancedSearch.get('limitSearch') === 'exact') ||
                                        false
                                    }
                                    label="components.AdvancedSearch.searchLimitation.exactExpression"
                                    name="limitSearch"
                                    onChange={this.handleChangeField('limitSearch')}
                                    value="exact"
                                />
                            </InlineItem>
                            <InlineItem>
                                <RadioButton
                                    checked={
                                        (advancedSearch &&
                                            advancedSearch.get('limitSearch') === 'excluding') ||
                                        false
                                    }
                                    label="components.AdvancedSearch.searchLimitation.excluding"
                                    name="limitSearch"
                                    onChange={this.handleChangeField('limitSearch')}
                                    value="excluding"
                                />
                            </InlineItem>
                        </List>
                    </Indented>
                </WhiteBlock>
                <Indented>
                    <SectionHeader>
                        <FormattedMessage id="components.AdvancedSearch.searchCriteria" />
                    </SectionHeader>
                    {this.renderSearchCriteriaBlock()}
                </Indented>
                <ModalActionsWrapper>
                    <ActionsContainer>
                        <PrimaryButton
                            onClick={this.handleAdvancedSearchSubmitForm}
                            disabled={
                                this.state.invalidFields && this.state.invalidFields.includes(true)
                            }
                        >
                            {this.props.intl.formatMessage({
                                id: 'components.Search.searchPlaceholder',
                            })}
                        </PrimaryButton>
                        <SecondaryButton onClick={this.handleOnCancel}>
                            {this.props.intl.formatMessage({
                                id: 'global.cancel',
                            })}
                        </SecondaryButton>
                    </ActionsContainer>
                </ModalActionsWrapper>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) =>
    createStructuredSelector({
        categories: categoryResource()
            .selectors()
            .selectAll(),
        countries: mediaResource()
            .selectors()
            .selectCountries(),
        currentUser: selectUser(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            getCategories: categoryResource().thunks().fetchAll,
            getCountries: mediaResource().thunks().fetchCountries,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(AdvancedSearchForm));
