// @flow

import React from 'react';
import { injectIntl } from 'react-intl';

// Styles
import { InnerLabel, Hidden, Label, StyledCheckBox } from './styles';

// Types
import type { IntlType } from 'types';

type DefaultProps = {
    disabled?: boolean,
    intlLabel?: string,
    label?: string,
    name?: string,
    onChange?: Function,
    value?: number | boolean | string,
};

type Props = DefaultProps & {
    checked: boolean,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
};

type State = {
    checked: boolean,
    disabled: boolean,
    value: number | boolean,
};

/**
 * Shared checkbox component
 */

class Checkbox extends React.Component<Props, State> {
    static defaultProps = {
        checked: false,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            checked: props.checked || false,
            disabled: props.disabled || false,
            value: props.value || false,
        };
    }

    componentWillReceiveProps(nextProps: Props) {
        this.setState((prevState: Object) => ({
            ...prevState,
            checked: nextProps.checked,
            disabled: nextProps.disabled,
            value: nextProps.value,
        }));
    }

    handleChange = (event: Event) => {
        event.persist();
        this.setState(
            (prevState: Object) => ({
                value: event.target.value,
                checked: !prevState.checked,
            }),
            () => {
                event.target.value = this.state.value;
                this.props.onChange(event);
            }
        );
    };

    render() {
        let innerLabel;
        if (this.props.intlLabel) {
            innerLabel = (
                <InnerLabel>
                    {this.props.intl.formatMessage({
                        id: this.props.intlLabel,
                    })}
                </InnerLabel>
            );
        } else if (this.props.label) {
            innerLabel = <InnerLabel>{this.props.label}</InnerLabel>;
        }

        return (
            <Label checked={this.state.checked}>
                <StyledCheckBox checked={this.state.checked} disabled={this.state.disabled} />
                {innerLabel}
                <Hidden
                    name={this.props.name}
                    checked={this.state.checked}
                    value={this.state.value}
                    onChange={this.handleChange}
                    type="checkbox"
                    disabled={this.state.disabled}
                    {...this.props}
                />
            </Label>
        );
    }
}

export default injectIntl(Checkbox);
