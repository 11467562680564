// @flow

import { injectGlobal } from 'styled-components';
import colors from 'styles/colors';

import Caret from 'assets/icons/Caret/Dark/Down.svg';

// eslint-disable-next-line
injectGlobal`
    .selectInput {
        background: url(${Caret}) no-repeat 98% center;
        background-color: ${colors.white};
        border: 1px solid ${colors.grayE1};
        border-radius: 3px;

        width: 100%;
        height: 30px;

        line-height: 16px;

        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        padding: 5px 30px 5px 10px;
    }

    .Select {
        &.is-open {
            .Select-control {
                border-color: ${colors.grayE1};
            }
            .Select-arrow-zone {
                .Select-arrow {
                    top: 0;
                }
            }
        }

        &.is-focused:not(.is-open) {
            > .Select-control {
                border-color: ${colors.grayE1};
                box-shadow: none;
            }
        }

        .Select-control {
            border-color: ${colors.grayE1};
            border-radius: 3px;
            overflow: auto;

            &:hover {
                box-shadow: none;
            }

            .Select-value {
                margin-left: 3px;
                margin-top: 3px;

                background-color: ${colors.grayF4};
                border-color: ${colors.grayE1};
                color: ${colors.gray66};

                .Select-value-icon {
                    border-right-color: ${colors.grayE1};
                    &:hover {
                        color: ${colors.gray7F};
                        background-color: ${colors.grayED};
                    }
                }
            }

            .Select-input {
                > input {
                    padding: 6px 0;
                }
            }

            .Select-clear-zone {
                .Select-clear {
                    line-height: 0;
                }
            }

            .Select-arrow-zone {
                padding-right: 7px;
                .Select-arrow {
                    display: block;

                    height: 28px;
                    width: 100%;

                    background: url(${Caret}) no-repeat 98% center;
                    border: 0 none;
                }
            }
        }

        .Select-control,
        .Select-input,
        .Select-placeholder {
            height: 28px;
        }

        .Select-placeholder {
            color: ${colors.gray7F};
            line-height: 28px;
        }

        .Select-menu-outer {
            border-color: ${colors.grayE1};
            .Select-menu {
                .Select-option {
                    &.is-selected,
                    &.is-focused {
                        background-color: ${colors.grayF4};
                    }
                }
            }
        }
    }
`;
