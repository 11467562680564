// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { fromJS, Map } from 'immutable';

// Components
import Button from 'components/Button';
import Image from 'components/Image';
import Thumbnail from 'components/Image/Thumbnail';

// Styles
import { Table, Tr, Td } from 'styles/common';
import { ResourceIndexContainer } from '../styles';

// Types
import type { ImmutableList, ImmutableMap } from 'types';

// Utils
import { formatDate, sentenceCase } from 'utils';

type DefaultProps = {
    onSortBy: Function,
    resourceList: ?ImmutableList<ImmutableMap<string, string>>,
};

type Props = DefaultProps & {
    currentSorting?: {
        sortBy?: string,
        sortOrder?: string,
    },
    customColumns?: {
        typeDates: number,
    },
    onCustomColumnChange?: Function,
    order?: string,
    onListItemModifyAction?: Function,
    onSelectItemsToBeDeleted?: Function,
    onSetMediaOrder?: Function,
};

type State = {
    checkAll: boolean,
    customColumns?: {
        typeDates: number,
    },
    selectedItemsToDelete: ?ImmutableList<ImmutableMap<string, string>>,
    order: string | null,
};

class ResourceMassImportIndex extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            checkAll: true,
            order: null,
            selectedItemsToDelete: fromJS([]),
            customColumns: props.customColumns || {
                typeDates: 1,
            },
        };
    }

    componentWillMount() {
        if (this.props.order) {
            this.setState({
                order: JSON.parse(this.props.order),
            });
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (nextProps.resourceList) {
            this.setState({ updating: false });
        }
    }

    renderImage = (image) => <Thumbnail maxWidth="70px" {...image} />;

    render() {
        if (!this.props.resourceList) {
            return null;
        }

        const thead = (
            <tr>
                <th>&nbsp;</th>
                <th>
                    <FormattedMessage id="components.ResourceIndex.Contents.tableHeaderFieldTitle" />
                </th>
                <th>
                    <FormattedMessage id="components.ResourceIndex.Contents.tableHeaderFieldId" />
                </th>
                <th>
                    <FormattedMessage id="components.ResourceIndex.Contents.tableHeaderFieldContributor" />
                </th>
                <th>
                    <FormattedMessage id="components.ResourceIndex.Contents.tableHeaderFieldUpdatedOn" />
                </th>
                <th>
                    <FormattedMessage id="components.ResourceIndex.Contents.tableHeaderFieldType" />
                </th>
                <th>
                    <FormattedMessage id="components.ResourceIndex.Contents.tableHeaderFieldStatus" />
                </th>
                {this.props.onListItemModifyAction && (
                    <th>
                        <FormattedMessage id="components.ResourceIndex.Contents.tableHeaderFieldActions" />
                    </th>
                )}
            </tr>
        );

        const list =
            this.props.resourceList &&
            this.props.resourceList.map((singleItem: Object) => {
                if (!singleItem || !Map.isMap(singleItem)) {
                    return null;
                }

                return (
                    <Tr
                        key={singleItem.get('id')}
                        inactive={singleItem.get('status') === 'INACTIVE'}
                    >
                        <Td padding="5px 0 5px 10px" width="80px">
                            <Link to={`/media/${singleItem.get('id')}`}>
                                <Image
                                    resource="media"
                                    id={singleItem.get('id')}
                                    alt={singleItem.get(`titleFr`)}
                                    type={singleItem.get(`type`)}
                                    render={this.renderImage}
                                    format="cropped"
                                />
                            </Link>
                        </Td>
                        <td>{singleItem.get(`titleFr`) || singleItem.get('titleEn')}</td>
                        <td>{singleItem.get('id')}</td>
                        <td>
                            {singleItem.get('contributor') &&
                                `${singleItem.get('contributor').get('firstName')} ${singleItem
                                    .get('contributor')
                                    .get('lastName')}`}
                        </td>
                        <td>{formatDate(singleItem.get('updatedAt'))}</td>
                        <td>{sentenceCase(singleItem.get('type'))}</td>
                        <td>
                            <FormattedMessage
                                id={
                                    singleItem.get('isPublished')
                                        ? 'components.ResourceIndex.Contents.isPublic'
                                        : 'components.ResourceIndex.Contents.isNotPublic'
                                }
                            />
                        </td>
                        {this.props.onListItemModifyAction && (
                            <td>
                                <Button onClick={this.props.onListItemModifyAction(singleItem)}>
                                    <FormattedMessage id="components.ResourceManagement.actionsEdit" />
                                </Button>
                            </td>
                        )}
                    </Tr>
                );
            });

        return (
            <ResourceIndexContainer>
                <Table>
                    <thead>{thead}</thead>
                    <tbody>{list}</tbody>
                </Table>
            </ResourceIndexContainer>
        );
    }
}

export default ResourceMassImportIndex;
