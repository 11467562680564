// @flow

import styled from 'styled-components';
import ContentEditable from 'react-contenteditable';
import colors from 'styles/colors';

import { FlexContainer, FlexItem, FlexItemContainer, FlexRowContainer } from 'styles/common';

export const ResourceIndexContainer = FlexContainer.extend`
    min-height: 382px;
`;

export const ResourceIndexMessage = styled.span`
    color: ${(props) => (props.error ? colors.redDark : colors.tealLight)};
    font-weight: normal;
    padding: 20px;
`;

export const ResourceIndexTable = styled.table`
    width: 100%;
    text-align: left;

    thead {
        background-color: ${colors.grayFA};
        border-top: 1px solid ${colors.grayE1};
        border-bottom: 1px solid ${colors.grayE1};
        color: ${colors.gray64};
        font-weight: normal;

        tr {
            height: 32px;
        }
    }

    tbody {
        background-color: ${colors.white};

        tr {
            height: 50px;
            box-shadow: inset 0 -1px 0 0 ${colors.grayED};
            color: ${colors.gray33};
        }
    }

    th {
        font-weight: normal;
    }

    th,
    td {
        padding: 5px 20px;
    }
`;

export const Tr = styled.tr`
    ${(props) =>
        props.inactive
            ? `
            td {
                color: ${colors.gray64}
            }
        `
            : ''};
`;

export const PaginationBarContainer = FlexRowContainer.extend`
    position: relative;
    justify-content: center;
    color: ${colors.gray64};
`;

export const PaginationSummary = styled.div`
    position: absolute;
    top: 10px;
    left: 20px;
    width: 120px;
    height: 30px;
    margin-top: 8px;
`;

export const PaginationControlsContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
`;

export const PaginationNavigationButton = styled.div`
    cursor: pointer;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-color: ${colors.white};
    box-shadow: inset 0 -1px 0 0 ${colors.grayED};
    ${(props: Object): string =>
        props.navigationSense === 'left' ? 'margin-right: 10px;' : 'margin-left: 10px;'} img {
        height: 20px;
        width: 20px;
        ${(props: Object): string =>
            props.navigationSense === 'left'
                ? 'transform: rotate(0.25turn); margin: 5px 0 0 4px;'
                : 'transform: rotate(0.75turn);margin: 5px 0 0 6px;'};
    }
`;

export const SinglePage = styled.div`
    cursor: pointer;
    color: ${colors.tealLight};
    ${(props) =>
        props.selected
            ? `
                font-weight: bold;
            `
            : ''};

    &::before {
        ${(props) =>
            props.includeSeparator
                ? `
                    content: "-";
                    padding: 0 10px;
                `
                : ''};
    }
`;

export const ControlActionsContainer = FlexRowContainer.extend`
    justify-content: space-between;
`;

export const ControlActionSearch = FlexItem.extend``;

export const ControlActionButtons = FlexItemContainer.extend`
    justify-content: flex-end;
`;

export const StyledContentEditable = styled.div`
    padding: 5px 10px;
    border: 1px solid ${colors.transparent};
    &:focus {
        outline: none;
        color: ${colors.tealDark};
        border: 1px solid ${colors.grayE1};
        border-radius: 3px;
    }
`;

export const ImportLogErrorContainer = styled.div`
    padding: 5px 0;
    display: flex;
`;

export const ImportLogErrorContainerKey = styled.div`
    display: inline-block;
    align-self: flex-start;
`;

export const ImportLogErrorContainerValue = styled.div`
    display: inline-block;
`;
