// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { camelize } from 'humps';

// Constants
import constants from './constants';

// Styles
import { Banner, Paragraph } from './styles';
import { BasicErrorMessage } from 'styles/common';

// Types
import type { ImmutableList, ImmutableMap, IntlType } from 'types';

type DefaultProps = {
    banner?: boolean,
    values?: {
        attribute?: string,
    },
};

type Props = DefaultProps & {
    basicErrorStyles?: boolean,
    error: ?ImmutableList<ImmutableMap<string, string>>,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
};

/**
 * Error Message component which pulls default messages from ./constants.js
 */

class ErrorMessage extends React.PureComponent<Props> {
    static defaultProps: DefaultProps = {
        banner: false,
        values: {},
    };

    render() {
        if (!this.props.error) {
            return null;
        }

        const error = this.props.error.first();

        if (!error) {
            return null;
        }

        const { values } = this.props;
        let message;

        if (error.get('code')) {
            message = constants[`${camelize(error.get('code'))}`];
        } else {
            message = constants.default;
        }

        if (!message) {
            return null;
        }

        const errorValues = {
            ...error.toJS(),
            ...(values || {}),
        };

        if (errorValues.attribute) {
            try {
                // Translate key and then lowecase result
                errorValues.attribute = this.props.intl
                    .formatMessage({ id: errorValues.attribute })
                    .toLowerCase();
            } catch (e) {
                if (errorValues.attribute) {
                    errorValues.attribute = errorValues.attribute.toLowerCase();
                }
            }
        }

        let errorsContainer;
        if (this.props.basicErrorStyles) {
            errorsContainer = (
                <BasicErrorMessage>
                    <FormattedMessage id={message} values={errorValues || {}} />
                </BasicErrorMessage>
            );
        } else if (this.props.banner) {
            errorsContainer = (
                <Banner>
                    <FormattedMessage id={message} values={errorValues || {}} />
                </Banner>
            );
        } else {
            errorsContainer = (
                <Paragraph>
                    <FormattedMessage id={message} values={errorValues || {}} />
                </Paragraph>
            );
        }

        return errorsContainer;
    }
}

export default injectIntl(ErrorMessage);
