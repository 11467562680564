// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

export const Hidden = styled.input`
    display: none;
`;

export const Radiobutton = styled.span`
    position: relative;
    display: inline-block;
    vertical-align: middle;

    margin-right: 10px;

    width: 20px;
    height: 20px;

    border: 1px solid ${colors.tealDark};
    border-radius: 50%;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -5px;
        margin-left: -5px;

        display: block;

        width: 10px;
        height: 10px;

        border-radius: 50%;
        background-color: ${(props) => (props.checked ? colors.tealDark : 'transparent')};
    }
`;

export const Text = styled.span`
    display: inline-block;
    vertical-align: middle;
    color: ${(props) => (props.checked ? colors.gray33 : colors.gray64)};
`;

export const TextState = Text.extend`
    opacity: ${(props) => (props.checked ? '1' : '.5')};
`;

export const Container = styled.label`
    position: relative;
    display: block;

    cursor: pointer;

    &:hover {
        ${Radiobutton} {
            &:after {
                background-color: ${colors.tealLight};
            }
        }
        ${Text} {
            color: ${colors.tealDark};
        }
    }
`;
