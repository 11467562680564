// @flow

import pluralize from 'pluralize';
import request from 'request';
import { decamelize } from 'humps';

/*
 *
 * CRUDResource resources
 *
 * Basic function: call request() with expected paramaters
 *
 */

const crudResources = (resourceName: string) => ({
    /*
    * batchAttachChildCollections: Batch attach collections to collection by id
    */
    batchAttachChildCollections: (id: number, resource: Object) =>
        request(`${pluralize(resourceName.toLowerCase())}/${id}/child-collections/batchAttach`, {
            method: 'POST',
            data: resource,
        }),

    /*
    * batchAttachMedia: Batch attach media to collection by id
    */
    batchAttachMedia: (id: number, resource: Object) =>
        request(`${pluralize(resourceName.toLowerCase())}/${id}/media/batchAttach`, {
            method: 'POST',
            data: resource,
        }),

    /*
    * batchDetachChildCollections: Batch detach collections from collection by id
    */
    batchDetachChildCollections: (id: number, resource: Object) =>
        request(`${pluralize(resourceName.toLowerCase())}/${id}/child-collections/batchDetach`, {
            method: 'DELETE',
            data: resource,
        }),

    /*
    * batchDetachMedia: Batch detach media from collection by id
    */
    batchDetachMedia: (id: number, resource: Object) =>
        request(`${pluralize(resourceName.toLowerCase())}/${id}/media/batchDetach`, {
            method: 'DELETE',
            data: resource,
        }),

    /*
    * create: Create a single entry of the resource
    */
    create: (resource): Object =>
        request(
            `${pluralize(resourceName.toLowerCase())}`,
            {
                method: 'POST',
                data: resource,
                headers: {
                    contentType: 'multipart/form-data',
                },
            },
            // eslint-disable-next-line no-undefined
            undefined,
            // eslint-disable-next-line no-undefined
            undefined,
            false
        ),

    /*
    * delete: Delete a single entry of the resource by id
    */
    delete: (id: number, deleteContent: boolean = false) =>
        request(`${pluralize(resourceName.toLowerCase())}/${id}`, {
            method: 'DELETE',
            data: {
                deleteContent,
            },
        }),

    /*
    * fetch: Fetch a single entry of the resource by id
    */
    fetch: (id: number) =>
        request(`${pluralize(resourceName.toLowerCase())}/${id}`, { method: 'GET' }),

    /*
    * fetchAll: Fetch all the entries of the resource
    */
    fetchAll: () => request(`${pluralize(resourceName.toLowerCase())}`, { method: 'GET' }),

    /*
    * fetchPaginatedBySearchCriteria:Fetch paginated entries of the resource with search criteria for filtering
    *
    * searchCriteriaUpdated: decamlizing sortBy to make backend happy
    */
    fetchPaginatedBySearchCriteria: (
        searchCriteria: Object,
        page: number = 1,
        perPage: number = 10
    ) => {
        let searchCriteriaUpdated;
        if (searchCriteria.sortBy) {
            searchCriteriaUpdated = {
                ...searchCriteria,
                sortBy: decamelize(searchCriteria.sortBy),
            };
        }
        return request(
            `${pluralize(resourceName.toLowerCase())}/fetchPaginatedBySearchCriteria`,
            {
                method: 'POST',
                data: searchCriteriaUpdated || searchCriteria,
            },
            { page, per_page: perPage }
        );
    },

    /*
    * postCollectionOrder: Set/Update collection's order within collection by id
    */
    postCollectionOrder: (collectionId: number, childCollectionId: number, order: number) =>
        request(
            `${pluralize(
                resourceName.toLowerCase()
            )}/${collectionId}/child-collection/${childCollectionId}/order`,
            {
                method: 'POST',
                data: {
                    order,
                },
            }
        ),

    /*
    * postMediaOrder: Set/Update media's order within collection by id
    */
    postMediaOrder: (collectionId: number, mediaId: number, order: number) =>
        request(`${pluralize(resourceName.toLowerCase())}/${collectionId}/media/${mediaId}/order`, {
            method: 'POST',
            data: {
                order,
            },
        }),

    /*
    * update: Update a single entry of the resource by id
    */
    update: (id: number, resource: Object) =>
        request(
            `${pluralize(resourceName.toLowerCase())}/${id}`,
            {
                method: 'POST',
                data: resource,
                headers: {
                    contentType: 'multipart/form-data',
                },
            },
            // eslint-disable-next-line no-undefined
            undefined,
            // eslint-disable-next-line no-undefined
            undefined,
            false
        ),
});

export default crudResources;
