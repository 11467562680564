// @flow

/*
 *
 * CRUDResource actions
 *
 * crudResourceActionTypes: Define constant types for each action
 * crudResourceActions: For each type, define action to pass data as payload
 *
 */

export const crudResourceActionTypes = (resourceName: string) => ({
    // Paginated Resource list
    FETCH_PAGINATED_IS_FETCHING: `app/services/${resourceName}/FETCH_PAGINATED_IS_FETCHING`,
    // Paginated Resource list filtered by search criteria
    FETCH_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS: `app/services/${resourceName}/FETCH_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS`,
    FETCH_PAGINATED_BY_SEARCH_CRITERIA_FAILURE: `app/services/${resourceName}/FETCH_PAGINATED_BY_SEARCH_CRITERIA_FAILURE`,
    // Fetch single
    FETCH_SUCCESS: `app/services/${resourceName}/FETCH_SUCCESS`,
    FETCH_FAILURE: `app/services/${resourceName}/FETCH_FAILURE`,
    // Create new
    CREATE_SUCCESS: `app/services/${resourceName}/CREATE_SUCCESS`,
    CREATE_FAILURE: `app/services/${resourceName}/CREATE_FAILURE`,
    // Update single
    UPDATE_SUCCESS: `app/services/${resourceName}/UPDATE_SUCCESS`,
    UPDATE_FAILURE: `app/services/${resourceName}/UPDATE_FAILURE`,
    // Delete single
    DELETE_SUCCESS: `app/services/${resourceName}/DELETE_SUCCESS`,
    DELETE_FAILURE: `app/services/${resourceName}/DELETE_FAILURE`,
    // Reset errors
    RESET_ERRORS: `app/services/${resourceName}/RESET_ERRORS`,
});

export const crudResourceActions = (resourceName: string) => ({
    receivedFetchPaginatedIsFetching: (isFetching) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_PAGINATED_IS_FETCHING,
        payload: { isFetching },
    }),
    receivedFetchPaginatedBySearchCriteriaSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string))
            .FETCH_PAGINATED_BY_SEARCH_CRITERIA_SUCCESS,
        payload: { data },
    }),
    receivedFetchPaginatedBySearchCriteriaFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string))
            .FETCH_PAGINATED_BY_SEARCH_CRITERIA_FAILURE,
        payload: { errors },
    }),
    receivedFetchSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_SUCCESS,
        payload: { data },
    }),
    receivedFetchFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).FETCH_FAILURE,
        payload: { errors },
    }),
    receivedCreateSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).CREATE_SUCCESS,
        payload: { data },
    }),
    receivedCreateFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).CREATE_FAILURE,
        payload: { errors },
    }),
    receivedUpdateSuccess: (data) => ({
        type: crudResourceActionTypes((resourceName: string)).UPDATE_SUCCESS,
        payload: { data },
    }),
    receivedUpdateFailure: (errors) => ({
        type: crudResourceActionTypes((resourceName: string)).UPDATE_FAILURE,
        payload: { errors },
    }),
    resetErrors: () => ({
        type: crudResourceActionTypes((resourceName: string)).RESET_ERRORS,
        payload: {},
    }),
});
