// @flow

// Libraries
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

// components
import Modal from 'components/Modal';

// Style
import { SecondarySpan } from 'styles/buttons';
import { Ul, Li } from './styles';

// ENV vars
import { API_URL, API_VER } from 'env';

type Props = {
    id: ?number,
    label: string,
    type?: string,
    declinations?: Object,
    originalFile?: Object,
};

type State = {
    showDownloadsList: boolean,
};

/**
 * Download component for Single Media View
 */

class Download extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            showDownloadsList: false,
        };
    }

    handleClick = () => {
        this.setState({ showDownloadsList: true });
    };

    handleCloseModal = () => {
        this.setState({ showDownloadsList: false });
    };

    render() {
        const { id, declinations, label, originalFile, type } = this.props;

        return (
            <React.Fragment>
                {type !== 'video' && (
                    <SecondarySpan>
                        <a href={`${API_URL}${API_VER}media/download/${id}`} download>
                            {label}
                        </a>
                    </SecondarySpan>
                )}
                {type === 'video' && (
                    <SecondarySpan onClick={this.handleClick}>{label}</SecondarySpan>
                )}
                {declinations &&
                    originalFile && (
                        <Modal
                            simple
                            onModalClose={this.handleCloseModal}
                            show={this.state.showDownloadsList}
                        >
                            <Ul>
                                <Li key={originalFile.get('id')}>
                                    <a
                                        href={`${API_URL}${API_VER}file/download/${originalFile.get(
                                            'id'
                                        )}`}
                                        download
                                    >
                                        <FormattedMessage id="components.Download.originalVersion" />
                                    </a>
                                </Li>
                                {this.props.declinations.map((declination: Object) => (
                                    <Li key={declination.get('id')}>
                                        <a
                                            href={`${API_URL}${API_VER}file/download/${declination.get(
                                                'id'
                                            )}`}
                                            download
                                        >
                                            {declination.get('resolution')}
                                        </a>
                                    </Li>
                                ))}
                            </Ul>
                        </Modal>
                    )}
            </React.Fragment>
        );
    }
}

export default injectIntl(Download);
