// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withRouter } from 'react-router-dom';

// Components
import Loading from 'components/Loading';
import ResourceUserIndex from './Resources/ResourceUsersIndex';
import ResourceApplicationIndex from './Resources/ResourceApplicationIndex';
import ResourceCollectionIndex from './Resources/ResourceCollectionIndex';
import ResourceMassImportIndex from './Resources/ResourceMassImportIndex';
import ResourceMediaIndex from './Resources/ResourceMediaIndex';

// Styles
import { ResourceIndexContainer, ResourceIndexMessage } from './styles';

// Types
import type { ImmutableList, ImmutableMap } from 'types';

// Utils
import { RESOURCE_IDS } from 'utils/constants';

type DefaultProps = {
    location: Object,
    onListItemModifyAction: Function,
    resourceList: ?ImmutableList<ImmutableMap<string, string>>,
    resourceListIsFetching: ?boolean,
    resourceType: ?string,
};

type Props = DefaultProps & {
    currentSorting?: {
        sortBy?: string,
        sortOrder?: string,
    },
    customColumns?: Object,
    onCustomColumnChange?: Function,
    onSortBy?: Function,
};

/**
 * Shared resource index junction which displays the appropriate Resource/index based on provided resourceType
 */

class ResourceIndex extends React.PureComponent<Props> {
    handleOnClickItemObj = (item: ImmutableMap<string, string>) => () => {
        this.props.onListItemModifyAction(item.toJS());
    };

    handleOnClickItem = (item: ImmutableMap<string, string>) => () => {
        this.props.onListItemModifyAction(item);
    };

    render() {
        // If ResourceManagement is still mounting display nothing (a spinner will be displayed by the top component)
        if (!this.props.resourceList) {
            return (
                <ResourceIndexContainer alignItems="center">
                    <ResourceIndexMessage error>
                        <FormattedMessage id="components.ErrorMessage.default" />
                    </ResourceIndexMessage>
                </ResourceIndexContainer>
            );
        }

        // If a new page is now being fetched display a spinner for the resoruce list portion of the page only
        if (this.props.resourceListIsFetching) {
            return (
                <ResourceIndexContainer>
                    <Loading loading />
                </ResourceIndexContainer>
            );
        }

        let index;
        switch (this.props.resourceType) {
            case RESOURCE_IDS.APPLICATION:
                index = (
                    <ResourceApplicationIndex
                        {...this.props}
                        currentSorting={this.props.currentSorting}
                        onListItemModifyAction={this.handleOnClickItemObj}
                        onSortBy={this.props.onSortBy}
                    />
                );
                break;
            case RESOURCE_IDS.COLLECTION:
                index = (
                    <ResourceCollectionIndex
                        {...this.props}
                        currentSorting={this.props.currentSorting}
                        customColumns={this.props.customColumns}
                        onCustomColumnChange={this.props.onCustomColumnChange}
                        onListItemModifyAction={this.handleOnClickItem}
                        onSortBy={this.props.onSortBy}
                    />
                );
                break;
            case RESOURCE_IDS.MEDIA:
                index = (
                    <ResourceMediaIndex
                        {...this.props}
                        currentSorting={this.props.currentSorting}
                        customColumns={this.props.customColumns}
                        onCustomColumnChange={this.props.onCustomColumnChange}
                        onListItemModifyAction={this.handleOnClickItem}
                        onSortBy={this.props.onSortBy}
                    />
                );
                break;
            default:
                index = (
                    <ResourceUserIndex
                        {...this.props}
                        currentSorting={this.props.currentSorting}
                        onListItemModifyAction={this.handleOnClickItemObj}
                        onSortBy={this.props.onSortBy}
                    />
                );
        }

        if (this.props.location.pathname && this.props.location.pathname.includes('import')) {
            index = (
                <ResourceMassImportIndex
                    {...this.props}
                    onListItemModifyAction={this.handleOnClickItem}
                />
            );
        }

        return index;
    }
}

export default withRouter(ResourceIndex);
