// @flow

import React from 'react';

// Styles
import { Label } from './styles';
import { FlexContainer, FlexItemContainer } from 'styles/common';

// Types
import type { ReactNode } from 'types';

type Props = {
    children: ReactNode,
    inputHeight?: boolean,
    tag?: ?string,
};

/**
 * Shared styling component to create right indent for input tag
 */

const InputTag = ({ children, inputHeight, tag }: Props) => (
    <FlexContainer justifyContent="space-between" gutter="10">
        <FlexItemContainer direction="column" justifyContent="center">
            {children}
        </FlexItemContainer>
        <FlexItemContainer direction="column" justifyContent="center" height={inputHeight ? '30px' : 'auto'} maxWidth="52px">
            <Label>{tag}</Label>
        </FlexItemContainer>
    </FlexContainer>
);

InputTag.defaultProps = {
    inputHeight: false,
    tag: null,
};

export default InputTag;
