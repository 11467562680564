// @flow

import styled from 'styled-components';

export const FileInputContainer = styled.div`
    ${(props) =>
        props.inline
            ? `
        display: inline-block;
    `
            : `
        display: block;
        width: 100%;
    `};
`;

export const Hidden = styled.input`
    display: none;
`;
