// @flow

import React from 'react';

// Styles
import { Title, TitleContainer } from './styles';
import { FlexContainer, FlexItem } from 'styles/common';

// Types
import type { ReactNode } from 'types';

type Props = {
    main?: boolean,
    text: string,
    children?: ?ReactNode,
};

/**
 * Shared section title with possible right side content
 */

const SectionTitle = (props: Props) => (
    <TitleContainer>
        <FlexItem>
            <Title main={props.main}>{props.text}</Title>
        </FlexItem>
        <FlexItem>
            {props.children ? (
                <FlexContainer justifyContent="flex-end">{props.children}</FlexContainer>
            ) : null}
        </FlexItem>
    </TitleContainer>
);

SectionTitle.defaultProps = {
    children: null,
    main: false,
};

export default SectionTitle;
