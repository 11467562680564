// @flow

import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';

// Components
import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import Input from 'components/Form/Input';

// Services
import { selectErrors } from 'services/Authentication/selectors';
import { authenticateUser } from 'services/Authentication/thunks';

// Styles
import { Container, Content, ForgotPasswordLink } from './styles';
import { PrimaryButton } from 'styles/buttons';
import { Line } from 'styles/common';
import {
    FormGroup,
    FormTitleGroup,
    FormTitle,
    Label,
    SubmitContainer,
    ErrorsContainer,
} from 'styles/form';

// Types
import type { AuthenticateUserType } from 'services/Authentication/thunks';
import type { ImmutableMap, InputEvent, IntlType } from 'types';

type Props = {
    /** authenticates user with backend */
    authenticateUser: AuthenticateUserType,
    /** ImmutableMap of errors from backend upon request */
    errors: ?ImmutableMap<string, Object>,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
};

type State = {
    email: string,
    password: string,
    loading: boolean,
};

/**
 * Login Page.
 *
 * Login view which allows users to log into their account
 * And provides access the forgot your password page
 *
 */

export class LoginPage extends React.Component<Props, State> {
    state = {
        email: '',
        password: '',
        loading: false,
    };

    componentWillReceiveProps() {
        this.setState({
            loading: false,
        });
    }

    handleChangeField = (name: string) => ({ target: { value } }: InputEvent) => {
        this.setState({
            [name]: value,
        });
    };

    /**
     * Submit form to send request to backend
     */
    handleSubmitForm = (evt: Event) => {
        evt.preventDefault();

        this.setState({
            loading: true,
        });

        this.props.authenticateUser(this.state.email, this.state.password);
    };

    getValidationState = (error: ?string) => (error ? 'error' : null);

    render() {
        return (
            <Container>
                <Content>
                    <Helmet>
                        <title>
                            {this.props.intl.formatMessage({
                                id: 'containers.LoginPage.helmetTitle',
                            })}
                        </title>
                        <meta name="description" content="Login page" />
                    </Helmet>
                    {this.state.loading ? (
                        <Loading loading />
                    ) : (
                        <div>
                            <FormTitleGroup>
                                <FormTitle data-test-id="headerText">
                                    <FormattedMessage id="containers.LoginPage.formTitle" />
                                </FormTitle>
                            </FormTitleGroup>

                            <form onSubmit={this.handleSubmitForm} data-test-id="loginForm">
                                <FormGroup
                                    validationState={this.getValidationState(
                                        this.props.errors &&
                                            (this.props.errors.get('email') ||
                                                this.props.errors.get('login'))
                                    )}
                                >
                                    <Label htmlFor="email">
                                        <FormattedMessage id="containers.LoginPage.emailLabel" />
                                    </Label>

                                    <Input
                                        name="email"
                                        type="email"
                                        placeholder={'containers.LoginPage.emailPlaceholder'}
                                        onChange={this.handleChangeField('email')}
                                        disabled={this.state.loading}
                                        value={this.state.email}
                                        data-test-id="emailInput"
                                    />
                                </FormGroup>

                                <FormGroup
                                    validationState={this.getValidationState(
                                        this.props.errors &&
                                            (this.props.errors.get('password') ||
                                                this.props.errors.get('login'))
                                    )}
                                >
                                    <Label htmlFor="password">
                                        <FormattedMessage id="containers.LoginPage.passwordLabel" />
                                    </Label>

                                    <Input
                                        name="password"
                                        type="password"
                                        placeholder={'containers.LoginPage.passwordPlaceholder'}
                                        onChange={this.handleChangeField('password')}
                                        disabled={this.state.loading}
                                        value={this.state.password}
                                        data-test-id="passwordInput"
                                    />
                                </FormGroup>

                                <ForgotPasswordLink>
                                    <Link to="/forgot-password">
                                        <FormattedMessage id="containers.LoginPage.passwordReset" />
                                    </Link>
                                </ForgotPasswordLink>

                                <Line />

                                <SubmitContainer>
                                    <PrimaryButton
                                        disabled={
                                            this.state.loading ||
                                            !this.state.email ||
                                            !this.state.password
                                        }
                                        data-test-id="submitButton"
                                    >
                                        <FormattedMessage id="containers.LoginPage.submitLogin" />
                                    </PrimaryButton>
                                </SubmitContainer>
                                <ErrorsContainer
                                    hidden={!this.props.errors || !this.props.errors.size}
                                >
                                    <ErrorMessage
                                        error={this.props.errors && this.props.errors.get('login')}
                                    />
                                    <ErrorMessage
                                        error={this.props.errors && this.props.errors.get('email')}
                                        values={{ attribute: 'containers.LoginPage.emailLabel' }}
                                    />
                                    <ErrorMessage
                                        error={
                                            this.props.errors && this.props.errors.get('password')
                                        }
                                        values={{ attribute: 'containers.LoginPage.passwordLabel' }}
                                    />
                                    <p>
                                        <FormattedMessage id="components.ErrorMessage.tryAgain" />
                                    </p>
                                </ErrorsContainer>
                            </form>
                        </div>
                    )}
                </Content>
            </Container>
        );
    }
}

const mapStateToProps = createStructuredSelector({
    errors: selectErrors(),
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            authenticateUser,
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(injectIntl(LoginPage))
);
