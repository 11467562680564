// @flow

import React from 'react';

// Components
import LoginLink from './LoginLink';
import LogoutLink from './LogoutLink';

// Styles
import { HeaderContainer, Navbar, PageTitle, Navigation, NavList } from './styles';

// Types
import type { LogoutUserType } from 'services/Authentication/thunks';
import type { ImmutableMap, UserType } from 'types';

type Props = {
    pageTitle: ?string,
    user: ?ImmutableMap<string, UserType>,
    onLogout: LogoutUserType,
    shouldDisplayAuthenticationLinks: ?boolean,
};

/**
 * Header component for privateRoutes
 */

function Header(props: Props) {
    return (
        <HeaderContainer>
            <Navbar>
                <PageTitle>{props.pageTitle}</PageTitle>
                {props.shouldDisplayAuthenticationLinks && (
                    <Navigation>
                        <NavList>
                            {props.user && <LogoutLink onLogout={props.onLogout} />}
                            {!props.user && <LoginLink />}
                        </NavList>
                    </Navigation>
                )}
            </Navbar>
        </HeaderContainer>
    );
}

export default Header;
