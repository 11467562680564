// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { createStructuredSelector } from 'reselect';
import { fromJS } from 'immutable';

// Components
import Loading from 'components/Loading';
import User from 'components/User';

// Styles
import { PrimaryButton, SecondaryButton } from 'styles/buttons';
import { LabelSmall } from 'styles/form';
import {
    FlexContainer,
    FlexItemContainer,
    Indented,
    ActionsContainer,
    ModalActionsWrapper,
} from 'styles/common';

// Services
import { selectUser } from 'services/Authentication/selectors';
import establishmentResource from 'services/Establishment';
import roleResource from 'services/Role';
import userResource from 'services/User';

// Types
import type {
    EstablishmentType,
    ImmutableMap,
    IntlType,
    InputEvent,
    ReduxDispatch,
    UserType,
} from 'types';

// Utils
import { formatDate } from 'utils';

type Props = {
    createUser: Function,
    errors: ImmutableMap<string, Object>,
    establishments: ImmutableMap<string, EstablishmentType>,
    getEstablishments: Function,
    getRoles: Function,
    getUser: Function,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    onModalShow?: Function,
    resetErrors: Function,
    resetPassword: Function,
    roles: ImmutableMap<string, EstablishmentType>,
    updateUser: Function,
    userId?: number,
    user?: UserType,
};

type State = {
    user: ?ImmutableMap<string, mixed>,
    loading: boolean,
};

/**
 * User Form: <User /> wrapper with model controls
 */

class UserForm extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            user: props.user || fromJS({}),
        };
    }

    componentDidMount() {
        this.props.resetErrors();
        if (!this.props.establishments.size) {
            this.props.getEstablishments();
        }

        if (this.props.userId && !this.props.user) {
            this.props.getUser(this.props.userId);
        }

        if (!this.props.roles) {
            this.props.getRoles();
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        const { user } = nextProps;
        if (user && user !== this.props.user) {
            this.setState({
                user,
            });
        }

        this.setState({
            loading: false,
        });
    }

    handleOnCancel = (event: Event) => {
        if (this.props.onModalShow) {
            this.props.onModalShow(false, true)(event);
        }
    };

    handleChangeField = (name: string, isCheckbox: boolean = false) => ({ target }: InputEvent) => {
        let value = null;

        if (isCheckbox && target.checked) {
            value = target.checked;
        } else if (target.value) {
            value = target.value;
        }

        this.setState((prevState: State) => {
            const user = prevState.user && prevState.user.set(name, value);
            return { user };
        });
    };

    handleOnSubmitForm = (event: Event) => {
        this.setState({
            loading: true,
        });

        const { user } = this.state;

        if (user && user.has('id')) {
            this.props.updateUser(user.get('id'), user && user.toJS(), '/users');
        } else {
            this.props.createUser(user && user.toJS());
        }
    };

    render() {
        if (
            (this.state.loading && this.props.errors && this.props.errors.isEmpty()) ||
            (this.props.userId && !this.props.user)
        ) {
            return <Loading />;
        }

        const { user } = this.state;
        const updatedAt = user && user.get('updatedAt');
        let timestamp = null;
        if (updatedAt) {
            timestamp = formatDate(updatedAt);
        }

        return (
            <div>
                <Indented>
                    <User
                        currentUser={this.props.currentUser}
                        mode={user && user.has('id') ? 'edit' : 'create'}
                        user={user}
                        handleChangeField={this.handleChangeField}
                        errors={this.props.errors}
                        roles={this.props.roles}
                        establishments={this.props.establishments}
                        resetPassword={this.props.resetPassword}
                        loading={this.state.loading}
                    />
                </Indented>
                <ModalActionsWrapper>
                    <FlexContainer justifyContent="space-between" gutter="30">
                        {timestamp ? (
                            <FlexItemContainer direction="column" textAlign="left">
                                <LabelSmall marginless>
                                    <FormattedMessage
                                        id="global.lastUpdate"
                                        values={{ time: timestamp }}
                                    />
                                </LabelSmall>
                            </FlexItemContainer>
                        ) : null}
                        <ActionsContainer>
                            <PrimaryButton onClick={this.handleOnSubmitForm}>
                                {this.props.intl.formatMessage({
                                    id: user ? 'global.saveAndSend' : 'global.saveChanges',
                                })}
                            </PrimaryButton>
                            <SecondaryButton onClick={this.handleOnCancel}>
                                {this.props.intl.formatMessage({
                                    id: 'global.cancel',
                                })}
                            </SecondaryButton>
                        </ActionsContainer>
                    </FlexContainer>
                </ModalActionsWrapper>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) =>
    createStructuredSelector({
        ...(ownProps && ownProps.userId
            ? {
                  user: userResource()
                      .selectors()
                      .select(Number(ownProps && ownProps.userId)),
              }
            : {}),
        errors: userResource()
            .selectors()
            .selectErrors(),
        establishments: establishmentResource()
            .selectors()
            .selectAll(),
        roles: roleResource()
            .selectors()
            .selectAll(),
        currentUser: selectUser(),
    });

const mapDispatchToProps = (dispatch: ReduxDispatch) =>
    bindActionCreators(
        {
            createUser: userResource().thunks().create,
            updateUser: userResource().thunks().update,
            getEstablishments: establishmentResource().thunks().fetchAll,
            getRoles: roleResource().thunks().fetchAll,
            getUser: userResource().thunks().fetch,
            resetPassword: userResource().thunks().resetPassword,
            resetErrors: userResource().thunks().resetErrors,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(injectIntl(UserForm));
