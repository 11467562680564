// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Flex, Box } from 'grid-styled';
import { fromJS } from 'immutable';

import { formatDate } from 'utils';

// Components
import ToggleSwitchBoolean from 'components/Form/ToggleSwitchBoolean';

// Styles
import { Indented, WhiteBlock, TimeStamps, Line, LineHeightFix } from 'styles/common';
import { Label } from 'styles/form';

// Types
import type { ImmutableList, ImmutableMap, InputEvent, UserType } from 'types';

// Utils
import { isAdminOrAbove, isReviewer, isTranslator } from 'utils/authentication';

type Props = {
    onChangeFieldFromState: Function,
    onChangeFieldFromStateMultiple: Function,
    data?: ImmutableMap<string, mixed>,
    user?: UserType,
};

type State = {
    reviews?: ?ImmutableList<string>,
    translations?: ?ImmutableList<string>,
    data?: ImmutableMap<string, mixed>,
};

/**
 * General Function of Component (To be validated/edited/removed)
 *
 * Contributor creates entity (Media/Collection)
 * By default, entity's review & translation is requested based on the presences of titleFr/titleEn
 * Admin is able to turn off requested review/translation which creates a reviewedAt/translatedAt entry
 * Reviwer is able to turn off requested review which updates the associated entry with reviewedAt
 * Translator is able to turn off requested translations which updates the associated entry with translatedAt
 */

export default class RevisionTranslationBlock extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const currentUserId = this.props.user && this.props.user.get('id');
        const data = props.data || null;
        const titleFrHasContent =
            (data && data.get('titleFr') && data.get('titleFr').length !== 0) || false;
        const titleEnHasContent =
            (data && data.get('titleEn') && data.get('titleEn').length !== 0) || false;

        const reviews =
            data && data.get('reviews') && data.get('reviews').size
                ? data.get('reviews')
                : fromJS([
                      {
                          locale: 'fr',
                          requested: titleFrHasContent, // true if titleFr has content
                          userId: currentUserId,
                          type: 'reviews',
                      },
                      {
                          locale: 'en',
                          requested: titleEnHasContent, // true if titleEn has content
                          userId: currentUserId,
                          type: 'reviews',
                      },
                  ]);
        const translations =
            data && data.get('translations') && data.get('translations').size
                ? data.get('translations')
                : fromJS([
                      {
                          locale: 'fr',
                          requested: !titleFrHasContent, // true if titleFr is empty
                          userId: currentUserId,
                          type: 'translations',
                      },
                      {
                          locale: 'en',
                          requested: !titleEnHasContent, // true if titleEn is empty
                          userId: currentUserId,
                          type: 'translations',
                      },
                  ]);
        this.state = {
            data,
            reviews,
            translations,
        };
    }

    componentDidMount() {
        // Send default review/translation state in case no changes are made (handleChange())
        this.props.onChangeFieldFromStateMultiple({
            reviews: this.filterUpdateOrRquestedEntries('reviews'),
            translations: this.filterUpdateOrRquestedEntries('translations'),
        });
    }

    // Filter objects that have 'id' (Updated DB entries) or requested === true (Newly created entries)
    filterUpdateOrRquestedEntries = (targetState: string) =>
        (this.state[targetState] &&
            this.state[targetState].filter(
                (obj: Object) => obj.get('requested') === true || obj.has('id')
            )) ||
        null;

    /**
     * On handleChange
     */
    handleChange = (targetState: string, locale: string) => ({
        target: { checked },
    }: InputEvent) => {
        // Verify user & their role
        const { user } = this.props;
        const userId = user && user.get('id');
        const role = user && user.get('role');
        const targetedRole =
            targetState === 'reviews' ? role && isReviewer(role) : role && isTranslator(role);
        const isAuthorized = role && (isAdminOrAbove(role) || targetedRole);

        if (isAuthorized) {
            // Get targetedKey based on targetState
            const targetedIdKey = targetState === 'reviews' ? 'reviewerId' : 'translatorId';

            // Get current state via targetState
            const currentState = this.state[targetState];
            // Find index of targeted entity based on locale (expected to be unique)
            const indexOfObjectToUpdate =
                currentState && currentState.findIndex((obj: {}) => obj.get('locale') === locale);

            let newState;
            if (!checked) {
                // If unchecked: make review/translation (targetState)
                // as reviewed/translated by setting reviwerId/translatorId (targetedIdKey)
                newState =
                    currentState &&
                    currentState.setIn([indexOfObjectToUpdate, targetedIdKey], userId);
            } else {
                // Create new entry
                const newEntry = fromJS({
                    locale,
                    requested: true,
                    userId,
                    type: targetState,
                });
                // If no index is found based on locale, push newEntry, else setIn newEntry
                newState =
                    indexOfObjectToUpdate === -1
                        ? currentState && currentState.push(newEntry)
                        : currentState && currentState.setIn([indexOfObjectToUpdate], newEntry);
            }
            if (newState) {
                this.setState(
                    {
                        [targetState]: newState,
                    },
                    () =>
                        this.props.onChangeFieldFromState(
                            targetState,
                            this.filterUpdateOrRquestedEntries(targetState)
                        )
                );
            }
        }
    };

    renderTimestamp = (
        map?: ImmutableMap<string, mixed>,
        review: boolean = true,
        disabled: boolean = false
    ) => {
        const role = review ? 'reviewer' : 'translator';
        const doneAt = review ? 'reviewedAt' : 'translatedAt';
        const doneAtIntlId = review
            ? 'components.RevisionTranslationBlock.inputLabelValueRevisedBy'
            : 'components.RevisionTranslationBlock.inputLabelValueTranslatedBy';
        return (
            <TimeStamps disabled={disabled}>
                {map &&
                    map.get('createdAt') && (
                        <FormattedMessage
                            id="components.RevisionTranslationBlock.inputLabelValueSentBy"
                            values={{
                                date: map && formatDate(map.get('createdAt')),
                                name:
                                    map &&
                                    map.get('user') &&
                                    `${map.get('user').get('firstName')} ${map
                                        .get('user')
                                        .get('lastName')}`,
                            }}
                        />
                    )}
                <br />
                {map &&
                    map.get(doneAt) && (
                        <FormattedMessage
                            id={doneAtIntlId}
                            values={{
                                date: map && formatDate(map.get(doneAt)),
                                name:
                                    map &&
                                    map.get(role) &&
                                    `${map.get(role).get('firstName')} ${map
                                        .get(role)
                                        .get('lastName')}`,
                            }}
                        />
                    )}
            </TimeStamps>
        );
    };

    renderRevisionBlockByLanguage = (role: string, lang: string) => {
        const { reviews } = this.state;

        // Set flag to disable inputs if not reviwer, admin or above
        const disableInput = !role || (!isReviewer(role) && !isAdminOrAbove(role));

        // review: Filtered reviews for one with locale === lang
        const review = reviews && reviews.find((obj: Object) => obj.get('locale') === lang);
        // reviewIsRequested: If review's (requested is true or has id) & if reviewedAt is null
        const reviewIsRequested =
            (review &&
                (review.get('requested') || review.has('id')) &&
                !review.get('reviewedAt')) ||
            false;
        const reviewIsReviewed = review && review.get('reviewedAt');

        return (
            <Flex>
                <Box py={1} width={3 / 12}>
                    <ToggleSwitchBoolean
                        disabled={disableInput || (reviewIsReviewed && role && isReviewer(role))}
                        id={`reviews${lang}`}
                        labelOn={'global.yes'}
                        labelOff={'global.no'}
                        name={`reviews${lang}`}
                        onChange={this.handleChange('reviews', lang)}
                        onOrOff
                        value={reviewIsRequested && !reviewIsReviewed}
                    />
                </Box>
                <Box width={2 / 12}>
                    <label htmlFor={`reviews${lang}`}>
                        <LineHeightFix>
                            <FormattedMessage id={`global.${lang}`} />
                        </LineHeightFix>
                    </label>
                </Box>
                <Box py={1} width={7 / 12}>
                    {this.renderTimestamp(
                        review,
                        true,
                        disableInput || (reviewIsReviewed && role && isReviewer(role)) || false
                    )}
                </Box>
            </Flex>
        );
    };

    renderTranslationBlockByLanguage = (role: string, lang: string) => {
        const { translations } = this.state;

        // Set flag to disable inputs if not translator, admin or above
        const disableInput = !role || (!isTranslator(role) && !isAdminOrAbove(role));

        // translation: Filtered translations for one with locale === lang
        const translation =
            translations && translations.find((obj: Object) => obj.get('locale') === lang);
        // translationIsRequested: If translation's (requested is true or has id) & if translatedAt is null
        const translationIsRequested =
            (translation &&
                (translation.get('requested') || translation.has('id')) &&
                !translation.get('translatedAt')) ||
            false;
        const translationIsTranslated = translation && translation.get('translatedAt');

        return (
            <Flex>
                <Box py={1} width={3 / 12}>
                    <ToggleSwitchBoolean
                        disabled={
                            disableInput || (translationIsTranslated && role && isTranslator(role))
                        }
                        id={`translations${lang}`}
                        labelOn={'global.yes'}
                        labelOff={'global.no'}
                        name={`translations${lang}`}
                        onChange={this.handleChange('translations', lang)}
                        onOrOff
                        value={translationIsRequested && !translationIsTranslated}
                    />
                </Box>
                <Box width={2 / 12}>
                    <label htmlFor={`translations${lang}`}>
                        <LineHeightFix>
                            <FormattedMessage id={`global.${lang}`} />
                        </LineHeightFix>
                    </label>
                </Box>
                <Box py={translationIsTranslated ? null : 1} width={7 / 12}>
                    {this.renderTimestamp(
                        translation,
                        true,
                        disableInput ||
                            (translationIsTranslated && role && isTranslator(role)) ||
                            false
                    )}
                </Box>
            </Flex>
        );
    };

    render() {
        const { user } = this.props;
        const role = user && user.get('role');
        return (
            <React.Fragment>
                <WhiteBlock>
                    <Indented>
                        <Flex>
                            <Box width={2 / 10}>
                                <Label
                                    disabled={!role || (!isReviewer(role) && !isAdminOrAbove(role))}
                                >
                                    <LineHeightFix>
                                        <FormattedMessage id="components.RevisionTranslationBlock.inputLabelValueRevisions" />
                                    </LineHeightFix>
                                </Label>
                            </Box>
                            <Box width={8 / 10}>
                                {role && this.renderRevisionBlockByLanguage(role, 'fr')}
                                <Line margin="10px 0" />
                                {role && this.renderRevisionBlockByLanguage(role, 'en')}
                            </Box>
                        </Flex>
                    </Indented>
                </WhiteBlock>
                <WhiteBlock>
                    <Indented>
                        <Flex>
                            <Box width={2 / 10}>
                                <Label
                                    disabled={
                                        !role || (!isTranslator(role) && !isAdminOrAbove(role))
                                    }
                                >
                                    <FormattedMessage id="components.RevisionTranslationBlock.inputLabelValueTranslations" />
                                </Label>
                            </Box>
                            <Box width={8 / 10}>
                                {role && this.renderTranslationBlockByLanguage(role, 'fr')}
                                <Line margin="10px 0" />
                                {role && this.renderTranslationBlockByLanguage(role, 'en')}
                            </Box>
                        </Flex>
                    </Indented>
                </WhiteBlock>
            </React.Fragment>
        );
    }
}
