// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import ErrorMessage from 'components/ErrorMessage';
import Modal from 'components/Modal';
import Input from 'components/Form/Input';
import SectionTitle from 'components/SectionTitle';
import Select from 'components/Form/Select';
import Textarea from 'components/Form/Textarea';
import ToggleSwitch from 'components/Form/ToggleSwitch';

// Styles
import { PrimaryButton, ReverseButton, SecondaryButton } from 'styles/buttons';
import { FormGroup, InputNotice, Label, LabelSmall } from 'styles/form';
import {
    FlexContainer,
    FlexItem,
    FlexItemContainer,
    FlexItemCrossAxisEnd,
    InformationMessage,
    ActionsContainer,
} from 'styles/common';
import { UserContainer, UserFormContainer } from './styles';

// Types
import type { EstablishmentType, ImmutableMap, IntlType, RoleType, UserType } from 'types';

// Utils
import { isSuperAdmin } from 'utils/authentication';
import { protectedRoles, titles } from 'utils/constants';

type Props = {
    currentUser?: ImmutableMap<string, Object>,
    errors: ImmutableMap<string, Object>,
    establishments: ImmutableMap<string, EstablishmentType>,
    handleChangeField: Function,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    loading: boolean,
    mode?: 'create' | 'edit' | 'read',
    resetPassword: Function,
    roles: ImmutableMap<string, EstablishmentType>,
    user?: UserType,
};

type State = {
    showResetPasswordModal: boolean,
};

/**
 * User create/edit form
 */

class User extends React.Component<Props, State> {
    state = {
        showResetPasswordModal: false,
    };

    handleOnClickResetPassword = (event: Event) => {
        event.preventDefault();
        this.setState({
            showResetPasswordModal: true,
        });
    };

    handleOnCancelResetPasswordModal = () => {
        this.setState({
            showResetPasswordModal: false,
        });
    };

    handleOnSubmitResetPasswordModal = () => {
        if (this.props.user) {
            this.props.resetPassword(this.props.user.get('id'));
        }

        this.setState({
            showResetPasswordModal: false,
        });

        // TODO enable?
        // this.props.getUser(this.props.userId);
    };

    getValidationState = (error: ?boolean) => (error ? 'error' : null);

    getProcessedErrorResponseForField = (fieldName: string) => {
        const { errors } = this.props;
        return errors && errors.size && errors.getIn(['data', 'errors', fieldName, '0', 'code']);
    };

    renderResetPasswordModalActions = () => (
        <ActionsContainer>
            <PrimaryButton onClick={this.handleOnSubmitResetPasswordModal}>
                {this.props.intl.formatMessage({
                    id: 'components.User.password.reset',
                })}
            </PrimaryButton>
            <SecondaryButton onClick={this.handleOnCancelResetPasswordModal}>
                {this.props.intl.formatMessage({
                    id: 'global.cancel',
                })}
            </SecondaryButton>
        </ActionsContainer>
    );

    render() {
        const {
            currentUser,
            errors,
            establishments,
            handleChangeField,
            intl,
            loading,
            mode,
            roles,
            user,
        } = this.props;

        let role;
        if (user && user.has('roles') && roles) {
            role = roles.find(
                (r: ImmutableMap<string, RoleType>) => r.get('slug') === user.get('roles')
            );
        }

        // Get possible user roles from props
        let roleOptions = roles;
        const superAdmin = currentUser && isSuperAdmin(currentUser.get('role'));
        // If not super admin, reassign roleOptions to filter out protected roles by slug
        if (!superAdmin) {
            roleOptions = roles.filter(
                (singleRole: ImmutableMap<string, string>) =>
                    !protectedRoles.includes(singleRole.get('slug'))
            );
        }

        return (
            <UserContainer>
                <UserFormContainer>
                    <SectionTitle
                        main
                        text={intl.formatMessage({
                            id: 'components.User.connectionInformation',
                        })}
                    >
                        <ToggleSwitch
                            value={user && user.get('status')}
                            labelOn={intl.formatMessage({
                                id: 'components.User.status.active',
                            })}
                            labelOff={intl.formatMessage({
                                id: 'components.User.status.inactive',
                            })}
                            valueOn={intl.formatMessage({
                                id: 'components.User.status.value.active',
                            })}
                            valueOff={intl.formatMessage({
                                id: 'components.User.status.value.suspended',
                            })}
                            disabled={mode === 'create'}
                            onChange={handleChangeField('status')}
                        />
                    </SectionTitle>

                    <FormGroup>
                        <FlexContainer justifyContent="space-between">
                            <FlexItemContainer
                                direction="column"
                                maxWidth="75px"
                                justifyContent="flex-start"
                            >
                                <Label htmlFor="firstName">
                                    <FormattedMessage id="components.User.title" />
                                </Label>

                                <Select
                                    name="title"
                                    onChange={handleChangeField('title')}
                                    disabled={loading}
                                    value={user && user.get('title')}
                                    options={titles.map((value: string) => ({
                                        key: value,
                                        value,
                                    }))}
                                />
                            </FlexItemContainer>

                            <FlexItemContainer direction="column" justifyContent="flex-start">
                                <Label htmlFor="firstName">
                                    <FormattedMessage id="components.User.firstName" />
                                </Label>
                                <Input
                                    name="firstName"
                                    type="text"
                                    placeholder={'global.writeHere'}
                                    onChange={handleChangeField('firstName')}
                                    disabled={loading}
                                    value={user && user.get('firstName')}
                                />
                                <ErrorMessage
                                    error={
                                        this.getProcessedErrorResponseForField('firstName')
                                            ? errors &&
                                              errors.getIn(['data', 'errors', 'firstName'])
                                            : null
                                    }
                                    values={{ attribute: 'components.User.firstName' }}
                                />
                            </FlexItemContainer>

                            <FlexItemContainer direction="column" justifyContent="flex-start">
                                <Label htmlFor="lastName">
                                    <FormattedMessage id="components.User.lastName" />
                                </Label>
                                <Input
                                    name="lastName"
                                    type="text"
                                    placeholder={'global.writeHere'}
                                    onChange={handleChangeField('lastName')}
                                    disabled={loading}
                                    value={user && user.get('lastName')}
                                />
                                <ErrorMessage
                                    error={
                                        this.getProcessedErrorResponseForField('lastName')
                                            ? errors && errors.getIn(['data', 'errors', 'lastName'])
                                            : null
                                    }
                                    values={{ attribute: 'components.User.lastName' }}
                                />
                            </FlexItemContainer>
                        </FlexContainer>
                    </FormGroup>

                    <FormGroup>
                        <Label htmlFor="establishment">
                            <FormattedMessage id="components.User.location" />
                        </Label>

                        <Select
                            name="establishmentId"
                            onChange={handleChangeField('establishmentId')}
                            disabled={loading}
                            value={user && user.get('establishmentId')}
                            options={establishments.toJS().map((est) => ({
                                key: est.id,
                                value: est.name,
                            }))}
                            defaultOption={'components.User.location.placeholder'}
                        />

                        <ErrorMessage
                            error={
                                this.getProcessedErrorResponseForField('establishment')
                                    ? errors && errors.getIn(['data', 'errors', 'email'])
                                    : null
                            }
                            values={{ attribute: 'components.User.location' }}
                        />
                    </FormGroup>

                    <hr />

                    <FormGroup
                        validationState={this.getValidationState(
                            this.getProcessedErrorResponseForField('email')
                        )}
                    >
                        <FlexContainer justifyContent="space-between">
                            <FlexItemContainer maxWidth={'calc(50% - 5px)'} direction={'column'}>
                                <Label htmlFor="email">
                                    <FormattedMessage id="components.User.emailLabel" />
                                </Label>
                                <Input
                                    name="email"
                                    type="email"
                                    placeholder={'components.User.emailPlaceholder'}
                                    onChange={handleChangeField('email')}
                                    disabled={loading}
                                    value={user && user.get('email')}
                                />
                                <ErrorMessage
                                    error={
                                        this.getProcessedErrorResponseForField('email')
                                            ? errors && errors.getIn(['data', 'errors', 'email'])
                                            : null
                                    }
                                    values={{ attribute: 'components.User.emailLabel' }}
                                />
                            </FlexItemContainer>

                            {mode === 'edit' && (
                                <FlexItemContainer
                                    direction={'column'}
                                    maxWidth={'calc(50% - 5px)'}
                                >
                                    <Label htmlFor="password">
                                        <FormattedMessage id="components.User.password" />
                                        {user &&
                                            user.get('resetPassword') && (
                                                <InformationMessage>
                                                    &nbsp;(<FormattedMessage id="global.waiting" />)
                                                </InformationMessage>
                                            )}
                                    </Label>

                                    <FlexItemContainer justifyContent="space-between">
                                        <Input
                                            width="auto"
                                            name="password"
                                            type={
                                                user && !user.get('resetPassword')
                                                    ? 'password'
                                                    : 'text'
                                            }
                                            value={
                                                user && !user.get('resetPassword')
                                                    ? 'random text'
                                                    : intl.formatMessage({
                                                          id: 'global.threePoints',
                                                      })
                                            }
                                            disabled
                                        />
                                        <ReverseButton
                                            onClick={this.handleOnClickResetPassword}
                                            disabled={user && user.get('resetPassword')}
                                        >
                                            {intl.formatMessage({
                                                id: 'global.reset',
                                            })}
                                        </ReverseButton>
                                    </FlexItemContainer>
                                </FlexItemContainer>
                            )}

                            {mode === 'create' && (
                                <FlexItemContainer
                                    direction={'column'}
                                    maxWidth={'calc(50% - 5px)'}
                                    justifyContent={'flex-end'}
                                >
                                    <FlexItemCrossAxisEnd>
                                        <InformationMessage>
                                            {intl.formatMessage({
                                                id:
                                                    'components.User.new.emailInviteForSettingPassword',
                                            })}
                                        </InformationMessage>
                                    </FlexItemCrossAxisEnd>
                                </FlexItemContainer>
                            )}
                        </FlexContainer>
                    </FormGroup>

                    <hr />

                    <FormGroup
                        validationState={this.getValidationState(
                            this.getProcessedErrorResponseForField('comment')
                        )}
                    >
                        <Label htmlFor="comment">
                            <FormattedMessage id="components.User.comment" />
                        </Label>

                        <Textarea
                            name="comment"
                            placeholder="components.User.comment.placeholder"
                            onChange={handleChangeField('comment')}
                            rows={3}
                            disabled={loading}
                            value={user && user.get('comment')}
                        />

                        <ErrorMessage
                            error={
                                this.getProcessedErrorResponseForField('comment')
                                    ? errors.getIn(['data', 'errors', 'comment'])
                                    : null
                            }
                            values={{ attribute: 'components.User.comment' }}
                        />
                    </FormGroup>

                    <SectionTitle
                        main
                        text={intl.formatMessage({
                            id: 'components.User.rolesAndPermissions',
                        })}
                    />

                    <FormGroup>
                        <Label htmlFor="role">
                            <FormattedMessage id="components.User.role.select" />
                        </Label>

                        <Select
                            name="role"
                            onChange={handleChangeField('role')}
                            disabled={loading}
                            value={user && user.get('role')}
                            options={roleOptions.toJS().map((r: RoleType) => ({
                                key: r.slug,
                                value: r.label,
                            }))}
                            defaultOption={'components.User.role.placeholder'}
                        />

                        {user &&
                            role && (
                                <FormGroup>
                                    <LabelSmall>
                                        <FormattedMessage id="global.description" />
                                    </LabelSmall>
                                    <span>{role.get('description')}</span>
                                </FormGroup>
                            )}

                        <ErrorMessage
                            error={
                                this.getProcessedErrorResponseForField('role')
                                    ? errors.getIn(['data', 'errors', 'email'])
                                    : null
                            }
                            values={{ attribute: 'components.User.role' }}
                        />
                    </FormGroup>
                </UserFormContainer>
                {user && (
                    <Modal
                        headerTitle={<FormattedMessage id={'components.User.password.reset'} />}
                        onModalClose={this.handleOnCancelResetPasswordModal}
                        show={this.state.showResetPasswordModal}
                        controls={this.renderResetPasswordModalActions()}
                    >
                        <div>
                            <p>
                                <FormattedMessage
                                    id={'components.User.password.reset.modalText'}
                                    values={{
                                        name: `${user.get('firstName')} ${user.get('lastName')}`,
                                    }}
                                />
                            </p>
                            <p>
                                <FormattedMessage
                                    id={'components.User.password.reset.modalTextExtra'}
                                />
                            </p>
                        </div>
                    </Modal>
                )}
            </UserContainer>
        );
    }
}

export default injectIntl(User);
