// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { fromJS, Map } from 'immutable';

// Components
import Button from 'components/Button';
import Checkbox from 'components/Form/Checkbox';
import CollectionThumbnail from 'components/Image/CollectionThumbnail';
import CustomTh from 'components/Table/CustomTh';
import Image from 'components/Image';
import SortableTh from 'components/Table/SortableTh';

// Styles
import { Table, Tr, Th, Td } from 'styles/common';
import { ResourceIndexContainer, StyledContentEditable } from '../styles';

// Types
import type { ImmutableList, ImmutableMap, InputEvent } from 'types';

// Utils
import { formatDate, getReviewStatus, getTranslationStatus, sanitizeString } from 'utils';

type DefaultProps = {
    resourceList: ?ImmutableList<ImmutableMap<string, string>>,
    onSortBy: Function,
};

type Props = DefaultProps & {
    currentSorting?: {
        sortBy?: string,
        sortOrder?: string,
    },
    customColumns?: {
        dates?: number,
    },
    onCustomColumnChange?: Function,
    order?: string,
    onListItemModifyAction?: Function,
    onSelectItemsToBeDeleted?: Function,
    onSetCollectionOrder?: Function,
};

type State = {
    order: string | null,
    checkAll: boolean,
    updating: boolean,
    customColumns?: {
        dates: number,
    },
    selectedItemsToDelete: ?ImmutableList<ImmutableMap<string, string>>,
};

/**
 * Resource table content for Collection index
 */

class ResourceCollectionIndex extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            checkAll: true,
            order: null,
            updating: false,
            selectedItemsToDelete: fromJS([]),
            customColumns: props.customColumns || {
                dates: 1,
            },
        };
    }

    componentWillMount() {
        if (this.props.order) {
            this.setState({
                order: JSON.parse(this.props.order),
            });
        }
    }

    handleSelectToDelete = (event: Event) => {
        event.persist();
        const { value } = event.target;
        const { selectedItemsToDelete } = this.state;
        if (selectedItemsToDelete) {
            const indexOfItemToDelete = selectedItemsToDelete.findIndex(
                (item: number) => item === value
            );
            const newSelectedItemsToDelete =
                indexOfItemToDelete !== -1
                    ? selectedItemsToDelete.delete(indexOfItemToDelete)
                    : selectedItemsToDelete.push(value);
            this.setState(
                {
                    selectedItemsToDelete: newSelectedItemsToDelete,
                },
                () =>
                    this.props.onSelectItemsToBeDeleted &&
                    this.props.onSelectItemsToBeDeleted(this.state.selectedItemsToDelete)
            );
        }
    };

    handleCheckAllToDelete = (checkAll: boolean) => (event: Event) => {
        if (!checkAll) {
            this.setState(
                {
                    checkAll: true,
                    selectedItemsToDelete: fromJS([]),
                },
                () =>
                    this.props.onSelectItemsToBeDeleted &&
                    this.props.onSelectItemsToBeDeleted(this.state.selectedItemsToDelete)
            );
        } else {
            const { resourceList } = this.props;
            const newlySelectedItemsToDelete = [];
            if (resourceList) {
                resourceList.map((singleItem: Object) =>
                    newlySelectedItemsToDelete.push(singleItem.get('id').toString())
                );
                this.setState(
                    {
                        checkAll: false,
                        selectedItemsToDelete: fromJS(newlySelectedItemsToDelete),
                    },
                    () =>
                        this.props.onSelectItemsToBeDeleted &&
                        this.props.onSelectItemsToBeDeleted(this.state.selectedItemsToDelete)
                );
            }
        }
    };

    // Save customColumn to state, send to parent for persistence
    // shouldFetch: sortedByCustomOption based on currentSorting.sortBy === datesOptions.key
    handleCustomColumn = (name: number | string) => ({ target: { value } }: InputEvent) => {
        const { onCustomColumnChange } = this.props;
        const datesOptions = this.renderDatesColumnOptions();
        const sortedByCustomOption =
            datesOptions &&
            !datesOptions
                .filter(
                    (option: ImmutableMap<string, string>) =>
                        option.get('key') === this.props.currentSorting.sortBy
                )
                .isEmpty();

        this.setState(
            (prevState: State) => ({
                customColumns: {
                    ...prevState.customColumns,
                    [name]: parseInt(value),
                },
            }),
            () =>
                onCustomColumnChange &&
                onCustomColumnChange(name, parseInt(value), sortedByCustomOption)
        );
    };

    handleOnOrderChange = (collectionId: number) => (event: Event) => {
        const {
            target: { innerHTML, value },
            key,
            type,
        } = event;
        let order = innerHTML || value;
        order = (order && Number(order.replace(/<(?:.|\n)*?>/gm, ''))) || null;
        const isNotUsed =
            !this.props.resourceList.map((collection) => collection.get('order')).includes(order) ||
            order === null;
        if (
            (key === 'Enter' || type === 'blur') &&
            isNotUsed &&
            this.props.onSetCollectionOrder &&
            (!isNaN(order) || order === null) &&
            !this.state.updating
        ) {
            const newEvent = {
                ...event,
                target: {
                    ...event.target,
                    value: order,
                },
            };
            this.setState({ updating: true }, () => {
                document.activeElement.blur();
                this.props.onSetCollectionOrder(collectionId)(newEvent);
            });
        } else if (key === 'Enter' && this.props.resourceList) {
            const oldOrder = this.props.resourceList
                .find((item) => item.get('id') === collectionId)
                .get('order');
            document.activeElement.innerHTML = oldOrder;
            document.activeElement.blur();
        }
    };

    // Build out List of options for Custom Column: Dates
    renderDatesColumnOptions = () =>
        fromJS([
            {
                id: 1,
                intl: 'components.ResourceIndex.Contents.tableHeaderFieldCreationDate',
                key: 'createdAt',
                sortable: true,
            },
            {
                id: 2,
                intl: 'components.ResourceIndex.Contents.tableHeaderFieldModifiedDate',
                key: 'updatedAt',
                sortable: true,
            },
        ]);

    renderImage = (image) => <CollectionThumbnail {...image} />;

    render() {
        if (!this.props.resourceList) {
            return null;
        }

        const { checkAll, customColumns, selectedItemsToDelete } = this.state;

        const datesColumnOptions = this.renderDatesColumnOptions();
        const selectedDatesColumnOption =
            datesColumnOptions.find(
                (option: ImmutableMap<string, string>) => option.get('id') === customColumns.dates
            ) || datesColumnOptions.first();

        const selectedDatesColumnKey =
            selectedDatesColumnOption && selectedDatesColumnOption.get('key');

        const thead = (
            <tr>
                {this.props.onSelectItemsToBeDeleted && (
                    <Th padding="5px 0 5px 20px" width="50px">
                        <Checkbox
                            name="selectedMedia"
                            onChange={this.handleCheckAllToDelete(checkAll)}
                            checked={!checkAll}
                            value={checkAll}
                        />
                    </Th>
                )}
                <th>&nbsp;</th>
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Contents.tableHeaderFieldTitle"
                    name="titleFr"
                    onClick={this.props.onSortBy}
                />
                {this.state.order &&
                    this.state.order.orderBy === 'custom' && (
                        <SortableTh
                            currentSorting={this.props.currentSorting}
                            id="components.ResourceIndex.Contents.tableHeaderFieldOrder"
                            name="order"
                            onClick={this.props.onSortBy}
                        />
                    )}
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Contents.tableHeaderFieldId"
                    name="id"
                    onClick={this.props.onSortBy}
                />
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Contents.tableHeaderFieldContributor"
                    name="contributor"
                    onClick={this.props.onSortBy}
                />
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Contents.tableHeaderFieldStatus"
                    name="isPublished"
                    onClick={this.props.onSortBy}
                />
                <CustomTh
                    currentSorting={this.props.currentSorting}
                    name="dates"
                    onChange={this.handleCustomColumn}
                    onSortBy={this.props.onSortBy}
                    options={this.renderDatesColumnOptions()}
                    selectedId={selectedDatesColumnOption.get('id')}
                />
                <th>
                    <FormattedMessage id="components.ResourceIndex.Contents.tableHeaderFieldCardLanguage" />
                </th>
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Contents.tableHeaderFieldTranslated"
                    name="isTranslated"
                    // onClick={this.props.onSortBy} // Temporarily removing sorting due to backend complexity
                />
                <SortableTh
                    currentSorting={this.props.currentSorting}
                    id="components.ResourceIndex.Contents.tableHeaderFieldRevised"
                    name="isReviewed"
                    // onClick={this.props.onSortBy} // Temporarily removing sorting due to backend complexity
                />
                {this.props.onListItemModifyAction && (
                    <th>
                        <FormattedMessage id="components.ResourceIndex.Contents.tableHeaderFieldActions" />
                    </th>
                )}
            </tr>
        );

        const list =
            this.props.resourceList &&
            this.props.resourceList.map((singleItem: Object) => {
                if (!singleItem || !Map.isMap(singleItem)) {
                    return null;
                }

                const thumbnail = singleItem && singleItem.get('thumbnail');
                const thumbnailFile = thumbnail && thumbnail.get('file');

                // Set Custom Column Value
                let datesColumnValue = '';
                if (
                    selectedDatesColumnKey === 'createdAt' ||
                    selectedDatesColumnKey === 'updatedAt'
                ) {
                    // If createdAt or updatedAt (timestamps), display in proper format
                    datesColumnValue = formatDate(singleItem.get(selectedDatesColumnKey));
                } else if (singleItem && singleItem.has(selectedDatesColumnKey)) {
                    // If key is present in singleItem, display value
                    datesColumnValue = singleItem.get(selectedDatesColumnKey);
                }

                const isChecked =
                    (selectedItemsToDelete &&
                        selectedItemsToDelete.includes(singleItem.get('id').toString())) ||
                    false;

                let title = singleItem.get(`titleFr`) || singleItem.get('titleEn') || '';
                title = sanitizeString(title);

                return (
                    <Tr
                        key={singleItem.get('id')}
                        inactive={singleItem.get('status') === 'INACTIVE'}
                    >
                        {this.props.onSelectItemsToBeDeleted && (
                            <Td padding="5px 0 5px 20px" width="50px">
                                <Checkbox
                                    name="selectedMedia"
                                    onChange={this.handleSelectToDelete}
                                    checked={isChecked}
                                    value={singleItem.get('id')}
                                />
                            </Td>
                        )}
                        <Td padding="5px 0 5px 10px" width="80px">
                            <Link to={`/collections/${singleItem.get('id')}`}>
                                {thumbnailFile ? (
                                    <Image
                                        alt={singleItem.get(`titleFr`) || singleItem.get(`titleEn`)}
                                        count={singleItem.get('childContentCount')}
                                        format="cropped"
                                        id={singleItem.get('id')}
                                        render={this.renderImage}
                                        resource="collections"
                                        showPlaceholder={
                                            !singleItem.get('thumbnailId') &&
                                            !singleItem.get('thumbnail')
                                        }
                                        width="70px"
                                    />
                                ) : (
                                    <CollectionThumbnail
                                        count={singleItem.get('childContentCount')}
                                        width="70px"
                                    />
                                )}
                            </Link>
                        </Td>
                        <td>
                            <Link to={`/collections/${singleItem.get('id')}`}>{title}</Link>
                        </td>
                        {this.state.order &&
                            this.state.order.orderBy === 'custom' && (
                                <td>
                                    <StyledContentEditable
                                        contentEditable
                                        disabled={false}
                                        onChange={this.handleOnOrderChange(singleItem.get('id'))}
                                        onBlur={this.handleOnOrderChange(singleItem.get('id'))}
                                    >
                                        {singleItem.get('order') || ''}
                                    </StyledContentEditable>
                                </td>
                            )}
                        <td>{singleItem.get('id')}</td>
                        <td>
                            {singleItem.get('contributor') ? (
                                `${singleItem.get('contributor').get('firstName')} ${singleItem
                                    .get('contributor')
                                    .get('lastName')}`
                            ) : (
                                <FormattedMessage id="global.threeDash" />
                            )}
                        </td>
                        <td>
                            <FormattedMessage
                                id={
                                    singleItem.get('isPublished')
                                        ? 'components.ResourceIndex.Contents.isPublic'
                                        : 'components.ResourceIndex.Contents.isNotPublic'
                                }
                            />
                        </td>
                        <Td padding="5px 20px 5px 2px">{datesColumnValue}</Td>
                        <td>{singleItem.get('cardLanguage')}</td>
                        <td>
                            <FormattedMessage id={getTranslationStatus(singleItem)} />
                        </td>
                        <td>
                            <FormattedMessage id={getReviewStatus(singleItem)} />
                        </td>
                        {this.props.onListItemModifyAction && (
                            <td>
                                <Button onClick={this.props.onListItemModifyAction(singleItem)}>
                                    <FormattedMessage id="components.ResourceManagement.actionsModify" />
                                </Button>
                            </td>
                        )}
                    </Tr>
                );
            });

        return (
            <ResourceIndexContainer>
                <Table>
                    <thead>{thead}</thead>
                    <tbody>{list}</tbody>
                </Table>
            </ResourceIndexContainer>
        );
    }
}

export default ResourceCollectionIndex;
