// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import PaginationControls from './PaginationControls';

// Styles
import { PaginationBarContainer, PaginationSummary } from './styles';

type Props = {
    /** Current page number */
    currentPage: number,
    /** Total page numbers */
    pagesTotal: ?number,
    /** Determines if on selected page */
    onPageSelection: Function,
};

/**
 * Shared pagination wrapper
 */

export default class PaginationBar extends React.PureComponent<Props> {
    render() {
        if (!this.props.pagesTotal) {
            return null;
        }

        return (
            <PaginationBarContainer>
                <PaginationSummary>
                    <FormattedMessage id="components.PaginationBar.labelLocationTextPage" />
                    <span> {this.props.currentPage} </span>
                    <FormattedMessage id="components.PaginationBar.labelLocationTextOf" />
                    <span> {this.props.pagesTotal}</span>
                </PaginationSummary>
                <PaginationControls
                    currentPage={this.props.currentPage}
                    pagesTotal={this.props.pagesTotal}
                    onPageSelection={this.props.onPageSelection}
                />
            </PaginationBarContainer>
        );
    }
}
