// @flow

import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
`;

const Content = styled.div`
    width: 100%;
    max-width: 260px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
`;

export { Container, Content };
