// @flow

import styled from 'styled-components';

import colors from 'styles/colors';

const Container = styled.div`
    width: 260px;
`;

const Content = styled.div``;

const FormTitleGroup = styled.div`
    height: 34px;
    line-height: 18px;
    font-size: 16px;
`;

const FormTitle = styled.div`
    display: inline-block;
    width: 171px;
    padding-bottom: 15px;
    border-bottom: 1px solid ${colors.tealDark};
`;

const FormTitleComplement = styled.div`
    display: inline-block;
    width: 89px;
    padding-bottom: 15px;
    border-bottom: 1px solid ${colors.grayE1};
    vertical-align: bottom;
`;

const ForgotPasswordLink = styled.div`
    line-height: 12px;
    font-size: 10px;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 20px;
    padding-left: 15px;

    a {
        text-decoration: none;
        color: ${colors.gray51};
    }
`;

const SubmitContainer = styled.div`
    width: 110px;
    margin-top: 20px;
    margin-bottom: 5px;
`;

const ErrorsContainer = styled.div`
    && > * {
        margin-top: 20px;
    }

    &&:last-child {
        color: ${colors.gray33};
    }
`;

export {
    Container,
    Content,
    FormTitleGroup,
    FormTitle,
    FormTitleComplement,
    ForgotPasswordLink,
    SubmitContainer,
    ErrorsContainer,
};
