// @flow

import type { ReduxDispatch, ResponseErrorType } from 'types';
import crudResources from './resources';
import { crudResourceActions } from './actions';

/*
 *
 * CRUDResource thunks
 *
 * Basic function: Check response and dispatch action to save data/error to redux store
 *
 */

const crudThunks = (resourceName: string) => ({
    /*
    * fetchCounts: Fetch statistics by date
    */
    fetchByDate: (startDate?: string, endDate?: string) => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .fetchByDate(startDate, endDate)
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchByDateFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchByDateSuccess(
                            response.data
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchByDateFailure(error)
                );
            }),

    /*
    * fetchCounts: Fetch static statistics
    */
    fetchCounts: () => (dispatch: ReduxDispatch) =>
        crudResources((resourceName: string))
            .fetchCounts()
            .then((response) => {
                if (response.data.error) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchCountsFailure(
                            response.data.errorMessage
                        )
                    );
                } else if (response.data) {
                    dispatch(
                        crudResourceActions((resourceName: string)).receivedFetchCountsSuccess(
                            response.data
                        )
                    );
                }
            })
            .catch((error: ResponseErrorType) => {
                dispatch(
                    crudResourceActions((resourceName: string)).receivedFetchCountsFailure(error)
                );
            }),
});

export default crudThunks;
