// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Components
import Input from 'components/Form/Input';
import MultiSelect from 'components/Form/MultiSelect';
import Select from 'components/Form/Select';

// Styles
import { FormGroup, Label } from 'styles/form';
import { FlexContainer, FlexItemContainer } from 'styles/common';

// Types
import type { ImmutableMap } from 'types';

// Utils
import {
    copyRightOptions,
    LOMContext,
    LOMResourceType,
    NormeticAggregateLevel,
    NormeticContext,
    NormeticResourceType,
} from 'utils/constants';

type Props = {
    normeticDisciplines?: ImmutableMap<string, mixed>,
    onChangeField: Function,
    onChangeFieldFromState: Function,
    isLoading: boolean,
    data?: ImmutableMap<string, mixed>,
};

export default class NormeticMetaDataBlock extends React.PureComponent<Props> {
    render() {
        const {
            data,
            isLoading,
            normeticDisciplines,
            onChangeField,
            onChangeFieldFromState,
        } = this.props;
        return (
            <React.Fragment>
                <FlexContainer justifyContent="space-between" gutter="20">
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="metadataNormeticCouverture">
                                <FormattedMessage id="components.Media.inputLabelValueCouverture" />
                            </Label>
                            <Input
                                name="metadataNormeticCouverture"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={onChangeField('metadataNormeticCouverture')}
                                disabled={isLoading}
                                value={data && data.get('metadataNormeticCouverture')}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="metadataNormeticDisciplines">
                                <FormattedMessage id="components.Media.inputLabelValueDiscipline" />
                            </Label>
                            <MultiSelect
                                name="metadataNormeticDisciplines"
                                onChange={onChangeFieldFromState}
                                disabled={isLoading}
                                values={data && data.get('metadataNormeticDisciplines')}
                                options={
                                    normeticDisciplines &&
                                    normeticDisciplines.toJS().map(
                                        (option: { id: number, label: string, value: string }) =>
                                            option.label && {
                                                id: option.id,
                                                label: option.label,
                                                value: option.value,
                                            }
                                    )
                                }
                                defaultOption={'global.select'}
                                full
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
                <FlexContainer justifyContent="space-between" gutter="20">
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="metadataNormeticMediaVersion">
                                <FormattedMessage id="components.Media.inputLabelValueMediaVersion" />
                            </Label>
                            <Input
                                name="metadataNormeticMediaVersion"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={onChangeField('metadataNormeticMediaVersion')}
                                disabled={isLoading}
                                value={data && data.get('metadataNormeticMediaVersion')}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="metadataNormeticAggregateLevel">
                                <FormattedMessage id="components.Media.inputLabelValueAggregateLevel" />
                            </Label>
                            <Select
                                name="metadataNormeticAggregateLevel"
                                onChange={onChangeField('metadataNormeticAggregateLevel')}
                                disabled={isLoading}
                                value={data && data.get('metadataNormeticAggregateLevel')}
                                options={NormeticAggregateLevel.map(
                                    (option: { value: string }) => ({
                                        key: option.value,
                                        value: option.value,
                                    })
                                )}
                                defaultOption={'global.select'}
                                full
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
                <FlexContainer justifyContent="space-between" gutter="20">
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="metadataNormeticLomResourceType">
                                <FormattedMessage id="components.Media.inputLabelValueLOMResourceType" />
                            </Label>
                            <Select
                                name="metadataNormeticLomResourceType"
                                onChange={onChangeField('metadataNormeticLomResourceType')}
                                disabled={isLoading}
                                value={
                                    data &&
                                    data.get('metadataNormeticLomResourceType') &&
                                    data.get('metadataNormeticLomResourceType').split(',')[0]
                                }
                                options={LOMResourceType.map((option: { value: string }) => ({
                                    key: option.value,
                                    value: option.value,
                                }))}
                                defaultOption={'global.select'}
                                full
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="metadataNormeticLomContext">
                                <FormattedMessage id="components.Media.inputLabelValueLOMContext" />
                            </Label>
                            <Select
                                name="metadataNormeticLomContext"
                                onChange={onChangeField('metadataNormeticLomContext')}
                                disabled={isLoading}
                                value={
                                    data &&
                                    data.get('metadataNormeticLomContext') &&
                                    data.get('metadataNormeticLomContext').split(',')[0]
                                }
                                options={LOMContext.map((option: { value: string }) => ({
                                    key: option.value,
                                    value: option.value,
                                }))}
                                defaultOption={'global.select'}
                                full
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
                <FlexContainer justifyContent="space-between" gutter="20">
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="metadataNormeticResourceType">
                                <FormattedMessage id="components.Media.inputLabelValueResourceType" />
                            </Label>
                            <Select
                                name="metadataNormeticResourceType"
                                onChange={onChangeField('metadataNormeticResourceType')}
                                disabled={isLoading}
                                value={
                                    data &&
                                    data.get('metadataNormeticResourceType') &&
                                    data.get('metadataNormeticResourceType').split(',')[0]
                                }
                                options={NormeticResourceType.map((option: { value: string }) => ({
                                    key: option.value,
                                    value: option.value,
                                }))}
                                defaultOption={'global.select'}
                                full
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="metadataNormeticContext">
                                <FormattedMessage id="components.Media.inputLabelValueContext" />
                            </Label>
                            <Select
                                name="metadataNormeticContext"
                                onChange={onChangeField('metadataNormeticContext')}
                                disabled={isLoading}
                                value={
                                    data &&
                                    data.get('metadataNormeticContext') &&
                                    data.get('metadataNormeticContext').split(',')[0]
                                }
                                options={NormeticContext.map((option: { value: string }) => ({
                                    key: option.value,
                                    value: option.value,
                                }))}
                                defaultOption={'global.select'}
                                full
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
                <FlexContainer justifyContent="space-between" gutter="20">
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="metadataNormeticCopyright">
                                <FormattedMessage id="components.Media.inputLabelValueCopyright" />
                            </Label>
                            <Select
                                name="metadataNormeticCopyright"
                                onChange={onChangeField('metadataNormeticCopyright')}
                                disabled={isLoading}
                                value={data && data.get('metadataNormeticCopyright')}
                                options={copyRightOptions.map((option: { value: string }) => ({
                                    key: option.value,
                                    value: option.value,
                                }))}
                                defaultOption={'global.select'}
                                full
                            />
                        </FormGroup>
                    </FlexItemContainer>
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label htmlFor="metadataNormeticRightCost">
                                <FormattedMessage id="components.Media.inputLabelValueRightCost" />
                            </Label>
                            <Input
                                name="metadataNormeticRightCost"
                                type="text"
                                placeholder={'global.writeHere'}
                                onChange={onChangeField('metadataNormeticRightCost')}
                                disabled={isLoading}
                                value={data && data.get('metadataNormeticRightCost')}
                            />
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
            </React.Fragment>
        );
    }
}
