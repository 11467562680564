// @flow

import { fromJS } from 'immutable';

import { crudResourceActionTypes } from './actions';

import type { ReduxActionType } from 'types';

/*
 *
 * CRUDResource reducer
 *
 * crudServiceReducer: Switch statement to set state based on current action type
 *
 */

const crudServiceReducer = (resourceName: string) => (
    state: Object = fromJS({
        single: {},
        byDate: {},
        errors: {},
    }),
    action: ReduxActionType
) => {
    switch (action.type) {
        case crudResourceActionTypes((resourceName: string)).FETCH_COUNTS_SUCCESS: {
            return state.set('counts', fromJS(action.payload.data)).set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string)).FETCH_COUNTS_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        case crudResourceActionTypes((resourceName: string)).FETCH_BY_DATE_SUCCESS: {
            return state.set('byDate', fromJS(action.payload.data)).set('errors', fromJS({}));
        }
        case crudResourceActionTypes((resourceName: string)).FETCH_BY_DATE_FAILURE:
            return state.set('errors', fromJS(action.payload.errors));
        default:
            return state;
    }
};

export default crudServiceReducer;
