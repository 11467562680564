// @flow

import styled from 'styled-components';

import colors from 'styles/colors';

export const Container = styled.div`
    width: 550px;
`;

export const SubmitContainer = styled.div`
    width: 110px;
    margin-top: 20px;
    margin-bottom: 5px;
`;

export const ErrorsContainer = styled.div`
    && > * {
        margin-top: 20px;
    }

    &&:last-child {
        color: ${colors.gray20};
    }
`;
