// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const Container = styled.div`
    display: flex;
    flex-flow: column nowrap;
`;

const SingleItemContainer = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 10px 0;
`;

const CollectionName = styled.div`
    padding-left: 20px;
    color: {$colors.gray33};
`;

export { Container, SingleItemContainer, CollectionName };
