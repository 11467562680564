// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Map } from 'immutable';

// Components
import ErrorMessage from 'components/ErrorMessage';
import Input from 'components/Form/Input';
import Image from 'components/Image';
import FileInput from 'components/Form/FileInput';
import Select from 'components/Form/Select';
import SquareImage from 'components/Image/SquareImage';
import Textarea from 'components/Form/Textarea';
import Thumbnail from 'components/Image/Thumbnail';
import ToggleSwitchBoolean from 'components/Form/ToggleSwitchBoolean';

import { basicLanguages, itunesCategories, itunesUMainVideoFormat } from 'utils/constants';

// Styles
import { FormGroup, InputNotice, Label } from 'styles/form';
import {
    FlexContainer,
    FlexItemContainer,
    FlexRowContainer,
    Indented,
    LineBreak,
    WhiteBlock,
} from 'styles/common';

// Types
import type { ImmutableMap, IntlType } from 'types';

type Props = {
    errors: ImmutableMap<string, any>,
    onChangeField: Function,
    onChangeFieldFromState: Function,
    handleErrors: Function,
    isLoading: boolean,
    data?: ImmutableMap<string, mixed>,
};

type State = {
    preview: ?string,
    thumbnail: ?Node,
};

class ItunesUBlock extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            preview: null,
            thumbnail: props.data && props.data.get('itunesUThumbnail'),
        };
    }

    componentDidMount() {
        const { thumbnail } = this.state;
        const { data } = this.props;

        // If thumbnail is local, and not saved in DB set preview to base64 of image
        if (thumbnail && !(data && data.getIn(['itunesUThumbnail', 'id']))) {
            const reader = new FileReader();
            reader.onload = (e: Event) => {
                const preview = e.target.result;
                this.setState(
                    {
                        preview,
                    },
                    () => this.props.onChangeField('itunesUThumbnail', thumbnail)
                );
            };
            reader.readAsDataURL(thumbnail);
        }
    }

    handleImagePreview = (name: string) => (event: Event) => {
        const image = event && event.target.files[0];
        if (image) {
            const reader = new FileReader();
            reader.onload = (e: Event) => {
                const preview = e.target.result;
                if (preview.includes('image')) {
                    this.setState(
                        {
                            preview,
                            [name]: image,
                        },
                        () => this.props.onChangeFieldFromState(name, this.state[name])
                    );
                }
            };
            reader.readAsDataURL(image);
        }
    };

    renderImage = (image) => <SquareImage cover={false} {...image} />;

    render() {
        const { data } = this.props;
        const dataId = data && data.get('id');
        const itunesUThumbnail = data && data.get('itunesUThumbnail');
        // Check if thumbnail has id (And therefore saved to DB)
        const savedItunesUThumbnail =
            itunesUThumbnail && Map.isMap(itunesUThumbnail) ? itunesUThumbnail : null;

        return (
            <React.Fragment>
                <Indented>
                    <FlexContainer>
                        <FormGroup>
                            <Label htmlFor="itunesUDisplayLanguage">
                                <FormattedMessage id="components.ItunesUBlock.inputLabelItunesUThumbnail" />
                            </Label>
                            <FlexContainer justifyContent="space-between" gutter="10">
                                <FlexItemContainer
                                    direction="column"
                                    justifyContent="center"
                                    collapsed
                                >
                                    {savedItunesUThumbnail ? (
                                        <Thumbnail square width="160px">
                                            <Image
                                                format="small"
                                                id={dataId}
                                                itunes
                                                render={this.renderImage}
                                                resource="collections"
                                                type="image"
                                                width="160px"
                                            />
                                        </Thumbnail>
                                    ) : (
                                        <Thumbnail square width="160px">
                                            {this.state.preview ? (
                                                <SquareImage
                                                    cover={false}
                                                    src={this.state.preview}
                                                />
                                            ) : (
                                                <FormattedMessage id="components.ItunesUBlock.inputLabelItunesUThumbnail.placeholder" />
                                            )}
                                        </Thumbnail>
                                    )}
                                </FlexItemContainer>
                                <FlexItemContainer direction="column" justifyContent="flex-start">
                                    <FlexContainer justifyContent="space-between" gutter="20">
                                        <FileInput
                                            buttonId="components.ItunesUBlock.inputLabelItunesUThumbnail.addFile"
                                            name="file"
                                            onChange={this.handleImagePreview('itunesUThumbnail')}
                                            disabled={this.props.isLoading}
                                            value={
                                                savedItunesUThumbnail &&
                                                savedItunesUThumbnail.get('originalFilename')
                                            }
                                            inputless
                                        />
                                    </FlexContainer>
                                    <LineBreak height="10px" />
                                    <InputNotice subtle>
                                        <FormattedMessage id="components.ItunesUBlock.inputLabelItunesUThumbnail.notice" />
                                    </InputNotice>
                                    <ErrorMessage
                                        error={
                                            this.props.handleErrors('itunesUThumbnail')
                                                ? this.props.errors.getIn([
                                                      'data',
                                                      'errors',
                                                      'itunesUThumbnail',
                                                  ])
                                                : null
                                        }
                                        values={{ attribute: 'itunesUThumbnail' }}
                                    />
                                </FlexItemContainer>
                            </FlexContainer>
                        </FormGroup>
                    </FlexContainer>
                    <FlexContainer justifyContent="space-between" gutter="20">
                        <FlexItemContainer direction="column" justifyContent="flex-start">
                            <FormGroup>
                                <Label htmlFor="itunesUDisplayLanguage">
                                    <FormattedMessage id="components.ItunesUBlock.inputLabelItunesUDisplayLanguage" />
                                </Label>
                                <Select
                                    name="itunesUDisplayLanguage"
                                    onChange={this.props.onChangeField('itunesUDisplayLanguage')}
                                    disabled={this.props.isLoading}
                                    value={data && data.get('itunesUDisplayLanguage')}
                                    options={basicLanguages}
                                    defaultOption="components.ItunesUBlock.inputLabelItunesUDisplayLanguage.placeholder"
                                    full
                                />
                            </FormGroup>
                        </FlexItemContainer>
                        <FlexItemContainer direction="column" justifyContent="flex-start">
                            <FormGroup>
                                <Label htmlFor="itunesUMainSubcategory">
                                    <FormattedMessage id="components.ItunesUBlock.inputLabelItunesUMainSubcategory" />
                                </Label>
                                <Select
                                    defaultOption="components.ItunesUBlock.inputLabelItunesUMainSubcategory.placeholder"
                                    disabled={this.props.isLoading}
                                    full
                                    grouped
                                    onChange={this.props.onChangeField('itunesUMainSubcategory')}
                                    options={itunesCategories.map((option: { value: string }) => ({
                                        value: option.label,
                                        key: option.value,
                                        group: option.group || false,
                                    }))}
                                    name="itunesUMainSubcategory"
                                    value={data && data.get('itunesUMainSubcategory')}
                                />
                            </FormGroup>
                        </FlexItemContainer>
                    </FlexContainer>
                    <FlexContainer justifyContent="space-between" gutter="20">
                        <FlexItemContainer direction="column" justifyContent="flex-start">
                            <FormGroup>
                                <Label htmlFor="itunesUMainVideoFormat">
                                    <FormattedMessage id="components.ItunesUBlock.inputLabelItunesUMainVideoFormat" />
                                </Label>
                                <Select
                                    name="itunesUMainVideoFormat"
                                    onChange={this.props.onChangeField('itunesUMainVideoFormat')}
                                    disabled={this.props.isLoading}
                                    value={data && data.get('itunesUMainVideoFormat')}
                                    options={itunesUMainVideoFormat}
                                    defaultOption="components.ItunesUBlock.inputLabelItunesUMainVideoFormat.placeholder"
                                    full
                                />
                            </FormGroup>
                        </FlexItemContainer>
                        <FlexItemContainer direction="column" justifyContent="flex-start">
                            <FormGroup>
                                <Label htmlFor="itunesUComplementarySubcategory">
                                    <FormattedMessage id="components.ItunesUBlock.inputLabelItunesUComplementarySubcategory" />
                                </Label>
                                <Select
                                    name="itunesUComplementarySubcategory"
                                    onChange={this.props.onChangeField(
                                        'itunesUComplementarySubcategory'
                                    )}
                                    disabled={this.props.isLoading}
                                    value={data && data.get('itunesUComplementarySubcategory')}
                                    options={itunesCategories.map((option: { value: string }) => ({
                                        value: option.label,
                                        key: option.value,
                                        group: option.group || false,
                                    }))}
                                    defaultOption="components.ItunesUBlock.inputLabelItunesUComplementarySubcategory.placeholder"
                                    full
                                />
                            </FormGroup>
                        </FlexItemContainer>
                    </FlexContainer>
                    <FlexContainer justifyContent="space-between" gutter="20">
                        <FlexItemContainer direction="column" justifyContent="flex-start">
                            <FormGroup>
                                <Label htmlFor="itunesUAuthor">
                                    <FormattedMessage id="components.ItunesUBlock.inputLabelItunesUAuthor" />
                                </Label>
                                <Input
                                    name="itunesUAuthor"
                                    type="text"
                                    placeholder="global.writeHere"
                                    onChange={this.props.onChangeField('itunesUAuthor')}
                                    disabled={this.props.isLoading}
                                    value={data && data.get('itunesUAuthor')}
                                />
                            </FormGroup>
                        </FlexItemContainer>
                        <FlexItemContainer direction="column" justifyContent="flex-start">
                            <FormGroup>
                                <Label htmlFor="itunesUComplementarySubcategory2">
                                    <FormattedMessage id="components.ItunesUBlock.inputLabelItunesUComplementarySubcategory2" />
                                </Label>
                                <Select
                                    name="itunesUComplementarySubcategory2"
                                    onChange={this.props.onChangeField(
                                        'itunesUComplementarySubcategory2'
                                    )}
                                    disabled={this.props.isLoading}
                                    value={data && data.get('itunesUComplementarySubcategory2')}
                                    options={itunesCategories.map((option: { value: string }) => ({
                                        value: option.label,
                                        key: option.value,
                                        group: option.group || false,
                                    }))}
                                    defaultOption="components.ItunesUBlock.inputLabelItunesUComplementarySubcategory2.placeholder"
                                    full
                                />
                            </FormGroup>
                        </FlexItemContainer>
                    </FlexContainer>
                    <FlexContainer justifyContent="space-between" gutter="20">
                        <FlexItemContainer direction="column" justifyContent="flex-start">
                            <FormGroup>
                                <Label htmlFor="itunesUKeywords">
                                    <FormattedMessage id="components.ItunesUBlock.inputLabelItunesUKeywords" />
                                </Label>
                                <Textarea
                                    name="itunesUKeywords"
                                    placeholder="global.writeHere"
                                    onChange={this.props.onChangeField('itunesUKeywords')}
                                    disabled={this.props.isLoading}
                                    value={data && data.get('itunesUKeywords')}
                                />
                            </FormGroup>
                        </FlexItemContainer>
                        <FlexItemContainer direction="column" justifyContent="flex-start">
                            <FormGroup>
                                <Label htmlFor="itunesUAutomaticKeywords">
                                    <FormattedMessage id="components.ItunesUBlock.inputLabelItunesUAutomaticKeywords" />
                                </Label>
                                <Textarea
                                    name="itunesUAutomaticKeywords"
                                    placeholder="global.writeHere"
                                    onChange={this.props.onChangeField('itunesUAutomaticKeywords')}
                                    disabled={this.props.isLoading}
                                    value={data && data.get('itunesUAutomaticKeywords')}
                                />
                            </FormGroup>
                        </FlexItemContainer>
                    </FlexContainer>
                    <FlexRowContainer>
                        <FlexItemContainer direction="column" justifyContent="flex-start">
                            <FormGroup>
                                <Label htmlFor="itunesUSummary">
                                    <FormattedMessage id="components.ItunesUBlock.inputLabelItunesUSummary" />
                                </Label>
                                <Textarea
                                    name="itunesUSummary"
                                    placeholder="global.writeHere"
                                    onChange={this.props.onChangeField('itunesUSummary')}
                                    disabled={this.props.isLoading}
                                    value={data && data.get('itunesUSummary')}
                                />
                            </FormGroup>
                        </FlexItemContainer>
                    </FlexRowContainer>
                    <FlexRowContainer>
                        <FlexItemContainer direction="column" justifyContent="flex-start">
                            <FormGroup>
                                <Label htmlFor="itunesUTermsOfUse">
                                    <FormattedMessage id="components.ItunesUBlock.inputLabelItunesUTermsOfUse" />
                                </Label>
                                <Textarea
                                    name="itunesUTermsOfUse"
                                    placeholder="global.writeHere"
                                    onChange={this.props.onChangeField('itunesUTermsOfUse')}
                                    disabled={this.props.isLoading}
                                    value={data && data.get('itunesUTermsOfUse')}
                                />
                            </FormGroup>
                        </FlexItemContainer>
                    </FlexRowContainer>
                </Indented>
                <FormGroup>
                    <Indented>
                        <FlexContainer justifyContent="space-between">
                            <FlexItemContainer direction="column" justifyContent="flex-start">
                                <Label htmlFor="key">
                                    <FormattedMessage id="components.ItunesUBlock.inputLabelItunesURSS" />
                                </Label>
                            </FlexItemContainer>
                            <FlexItemContainer
                                direction="column"
                                justifyContent="flex-end"
                                textAlign="right"
                            >
                                <Label htmlFor="key">
                                    <FormattedMessage id="components.ItunesUBlock.inputLabelItunesUExplicit" />
                                </Label>
                            </FlexItemContainer>
                        </FlexContainer>
                    </Indented>
                    <WhiteBlock>
                        <Indented>
                            <FlexContainer justifyContent="space-between">
                                <FlexItemContainer direction="column" justifyContent="flex-start">
                                    {data &&
                                        data.get('itunesURssFeed') && (
                                            <a
                                                href={data && data.get('itunesURssFeed')}
                                                rel="noopener"
                                                target="_blank"
                                            >
                                                {data && data.get('itunesURssFeed')}
                                            </a>
                                        )}
                                </FlexItemContainer>
                                <FlexItemContainer
                                    direction="column"
                                    justifyContent="flex-end"
                                    textAlign="right"
                                >
                                    <ToggleSwitchBoolean
                                        labelOn="global.yes"
                                        labelOff="global.no"
                                        name="itunesUExplicitContent"
                                        onChange={this.props.onChangeField(
                                            'itunesUExplicitContent',
                                            true
                                        )}
                                        onOrOff
                                        value={data && data.get('itunesUExplicitContent')}
                                    />
                                </FlexItemContainer>
                            </FlexContainer>
                        </Indented>
                    </WhiteBlock>
                </FormGroup>
            </React.Fragment>
        );
    }
}

export default ItunesUBlock;
