// @flow

import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

// Components
import ErrorMessage from 'components/ErrorMessage';
import Loading from 'components/Loading';
import Input from 'components/Form/Input';
import Copy from 'components/Form/Copy';
import SectionTitle from 'components/SectionTitle';
import ToggleSwitch from 'components/Form/ToggleSwitch';
import PermissionSelector from './PermissionSelector';

// Styles
import { PrimaryButton } from 'styles/buttons';
import { FormGroup, Label, ButtonNotice, TagList, TagItem } from 'styles/form';
import { FlexContainer, FlexItemContainer, Indented } from 'styles/common';
import { ApplicationContainer } from './styles';

// Types
import type { ImmutableMap, IntlType } from 'types';

type Props = {
    applicationId?: number,
    application?: ImmutableMap<string, any>,
    errors: ?ImmutableMap<string, Object>,
    handleChangeField: Function,
    handleGenerateApplication: Function,
    handlePermissionsSelect: Function,
    /** injectIntl for formatMessage strings */
    intl: IntlType,
    loading: boolean,
};

/**
 * Application create/edit form
 */

class Application extends React.Component<Props> {
    getCurrentPermissions = () => {
        let permission = null;
        if (this.props.application && this.props.application.get('delete')) {
            permission = 'delete';
        } else if (this.props.application && this.props.application.get('write')) {
            permission = 'write';
        } else if (this.props.application && this.props.application.get('read')) {
            permission = 'read';
        }
        return permission;
    };

    getValidationState = (error: ?boolean) => (error ? 'error' : null);

    getProcessedErrorResponseForField = (fieldName: string) => {
        const { errors } = this.props;
        return errors && errors.size && errors.getIn(['data', 'errors', fieldName, '0', 'code']);
    };

    renderInvisibleContentToggle = () => (
        <ToggleSwitch
            value={this.props.application && this.props.application.get('invisibleContent')}
            labelOn={this.props.intl.formatMessage({
                id: 'components.ResourceIndex.Applications.invisibleContentAuthorized',
            })}
            labelOff={this.props.intl.formatMessage({
                id: 'components.ResourceIndex.Applications.invisibleContentNotAuthorized',
            })}
            valueOn={this.props.intl.formatMessage({
                id: 'components.ResourceIndex.Applications.value.invisibleContentAuthorized',
            })}
            valueOff={this.props.intl.formatMessage({
                id: 'components.ResourceIndex.Applications.value.invisibleContentNotAuthorized',
            })}
            onChange={this.props.handleChangeField('invisibleContent')}
            disabled={false}
        />
    );

    renderPermissionOptions = () => ({
        read: (
            <TagList>
                <TagItem>
                    <FormattedMessage id="components.ResourceIndex.Applications.permissionRead" />
                </TagItem>
            </TagList>
        ),
        write: (
            <TagList>
                <TagItem>
                    <FormattedMessage id="components.ResourceIndex.Applications.permissionRead" />
                </TagItem>
                <TagItem>
                    <FormattedMessage id="components.ResourceIndex.Applications.permissionWrite" />
                </TagItem>
            </TagList>
        ),
        delete: (
            <TagList>
                <TagItem>
                    <FormattedMessage id="components.ResourceIndex.Applications.permissionRead" />
                </TagItem>
                <TagItem>
                    <FormattedMessage id="components.ResourceIndex.Applications.permissionWrite" />
                </TagItem>
                <TagItem>
                    <FormattedMessage id="components.ResourceIndex.Applications.permissionDelete" />
                </TagItem>
            </TagList>
        ),
    });

    render() {
        const { application } = this.props;

        if (
            (this.props.loading && !this.props.errors && this.props.errors.size) ||
            (this.props.applicationId && !this.props.application)
        ) {
            return <Loading />;
        }

        return (
            <ApplicationContainer>
                <form>
                    <Indented>
                        <SectionTitle
                            main
                            text={this.props.intl.formatMessage({
                                id: 'components.Application.applicationInformations',
                            })}
                        >
                            <ToggleSwitch
                                value={application && application.get('status')}
                                labelOn={this.props.intl.formatMessage({
                                    id: 'components.Application.status.active',
                                })}
                                labelOff={this.props.intl.formatMessage({
                                    id: 'components.Application.status.inactive',
                                })}
                                valueOn={this.props.intl.formatMessage({
                                    id: 'components.Application.status.value.active',
                                })}
                                valueOff={this.props.intl.formatMessage({
                                    id: 'components.Application.status.value.inactive',
                                })}
                                onChange={this.props.handleChangeField('status')}
                            />
                        </SectionTitle>

                        <FormGroup>
                            <FlexItemContainer direction="column" justifyContent="flex-start">
                                <Label htmlFor="name">
                                    <FormattedMessage id="components.Application.name" />
                                </Label>
                                <Input
                                    name="name"
                                    type="text"
                                    placeholder={'global.writeHere'}
                                    onChange={this.props.handleChangeField('name')}
                                    disabled={this.props.loading}
                                    value={application && application.get('name')}
                                />
                                <ErrorMessage
                                    error={
                                        this.getProcessedErrorResponseForField('name')
                                            ? this.props.errors &&
                                              this.props.errors.getIn(['data', 'errors', 'name'])
                                            : null
                                    }
                                    values={{ attribute: 'components.Application.name' }}
                                />
                            </FlexItemContainer>
                        </FormGroup>

                        <hr />

                        <FormGroup>
                            <FlexContainer justifyContent="space-between" gutter="20">
                                <FlexItemContainer
                                    direction="column"
                                    justifyContent="center"
                                    collapsed
                                >
                                    <PrimaryButton
                                        onClick={this.props.handleGenerateApplication}
                                        inline
                                        disabled={application && !application.get('name')}
                                    >
                                        {this.props.intl.formatMessage({
                                            id: 'components.Application.generate',
                                        })}
                                    </PrimaryButton>
                                </FlexItemContainer>
                                <FlexItemContainer direction="column" justifyContent="center">
                                    <ButtonNotice marginless>
                                        <FormattedMessage id="components.Application.generateNotice" />
                                    </ButtonNotice>
                                </FlexItemContainer>
                            </FlexContainer>
                        </FormGroup>
                    </Indented>

                    <FormGroup>
                        <FlexItemContainer direction="column" justifyContent="flex-start">
                            <Indented>
                                <Label htmlFor="account">
                                    <FormattedMessage id="components.Application.account" />
                                </Label>
                            </Indented>
                            <Copy
                                name="account"
                                type="text"
                                placeholder="global.threePoints"
                                onChange={this.props.handleChangeField('account')}
                                disabled={this.props.loading}
                                value={application && application.get('account')}
                            />
                            <Indented>
                                <ErrorMessage
                                    error={
                                        this.getProcessedErrorResponseForField('account')
                                            ? this.props.errors &&
                                              this.props.errors.getIn(['data', 'errors', 'account'])
                                            : null
                                    }
                                    values={{ attribute: 'components.Application.account' }}
                                />
                            </Indented>
                        </FlexItemContainer>
                    </FormGroup>

                    <FormGroup>
                        <FlexItemContainer direction="column" justifyContent="flex-start">
                            <Indented>
                                <Label htmlFor="key">
                                    <FormattedMessage id="components.Application.key" />
                                </Label>
                            </Indented>
                            <Copy
                                name="key"
                                type="text"
                                placeholder="global.threePoints"
                                onChange={this.props.handleChangeField('key')}
                                disabled={this.props.loading}
                                value={application && application.get('key')}
                            />
                            <Indented>
                                <ErrorMessage
                                    error={
                                        this.getProcessedErrorResponseForField('key')
                                            ? this.props.errors &&
                                              this.props.errors.getIn(['data', 'errors', 'key'])
                                            : null
                                    }
                                    values={{ attribute: 'components.Application.key' }}
                                />
                            </Indented>
                        </FlexItemContainer>
                    </FormGroup>

                    <Indented>
                        <br />
                        <SectionTitle
                            main
                            text={this.props.intl.formatMessage({
                                id: 'components.Application.permissions',
                            })}
                        />
                    </Indented>

                    <FormGroup>
                        <Indented>
                            <FlexContainer justifyContent="space-between">
                                <FlexItemContainer direction="column" justifyContent="flex-start">
                                    <Label htmlFor="key">
                                        <FormattedMessage id="components.Application.permissionsSelect" />
                                    </Label>
                                </FlexItemContainer>

                                <FlexItemContainer
                                    direction="column"
                                    justifyContent="flex-end"
                                    textAlign="right"
                                >
                                    <Label htmlFor="key">
                                        <FormattedMessage id="components.Application.invisibleContent" />
                                    </Label>
                                </FlexItemContainer>
                            </FlexContainer>
                        </Indented>
                        <PermissionSelector
                            value={this.getCurrentPermissions()}
                            options={this.renderPermissionOptions()}
                            onChange={this.props.handlePermissionsSelect}
                            controls={this.renderInvisibleContentToggle()}
                        />
                        <Indented>
                            <ErrorMessage
                                error={
                                    this.getProcessedErrorResponseForField('read')
                                        ? this.props.errors &&
                                          this.props.errors.getIn(['data', 'errors', 'read'])
                                        : null
                                }
                                values={{ attribute: 'components.Application.permissions' }}
                            />
                        </Indented>
                    </FormGroup>
                </form>
            </ApplicationContainer>
        );
    }
}

export default injectIntl(Application);
