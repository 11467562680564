// @flow

import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';
import colors from './colors';

const flexContainer = css`
    display: flex;
    justify-content: center;
    width: ${(props) => (props.width ? props.width : '100%')};
    flex-wrap: wrap;
    ${(props) =>
        props.direction &&
        `
        flex-direction: ${props.direction};
        `};
    ${(props) =>
        props.justifyContent &&
        `
        justify-content: ${props.justifyContent};
        `};
    ${(props) =>
        props.alignContent &&
        `
        align-content: ${props.alignContent};
        `};
    ${(props) =>
        props.alignItems &&
        `
        align-items: ${props.alignItems};
        `};
    ${(props) =>
        props.textAlign &&
        `
        text-align: ${props.textAlign};
        `};
    ${(props) =>
        props.paddingTop &&
        `
        padding-top: ${props.paddingTop};
        `};
    ${(props) =>
        props.paddingBottom &&
        `
        padding-bottom: ${props.paddingBottom};
        `};
    ${(props) =>
        props.gutter &&
        `
            > div {
                padding-left: ${props.gutter / 2}px;
                padding-right: ${props.gutter / 2}px;
                &:first-child {
                    padding-left: 0;
                }
                &:last-child {
                    padding-right: 0;
                }
                &:not(:first-child):not(:last-child) {
                    padding: 0 ${props.gutter / 2}px;
                }
            }
        `};
`;

const flexItem = css`
    flex: ${(props) => (props.collapsed ? `0` : `1`)};
    &:not(:first-child):not(:last-child) {
        padding: 0 10px;
    }
    ${(props) =>
        props.width &&
        `
        width: ${props.width};
        `};
    ${(props) =>
        props.minWidth &&
        `
        min-width: ${props.minWidth};
        `};
    ${(props) =>
        props.maxWidth &&
        `
        max-width: ${props.maxWidth};
        `};
    ${(props) =>
        props.height &&
        `
        height: ${props.height};
        `};
    ${(props) =>
        props.basis &&
        `
        flex-basis: ${props.basis};
        `};
    ${(props) =>
        props.flex &&
        `
        flex: ${props.flex};
        `};
    ${(props) =>
        props.margin &&
        `
        margin: ${props.margin}px;
        `};
    ${(props) =>
        props.direction &&
        `
        flex-direction: ${props.direction};
        `};
    ${(props) =>
        props.justifyContent &&
        `
        justify-content: ${props.justifyContent};
        `};
    ${(props) =>
        props.alignContent &&
        `
        align-content: ${props.alignContent};
        `};
    ${(props) =>
        props.alignItems &&
        `
        align-items: ${props.alignItems};
        `};
    ${(props) =>
        props.flexGrown &&
        `
        flex-grow: ${props.flexGrown};
        `};
`;

export const AppWrapper = styled.div`
    width: 100%;
    margin: 0 auto;
    min-height: 100%;
    color: ${colors.gray33};
    font-family: 'Helvetica Neue';
    font-size: 14px;
    font-weight: normal;
    line-height: 14px;
`;

export const Container = styled.div`
    color: ${colors.gray1A};
    background-color: ${colors.grayF4};
    min-height: 100vh;
    position: relative;
`;

export const Content = styled.div`
    padding: 50px 35px;

    h1:first-child {
        margin-top: 0;
    }
`;

export const Clearfix = styled.div`
    clear: both;
`;

export const FlexContainer = styled.div`
    ${flexContainer};
`;

export const FlexRowGroup = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`;

export const FlexItem = styled.div`
    ${flexItem};
`;

export const FlexItemContainer = styled.div`
    ${flexContainer} ${flexItem};
`;

export const FlexItemCrossAxisStart = FlexItem.extend`
    display: flex;
    align-items: flex-start;
`;

export const FlexItemCrossAxisCentered = FlexItem.extend`
    display: flex;
    align-items: center;
`;

export const FlexItemCrossAxisEnd = FlexItem.extend`
    display: flex;
    align-items: flex-end;
`;

export const Center = styled.div`
    text-align: center;
`;

export const FlexRowContainer = styled.div`
    ${flexContainer} flex-flow: row nowrap;
    width: 100%;
`;

export const BrandLink = styled(Link)`
    height: 100px;
    width: 95px;
    margin: 35px auto 25px;

    img {
        max-width: 100%;
    }
`;

export const Line = styled.div`
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border-top: 1px solid ${colors.grayE1};
    position: relative;
    transform: translate(0, -1px);

    ${(props) => {
        let borderColor = 'border-color: ';

        if (props.light) {
            borderColor += colors.grayFA;
        }

        if (props.dark) {
            borderColor += colors.tealDark;
        }

        return `${borderColor};`;
    }} ${(props) =>
        props.margin &&
        `
            margin: ${props.margin};
        `};
`;

export const LineBreak = styled.div`
    width: 100%;
    height: ${(props) => (props.height ? props.height : `20px`)};
`;

export const LineHeightFix = styled.span`
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : `24px`)};
`;

export const Ellipsis = styled.div`
    &::before {
        content: "...";
        ${(props) => {
            let styles = '';

            if (props.paddingLeft) {
                styles += `padding-left: 10px;`;
            }

            if (props.paddingRight) {
                styles += `padding-right: 10px;`;
            }

            return styles;
        }}
`;

export const InformationMessage = styled.span`
    color: ${colors.gray64};
    font-size: 12px;
`;

export const BasicErrorMessage = styled.span`
    height: 30px;
    line-height: 14px;
    font-size: 12px;
    color: ${colors.redDark};
`;

export const ModalFooter = FlexRowContainer.extend`
    background-color: ${colors.white};
    height: 70px;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
`;

export const ModalActionsWrapper = styled.div`
    margin-top: 20px;
    padding: ${(props) => (props.padding ? props.padding : '20px')};
    background-color: ${colors.white};
`;

export const ActionsContainer = FlexItemContainer.extend`
    justify-content: flex-end;
    & button:not(:last-child) {
        margin-right: 20px;
    }
`;

export const Indented = styled.div`
    margin: ${(props) => (props.margin ? props.margin : `0 20px`)};
`;

export const Table = styled.table`
    width: 100%;
    text-align: left;

    thead {
        background-color: ${colors.grayFA};
        border-top: 1px solid ${colors.grayE1};
        border-bottom: 1px solid ${colors.grayE1};
        color: ${colors.gray64};
        font-size: 14px;
        font-weight: normal;

        tr {
            height: 30px;
        }

        ${(props) =>
            props.fixedHeader &&
            `
            display: table;
            width: 100%;
            table-layout: fixed;
        `};
    }

    tbody {
        background-color: ${colors.white};
        border-bottom: 1px solid ${colors.grayE1};

        ${(props) =>
            props.fixedHeader &&
            `
            display: block;
            height: ${props.height ? props.height : `auto`};
            min-height: ${props.minHeight ? props.minHeight : `auto`};
            max-height: ${props.maxHeight ? props.maxHeight : `50vh`};

            overflow: auto;
            > tr {
                display: table;
                width: 100%;
                table-layout: fixed;
            }
        `};

        tr {
            ${(props) =>
                !props.tight
                    ? `
                height: 50px;
                box-shadow: inset 0 -1px 0 0 ${colors.grayED};

                td {
                    height: 50px;
                }
            `
                    : `
                &:first-child td {
                    padding-top: 15px;
                }
                &:last-child td {
                    padding-bottom: 15px;
                }

                td {
                    padding: 7px 20px;
                }
            `};

            color: ${colors.gray33};
        }
    }

    th {
        padding: 5px 20px;
        font-weight: normal;
    }

    td {
        padding: ${(props) => (props.padding ? props.padding : `5px 20px`)};
        vertical-align: ${(props) => (props.valign ? props.valign : `middle`)};
    }
`;

export const Tr = styled.tr`
    ${(props) =>
        props.inactive
            ? `
            td {
                color: ${colors.gray64}
            }
        `
            : ''};
`;

export const Td = styled.td`
    width: ${(props) => (props.width ? props.width : `auto`)};
    min-width: ${(props) => (props.minWidth ? props.minWidth : `auto`)};
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : `none`)};
    height: ${(props) => (props.height ? props.height : `auto`)};

    padding: ${(props) => props.padding && `${props.padding} !important`};

    ${(props) =>
        props.inactive &&
        `
        color: ${colors.grayB3}
    `};
`;

export const Th = styled.td`
    width: ${(props) => (props.width ? props.width : `auto`)};
    min-width: ${(props) => (props.minWidth ? props.minWidth : `auto`)};
    max-width: ${(props) => (props.maxWidth ? props.maxWidth : `none`)};

    padding: ${(props) => props.padding && `${props.padding} !important`};

    ${(props) =>
        props.inactive &&
        `
        color: ${colors.grayB3}
    `};
`;

export const WhiteBlock = styled.div`
    padding: 10px 0;
    background-color: ${colors.white};
    border-bottom: 1px solid ${colors.grayED};
`;

export const TimeStamps = styled.div`
    color: ${colors.gray64};
    line-height: 16px;

    ${(props) =>
        props.disabled &&
        `
        color: ${colors.grayB3};
        `};
`;

export const MapContainer = styled.div`
    height: 300px;
    margin-top: 15px;
`;

export const Divider = styled.div`
    display: inline-block;
    vertical-align: middle;

    height: ${(props) => (props.height ? props.height : `18px`)};
    ${(props) =>
        props.margin &&
        `
        margin: ${props.margin};
    `};
    border-left: 1px solid ${colors.grayE1};
`;

export const Bold = styled.strong`
    color: ${colors.teal};
    font-weight: 500;
`;

export const NoWrap = styled.span`
    white-space: nowrap;
`;
