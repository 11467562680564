// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

// Styles
import { FormGroup } from 'styles/form';
import { Table, Td } from 'styles/common';

// Types
import type { ImmutableMap } from 'types';

type Props = {
    media?: ImmutableMap<string, mixed>,
};

class MediaPerformanceBlock extends React.PureComponent<Props> {
    render() {
        return (
            <React.Fragment>
                <FormGroup>
                    <Table>
                        <thead>
                            <tr>
                                <th>
                                    <FormattedMessage id="components.Media.preformanceTable.Views" />
                                </th>
                                <th>
                                    <FormattedMessage id="components.Media.preformanceTable.Downloads" />
                                </th>
                                <th>
                                    <FormattedMessage id="components.Media.preformanceTable.Vote" />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <Td inactive={this.props.media && !this.props.media.get('views')}>
                                    {(this.props.media && this.props.media.get('views')) || (
                                        <FormattedMessage id="global.threeDash" />
                                    )}
                                </Td>
                                <Td
                                    inactive={
                                        this.props.media && !this.props.media.get('downloads')
                                    }
                                >
                                    {(this.props.media && this.props.media.get('downloads')) || (
                                        <FormattedMessage id="global.threeDash" />
                                    )}
                                </Td>
                                <Td inactive={this.props.media && !this.props.media.get('vote')}>
                                    {(this.props.media && this.props.media.get('vote')) || (
                                        <FormattedMessage id="global.threeDash" />
                                    )}
                                </Td>
                            </tr>
                        </tbody>
                    </Table>
                </FormGroup>
            </React.Fragment>
        );
    }
}

export default MediaPerformanceBlock;
