// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { orderOptions } from 'utils/constants';

// Components
import Select from 'components/Form/Select';

// Styles
import { FlexContainer, FlexItemContainer } from 'styles/common';
import { FormGroup, Label, LabelSubtle } from 'styles/form';

// Types
import type { ImmutableMap } from 'types';

type Props = {
    onChangeField: Function,
    isLoading: boolean,
    data?: ImmutableMap<string, mixed>,
};

class CollectionOrderBlock extends React.PureComponent<Props> {
    render() {
        return (
            <React.Fragment>
                <FlexContainer justifyContent="space-between" gutter="30">
                    <FlexItemContainer direction="column" justifyContent="flex-start">
                        <FormGroup>
                            <Label>
                                <FormattedMessage id="components.CollectionOrderBlock.inputLabelValueMediaOrder" />
                            </Label>
                            <FlexContainer justifyContent="space-between" gutter="10">
                                <FlexItemContainer direction="column" justifyContent="center">
                                    <Select
                                        name="mediaOrdering"
                                        onChange={this.props.onChangeField('mediaOrdering')}
                                        disabled={this.props.isLoading}
                                        value={
                                            this.props.data &&
                                            this.props.data.get('mediaOrdering') &&
                                            this.props.data.get('mediaOrdering').toString()
                                        }
                                        options={orderOptions}
                                        defaultOption="components.CollectionOrderBlock.inputLabelValueMediaOrder.placeholder"
                                        full
                                    />
                                </FlexItemContainer>
                                <FlexItemContainer direction="column" justifyContent="center">
                                    <LabelSubtle>
                                        <FormattedMessage id="components.CollectionOrderBlock.inputLabelValueMediaOrder.notice" />
                                    </LabelSubtle>
                                </FlexItemContainer>
                            </FlexContainer>
                        </FormGroup>
                        <FormGroup>
                            <Label>
                                <FormattedMessage id="components.CollectionOrderBlock.inputLabelValueCollectionOrder" />
                            </Label>
                            <FlexContainer justifyContent="space-between" gutter="10">
                                <FlexItemContainer direction="column" justifyContent="center">
                                    <Select
                                        name="collectionOrdering"
                                        onChange={this.props.onChangeField('collectionOrdering')}
                                        disabled={this.props.isLoading}
                                        value={
                                            this.props.data &&
                                            this.props.data.get('collectionOrdering') &&
                                            this.props.data.get('collectionOrdering').toString()
                                        }
                                        options={orderOptions}
                                        defaultOption="components.CollectionOrderBlock.inputLabelValueCollectionOrder.placeholder"
                                        full
                                    />
                                </FlexItemContainer>
                                <FlexItemContainer direction="column" justifyContent="center">
                                    <LabelSubtle>
                                        <FormattedMessage id="components.CollectionOrderBlock.inputLabelValueCollectionOrder.notice" />
                                    </LabelSubtle>
                                </FlexItemContainer>
                            </FlexContainer>
                        </FormGroup>
                    </FlexItemContainer>
                </FlexContainer>
            </React.Fragment>
        );
    }
}

export default CollectionOrderBlock;
