// @flow

import styled from 'styled-components';
import colors from 'styles/colors';

const LogoutContainer = styled.div`
    padding: 0 30px 10px 0;
`;

export default LogoutContainer;
