// @flow

import styled from 'styled-components';

import colors from 'styles/colors';

export const SideNavLayoutContainer = styled.div`
    min-height: 100vh;
    width: 100vw;
    min-width: 1280px;
    display: flex;
`;

export const SideNavContainer = styled.div`
    width: 200px;
    min-width: 200px;
    display: flex;
    flex-flow: column nowrap;
    background-color: ${colors.gray15};
    color: ${colors.grayE1};
    line-height: 17px;

    a {
        text-decoration: none;
        color: ${colors.grayE1};
        line-height: 17px;
    }
`;

export const ContentContainer = styled.div`
    display: inline-block;
    height: 100%;
    width: 100%;
    min-width: 1080px;
`;

export const NavItemContainer = styled.div`
    height: 40px;
    line-height: 40px;
    width: 100%;
    padding-left: 20px;
`;

export const NavItemWithActionContainer = NavItemContainer.extend`
    ${(props) =>
        props.selected
            ? `
                border-left: 3px solid ${colors.teal};
                padding-left: 17px;
            `
            : ''};
`;

export const LinkGroupContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;

    background-color: ${colors.black};

    a {
        padding-left: 15px;
    }
`;

export const GroupName = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    img {
        margin: auto 15px auto 0;
        height: 20px;
        width: 20px;
        ${(props: Object): string => (props.itemsVisible ? 'transform: scaleY(-1)' : '')};
    }
`;
