// @flow

import styled from 'styled-components';

import colors from 'styles/colors';

export const BasicLayoutContainer = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-flow: column nowrap;
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100%;
    max-width: 1240px;
    margin: 180px auto 0;
`;
